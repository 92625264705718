<div [ngClass]="{ 'onRoot': onRootRoute() }" class="d appSignInOutContainer" *ngIf="!isLoggedIn()&&ready()">
  <p>
    <!-- <a class="linkAlt appSignInOut" (click)="login()">Sign In</a> -->
    <!-- Temp disable -->
    <a *ngIf="onRootRoute()" class="linkAlt appSignInOut" (click)="login()">Sign In</a>
    <a *ngIf="!onRootRoute()" class="linkAlt appSignInOut" (click)="routeToHome()">Sign Out</a>
  </p>
</div>

<div [ngClass]="{ 'onRoot': onRootRoute() }" class="d appSignInOutContainer" *ngIf="isLoggedIn()&&ready()">
  <p class="welcomeText">Welcome,
    {{getUsername()}}! <a class="linkAlt appSignInOut" routerLink="/logout">Sign Out</a>
  </p>
</div>
