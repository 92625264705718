<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-content>
        <div>
          <p><span class="appFieldLabel">Total Planned Funding: </span> <span class="appFieldContent">{{ projectFunding.plannedAmountTotal | currency }}</span></p>
          <p><span class="appFieldLabel">Total Actual Funding: </span> <span class="appFieldContent">{{ projectFunding.actualAmountTotal | currency }}</span></p>
          <p><span class="appFieldLabel">Total Planned Match: </span> <span class="appFieldContent">{{ projectFunding.plannedAmountAsMatchTotal | currency }}</span></p>
          <p><span class="appFieldLabel">Total Actual Match: </span> <span class="appFieldContent">{{ projectFunding.actualAmountAsMatchTotal | currency }}</span></p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
