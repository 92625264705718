import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() link: any;

  @Output() linkToRemove: EventEmitter<any> = new EventEmitter();

  projectLeadOrg: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.link);
  }

  removeLink(_: any) {
    this.linkToRemove.emit(this.link);
  }

  getLinkUrl() {
    if (this.link?.linkUrl.startsWith('http')) {
      return this.link.linkUrl;
    } else {
      return '//' + this.link.linkUrl;
    }
  }

}
