import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from 'src/app/_shared/models/dynamic-forms/field-base';
import { DynamicDropdownField } from 'src/app/_shared/models/dynamic-forms/field-dynamic-dropdown';
import { FieldControlService } from 'src/app/_shared/services/forms/field-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldBase<any>[] = [];
  @Input() disabled = false;
  @Input() type = 'create';
  @Input() activeEdit = true;
  @Input() submitButtonText = 'Create';
  @Input() featureNotEditable = false;
  @Input() showSubmissionButtons = false;
  @Input() largeLabel = false;

  @Output() newFormSubmission = new EventEmitter<any>();

  form: FormGroup;
  // payload: any = null;
  get payload() {
    return this.form.value;
  }

  isValid() {
    return this.form.valid;
  }

  constructor(
    private fieldControlService: FieldControlService
  ) { }

  ngOnInit(): void {
    this.form = this.fieldControlService.toFormGroup(this.fields);

    if (this.disabled) {
      this.form.disable();
    }
  }

  onSubmit() {
    this.newFormSubmission.emit(this.payload)
  }

  getPayload() {
    return this.payload;
  }

  clickEdit() {
    console.log('EDIT');
  }

  clickCancel() {
    console.log('CANCEL');
  }

  refreshForm(data: any[]) {
    this.form = this.fieldControlService.toFormGroup(data);
  }

  public updateDynamicOptions(data) {
    const foundField = this.fields.find(item => {
      return item.key === data.key;
    });
    if (foundField) {
      const dropDownField = foundField as DynamicDropdownField;
      dropDownField.options = data.data;
    }
  }

  public newOrgValue(data) {
    const foundFc = this.form.get(data.key);
    const foundField = this.fields.find(item => {
      return item.key === data.key;
    }) as DynamicDropdownField;
    if (foundField && !foundField?.value) {
      const foundOption = foundField.options.find(item => {
        return item.key === data.data;
      });

      if (foundFc && foundOption) {
        foundFc.setValue(foundOption.value);
      }
    }
  }

}
