import { Component, Input, OnInit } from '@angular/core';
import { GeoStatConstants, GeoStatRecordVm, GeoStatVm } from '../geo-stats.model';

@Component({
  selector: 'app-geo-slot',
  templateUrl: './geo-slot.component.html',
  styleUrls: ['./geo-slot.component.scss']
})
export class GeoSlotComponent implements OnInit {
  @Input() statRow: GeoStatRecordVm;
  @Input() hasStats: boolean;
  @Input() isLastElement = false;
  @Input() isFocal = false;

  constructor() { }

  ngOnInit(): void {
  }

  getStringStat(): string {
    let baseString = '';
    const separator = ', ';

    this.statRow.stat.sort((a, b) => {
      const aString = a.acres as string;
      const bString = b.acres as string;
      const aVal = parseFloat(aString.replace(',', '')) as number;
      const bVal = parseFloat(bString.replace(',', '')) as number;
      return bVal - aVal;
    });

    this.statRow.stat.forEach((element, idx) => {
      if (this.isFocal) {
        if (element.title !== GeoStatConstants.NonFocalArea) {
          baseString = `${baseString}<span class="appFocalLink"><a href="#/app/focal/view?name=${element.title}">${element.title} (${element.acres} acres)</a>${(idx === this.statRow.stat.length - 1 && (baseString.length > 1 + separator.length)) ? '' : separator}</span>`;
        } else {
          baseString = `${baseString}<span class="appFocalLink">${element.title} (${element.acres} acres)${(idx === this.statRow.stat.length - 1 && (baseString.length > 1 + separator.length)) ? '' : separator}</span>`;
        }
      } else {
        baseString = `${baseString}${element.title} (${element.acres} acres)${separator}`;
      }

      // Check if basestring is not empty and this is the last iteration and remove the last separator
      if (!this.isFocal) {
        if (idx === this.statRow.stat.length - 1 && (baseString.length > 1 + separator.length)) {
          baseString = baseString.substring(0, baseString.length - separator.length);
        };
      }
    });

    // Check for an empty string - assume no stats returned
    if (baseString === '') {
      baseString = 'No intersection';
    }

    return baseString;
  }

}
