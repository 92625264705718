<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        This user is in the Project Editor group (role). If they are no longer associated to any Organization, the Organization's Administrator must first remove them from this group (role) before removing their Organization affiliation. Alternatively, you can remove them from this group through the Cognito console.
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions style="width: 100%;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
    <button mat-flat-button color="warn" class="appSquareButton appConfirmButton appSkinnyFontButton" matDialogClose style="width: 12rem;">Close</button>
  </div>
</div>
