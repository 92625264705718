<div class="appEditIconContainer">
  <app-edit-button-cluster [editModeOn]="true" [formsAreValid]="!isInActiveEdit" (saveClicked)="this.saveClicked()"
    (cancelClicked)="this.cancelClicked()" [confirmName]="'Save Drawing'" [cancelName]="'Cancel Drawing'">
  </app-edit-button-cluster>
</div>
<div id="appEditMapContainer">
  <app-slim-map #modalSlimMap [showEditCreateButtons]="true" (editModeChanged)="checkEditMode($event)"
    (shapeUpdated)="newShapeData($event)" [mainMapId]="'editModalMap'" [fillColor]="this.fillColor"
    [strokeColor]="this.strokeColor">
  </app-slim-map>
</div>
