import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../_shared/services/map/map.service';
import { FixedLengthArray } from '../_shared/types/fixed-length-array';
import { Subscription, Observable, Subject } from 'rxjs';
import { MapCategory, MapLayer } from '../_shared/models/map/config.model';
import { PlugmapAPIService } from '../_shared/services/map/plugmap-api.service';
import { ConfiguredIdentifySources, Result } from '../_shared/services/map/identify.service';
import { Candidates } from '../_shared/models/map/geo-search.model';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component'
import { ApiService } from '../_shared/services/api.service';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component'
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { Import, ImportState } from './import';
import { SidebarContentComponent } from './sidebar/sidebar-content/sidebar-content.component'
import { promptAndProcessShapefile } from '../../../node_modules/geometry-web-worker'
import { CoreExplorerLayers } from './sidebar/layer-explorer-container/layer-explorer.model';
import { MatDrawer } from '@angular/material/sidenav';



declare var ol: any;
declare var famMap: any;
declare var window: any;
declare var ResizeObserver: any;



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  constructor(
    private mapService: MapService,
    private mapAPI: PlugmapAPIService,
    private dialog: MatDialog,
    private api: ApiService,
    private identify: ConfiguredIdentifySources,
    private route: ActivatedRoute,
    private auth: AuthorizationService
  ) { }

  import: Import = new Import(this.identify);

  @Input() mapCenter: FixedLengthArray<[number, number]> = [-106.1895148695699, 34.51098276276389]; // Default to centroid of NM

  @Input() defaultZoom: number = 6;

  @Input() maxZoom: number = 22;

  @Input() minZoom: number = 3;

  @Input() defaultExtent: FixedLengthArray<[number, number, number, number]> = [-12636125.720420806, 3533818.2337872875, -10874868.852807505, 4597910.92463699];
  //This was hacked by doing the following
  //Reduce window width to close that of the aspect ratio of NM
  //Make NM fit centered in the map
  //Run this in the console
  //famMap.getMap().getView().calculateExtent()

  public drawerOpened: boolean;

  private mapConfigSub: Subscription;

  private mapDrawSub: Subscription;

  private mapCenterSub: Subscription;
  private mapExtentSub: Subscription;
  private mapZoomSub: Subscription;

  private mapConfigLoaded = false;

  private assessmentEditOverlay: any;
  private importOverlay: any;

  mapCategories: MapCategory[];

  assessmentOverlayContainerHeight: number;
  importOverlayContainerHeight: number;

  drawnShape: any;

  sideDrawer: HTMLElement;

  drawLayer: any;

  currentMapCenter: any;
  currentMapExtent: any;
  currentMapZoom: any;


  highlightLayer: any;
  refLayer: any;

  ImportState = ImportState;


  featureType: string;

  importEnabled: boolean = false;

  resizeObserver: any;

  @ViewChild('sidebar') sidebar: SidebarContentComponent;
  @ViewChild('drawer') drawer: MatDrawer;

  ngOnInit(): void {
    this.bootMap();
    this.mapConfigSub = this.mapService.getMapConfig().subscribe(config => {
      if (config && !this.mapConfigLoaded) {
        // On first map theme load
        this.mapCategories = config;
        this.mapConfigLoaded = true;
        this.mapAPI.setCategories(config);
        config.forEach(cat => {
          cat.layers.forEach(layer => {
            if (layer.identify && cat.selection.isSelected(layer.key)) {
              this.identifyResults.resultMap[layer.key] = { status: 'Idle', results: [] };
            }
          })
        })

      } else if (config) {
        // Add any routines that need to listen for other theme changes
      }
    });
    this.sideDrawer = document.getElementsByName("map-drawer")[0];

    this.mapCenterSub = this.mapAPI.getMapCenter().subscribe(newCenter => {
      this.currentMapCenter = newCenter;
    });
    this.mapExtentSub = this.mapAPI.getMapExtent().subscribe(newExtent => {
      this.currentMapExtent = newExtent;
      this.invalidateIdentifyResults();
    });

    this.mapZoomSub = this.mapAPI.getMapZoom().subscribe(newZoom => {
      console.log("New zoom", newZoom)
      this.currentMapZoom = newZoom;
    });

    window.searchThisArea = this.searchThisArea.bind(this);

    this.route.queryParams.subscribe(params => {
      let id = params["id"];
      let type = params["type"];
      let name = params["name"];

      if (id == undefined && name == undefined)
        return;

      let iid = id ? parseInt(id) : 0;

      const saveRef = this.dialog.open(LoadingDialogComponent, {
        width: '25rem',
        data: "Loading Selected Feature",
        disableClose: true
      });

      if (type === 'p') {
        this.api.post<any>("Project", "Get", [iid]).subscribe(results => {
          if (results && results.length) {
            let target = results[0];
            if (target.fields && target.fields.Geometry) {
              let obj = { wkt: target.fields.Geometry.wkt, left: 0, bottom: 0 }
              setTimeout(() => { this.mapAPI.fitToWKT(obj) }, 1500);
              setTimeout(() => { this.setIdentifyResults(iid, CoreExplorerLayers.ProjectKey) }, 1500);
              setTimeout(() => { this.addGeomHighlightFromWkt(target.fields.Geometry.wkt) }, 3000);
            }
          }
          saveRef.close();
        })
      } else if (type === 'f') {
        this.api.post<any>("Focal", "Get", [iid]).subscribe(results => {
          if (results && results.result) {
            let target = results.result;
            if (target.fields && target.fields.Geometry) {
              let obj = { wkt: target.fields.Geometry.wkt, left: 0, bottom: 0 }
              setTimeout(() => { this.mapAPI.fitToWKT(obj) }, 1500);
              setTimeout(() => { this.setIdentifyResults(iid, CoreExplorerLayers.FocalKey) }, 1500);
              setTimeout(() => { this.addGeomHighlightFromWkt(target.fields.Geometry.wkt) }, 3000);
            }
          }
          saveRef.close();
        })
      } 
      else if (type === 'a') {
        this.api.post<any>("Activity", "LongRead", iid).subscribe(results => {
          if (results && results.valid) {
            let target = results.result;
            if (target.geometry) {
              let obj = { wkt: target.geometry.wkt, left: 0, bottom: 0 }
              setTimeout(() => { this.mapAPI.fitToWKT(obj) }, 1500);
              setTimeout(() => { this.setIdentifyResults(iid, CoreExplorerLayers.ActivityKey) }, 1500);
              setTimeout(() => { this.addGeomHighlightFromWkt(target.geometry.wkt) }, 3000);
            }
          }
          saveRef.close();
        })
      } else if (type === 'l') {
        this.getExtentAndZoomWithDialog(name, CoreExplorerLayers.PrioLandKey, saveRef);
      } else if (type === 'w') {
        this.getExtentAndZoomWithDialog(name, CoreExplorerLayers.WatershedKey, saveRef);
      } else {
        saveRef.close();
      }
    });

    this.import = new Import(this.identify);

    this.import.onResult().subscribe(() => {
      this.updateImportFocus();
    })

    var that = this;

    this.resizeObserver = new ResizeObserver(() => { that.drawerAnimate() });
    this.resizeObserver.observe(document.getElementsByTagName("mat-drawer-content")[0]);
  }

  ngOnDestroy() {
    this.mapAPI.destroyMap();
    this.mapConfigSub.unsubscribe();
    this.mapCenterSub.unsubscribe();
    this.mapExtentSub.unsubscribe();
    if (this.mapDrawSub)
      this.mapDrawSub.unsubscribe();
    this.resizeObserver.unobserve(document.getElementsByTagName("mat-drawer-content")[0]);
  }

  async getExtentAndZoomWithDialog(name, key, saveRef) {
    const extent = await this.identify.requestFeatureExtent(key, name);
    const featureGeom = await this.identify.requestFeatureGeom(key, name);
    setTimeout(() => {
      this.zoomToExtent(extent, true);
      this.selectLayer({ key });
      this.tempHighlight(featureGeom);
      saveRef.close();
    }, 2000);
  }

  setIdentifyResults(iid, layerKey?: string) {
    this.identify.FromQuery(`objectid=${iid}`, false, true).subscribe(res => {
      this.identifyResults.hasResults = true;
      this.identifyResults.resultMap[res.layer] = { status: 'Ready', results: res.results, error: res.error };
      this.identifyResults.reverseMap[res.layer] = {};

      if (res.results && res.results.length > 0) {
        let chunk = res.results.length > 750 ? Math.ceil(res.results.length / 750) : 2;
        let delay = 750 / (res.results.length / chunk);

        res.results.forEach(result => {
          this.identifyResults.reverseMap[res.layer][result.id] = result;
        })

        if (layerKey) {
          const newObj = {};
          newObj[layerKey] = { ...this.identifyResults.resultMap[layerKey] }
          this.identifyResults.resultMap = { ...newObj} ;
        }

        this.identify.newIdentifyFromQuery.next();
      }

    });
  }

  addGeomHighlightFromWkt(wkt) {
    const format = new ol.format.WKT()
    const feature = format.readFeatures(wkt, {
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857',
    });
    this.clearHighlight();
    this.highlightLayer.getSource().addFeatures(feature);
  }

  selectLayer(layer: MapLayer) {

    if (layer.identify)
      if (!this.identifyResults.resultMap[layer.key])
        this.identifyResults.resultMap[layer.key] = { status: 'Idle', results: [] };

    this.mapAPI.turnLayerOnByKey(layer.key);
  }

  deselectLayer(layer: MapLayer) {

    console.log(layer);
    console.log(this.identifyResults.resultMap)

    if (layer.identify)
      if (this.identifyResults.resultMap[layer.key])
        delete this.identifyResults.resultMap[layer.key];

    console.log(this.identifyResults.resultMap);


    this.mapAPI.turnLayerOffByKey(layer.key);
  }

  opacityChange(event: any) {
    this.mapAPI.setCategoryTransparencyByKey(event.key, event.opacity);
  }

  //This boots the plugmap on the element with the id mapmap
  bootMap(): void {

    this.mapAPI.createMap("mapmap", this.mapCenter, this.defaultZoom, this.maxZoom, this.minZoom);
    setTimeout(() => {
      famMap.getMap().handleSizeChanged_()
    }, 1500);

    this.installExtensions();
  }

  private installExtensions(): void {
    let map = famMap.getMap();

    let zte = new ol.control.ZoomToExtent({
      extent: this.defaultExtent,
    });

    zte.handleClickOld = zte.handleClick_;
    let that = this;

    zte.handleZoomToExtent = function () {
      let sd = document.getElementsByName("map-drawer");
      let mp = this.getMap();
      let vw = mp.getView();

      let pudding = [0, 0, 0, 0];
      if (sd.length)
        pudding[3] += sd[0].offsetWidth;

      vw.fit(that.defaultExtent, {
        size: mp.getSize(),
        duration: 1000,
        padding: pudding
      });
    }

    let assessmentEditOverlayContainer = document.getElementById('assessmentEditOverlay');
    this.assessmentOverlayContainerHeight = assessmentEditOverlayContainer.offsetHeight;

    let importOverlayContainer = document.getElementById('importOverlay');

    this.assessmentEditOverlay = new ol.Overlay({
      element: assessmentEditOverlayContainer,
      positioning: 'bottom-center',
      autoPan: {
        margin: 20
      }
    });

    this.importOverlay = new ol.Overlay({
      element: importOverlayContainer,
      positioning: 'bottom-center',
      autoPan: {
        margin: 20
      }
    });



    let sl = new ol.control.ScaleLine({ units: 'us' });

    setTimeout(() => { zte.handleClick_({ preventDefault: function () { } }) }, 1500);
    map.controls.extend([zte, sl]);
    map.addOverlay(this.assessmentEditOverlay);
    map.addOverlay(this.importOverlay);
    window.zte = zte;

    map.on('singleclick', (evt) => {
      this.handleClick(evt);
    })


    var IdentifyControl = /*@__PURE__*/(function (Control) {
      function IdentifyControl(opt_options) {
        var options = opt_options || {};

        var button = document.getElementById("idBtn");

        if (!button) {
          button = document.createElement('button');
          button.innerHTML = '<img src="https://timmons-branding.s3.amazonaws.com/Shared+Icons/73+-+Select+Icon.svg" matTooltip="Features from this layer can be imported into a new Area of Interest." style="width: 1em; height: 1em; margin-left: auto; margin-right: 0;" aria-label="Features from this layer can be imported into a new Area of Interest." [appRequireACL]="\'Map:ClickToImport\'"/>';
        }

        var element = document.createElement('div');
        element.className = 'identify-area ol-unselectable ol-control';
        element.appendChild(button);

        Control.call(this, {
          element: element,
          target: options.target,
        });

        button.addEventListener('click', this.handleRotateNorth.bind(this), false);
      }

      if (Control) IdentifyControl.__proto__ = Control;
      IdentifyControl.prototype = Object.create(Control && Control.prototype);
      IdentifyControl.prototype.constructor = IdentifyControl;

      IdentifyControl.prototype.handleRotateNorth = function handleRotateNorth() {
        this.getMap().getView().setRotation(0);
      };


      return IdentifyControl;
    }(ol.control.Control));

    this.setupIdentifyHighlightLayer();



  }


  unHighlightIdentifyResults() {
    this.unhighlightTemp();
    this.identifyResults.highlightedItems.forEach(result => {
      result.highlight = false;
      result.raw.forEach(f => {
        f.set('highlight', false);
      })
    });
  }

  handleIdentifyClick(evt: any) {
    if (this.sidebar) {
      this.sidebar.openIdentify();
      this.drawer.open();
    }

    this.unHighlightIdentifyResults();

    this.searchThisAreaByPoint(evt.coordinate);

    this.identifyResults.highlightedItems = [];

    this.identifyHighlightLayer.getSource().forEachFeatureAtCoordinateDirect(evt.coordinate, (f) => {
      let layer = f.get('layer');
      let id = f.get('tfid');

      if (layer) {
        let r = this.identifyResults.reverseMap[layer][id];
        if (r) {
          r.highlight = true;
          r.raw.forEach(f => {
            f.set('highlight', true);
          })
          this.identifyResults.highlightedItems.push(r);

          this.sidebar.scrollToResult(layer, id);
        }
        else {
          console.log("Cannot find " + layer + " " + id, this.identifyResults.reverseMap[layer])
          console.log(f)
        }
      }
    })
  }

  handleClickToImportClick(evt: any) {
    if (!this.auth.getLogin().acls.includes("Map:ClickToImport") || !this.importEnabled)
      return;

    this.clearHighlight();

    this.import.search(evt.coordinate);
    this.setImportOverlayPositionAndContents(evt.coordinate)
  }

  handleClick(evt: any) {
    this.handleClickToImportClick(evt);
    this.handleIdentifyClick(evt);
  }

  clearHighlight() {
    if (!this.highlightLayer)
      this.setupHighlightLayer();

    let src = this.highlightLayer.getSource()
    src.clear();
  }

  updateImportFocus() {

    this.clearHighlight();


    if (this.import.getState() == ImportState.NoResults)
      return;


    let r = this.import.getFocusedResult();

    var f = r.raw;
    let g = f[0].getGeometry();
    let ext = ol.extent.createEmpty();

    for (let feat of f) {
      ol.extent.extend(ext, feat.getGeometry().getExtent());
    }

    let center = [(ext[0] + ext[2]) / 2, ext[3]];

    this.highlightLayer.getSource().addFeatures(f);

    this.setImportOverlayPositionAndContents(center)
  }

  importPreviousResult() {
    this.import.previousResult();
    this.updateImportFocus();
  }

  importNextResult() {
    this.import.nextResult();
    this.updateImportFocus();
  }

  setupHighlightLayer() {
    this.highlightLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    this.highlightLayer.setSource(src);

    let sty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: "rgba(0,0,0,0.15)"
      }),
      stroke: new ol.style.Stroke({
        color: "rgba(255,255,255,1.0)",
        width: 4
      })
    });
    this.highlightLayer.setStyle(sty);

    this.highlightLayer.setZIndex(9999);

    famMap.getMap().addLayer(this.highlightLayer);
  }

  zoomToCandidates(candidates: Candidates) {
    this.mapAPI.zoomToCandidates(candidates);
  }

  startImport() {
    this.importEnabled = true;
  }

  populateDrawLayer() {

    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    }

    if (!ml)
      console.error('Issue occured in map.'); //We shouldnt get here

    this.drawLayer = ml;
  }

  setImportOverlayPositionAndContents(center: number[]) {

    let overlay = this.importOverlay;

    //Set the position
    setTimeout(() => { overlay.setPosition(center); }, 250);

    //Populate contents
    let container = document.getElementById("importOverlay");

    //Only append if its not already there
    if (container.children.length == 0) {
      let cnt = document.getElementById("importOverlayContents");
      container.appendChild(cnt);
    }
  }

  importShapeFile() {
    promptAndProcessShapefile().then(features => {
      //Features is an array of features
      this.import.setState(ImportState.Searching);
      var tasks = [];

      const saveRef = this.dialog.open(LoadingDialogComponent, {
        width: '25rem',
        data: "Loading Shapefile",
        disableClose: true
      });
      var featuresImported = features.length;
      this.mapAPI.setImportCount(0);
      this.mapAPI.setImporting(true);
      features.forEach(feat => {
        let draw = this.mapAPI.setFeatsGeoJSON(feat);

        if (this.mapDrawSub)
          this.mapDrawSub.unsubscribe();

        this.mapDrawSub = draw.subscribe(this.handleImportResult.bind(this));
      }
      )

      var id = setInterval(() => {
        if (!(this.mapAPI.getImportCount() < featuresImported)) {
          // Run this once to center and zoom to newly imported shapes
          let map = famMap.getMap();
          let mapSize = map.getSize();
          this.grabSnapshotOfDrawnShape(mapSize).subscribe();

          // If only a few features were imported then zoom out a little bit so the save popup is not cutoff to the side
          if (featuresImported < 5) {
            let vw = map.getView();
            vw.setZoom(vw.getZoom() - 2);
          }

          this.dismissImport();
          this.mapAPI.setImporting(false);
          clearInterval(id);
          saveRef.close();
        }
      }, 1000);

    })
      .catch((err) => {
        console.log(err);
      });

  }

  grabSnapshotOfDrawnShape(targetSize: number[]): Observable<any> {
    // Show a loading screen when we are getting a screenshot
    const snapshotRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Generating Snapshot",
      disableClose: true
    });

    var ds = new Subject<any>();

    let map = famMap.getMap();
    let origSize = map.getSize();
    let that = this;

    map.setSize(targetSize);

    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    };

    let pudding = [20, 20, 20, 20];
    let ext = [];

    try {
      ext = ml.getSource().getFeatureById(this.drawnShape.olUID).getGeometry().getExtent();
    }
    catch (ex) {
      snapshotRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["Error Generating Snapshot", "An error occured. Please try again. If this happens again, modify the shape slightly and click save."],
        disableClose: true
      });
      throw ex;
    }

    let vw = map.getView();
    vw.fit(ext, {
      size: targetSize,
      nearest: false,
      padding: pudding
    });

    map.once('rendercomplete', function () {
      var mapCanvas = document.createElement('canvas');

      mapCanvas.width = targetSize[0];
      mapCanvas.height = targetSize[1];
      var mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );

      map.setSize(origSize);

      snapshotRef.close();

      setTimeout(() => { ds.next(mapCanvas.toDataURL()); }, 100);
    });

    // Again there is some weirdness with resizing the map, so manually call renderSync after a delay of like 500ms or so
    setTimeout(() => { map.renderSync(); }, 500);

    return ds.asObservable();
  }

  setOverlayPositionAndContents(center: number[]) {

    let overlay = this.assessmentEditOverlay;

    // Set the position
    setTimeout(() => { overlay.setPosition(center); }, 250);

    // Populate contents
    let container = document.getElementById("assessmentEditOverlay");

    // Only append if its not already there
    if (container.children.length == 0) {
      let cnt = document.getElementById("assessmentEditOverlayContents");
      container.appendChild(cnt);
    }
  }

  fitToExtentAndOverlay(ext: number[]) {
    let mp = famMap.getMap();

    let vw = mp.getView();

    // Top, right, bottom and left
    let pudding = [100, 10, 10, 10];

    if (this.sideDrawer)
      pudding[3] += this.sideDrawer.offsetWidth;


    if (this.assessmentOverlayContainerHeight) {
      pudding[0] += this.assessmentOverlayContainerHeight;
    }

    //Theres some strange interaction between the drawing and overlay that if we do this immediatly it gets a bit wonky. Hackypatch is to wait a short time to do the fit
    // setTimeout(() => {
    //   vw.fit(ext, {
    //     size: mp.getSize(),
    //     duration: 250,
    //     nearest: false,
    //     padding: pudding,

    //   });
    // }, 500);
  }

  handleImportResult(newShape) {
    //The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;

    this.drawnShape = newShape;


    this.populateDrawLayer();

    if (!this.drawLayer) return; //Something bork

    let feat = this.drawLayer.getSource().getFeatureById(newShape.olUID);

    if (!feat)
      //Something bad happened
      feat = this.drawLayer.getSource().getFeatures()[0];

    if (!feat) {
      return;
    }

    let ext = feat.getGeometry().getExtent();
    let center = ol.extent.getCenter(ext);

    center = [(ext[0] + ext[2]) / 2, ext[3]]

    this.setOverlayPositionAndContents(center);

    if (this.mapDrawSub)
      this.mapDrawSub.unsubscribe();
    this.mapDrawSub =
      this.mapAPI.getShapeChange().subscribe(this.handleDrawResult.bind(this));

  }

  handleDrawResult(newShape) {
    // The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;

    this.drawnShape = newShape;

    this.featureType = "Custom"

    this.populateDrawLayer();

    if (!this.drawLayer) return; // Something bork

    let feat = this.drawLayer.getSource().getFeatureById(newShape.olUID);

    if (!feat)
      // Something bad happened
      feat = this.drawLayer.getSource().getFeatures()[0];

    if (!feat) {
      return;
    }

    let ext = feat.getGeometry().getExtent();
    let center = ol.extent.getCenter(ext);

    center = [(ext[0] + ext[2]) / 2, ext[3] + 50]

    this.setOverlayPositionAndContents(center);
  }

  showErrors(errors: string[]) {
    this.dialog.open(ErrorDialogComponent, {
      width: '25rem',
      data: errors
    });
  }

  dismissAssessmentEdit() {
    this.stopDraw();
  }

  dismissImport() {
    this.importEnabled = false;
    this.import.clear();
    this.importOverlay.setPosition(undefined);
    if (this.highlightLayer)
      this.highlightLayer.getSource().clear();
  }

  isDrawing(): boolean {
    return this.mapAPI.isDrawing();
  }

  hasArea(): boolean {
    return this.mapAPI.hasShape();
  }

  stopDraw(): void {
    this.mapAPI.stopDrawing();
    this.drawnShape = undefined;
    this.assessmentEditOverlay.setPosition(undefined);
  }

  refreshMap(): void {
    this.mapAPI.refreshMap();
  }

  clearPoints() {
    this.mapAPI.clearPoints();
  }

  identifyResults: any = {
    hasResults: false,
    extentChanged: false,
    resultMap: {},
    reverseMap: {},
    highlightedItems: [],
    extent: [],
    currentExtent: []
  };


  invalidateIdentifyResults() {
    if (!this.identifyResults.hasResults)
      return;

    var mp = famMap.getMap();
    var vw = mp.getView();
    this.identifyResults.currentExtent = vw.calculateExtent();

    var xMinMatch = Math.floor(this.identifyResults.currentExtent[0] / 100) == Math.floor(this.identifyResults.extent[0] / 100)
    var xMaxMatch = Math.floor(this.identifyResults.currentExtent[1] / 100) == Math.floor(this.identifyResults.extent[1] / 100)
    var yMinMatch = Math.floor(this.identifyResults.currentExtent[2] / 100) == Math.floor(this.identifyResults.extent[2] / 100)
    var yMaxMatch = Math.floor(this.identifyResults.currentExtent[3] / 100) == Math.floor(this.identifyResults.extent[3] / 100)

    this.identifyResults.extentChanged = !(xMinMatch && xMaxMatch && yMinMatch && yMaxMatch)

  }

  returnToIdentifyExtent() {
    var mp = famMap.getMap();
    var vw = mp.getView();
    vw.fit(this.identifyResults.extent, {
      size: mp.getSize(),
      duration: 1000,
      padding: [0, 0, 0, 0]
    });
  }

  searchThisArea() {
    if (this.sidebar)
      this.sidebar.openIdentify();

    var mp = famMap.getMap();
    var vw = mp.getView();
    this.identifyResults =
    {
      hasResults: false,
      extentChanged: false,
      resultMap: {},
      reverseMap: {},
      highlightedItems: [],
      extent: [],
      currentExtent: []
    };


    this.identifyResults.extent = vw.calculateExtent();


    let ext = vw.calculateExtent();
    this.clearIdentifyHighlight();

    if (this.auth.getLogin().acls.includes("Map:DebugIdentify")) {
      let polygon = ol.geom.Polygon.fromExtent(ext);
      let feature = new ol.Feature(polygon);
      let src = this.identifyDebugLayer.getSource();
      src.clear();
      src.addFeatures([feature]);
    }

    let src = this.identifyHighlightLayer.getSource();
    src.clear();


    this.mapCategories.forEach(cat => {
      cat.layers.forEach(layer => {
        if (layer.identify && layer.identify.enabled && cat.selection.isSelected(layer.key))
          this.identifyResults.resultMap[layer.key] = { status: 'Searching', results: [] };
      })
    })

    this.identify.FromExtent(ext, true).subscribe(res => {
      this.identifyResults.hasResults = true;
      this.identifyResults.resultMap[res.layer] = { status: 'Ready', results: res.results, error: res.error };
      this.identifyResults.reverseMap[res.layer] = {};

      if (res.results && res.results.length > 0) {
        let chunk = res.results.length > 750 ? Math.ceil(res.results.length / 750) : 2;
        let delay = 750 / (res.results.length / chunk);

        res.results.forEach(result => {
          src.addFeatures(result.raw);
          this.identifyResults.reverseMap[res.layer][result.id] = result;
        })
      }

    })

  }

  searchThisAreaByPoint(point) {
    if (this.sidebar)
      this.sidebar.openIdentify();

    var mp = famMap.getMap();
    var vw = mp.getView();
    this.identifyResults =
    {
      hasResults: false,
      extentChanged: false,
      resultMap: {},
      reverseMap: {},
      highlightedItems: [],
      extent: [],
      currentExtent: []
    };


    this.identifyResults.extent = vw.calculateExtent();


    let ext = vw.calculateExtent();
    this.clearIdentifyHighlight();

    let src = this.identifyHighlightLayer.getSource();
    src.clear();


    this.mapCategories.forEach(cat => {
      cat.layers.forEach(layer => {
        if (layer.identify && layer.identify.enabled && cat.selection.isSelected(layer.key))
          this.identifyResults.resultMap[layer.key] = { status: 'Searching', results: [] };
      })
    })

    this.identify.FromPoint(point, false, true).subscribe(res => {
      this.identifyResults.hasResults = true;
      this.identifyResults.resultMap[res.layer] = { status: 'Ready', results: res.results, error: res.error };
      this.identifyResults.reverseMap[res.layer] = {};

      if (res.results && res.results.length > 0) {
        let chunk = res.results.length > 750 ? Math.ceil(res.results.length / 750) : 2;
        let delay = 750 / (res.results.length / chunk);

        res.results.forEach(result => {
          src.addFeatures(result.raw);
          this.identifyResults.reverseMap[res.layer][result.id] = result;
        })
      }

    })

  }

  identifyHighlightLayer: any;
  identifyDebugLayer: any;

  clearIdentifyHighlight() {
    if (!this.identifyHighlightLayer)
      this.setupIdentifyHighlightLayer();

    let src = this.identifyHighlightLayer.getSource()
  }

  setupIdentifyHighlightLayer() {
    if (this.identifyHighlightLayer)
      return;
    this.identifyHighlightLayer = new ol.layer.Vector();
    this.identifyDebugLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    let debugSrc = new ol.source.Vector();

    this.identifyHighlightLayer.setSource(src);
    this.identifyDebugLayer.setSource(debugSrc);

    let sty = function (feature) {
      if (feature.get('highlight'))
        return new ol.style.Style({
          fill: new ol.style.Fill({
            color: "rgba(0,0,0,0.15)"
          }),
          stroke: new ol.style.Stroke({
            color: "rgba(255,255,255,1.0)",
            width: 4
          })
        });
    }

    let debugSty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: "rgba(255,255,255,0.05)"
      }),
      stroke: new ol.style.Stroke({
        color: "rgba(255,255,255,0.75)",
        width: 4
      })
    });

    this.identifyHighlightLayer.setStyle(sty);
    this.identifyDebugLayer.setStyle(debugSty);

    this.identifyHighlightLayer.setZIndex(9999);
    this.identifyDebugLayer.setZIndex(9998);

    famMap.getMap().addLayer(this.identifyHighlightLayer);
    famMap.getMap().addLayer(this.identifyDebugLayer);

    window.layerMap["INTERNAL_IDENTIFY_HIGHLIGHT"] = this.identifyHighlightLayer;
    window.layerMap["INTERNAL_IDENTIFY_DEBUG"] = this.identifyDebugLayer;

  }


  highlightFeature(result: Result) {
    this.unhighlightTemp();
    console.log("Highlighting", result);

    if (this.highlightLayer) {
      const src = this.highlightLayer.getSource();
      src.clear();
    }
    
    this.unHighlightIdentifyResults();

    if (!this.identifyHighlightLayer)
      this.setupIdentifyHighlightLayer();
    result.highlight = true;

    result.raw.forEach(res => {
      res.set('highlight', true);
    })
  }

  unhighlightFeature(result: Result) {
    this.unhighlightTemp();
    if (this.highlightLayer) {
      const src = this.highlightLayer.getSource();
      src.clear();
    }
    this.clearIdentifyHighlight();
    result.highlight = false;
    result.raw.forEach(res => {
      res.set('highlight', false);
    })
  }

  zoomToFeature(result: Result) {
    let sd = document.getElementsByName("map-drawer");
    let map = famMap.getMap();
    let view = map.getView();

    let pudding = [20, 20, 20, 20];
    if (sd.length)
      pudding[3] += sd[0].offsetWidth;

    view.fit(result.raw[0].getGeometry(), {
      size: map.getSize(),
      duration: 1000,
      padding: pudding
    });
  }

  zoomToExtent(extent, slimPadding?: boolean) {
    let sd = document.getElementsByName("map-drawer");
    let map = famMap.getMap();
    let view = map.getView();

    let pudding = [200, 200, 200, 200];
    if (slimPadding) {
      pudding = [20, 20, 20, 20];
    }
    
    if (sd.length)
      pudding[3] += sd[0].offsetWidth;

    view.fit(extent, {
      size: map.getSize(),
      duration: 1000,
      padding: pudding
    });
  }

  importFeature(result: Result) {
    console.log('Not implementned');
  }

  drawerAnimate() {
    famMap.getMap().handleResize_();
  }

  tempHighlight(geom: any) {
    let reader = new ol.format.GeoJSON(); // geojson
    let f = reader.readFeature(geom);

    this.setupRefLayer('rgba(0,0,0,0.15)', 'rgba(0,255,255,0.9)');

    this.unhighlightTemp();
    this.refLayer.getSource().addFeature(f);
  }

  unhighlightTemp() {
    if (this.refLayer)
      this.refLayer.getSource().clear();
  }

  setupRefLayer(fillColor?: any, strokeColor?: any) {
    if (this.refLayer)
      return;
    this.refLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    this.refLayer.setSource(src);

    let sty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: fillColor || 'rgba(0,0,0,0.15)'
      }),
      stroke: new ol.style.Stroke({
        color: strokeColor || 'rgba(0,255,255,0.9)',
        width: 2
      })
    });
    this.refLayer.setStyle(sty);

    this.refLayer.setZIndex(8888);

    famMap.getMap().addLayer(this.refLayer);
  }

}
