import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  @Input() partner: any;

  @Input() projectLeadOrg: any;

  @Output() partnerToRemove: EventEmitter<any> = new EventEmitter();

  constructor(){ }

  ngOnInit(): void {
    let partnerTypes = this.partner.type.join(", ")
    this.partner.type = partnerTypes;
    console.log(this.partner);
  }

  removePartner(_: any){
    this.partnerToRemove.emit(this.partner);
  }

}
