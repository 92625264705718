<div
  [ngClass]="{'d-none': field.controlType === 'hidden', required: field.required || (field.elements && field.elements[0].required) }"
  [formGroup]="form" class="form-group" [ngClass]="{required: field.required}">

  <!-- Label -->
  <div>
    <label [attr.for]="field.key" class="control-label appFieldLabel"
      [ngClass]="{required: field.required && activeEdit, appLargeLabel: largeLabel === true}">{{field.label}}</label>
    <img alt="tooltip" src="/assets/images/info-icon.png" *ngIf="field.hint && activeEdit" [matTooltip]="field.hint"
      class="appSecondaryIcon appCustomTooltip appFormTooltip">
  </div>

  <!-- Form Control Switch -->
  <div [ngSwitch]="field.controlType">

    <!-- Textbox -->
    <div *ngSwitchCase="'textbox'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <input *ngIf="!field.decimalCappedNonNegative" matInput class="form-control" [formControlName]="field.key" [id]="field.key" [type]="field.type"
          placeholder="{{ field.example }}" [ngClass]="{ 'is-invalid ': !isValid && isDirty, required: field.required }"
          maxlength=" {{ field.maxlength }}" minlength=" {{ field.minlength }}">
        <input *ngIf="field.decimalCappedNonNegative" minMaxDirective decimalCapDirective [min]="0" [decimalCap]="field.decimalCapValue" matInput class="form-control" [formControlName]="field.key" [id]="field.key" [type]="field.type"
          placeholder="{{ field.example }}" [ngClass]="{ 'is-invalid ': !isValid && isDirty, required: field.required }"
          maxlength=" {{ field.maxlength }}" minlength=" {{ field.minlength }}">
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">{{ field.value || 'N/A' }}</div>
    </div>

    <!-- Drop Down -->
    <div *ngSwitchCase="'dropdown'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <mat-select class="form-control" [id]="field.key" [formControlName]="field.key"
          [ngClass]="{ 'is-invalid ': !isValid && isDirty }" [value]="field.value">
          <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">{{ field.value || 'N/A' }}</div>
    </div>

    <!-- Dynamic Drop Down -->
    <div *ngSwitchCase="'dynamic-dropdown'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <mat-select class="form-control" [id]="field.key" [formControlName]="field.key"
          [ngClass]="{ 'is-invalid ': !isValid && isDirty }" [value]="field.value">
          <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">{{ field.value || 'N/A' }}</div>
    </div>

    <!-- Multi-select Drop Down -->
    <div *ngSwitchCase="'multi-select'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <mat-select class="form-control" [id]="field.key" [formControlName]="field.key"
          [ngClass]="{ 'is-invalid ': !isValid && isDirty }" [value]="field.value" multiple>
          <mat-select-trigger>
            {{ this.fieldValue ? this.fieldValue[0] : ''}}
            <span *ngIf="this.fieldValue?.length > 1">
              (+{{this.fieldValue.length - 1}} {{this.fieldValue?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">
        <mat-list *ngFor="let value of this.fieldValue">
          <mat-list-item class="appFieldContent">{{value}}</mat-list-item>
        </mat-list>
      </div>
    </div>

    <div *ngSwitchCase="'date'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <input matInput [matDatepicker]="picker" [value]="field.value" [formControlName]="field.key">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit && field.value">{{ field.value | date:'M/d/yy'}}
      </div>
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit && !field.value">N/A</div>
    </div>

    <!-- Text Area -->
    <div *ngSwitchCase="'textarea'">
      <!-- Edit Mode -->
      <mat-form-field appearance="outline" *ngIf="activeEdit">
        <textarea style="max-height: 175px;" matInput [rows]="field.rows" [id]="field.key" [formControlName]="field.key"
          maxlength=" {{ field.maxlength }}" minlength=" {{ field.minlength }}"></textarea>
      </mat-form-field>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">{{ field.value }}</div>
    </div>

    <!-- Checkbox -->
    <div *ngSwitchCase="'checkbox'">
      <!-- Edit Mode -->
      <mat-checkbox *ngIf="activeEdit" [formControlName]="field.key" [id]="field.key" [value]="field.value">
      </mat-checkbox>
      <!-- View Mode -->
      <div class="appViewModeLabel appFieldContent" *ngIf="!activeEdit">{{ field.value ? 'Yes' : 'No' }}</div>
    </div>

    <!-- End Form Control Switch -->
  </div>




  <!-- Error Messages -->
  <mat-error *ngIf="!isValid && isDirty" class="invalid-feedback d-block">
    <span *ngIf="errors.required">
      {{field.label}} is required.
    </span>
    <span *ngIf="errors.minlength">
      Must enter at least {{field.minlength}} characters.
    </span>
  </mat-error>

</div>
