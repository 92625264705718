import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { RegistrationService, RegistrationUser } from '../registration.service'
import { Organization } from '../_shared/models/user.model';
import { ApiService } from '../_shared/services/api.service';
import { RegisterErrorDialogComponent } from './register-error-dialog/register-error-dialog.component';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { Router } from '@angular/router';

// Used for keeping track of the step in the registration process
export enum Steps {
  Enter,
  Communicating,
  Next,
  Error
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

  emailFormControl!: FormControl;
  firstFormControl!: FormControl;
  lastFormControl!: FormControl;
  editorFormControl!: FormControl;
  orgFormControl!: FormControl;

  orgs: any[];

  // Keep track of the current step
  step: Steps = Steps.Enter;

  // Expose all the possible steps to the template
  Steps = Steps;

  // Forms workaround
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  // Temp password
  temporaryPassword: string;
  showCopiedMessage = false;

  constructor(
    private registration: RegistrationService,
    private api: ApiService,
    private dialog: MatDialog, 
    private auth: AuthorizationService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.api.get<Organization[]>("Organization", "GetAll", false).subscribe(result => {
      result.sort(function(a,b){
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      this.orgs = result;
    });

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);

    this.firstFormControl = new FormControl('', [
      Validators.required
    ]);

    this.lastFormControl = new FormControl('', [
      Validators.required
    ]);

    this.editorFormControl = new FormControl('', []);
    this.editorFormControl.setValue(false);

    this.orgFormControl = new FormControl('', [])
    this.orgFormControl.setValue('None - General Public')
  }

  ngAfterViewInit(): void {
    // (╯ ͠° ͟ʖ ͡°)╯┻━┻  https://github.com/angular/components/issues/15027
    setTimeout(() => this.formFields.forEach(ff => ff.updateOutlineGap()), 100);
  }

  resetEditor(){
    this.editorFormControl.reset();
    this.editorFormControl.setValue(true);
  }

  // Returns true if the input is valid across all fields.
  isValid(): boolean {
    return this.emailFormControl.valid &&
      this.firstFormControl.valid &&
      this.lastFormControl.valid &&
      this.orgFormControl.valid;
  }

  validEditor(){
    return this.orgFormControl.value === 'None - General Public' && this.editorFormControl.value === true;
  }

  register() {
    if(!this.validEditor())
    {
      // Set the step to communicating so the frontend can display "progress"
      this.step = Steps.Communicating;


      // Create a registration user from the form
      let ru: RegistrationUser = new RegistrationUser();

      ru.email = this.emailFormControl.value;

      ru.first = this.firstFormControl.value;
      ru.last = this.lastFormControl.value;
      ru.org = this.orgFormControl.value;

      // General users may be implied and thus this may be removed in the near future
      // ru.general =
      // typeof this.generalFormControl.value === "boolean" ? this.generalFormControl.value : false;

      ru.editor =
        typeof this.editorFormControl.value === "boolean" ? this.editorFormControl.value : false;

      // Call the registration service to handle the dirty work
      this.registration.registerUser(ru).subscribe((result) => {
        // The API can respond with valid = false if the account doesnt exist or the api cannot be reached.        
        if (result.valid) {
          this.temporaryPassword = result.temporaryPassword;
          // Update the step to give the user feedback on what to do next
          this.step = Steps.Next;
        }
        else {
          console.log(result);
          // Something went wrong. Show it to the user.
          this.step = Steps.Error;
        }
      });

    } else {
      this.resetEditor();
      this.openDialog();
    }


  }

  // Go back to being able to enter data without clearing the form
  return() {
    this.step = Steps.Enter;
  }

  openDialog() {
    const dialogRef = this.dialog.open(RegisterErrorDialogComponent, {
      width: '35rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // Do nothing
    });
  }

  login(): void {
    this.auth.authorize().subscribe(login => {
      // Go back to the home page
      this.router.navigate(['/']);
    });
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showCopiedMessage = true;
    setTimeout(() => this.showCopiedMessage = false, 3000); // hides the message after 3 seconds
  }

}
