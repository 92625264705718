<div fxFlex fxFill fxLayout="column" class="" fxLayoutGap="0.15rem"
  style="padding: 0.25rem 10rem; padding-top: 1rem; width: 100%; height: 100%; overflow: auto; background: rgb(245,245,245); ">
  <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class=""
    style="flex-shrink: 1; flex-grow: 0; width: 100%; padding-left: 1.5rem;">
    <a routerLink="../all" style="margin-left: 0; width: auto; text-decoration: none; color: #0A4D0F">&lt; Back to
      Areas of Interest</a>
  </div>
  <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class=""
    style="flex-shrink: 1; flex-grow: 0; padding: 0 0.25rem; width: 100%; padding-left: 1.5rem; height: 4rem;">
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!editingName" class="" style="">
      <p style="width:auto; color: #54200E; font-size: 1.5rem; font-weight: bold">
        {{target.name}}
      </p>

      <button [appRequireACL]="'AOI:EditName'" mat-icon-button color="accent"
        aria-label="Click to edit Area of Interest Name." (click)="toggleEditName()"
        matTooltip="Click to edit Area of Interest name." *ngIf="canManage(target)" [disabled]="editing()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="editingName" xstyle="padding-top: 1.25rem;">
      <mat-form-field fxFill class="example-full-width">
        <mat-label>Area of Interest Name</mat-label>
        <input name="name" #name matInput [formControl]="nameFormControl" placeholder="Area of Interest Name"
          required="true" />
      </mat-form-field>

      <button mat-icon-button color="warning" aria-label="Cancel" (click)="toggleEditName()"
        matTooltip="Cancel and revert to saved name.">
        <mat-icon>close</mat-icon>
      </button>

      <button mat-icon-button color="accent" aria-label="Save entered name." (click)="saveName()"
        [disabled]="!nameFormControl.valid" matTooltip="Save name.">
        <mat-icon>check</mat-icon>
      </button>
    </div>




    <button [appRequireACL]="'AOI:Delete'" *ngIf="canManage(target)" [disabled]="editing()" mat-flat-button
      color="primary" (click)="deleteClick()" class="align-right">DELETE AREA OF INTEREST</button>

  </div>
  <div fxFlex fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start" class="mat-elevation-z0"
    style="width: 100%; padding: 0.5rem 0.25rem; overflow-y: auto;">

    <div fxFlex="35" fxFill fxLayout="column" class="" fxLayoutGap="0.5rem"
      style="width: 100%; height: 100%; overflow: hidden; background: rgb(245,245,245); padding:0 0;">
      <mat-card fxFlex="25" style="width:100%; height: 100%;">
        <div fxFlex fxLayout="row" fxLayoutGap="1.25rem" fxLayoutAlign="start start" class=""
          style="width: 100%; padding: 0.5rem 0.5rem;">

          <div fxFlex fxLayout="column" fxLayoutGap="1.0rem"
            style="font-size: 1.25rem; line-height: 1.5rem; padding: 0.5rem 0;">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
              <strong>Date Created:</strong>
              <div>{{epochDateToString(target.created)}}</div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
              <strong>Feature Type:</strong>
              <div>{{getField("type", target) || "Custom"}}</div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
              <a style="text-decoration: none; padding-right: 0.5rem; cursor: pointer; margin: auto;"
                routerLink="/app/manage/reports" [queryParams]="{id:target.id}"
                [appRequireACL]="'Assessment:Reports:Manage'">Manage Reports</a>
            </div>
          </div>

        </div>
      </mat-card>
      <div fxLayout="column" fxFlex="80" class="mat-elevation-z2"
        style="width:100%; height: 100%; padding:0; padding-bottom: 0.25rem; border-radius: 4px; overflow:hidden; background-color: white;"
        [appRequireACL]="'AOI:AssessmentView'">
        <p
          style="color:white; background-color: #54200E; margin:0; font-size:1.5rem; line-height: 3.5rem; padding:0 0.75rem;">
          Resource Concerns
        </p>
        <div style="height: 100%; overflow: hidden;">
          <div fxFill fxLayout="column" class="" fxLayoutGap="0.5rem" fxLayoutAlign="start center"
            style="width: 100%; height: 100%; overflow: hidden; padding:0.5rem 0.5rem; min-height: unset!important;">
            <button *ngIf="!editing() && canManage(target)" [disabled]="editing()" mat-flat-button color="primary"
              (click)="addConcern()" class="" [appRequireACL]="'AOI:AssessmentAdd'">ADD RESOURCE
              CONCERN</button>
            <div fxLayout="column" class="" fxLayoutGap="0.5rem" fxLayoutAlign="start center"
              style="overflow: auto; padding: 0.25rem; width: 100%;">
              <mat-card style="width:100%; color: #54200E;" *ngFor="let concern of target.fields.resourceConcerns">
                <div fxFlex fxLayout="column" fxLayoutGap="0.25rem"
                  style="font-size: 1rem; line-height: 1rem; padding: 0.25rem 0.5rem;">
                  <div fxLayout="row" style="min-height:2.25rem;">
                    <p
                      style="margin:auto 0; font-size:1.25rem; line-height: 1.5rem; font-weight: bold; padding-bottom: 0.5rem;">
                      {{concern.value}}
                    </p>
                    <button mat-icon-button class="align-right buttonhax" color="primary"
                      style="width: 24px; height: 24px; margin-top: 0;" (click)="deleteConcern(concern)"
                      [appRequireACL]="'AOI:AssessmentDelete'" *ngIf="!editing() && canManage(target)"
                      matTooltip="Click to remove this Resource Concern.">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <div fxFlex fxLayout="row" fxLayoutAlign="start start" class="" fxLayoutGap="0.75rem">
                    <strong>Group:</strong>
                    <div>{{concern.group}}</div>
                  </div>
                  <div fxFlex fxLayout="row" fxLayoutAlign="start start" class="" fxLayoutGap="0.75rem">
                    <strong>Category:</strong>
                    <div>{{concern.category}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="65" style="height: 100%; width: 100%; position: relative;">
      <div id="mgmap" style="height: 100%; width: 100%;">
      </div>
      <div [appRequireACL]="'AOI:ToggleLayers'" class="toggleLayerButton">
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onLayerSelect($event)" class="rounderToggles">
          <mat-button-toggle class="buttonToggleThemeingWorkaround widerToggles" value="aerial" checked>AERIAL
          </mat-button-toggle>
          <mat-button-toggle class="buttonToggleThemeingWorkaround widerToggles" value="topo">TOPOGRAPHY
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div [appRequireACL]="'AOI:EditBoundary'" class="editBoundsButton">
        <button *ngIf="!editing() && canManage(target)" mat-flat-button color="accent" (click)="editBoundsClick()">EDIT
          BOUNDARY</button>
        <button *ngIf="editingBounds" mat-flat-button color="accent" (click)="stopDraw()">CANCEL EDIT</button>
      </div>

      <div class="hide">
        <a id="image-download" download="map.png"></a>
        <div mat-card id="assessmentEditOverlayContents">
          <mat-card class="assessmentEditOverlayContents" *ngIf="true">
            <mat-card-title class="title">
              <div fxFlex fxLayout="row" fxLayoutGap="0.75rem">
                <p>Feature Type: {{this.target.fields.featureType}}</p>
                <button mat-button class="align-right buttonhax" color="primary"
                  (click)="dismissAssessmentEdit()">X</button>
              </div>
            </mat-card-title>
            <mat-card-content class="title align-bottom">
              <div fxFlex fxLayout="column" fxLayoutGap="0.75rem" style="font-size: 0.75rem;">
                <p style="font-size: 1.25rem; text-align: center; color: #54200E;" *ngIf="!hasShape()">
                  Boundary is required.</p>
                <button [disabled]="!hasShape()" mat-flat-button color="accent"
                  (click)="saveBoundsClick()">SAVE</button>
                <button *ngIf="!hasShape()" mat-flat-button color="primary" (click)="drawNewAssessmentArea()">DRAW
                  SHAPE</button>
                <button *ngIf="hasShape()" mat-flat-button color="primary" (click)="drawNewAssessmentArea()">DRAW
                  ADDITIONAL SHAPE</button>
                <button *ngIf="hasShape()" mat-button color="primary" (click)="clearBounds()">CLEAR
                  FEATURE</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
      <div id="assessmentEditOverlay">
      </div>


    </div>
  </div>


</div>
