<div class="search-container">

  <form class="search-form" [matTooltip]="!searchCtrl.value || searchCtrl.value.length < 1 ? tooltipMessage : null">

    <mat-form-field class="search-full-width">
      <input matInput placeholder="Search" aria-label="Search" [matAutocomplete]="auto" [formControl]="searchCtrl">
      <mat-icon class="appSearchIcon">search</mat-icon>
      <mat-icon *ngIf="(searchCtrl.value && searchCtrl.value.length > 0) || searchCtrl.value && searchCtrl.value.name"
        class="appClearIcon" (click)="clearValues()">
        clear</mat-icon>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="goToLocation($event.option.value)" [displayWith]="getOptionName">
        <mat-option class="search-option" *ngFor="let candidate of candidates" [value]="candidate">
          <span>{{candidate.name}}</span> |
          <small>Source: {{candidate.source}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </form>

</div>
