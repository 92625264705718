import { Injectable, DebugEventListener } from '@angular/core';
import { MapCategory, Service } from '../../models/map/config.model';
import { FixedLengthArray } from '../../types/fixed-length-array';
import { Candidates } from '../../models/map/geo-search.model';
import { IPoint } from '@esri/arcgis-rest-types';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { TouchSequence } from 'selenium-webdriver';

declare var window: any;
declare var famMap: any;
declare var ol: any;

@Injectable({
  providedIn: 'root'
})
export class PlugmapAPIService {

  private mapCategories: MapCategory[];

  drawing: boolean = false;

  drawnShape: any = undefined;

  private shapeChange: Subject<any> = new Subject();

  private mapCenter$: BehaviorSubject<any> = new BehaviorSubject({ x: 0, y: 0 });
  private mapZoom$: BehaviorSubject<any> = new BehaviorSubject(0);
  private mapExtent$: BehaviorSubject<any> = new BehaviorSubject([0, 0, 0, 0]);

  private importCount: number;
  importing: boolean = false;

  constructor() {

    //These are hacks to test stuff in the browser via the console
    window.setCategoryTransparencyByKey = this.setCategoryTransparencyByKey.bind(this);
    window.turnLayerOnByKey = this.turnLayerOnByKey.bind(this);
    window.turnLayerOffByKey = this.turnLayerOffByKey.bind(this);

    window.addEventListener("message", this.receiveMessage.bind(this), false);

  }

  getMapCenter(): Observable<any> {
    return this.mapCenter$.asObservable();
  }

  getMapExtent(): Observable<any> {
    return this.mapExtent$.asObservable();
  }

  getMapZoom(): Observable<any> {
    return this.mapZoom$.asObservable();
  }

  private setMapCenter(newCenter: any) {
    this.mapCenter$.next(newCenter);
  }

  private setMapExtent(newExtent: any) {
    this.mapExtent$.next(newExtent);
  }

  private setMapZoom(newZoom: any) {
    this.mapZoom$.next(newZoom);
  }

  receiveMessage(msg) {

    //Validate there is data
    if (!msg.data)
      return;

    //We only care about map messages
    if (msg.data.source !== "map")
      return;

    //Ideally any message we need would have the type tagged with auth

    switch (msg.data.cmd) {
      case "map:drawingStarted":
        {
          this.drawing = true;
        }; break;
      case "map:drawingEnd":
        {
          this.drawing = false;
        }; break;
      case "map:drawingEnded":
        {
        }; break;
      case "map:flattened":
        {
          //Here we have the flat shape as a wkt and the area of the flattened area
          // {
          //   "type_": "Polygon",
          //   "wkt": "POLYGON((-12530374.855545709 4267313.641800819,-12582488.841428438 4169466.9744291664,-12539946.812136415 4107781.031955733,-12442100.144764762 4180102.481752172,-12530374.855545709 4267313.641800819))",
          //   "acres": 1840459.8756376486,
          //   "id": -1
          // }
          console.log(msg)
          this.drawnShape = msg.data.data;
          this.shapeChange.next(this.drawnShape);
        }; break;
      case "map:extentUpdated":
        {
          const newCenter = { ...msg.data.data.center };
          const ext = [msg.data.data.xmin, msg.data.data.xmax, msg.data.data.ymin, msg.data.data.ymax]
          let zoom = famMap.getMap().getView().getZoom();
          this.setMapCenter(newCenter);
          this.setMapExtent(ext);
          this.setMapZoom(zoom);
        }; break;
      case "map:modifyEnd":
        {

        }; break;
      case "map:mapReady":
        {

        }; break;
      default: {
      }
    }

  }

  public setImportCount(count: number) {
    this.importCount = count;
  }

  public getImportCount() {
    return this.importCount;
  }

  public setImporting(importing: boolean) {
    this.importing = importing;
  }

  public getImporting() {
    return this.importing;
  }

  public setCategories(mapCategories: MapCategory[]) {
    this.mapCategories = mapCategories;
    this.setupThemes(mapCategories);
  }

  public getMapCategories(): MapCategory[] {
    return this.mapCategories;
  }

  public setServices(services: Service[]) {
    this.sendMapCommand("setServicesCmd", services)
  }

  public createMap(elid: string, mapCenter: FixedLengthArray<[number, number]>, defaultZoom: number, maxZoom: number, minZoom: number) {
    window.app = { ports: {}, suppressPortWarnings: true };
    window.renderMap(elid, { "target": elid, "center": mapCenter, "zoom": defaultZoom, "maxZoom": maxZoom, "minZoom": minZoom })
  }

  public destroyMap(): void {
    if (window.famMap) window.famMap.destruct();
  }

  //This applies the themes to the map
  private setupThemes(themes: MapCategory[]) {
    window.postMessage({ destination: "map", cmd: "addThemesCmd", data: themes }, window.location.origin);
  }

  private sendMapCommand(command: string, data: any) {
    window.postMessage({ destination: "map", cmd: command, data: data }, window.location.origin);
  }

  //We should move this into a service or api of some sort

  setCategoryTransparencyByKey(key: string, transparency: number) {
    for (let cat of this.mapCategories) {
      if (cat.category_key === key) {
        cat.opacity = transparency;

        var data = { category_key: cat.category_key, transparency: transparency };
        this.sendMapCommand("setCategoryTransparencyCmd", data);
        break;
      }
    }
  }


  //TODO: These can be made faster if we mapped the layers to the categories but the extra development effort may not be worth it
  turnLayerOnByKey(key: string) {
    for (let cat of this.mapCategories) {
      for (let layer of cat.layers) {
        if (layer.key === key) {
          cat.selection.select(key);
          var data = [{ category_key: cat.category_key, selection: cat.selection }];
          this.sendMapCommand("toggleSelectedThemesCmd", data);
        }
      }
    }
  }

  //This probably shouldnt work for monoselective but eh
  turnLayerOffByKey(key: string) {
    for (let cat of this.mapCategories) {
      for (let layer of cat.layers) {
        if (layer.key === key) {
          cat.selection.select(key); //TODO

          var data = [{ category_key: cat.category_key, selection: cat.selection }];
          this.sendMapCommand("toggleSelectedThemesCmd", data);
        }

      }
    }
  }

  zoomToCandidates(candidates: Candidates) {
    this.sendMapCommand("gotoSearchCandidates", candidates);
  }

  zoomToPoint(point: IPoint) {
    const webPoint = ol.proj.fromLonLat([point.x, point.y])
    this.sendMapCommand("extentToPointZoomCmd", { x: webPoint[0], y: webPoint[1], zoom: 11 });
  }

  startDrawingPolygon(): Observable<any> {

    this.drawing = true;
    this.sendMapCommand("startDrawingWithMode", { "mode": "polygon" });
    return this.shapeChange.asObservable();
  }

  isDrawing(): boolean {
    return this.drawing;
  }

  hasShape(): boolean {
    return this.drawnShape != undefined;
  }

  stopDrawing(): any {
    console.log("stop drawing")
    let tmp = this.drawnShape;
    this.sendMapCommand("setWKTs", { clear: true, wkts: [] });
    setTimeout(() => { this.sendMapCommand("disableDraw", undefined); }, 250);
    this.drawnShape = undefined;
    this.drawing = false;
    return tmp;
  }

  clearShape() {
    this.sendMapCommand("setWKTs", { clear: true, wkts: [] });
  }

  refreshMap(): void {
    this.sendMapCommand("refreshMap", undefined);
  }

  clearPoints() {
    this.sendMapCommand("clearSearchCmd", undefined);
  }

  fitToWKT(wkt: any) {
    this.sendMapCommand("fitToWktCmd", wkt);
  }

  public initDraw() {
    this.sendMapCommand("initDrawingCmd", null);
  }

  public setWKTs(wkts: string[]): Observable<any> {
    console.log("Setting features to ", wkts)


    var m = wkts.map(wkt => { return { wkt: wkt } });

    this.sendMapCommand("addWKTsToMap", m);

    return this.shapeChange.asObservable();
  }

  public setFeats(feats: any): Observable<any> {
    console.log("Setting features to ", feats)
    if (feats.length > 1) {
      feats = [feats[0]];
    }
    this.drawing = true;
    let geoJSON = new ol.format.GeoJSON().writeFeatures(feats);
    this.sendMapCommand("drawSetGeoJSON", geoJSON);
    return this.shapeChange.asObservable();
  }

  public getShapeChange() { return this.shapeChange.asObservable(); }

  public setFeatsGeoJSON(feats: any): Observable<any> {
    let geoJSON = JSON.stringify(feats);

    this.drawing = true;
    this.sendMapCommand("drawSetGeoJSON", geoJSON);
    return this.shapeChange.asObservable();
  }

  //All ports
  // 2
  // addThemesCmd
  // toggleSelectedThemesCmd
  // setCategoryTransparencyCmd
  // setServicesCmd

  // 3
  // unloadDrawingPlugin
  // setWKTs
  // startDrawingWithMode
  // enableModify
  // disableModify
  // disableDraw
  // initDrawingCmd
  // destructDrawingCmd
  // hideDrawingCmd
  // drawingEnded


  // fitToWktCmd
  // refreshMap


  // extentToPointZoomCmd
  // addReferenceItems
  // setBuffer
  // selectById
  // deleteFeatureById
  // setExtentByFeatueId
  // featurePickerSetLayer
  // hideReferenceLayer
  // hideReferenceLayerDisplay
  // featurePickerDisable
  // xmlToJs
  // selectFeature
  // deselectFeature
  // clearReferenceFeatures
  // normalizeEsri
  // addWKTsToMap
  // highlightCmd
  // unhighlightCmd
  // clearHighlightLayerCmd
  // openChooseFile
  // gotoSearchCandidates
  // gotoSearchCandidate
  // setPoints
  // moveMapCenter
  // clearSearchCmd
}
