import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MapConfigConstants } from '../../../_shared/models/map/config.model';
import { MapLayer } from '../../../_shared/models/map/config.model';

import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  selector: 'app-layer-list-item',
  templateUrl: './layer-list-item.component.html',
  styleUrls: ['./layer-list-item.component.scss']
})
export class LayerListItemComponent implements OnInit {

  @Input() selected: boolean = true;

  @Input() multi: boolean = true;

  @Input() layer: MapLayer;

  @Input() selectionType: string = MapConfigConstants.polyselective;

  @Input() mode : string;

  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onChange(event : MatCheckboxChange)
  {
    this.selectionChange.next(event.checked);
  }

}
