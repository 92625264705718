import { Injectable } from '@angular/core';

import { ConfigurationService, AuthConfig } from './_shared/services/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from './_shared/services/api.service';


//This is our DTO to submit a registration to the system
export class RegistrationUser {

  public email: string;
  public password : string;
  public first: string;
  public last: string;
  public org: string;

  public general: boolean;
  public editor: boolean;

  public registered: boolean;

  public temporaryPassword?: string;

  constructor() {
    this.registered = false;
  }
}

export class ForgotPasswordUser {
  public email: string;
}

//This represents a validation result from the system
export class Validation<e> {
  public valid: boolean;
  public result: e;
  temporaryPassword?: string;
}

//This handles registration of users in the system
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private dataSubject: ReplaySubject<Validation<RegistrationUser>> = new ReplaySubject<Validation<RegistrationUser>>(1);
  private forgotPasswordDataSubject: ReplaySubject<Validation<ForgotPasswordUser>> = new ReplaySubject<Validation<ForgotPasswordUser>>(1);

  constructor(private api : ApiService, private config: ConfigurationService) {
  }

  public registerUser(user: RegistrationUser): Observable<Validation<RegistrationUser>> {
    this.dataSubject = new ReplaySubject<Validation<RegistrationUser>>(1);
    this.config.getConfig().subscribe((config) => {

      this.api.post<Validation<RegistrationUser>>("User", "Register", user, false).subscribe(res => {
        this.dataSubject.next(res)
      }, err => {
        console.log(err);
        this.dataSubject.next(
          {
            valid: false,
            result: user
          })
      });
    });

    return this.dataSubject;
  }

  public forgotPassword(user: ForgotPasswordUser): Observable<Validation<ForgotPasswordUser>> {
    this.forgotPasswordDataSubject = new ReplaySubject<Validation<ForgotPasswordUser>>(1);
    this.config.getConfig().subscribe((config) => {

      this.api.post<Validation<RegistrationUser>>("User", "ForgotPassword", user, false).subscribe(res => {
        this.forgotPasswordDataSubject.next(res)
      }, err => {
        console.log(err);
        this.forgotPasswordDataSubject.next(
          {
            valid: false,
            result: user
          })
      });
    });

    return this.forgotPasswordDataSubject;
  }
}
