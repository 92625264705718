import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { allRoles, Role } from '../../../_shared/models/roles.model';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit {

  allRoles : Role[] = allRoles(); //[ { id:1, name:"Browser"}, { id:2, name:"Editor"}, { id:3, name:"Administrator"}]

  managerFormControl!: FormControl;
  selectedRole: string;
  checked: boolean;

  constructor(
    public dialogRef: MatDialogRef<ManageRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public user: any) {}

  onNoClick(): void {
    this.dialogRef.close();
    console.log(this.selectedRole)
  }

  onOkClick() : void {
    this.dialogRef.close(this.selectedRole);
  }

  ngOnInit(): void {

    for(let role of this.allRoles)
    {
      if(role.name == this.user.displayRole){
        this.selectedRole = role.name
      }
    }
    console.log(this.selectedRole)
  }

}
