import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mockauth',
  templateUrl: './mockauth.component.html',
  styleUrls: ['./mockauth.component.scss']
})
export class MockauthComponent implements OnInit {

  constructor() { }

  makeFakeIdToken(id) {
    return {
      "sub": id,
      "email": "fake@example",
      "custom:firstName": "Fakey",
      "custom:lastName": "McFakerson"
    }

  }
  makeFakeToken(id) {
    return {
      "sub": id,
      "cognito:groups": [
      ],
      "iss": "aacd",
      "version": 2,
      "client_id": "aacd-mocks",
      "event_id": "mocky-mock",
      "token_use": "access",
      "scope": "openid profile",
      "auth_time": new Date().getTime() / 1000,
      "exp": new Date().getTime() / 1000 + 3000,
      "iat": new Date().getTime() / 1000,
      "jti": "0000",
      "username": "0000"
    }
  }


  ngOnInit(): void {
  }

  fakeNone(): void {
    //This is a hack to tie into the auth system
    //We are faking a response from the authorization system and providing a faked jwt
    var opener = this.getOpener();
    opener.postMessage(
      {
        type: "auth",
        status: "complete",
        token: this.encode(this.makeFakeToken("0000")),
        refreshToken: "refresh",
        idToken: this.encode(this.makeFakeIdToken("0000"))
      }, "http://localhost:4200/");
  }


  fakeGeneral() {
    var opener = this.getOpener();
    opener.postMessage(
      {
        type: "auth",
        status: "complete",
        token: this.encode(this.makeFakeToken("1111")),
        refreshToken: "refresh",
        idToken: this.encode(this.makeFakeIdToken("1111"))
      }, "http://localhost:4200/");
  }
  fakeManager() {
    var opener = this.getOpener();
    opener.postMessage(
      {
        type: "auth",
        status: "complete",
        token: this.encode(this.makeFakeToken("2222")),
        refreshToken: "refresh",
        idToken: this.encode(this.makeFakeIdToken("2222"))
      }, "http://localhost:4200/");
  }

  signOut() {
    if (window.opener)
      window.close();
  }

  getOpener() {
    if (window.opener)
      return opener;

    return window;
  }

  fakeAdmin() {
    var opener = this.getOpener();
    opener.postMessage(
      {
        type: "auth",
        status: "complete",
        token: this.encode(this.makeFakeToken("3333")),
        refreshToken: "refresh",
        idToken: this.encode(this.makeFakeIdToken("3333"))
      }, "http://localhost:4200/");
  }


  encode(value) {

    let str = JSON.stringify(value);
    let b64 = "fake." + btoa(str);

    let base64Url = b64.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let atobval = atob(base64);
    let atobmapped = atobval.split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')
    let jsonPayload = decodeURIComponent(atobmapped);
    let rv = JSON.parse(jsonPayload);

    return b64
  }

}
