<div class="appClusterButtonContainer">
  <button *ngIf="this.editModeOn" mat-flat-button
    class="cancel align-right appSmallButton appSquareButton appBrownActionButton"
    (click)="this.clickCancel()">{{ this.cancelName }}</button>
  <span [matTooltip]="optionalEditTooltip">
    <button *ngIf="!this.editModeOn" mat-flat-button
      class="confirm align-right appSmallButton appSquareButton appBrownActionButton" (click)="this.clickEdit()"
      [disabled]="this.disabledEdit">{{ this.editName }}</button>
  </span>
  <button [disabled]="!this.formsAreValid" *ngIf="this.editModeOn" mat-flat-button
    class="confirm align-right appSmallButton appSquareButton appBrownActionButton"
    (click)="this.clickSave()">{{ this.confirmName }}</button>
</div>
