<p mat-dialog-title color="primary" style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
    class="themePrimaryTextInverse">Area of Interest Saved</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center" style="width: 100%;">
        <div style="width: 100%;">
            <p style="font-size: 1.2rem; line-height: 1.5rem; text-overflow: ellipsis; overflow:hidden;">
                You can view your Area of Interest on the map by checking the "Areas of Interest" layer in the left panel layer list. You
                can also view all your Areas of Interest in the "Management" tab and manage them in the "My Areas" tab.
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions style="width: 100%;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <button mat-button color="primary" [mat-dialog-close]="" style="width: 12rem;">CLOSE</button>
    </div>
</div>