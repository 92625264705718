<div id="appScroller">

  <!-- Main page container -->
  <div fxFlex fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem" [style]="'height:' + heightValue">
    <!-- <div fxFlex fxFill fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem" [style]="'height:' + heightValue"> -->

    <!-- Header bar and submit buttons -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth"
      style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">

      <!-- Title -->
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth">
        <h1 class="appProjectHeader">Create New Project
        </h1>
        <img alt="Info tooltip" src="/assets/images/info-icon.png" style="margin-left: 0.5rem;" (click)="openDialog()"
          class="appSecondaryIcon appCustomTooltip">
      </div>

      <!-- Confirmation buttons -->
      <button mat-flat-button color="warn" class="align-right appConfirmButton appSquareButton"
        style="margin-right: 1rem;" routerLink="/app/home">Cancel</button>
      <div [matTooltip]="saveHint()">
        <button mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
          (click)="trySave($event)" [disabled]="!this.isValid()">Save</button>
      </div>

      <!-- End Header bar and submit buttons -->
    </div>

    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center"
      class="appFlexShrink appFullWidth appProjectHeaderContainer">
      <!-- End Header bar and submit buttons -->
    </div>

    <!-- Map -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer">

      <div fxFlex="100" fxFill fxLayout="column"
        class="appFullWidth appFullHeight appCardContainer appSolidCard appBottomRounded" fxLayoutGap="0.5rem">

        <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard appSolidCard" fxFlex="100">
          <mat-card-header>
            <mat-card-title style="margin-left: 1rem;">
              <span>Map</span>
              <img id="appMapIcon" alt="tooltip" src="/assets/images/info-icon.png"
                [matTooltip]="'Click in the map to draw the Project boundary. If you are uncertain of your area, map the approximate expected area, and you can update it later, as needed.'"
                class="appSecondaryIcon appCustomTooltip appFormTooltip">
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="appMapCardContent">
            <app-slim-map [showEditCreateButtons]="true" (shapeUpdated)="newShapeData($event)"
              [strokeColor]="'rgba(0, 19, 2, 0.5)'" [fillColor]="'rgba(185, 232, 42, 0.5)'"></app-slim-map>
          </mat-card-content>
        </mat-card>

      </div>

      <!-- End map -->
    </div>

    <!-- Project details -->
    <div fxFlex fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer">

      <div fxFlex="25" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer"
        fxLayoutGap="0.5rem">
        <mat-card fxFlex=" 100" class="appFullWidth appFullHeight mat-elevation-z0 appSolidCard">

          <div class="appFormContainer">
            <mat-card class="mat-elevation-z0">
              <mat-card-header class="appCardHeaderUnderline">
                <mat-card-title>General Information</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-dynamic-form #genInfo [fields]="fields"></app-dynamic-form>
              </mat-card-content>
            </mat-card>
          </div>

        </mat-card>
      </div>

      <!-- Project Focus -->
      <div fxFlex="25" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer"
        fxLayoutGap="0.5rem">
        <mat-card fxFlex="100" class="appFullWidth appFullHeight mat-elevation-z0 appSolidCard">
          <div class="appFormContainer">
            <mat-card class="mat-elevation-z0">
              <mat-card-header class="appCardHeaderUnderline">
                <mat-card-title>Project Focus & Strategies</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-dynamic-form #focus [fields]="fieldsFocus"></app-dynamic-form>
              </mat-card-content>
            </mat-card>
          </div>

        </mat-card>
      </div>

      <!-- Project Narrative -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer"
        fxLayoutGap="0.5rem">
        <mat-card fxFlex="100" class="appFullWidth appFullHeight mat-elevation-z0 appSolidCard">
          <mat-card class="mat-elevation-z0">
            <mat-card-header class="appCardHeaderUnderline">
              <mat-card-title>Project Narrative</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <app-dynamic-form #narrative [fields]="fieldsNarrative"></app-dynamic-form>
            </mat-card-content>
          </mat-card>

        </mat-card>
      </div>

      <!-- End Project details -->
    </div>

    <!-- End Main page container -->
  </div>

</div>
