<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-header class="appTrashCanHeaderBuffer">
        <mat-card-title class="appH3SecondaryHeader">{{partner.name}}
        </mat-card-title>
        <div class="appTrashCan" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
          <mat-icon (click)="removePartner($event)">delete</mat-icon>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p class="appFieldContent">{{partner.type}}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
