import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-org',
  templateUrl: './delete-org.component.html',
  styleUrls: ['./delete-org.component.scss']
})
export class DeleteOrgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
