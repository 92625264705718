import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { ProjectInfoDialogComponent } from 'src/app/project-info-dialog/project-info-dialog.component';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { CoreEntity } from 'src/app/_shared/models/core-entity.model';
import { PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AuthorizationService } from 'src/app/_shared/services/authorization.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  fields: any[];
  fieldsNarrative: any[];
  fieldsFocus: any[];
  projectGeom: PamShapeData;
  screenHeight: number;
  screenWidth: number;
  heightValue = '60rem;'
  leadOrg: string;

  @ViewChild('narrative') narrative: DynamicFormComponent
  @ViewChild('genInfo') genInfo: DynamicFormComponent
  @ViewChild('focus') focus: DynamicFormComponent

  constructor(
    private fieldService: FieldService,
    private api: ApiService,
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthorizationService,
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.fields = this.fieldService.getFields(CoreEntity.Project);
    this.fieldsNarrative = this.fieldService.getFields('ProjectNarrative'); // TODO - fix the magic strings
    this.fieldsFocus = this.fieldService.getFields('ProjectFocus');
    const login = this.authService.getLogin();
    this.leadOrg = login.orgs.organization;
  }

  createProject(newObj: any) {
    let formattedObj: any = {};
    formattedObj.name = newObj.genInfoData.PROJECT_NAME;
    formattedObj.fields = {};
    formattedObj.fields.projectType = newObj.genInfoData.PROJECT_TYPE;
    formattedObj.fields.projectStatus = newObj.genInfoData.PROJECT_STATUS;
    formattedObj.fields.projectNarrative = newObj.narrativeData.PROJECT_NARRATIVE;
    formattedObj.fields.projectFocus = newObj.focusData.PROJECT_FOCUS;
    formattedObj.fields.projectStrategies = newObj.focusData.PROJECT_STRATEGIES;
    formattedObj.fields.Geometry = this.projectGeom.fields.Geometry;
    formattedObj.fields.leadOrganization = this.leadOrg;

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Creating Project.",
      disableClose: true
    });
    this.api.post<any>("Project", "Create", formattedObj, true).subscribe(result => {

      if (!result.valid) {
        //Backend validation didnt pass show the errors
        //this.showErrors(result.messages);
      }
      else {
        //Clean some stuff up
        //We should also keep a reference to the ID I guess?
        //this.refreshMap();
        this.showSaved(result.result.id);
      }
      dialogRef.close();
    }, error => {
      //Backend gave us a non-200 response
      //this.showErrors(["The api responded incorrectly.", JSON.stringify(error, undefined, 2)]);
      dialogRef.close();
    })
  }


  trySave(_: any): void {
    const genInfoData = this.genInfo.getPayload();
    const narrativeData = this.narrative.getPayload();
    const focusData = this.focus.getPayload();

    let merged = { genInfoData, narrativeData, focusData };

    console.log(narrativeData);
    console.log(genInfoData);
    console.log(focusData);

    console.log(merged);

    this.createProject(merged);
  }

  isValid(): boolean {
    if (this.genInfo && this.narrative && this.focus && this.projectGeom) {
      return (this.genInfo.isValid() && this.narrative.isValid() && this.focus.isValid());
    } else {
      return false;
    }
  }

  showSaved(id: number): void {
    this.router.navigateByUrl(`/app/project/view?id=${id}`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1366) {
      this.heightValue = '80rem;';
    } else {
      this.heightValue = '60rem;';
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProjectInfoDialogComponent, {
      width: '50rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  newShapeData(data: PamShapeData) {
    console.log('Got Data');
    console.log(data);
    this.projectGeom = data;
  }

  saveHint() {
    if (!this.projectGeom) {
      return 'You must map the Project boundary in order to continue.';
    }
    else {
      return '';
    }
  }

}
