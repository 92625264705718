<div class="widthFill heightFill">
  <mat-card class="widthFill heightFill">
    <div fxLayout="column" fxLayoutAlign="space-evenly center" class="widthFill heightFill" *ngIf="!isLoggedIn()">
      <button mat-flat-button color="accent" class="widthFill" (click)="login()">SIGN IN</button>
      <a routerLink="/register" class="widthFill centerText">Sign Up</a>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-evenly center" class="widthFill heightFill" *ngIf="isLoggedIn()">
      <p>Welcome {{getUsername()}}</p>
      <button class="widthFill" mat-button (click)="logout()">Logout</button>
    </div>
  </mat-card>
</div>
