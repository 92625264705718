import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from 'src/app/map/error-dialog/error-dialog.component';
import { allRoles, Role } from 'src/app/_shared/models/roles.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { User } from '../../admin.model';
import { AddOrgComponent } from '../add-org/add-org.component';
import { DeleteOrgComponent } from '../delete-org/delete-org.component';
import { ArchiveErrorDialogComponent } from './archive-error-dialog/archive-error-dialog.component';

@Component({
  selector: 'app-org-edit',
  templateUrl: './org-edit.component.html',
  styleUrls: ['./org-edit.component.scss']
})
export class OrgEditComponent implements OnInit {
  displayedColumns: string[] = ['name', 'orgShort', 'orgType', 'actions'];
  orgs: any[];
  dataSource = new MatTableDataSource<any>([]);
  //allRoles: Role[] = allRoles();
  //acceptableRoles: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.retreiveOrgs();
  }

  retreiveOrgs(){
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (sortHeaderId == 'name') {
        return (data['name']).toLocaleLowerCase();
      }

      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading",
      disableClose: true
    });

    this.api.get<any[]>("Organization", "GetAll").subscribe(res => {

      this.orgs = res;
      this.orgs = this.orgs.filter(function (obj) {
        return obj.name !== "None - General Public"
      });

      this.initDataSource();
    });
    saveRef.close();

  }


  initDataSource() {
    this.dataSource.data = this.orgs;// = new MatTableDataSource<User>(this.users);
  }

  archiveOrg(org: any) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading",
      disableClose: true
    });

    this.api.post<any[]>("Users", "GetAll", true).subscribe(res => {
      if (res) {
        let users = res;
        let hasAssociation = false;
        users.forEach(u => {
          if(u.organization === org.name){
            hasAssociation = true;
            return
          }
        });

        saveRef.close();

        if(hasAssociation){
          this.dialog.open(ArchiveErrorDialogComponent, {
            width: '25rem',
            disableClose: false
          });

        }


        if(!hasAssociation){
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '65rem',
            data: { title: 'Confirm Archive', text: "Archiving will remove this Organization from this screen's list and any pick lists (e.g., account registration, partner organizations, etc.), but will not expunge it as Partner on Projects or as Activity Managers on Activities. You cannot undo this archive. Are you sure you want this Organization to be unavailable for use?", confirm: "Yes", cancel: "No" },
          });

          dialogRef.afterClosed().subscribe(data => {
            if (data) {
              // Delete
              this.commitArchive(org);
            } else {
              // No action needed
            }
          });
        }
      }
    });
  }


  async commitArchive(data: any) {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Archiving Organization",
      disableClose: true
    });

    let obj = { id: data.id }

    this.api.post<any>("Organization", "Delete", obj, true).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.retreiveOrgs();
      delRef.close();
    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }
}
