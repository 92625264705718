import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from '../map/error-dialog/error-dialog.component';
import { NoteCreateDialogComponent } from '../note-create-dialog/note-create-dialog.component';
import { PamObject } from '../_shared/models/pam-object.model';
import { ApiService } from '../_shared/services/api.service';
import { AuthorizationService } from '../_shared/services/authorization.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent implements OnInit {

  @Input() opportunity: any;

  @Input() projectLeadOrg: any;

  @Input() projectId: any;

  @Output() opportunityToRemove: EventEmitter<any> = new EventEmitter();

  displayName: string;


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private api: ApiService,
    private auth: AuthorizationService
  ){ }

  ngOnInit(): void {
    this.displayName = this.auth.login.getFullName();
    console.log(this.opportunity);
  }

  removeOpportunity(_: any){
    this.opportunityToRemove.emit(this.opportunity);
  }

  editOpportunity() {
    let noteDialog = this.dialog.open(NoteCreateDialogComponent, {
      width: '35rem',
      disableClose: false,
      data: { "opportunity": this.opportunity, "editMode": true, "isOpportunity": true }
    });


    noteDialog.afterClosed().subscribe(data => {
      if (data) {
        let formattedObj = this.createOpportunityPayload(data);
        this.updateOpportunity(formattedObj)
      }
    });
  }

  createOpportunityPayload(data: any) {
    let formattedObj: PamObject = { id: data.id, name: "default", fields: {} };
    formattedObj.name = data.isOpportunity === true ? "Opportunity" : "Impediment";
    formattedObj.fields = {};
    formattedObj.fields.note = data.note;
    formattedObj.fields.isOpportunity = data.isOpportunity;
    formattedObj.fields.displayName = this.displayName;


    return formattedObj;
  }


  updateOpportunity(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      //pass object here (edit mode and other things)
      data: "Saving Record.",
      disableClose: true
    });

    this.api.post<any>("Note", "Update", obj).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleOpportunityUpdate(res.result)
      saveRef.close();

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  handleOpportunityUpdate(res){
    console.log("transforming opportunity response");

    this.opportunity.note = res.note;
    this.opportunity.lastModified = res.lastModified;
    this.opportunity.username = res.username;

  }
}
