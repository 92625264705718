import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  constructor(private auth : AuthorizationService, private router: Router) { }
  logoutsub : Subscription;

  ngOnInit(): void {
    this.logoutsub = this.auth.logout().subscribe(res => {
      setTimeout(() => {
         
        this.router.navigate(['']);
      }, 500)
    })
  }

  ngOnDestroy(): void {
    this.logoutsub.unsubscribe();
  }

  loggedOut() : boolean {
    return !this.auth.isLoggedIn();
  }

}
