<div
  id="appRegisterBackground"
  style="padding: 1em; background-color: rgb(200, 200, 200)"
>
  <mat-card
    style="
      width: 25rem;
      min-width: 700px;
      overflow: hidden;
      padding: 2em !important;
      margin: auto;
    "
  >
    <div
      class="appRegisterContainer"
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="1em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Enter"
    >
      <div fxFlex fxLayoutAlign="center center">
        <h3 class="primary-dark-text header-text">Sign Up</h3>
      </div>

      <div fxFlex fxLayoutAlign="end center">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Email</mat-label>
          <input
            name="email"
            #email
            matInput
            [formControl]="emailFormControl"
            placeholder="Ex. pat@example.com"
            required="true"
          />
        </mat-form-field>
      </div>

      <div fxFlex fxLayoutAlign="end center">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>First Name</mat-label>
          <input
            name="first"
            #first
            matInput
            [formControl]="firstFormControl"
            placeholder="Ex. John"
            required="true"
          />
        </mat-form-field>
      </div>

      <div fxFlex fxLayoutAlign="end center">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Last Name</mat-label>
          <input
            name="last"
            #last
            matInput
            [formControl]="lastFormControl"
            placeholder="Ex. Doe"
            required="true"
          />
        </mat-form-field>
      </div>

      <!--This is a placeholder as it may be needed in the near future-->
      <!--<div fxFlex fxLayout="column" fxLayoutGap="0.25em" fxLayoutAlign="end start">
        <div>
            Are you a General User? <br />
            A General User may do the following
            <ul>
                <li>Access The Map</li>
                <li>View Projects</li>
                <li>View Reports</li>
                <li>View Dashboards</li>
            </ul>
        </div>
            <mat-checkbox value="1" [formControl]="generalFormControl">Yes I am a General User</mat-checkbox>
        </div>-->

      <div
        fxFlex
        fxLayout="column"
        fxLayoutGap="0.25em"
        fxLayoutAlign="end start"
      >
        <div>
          Do you need editing capabilities?<br />
          As a Project Editor, you may do the following:
          <ul>
            <li>Create Projects for your Organization</li>
            <li>
              Add Activities to your Organization's Projects, and to Projects
              that your Organization is a listed partner for
            </li>
          </ul>
        </div>
        <mat-checkbox
          value="1"
          [formControl]="editorFormControl"
          style="margin-bottom: 1.5em"
          >Yes, I want to request access to the above features.
        </mat-checkbox>
        <div class="appRegisterSpacer"></div>
      </div>

      <div>
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Organization</mat-label>
          <mat-select
            name="org"
            #org
            [formControl]="orgFormControl"
            required="true"
          >
            <mat-option *ngFor="let org of orgs" [value]="org.name">
              {{ org.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxFlex
        fxLayout="column"
        fxLayoutGap="0.25em"
        fxLayoutAlign="end start"
      >
        <div>
          Don't see your Organization in this list yet? Contact us
          <a href="mailto:gissupport@timmons.com">here</a> to request it
          be added. In the meantime, you can register using the 'None - General
          Public' option to begin viewing the projects in the Portal.
        </div>
      </div>

      <div fxFlex="5" fxLayoutAlign="end center" fxLayout="column">
        <div
          fxFlex
          fxLayout="row"
          fxLayoutGap="2em"
          matTooltip="Please complete the fields above to sign up for an account."
          matTooltipPosition="above"
          [matTooltipDisabled]="isValid()"
        >
          <button
            mat-flat-button
            class="appSquareButton appSignUp"
            color="accent"
            name="register"
            fxFlex="100"
            [disabled]="!isValid()"
            (click)="register()"
          >
            SIGN UP
          </button>
        </div>
      </div>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Communicating"
      fxLayoutAlign="center center"
    >
      <h3 class="primary-dark-text header-text">Signing Up</h3>
      <mat-spinner></mat-spinner>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Next"
    >
      <div fxFlex fxLayoutAlign="center center">
        <h3 class="primary-dark-text header-text">Almost There.</h3>
      </div>
      <div fxFlex fxLayoutAlign="center center">
        <img alt="check" src="/assets/images/checkmark.svg"
        style="width: auto; height: 100%; margin: auto; align-self: center;" />
      </div>
        <!-- TODO: Old text when email was enabled -->
        <!-- <p>You should receive a confirmation email shortly containing the instructions to confirm your registration.
          Please follow those instructions to complete your account setup.</p>
        <p>If you do not see an email from the system, please check the "Spam" and/or "Promotions" sections of your email provider.</p> -->
        <!-- TODO: New text to enable users to loging wihtout emailed temp pw -->
        <p>You have successfully signed up for the New Mexico Shared Stewardship Portal.</p>
        <p>
          Your temporary password is
          <span
            class="temp-password"
            (click)="this.copyToClipboard(this.temporaryPassword)"
            >{{ this.temporaryPassword }}
          </span>
          (click the password to copy). Once you sign in you will be required to change your password.
        
          <br />
          <span *ngIf="showCopiedMessage" class="copied-message">
            Copied temp password to clipboard.
          </span>
        </p>
        <p><b>
          Please copy your temporary password and use it the first time you sign
          in with your email.</b>
        </p>


      <div fxFlex fxLayoutAlign="center center">
        <a mat-flat-button color="accent" class="centerText" (click)="login()">GO TO SIGN IN</a>
      </div>
      <div fxFlex fxLayoutAlign="center center">
        <a routerLink="/" class="widthFill centerText">Back to Home</a>
      </div>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Error"
    >
      <div fxFlex fxLayoutAlign="center center">
        <h3 class="primary-dark-text header-text">Something went wrong!</h3>
      </div>

      <p>
        This may occur if a user with that email already exists. If you think
        this may be the case, use the "Forgot Password" link from the Sign In
        screen.
      </p>

      <p>
        Click the Retry button below to return to the form so that you can try
        again or the Back to Home link to return to the home screen.
      </p>

      <div fxFlex fxLayoutAlign="center center">
        <button mat-button (click)="return()">Retry</button>
      </div>

      <div fxFlex fxLayoutAlign="center center">
        <a routerLink="/" class="widthFill centerText">Back to Home</a>
      </div>
    </div>
  </mat-card>
</div>
