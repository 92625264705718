import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ApiService } from '../../_shared/services/api.service';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { MatDialog } from '@angular/material/dialog';
import { Role, allRoles } from '../../_shared/models/roles.model';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { User } from '../admin.model';
import { AuthorizationService } from 'src/app/_shared/services/authorization.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['last', 'first', 'email', 'requestEditor', 'displayRole', 'actions'];
  users: User[];
  dataSource = new MatTableDataSource<User>([]);
  allRoles: Role[] = allRoles();
  acceptableRoles: string[] = [];
  leadOrg: any;
  isRefreshing: boolean = false;


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private auth: AuthorizationService) { }


  ngOnInit(): void {
    if (this.auth.login.orgs == undefined) {     
      this.auth.authorize().subscribe((login) =>
      {  
        this.auth.login = login;
        if (!this.isRefreshing) {
          this.refreshList();
        }
        
      })
    }
    else {
      this.refreshList();      
    }    
  }

  refreshList() {
    this.isRefreshing = true;
    this.leadOrg = this.auth.login.orgs.organization;
      this.dataSource.paginator = this.paginator;
      this.sort.sort(({ id: 'lastfirst', start: 'asc' }) as MatSortable);
      this.dataSource.sort = this.sort;
  
      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (sortHeaderId == 'lastfirst') {
          return (data['last'] + data['first']).toLocaleLowerCase();
        }
  
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
  
        return data[sortHeaderId];
      };
  debugger;
      const saveRef = this.dialog.open(LoadingDialogComponent, {
        width: '25rem',
        data: "Loading Users",
        disableClose: true
      });
  
      let org =  this.auth.login.orgs.organization;
  
      this.api.post<User[]>("Users", "GetAllByOrg", { searchString: org }, true).subscribe(res => {
  
        this.acceptableRoles = [];
  
        for (let role of this.allRoles)
          this.acceptableRoles.push(role.name);
  
        //This filters out all the extra roles we may have in the system
        //For instance we dont want to show Develoiper roles or feature roles
        for (let usr of res) {
          let oldRoles = usr.roles;
          usr.roles = [];
          for (let role of oldRoles)
            if (this.acceptableRoles.includes(role))
              usr.roles.push(role);
        }
  
        this.users = res;
        this.initDataSource();
        saveRef.close();
      })    
  }

  initDataSource() {
    this.dataSource.data = this.users;// = new MatTableDataSource<User>(this.users);
  }

  manageRolesFor(user: User) {
    const dialogRef = this.dialog.open(ManageRolesComponent, {
      width: '35rem',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let unassignRole = "";

        for (let role of this.allRoles)
          if (role.name !== result)
            unassignRole = role.name;

        let req =
        {
          unassign: unassignRole
          , assign: result
          , id: user.id
          , email: user.email
        };

        const saveRef = this.dialog.open(LoadingDialogComponent, {
          width: '25rem',
          data: "Saving Roles",
          disableClose: true
        });

        this.api.post<any>("Users", "ManageRoles", req, true).subscribe(usr => {
          let oldRoles = usr.result.roles;
          usr.roles = [];
          for (let role of oldRoles)
            if (this.acceptableRoles.includes(role))
              usr.roles.push(role);
          user.roles = usr.roles;

          let org =  this.auth.login.orgs.organization;

          this.api.post<User[]>("Users", "GetAllByOrg", { searchString: org }, true).subscribe(res => {

            this.users = res;
            this.initDataSource();

            saveRef.close();
          });
        });

      }
      console.log('The dialog was closed', result);
    });
  }

}
