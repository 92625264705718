import { FieldBase } from './field-base';

export class TextareaField extends FieldBase<string> {
  controlType = 'textarea';
  type: string;
  rows: number;
  maxlength?: string;
  minlength?: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.rows = options['rows'] || '';
    this.maxlength = options['maxlength'] || '';
    this.minlength = options['minlength'] || '';
  }
}
