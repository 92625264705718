import { Component, OnInit, OnDestroy } from '@angular/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from 'src/app/_shared/services/dashboard.service';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-all-cards-view',
  templateUrl: './all-cards-view.component.html',
  styleUrls: ['./all-cards-view.component.scss']
})
export class AllCardsViewComponent implements OnInit, OnDestroy {

  public cardGrid = [];
  public refreshId = undefined;

  constructor(private dialog: MatDialog, private dashboardService : DashboardService) { }
  ngOnDestroy(): void {
    if(this.refreshId != undefined)
      clearInterval(this.refreshId);
  }

  refresh() {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading All Cards",
      disableClose: false
    });
    this.dashboardService.getCards().subscribe(result => {
      this.cardGrid = result;
      setTimeout(() => { saveRef.close()}, 500)
    })
  }

  ngOnInit(): void {
    
    //this.refreshId = setInterval(() => { this.refresh() }, 5000 );
    
    this.refresh();
  }

  // cardGrid = [
  //   [ 
      // { title: "ASSESSMENTS", 
      //   bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Curvy+Line+Graph.svg", 
      //   description: "The assessments stats are based on data entered into this app.",
      //   link: "https://google.com",
      //   id: 7,
      //   stats: [ 
      //     { value: "96,432", label: "Acres Assessed" },
      //     { value: "16,432", label: "Acres With Resource Concerns" }
      //   ]  
      // }
  //   ],
  //   [ 
  //     { title: "INVENTORY", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Flat+Bar+Graph.svg", 
  //       stats: [ 
  //         { value: "56,789", label: "Acres Inventoried" }
  //       ]  
  //     }
  //   ],
  //   [  
  //     { title: "PLANTS", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Desert+Plants.svg", 
  //       stats: [ 
  //         { value: "189", label: "Total Acres With Vegetation Resource Concerns" }
  //       ]  
  //     }
  //   , { title: "ANIMALS", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Deer.svg", 
  //       stats: [ 
  //         { value: "4,221", label: "Total Acres With Wildlife Resource Concerns" }
  //       ]  
  //     }
  //   , { title: "ENERGY", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/High+Tension+Power+Lines.svg", 
  //       stats: [ 
  //         { value: "32.6 Million", label: "Total Acres With Infrastructure Resource Concerns" }
  //       ]  
  //     }
  //   ],
  //   [ 
  //     { title: "WATER", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Water.svg", 
  //       stats: [ 
  //         { value: "14", label: "Total Acres With Water Resource Concerns" }
  //       ]  
  //     }
  //   , { title: "AIR", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Clouds.svg", 
  //       stats: [ 
  //         { value: "1,234,567", label: "Total Acres With Air Resource Concerns" }
  //       ]  
  //     }
  //   , { title: "SOILS", 
  //       bg: "https://timmons-branding.s3.amazonaws.com/Shared+Images/Soils.svg", 
  //       stats: [ 
  //         { value: "987,654,321", label: "Total Acres With Soil Resource Concerns" }
  //       ]  
  //     }
  //   ]
  // ]

}
