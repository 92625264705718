<div id="appHeaderBar" class="mat-elevation-z4" fxFlex fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="start center">

  <!--App Logo-->
  <div fxFlex="nogrow" fxShrink fxLayout="row" fxLayoutGap="1.5rem" style="height:100%; padding: 0.25rem"
    fxLayoutAlign="center center">
    <a id="appHeaderLogoLink" name="homeLink" color="accent" routerLink="/" class="centerText navLink"><img alt="Logo"
        id="appHeaderLogo" name="logo" src="https://timmons-branding.s3.amazonaws.com/NewMexico/HeaderLogo.png" />
    </a>
  </div>

  <div fxFlex="grow" fxLayout="column" style="height:100%; overflow:hidden; width: 100%;" fxLayoutAlign="center start"
    class="">

    <div style="height:35%; width: 100%; font-size: 0.95rem; align-content: center;" class="">
      <app-user-menu class="align-top align-right fillHeight flexy"></app-user-menu>
    </div>

    <div fxFlex="65" fxLayout="row" fxLayoutGap="1.5rem" style="height:100%; width: 100%;" fxLayoutAlign="start center">
      <app-shared-nav class="headerNav"></app-shared-nav>
    </div>

  </div>

</div>
