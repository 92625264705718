<div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxFlex=100 fxLayoutAlign="top left"
style="margin:1.5rem 0; width: 100%;">

  <mat-card class="appSolidCard mat-elevation-z0" style="margin-bottom: 1rem; padding-top: 0 !important;">

    <div class="appTableSpacing">
      <div fxFlex fxLayout="column">
        <div style="padding: 1rem 0" class="themePrimaryTextInverse">
          <h1 class="appMainCardHeader">Manage Users for {{this.leadOrg}}</h1>
        </div>
        <div name="userGrid" class="mat-elevation-z0 appSolidTable" style="font-size: 0.9rem;">
          <table aria-describedby="Users table" mat-table [dataSource]="dataSource" matSort style="width: 100%">

            <!-- Last Name -->
            <ng-container matColumnDef="last">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
              <td mat-cell *matCellDef="let user"> {{user.last}} </td>
            </ng-container>

            <!-- First Name -->
            <ng-container matColumnDef="first">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
              <td mat-cell *matCellDef="let user"> {{user.first}} </td>
            </ng-container>

            <!-- Email -->
            <ng-container matColumnDef="email">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let user"> {{user.email}} </td>
            </ng-container>

            <!-- Request -->
            <ng-container matColumnDef="requestEditor">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Requested Editor </th>
              <td mat-cell *matCellDef="let user"> {{ user.requestEditor ? "Yes" : "" }} </td>
            </ng-container>

            <!-- Roles -->
            <ng-container matColumnDef="displayRole">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
              <td mat-cell *matCellDef="let user"> {{user.displayRole}} </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let user">
                <button class="appClickable" mat-button (click)="manageRolesFor(user)">Update Role</button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>
