import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Organization } from 'src/app/_shared/models/user.model';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-partner-add-dialog',
  templateUrl: './partner-add-dialog.component.html',
  styleUrls: ['./partner-add-dialog.component.scss']
})
export class PartnerAddDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PartnerAddDialogComponent>,
    private api: ApiService
  ) { }

  partnerFormControl!: FormControl;
  partnerTypeFormControl!: FormControl;

  partner: any;
  orgs: Organization[];
  filteredOrgs: Organization[] = [];
  //Temporary
  types: any[] = [{ name: "Financial" }, { name: "Planning" },
  { name: "Implementation" }, { name: "Education & Outreach" },
  { name: "Monitoring" }, { name: "Utilization" }];

  ngOnInit(): void {
    const that = this;

    this.partnerFormControl = new FormControl('', [
      Validators.required
    ]);

    this.partnerTypeFormControl = new FormControl('', [
      Validators.required
    ]);


    this.api.get<Organization[]>("Organization", "GetAll", true).subscribe(result => {
      result.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      this.orgs = result;

      let orgsToFilter = this.orgs;

      // Filter lead org and current
      let removeNames = [this.data?.leadOrg];
      const currentPartners = this.data?.currentPartners || [];
      const currentPartnerNames = currentPartners.map((x) => { return x.name; });
      removeNames = removeNames.concat(currentPartnerNames);

      this.filteredOrgs = orgsToFilter.filter(function (obj) {
        return !removeNames.includes(obj.name);
      })
    });

  }

  onSubmit(): void {
    let partner = this.partnerFormControl.value;
    let type = this.partnerTypeFormControl.value;

    let partnerObject =
    {
      "name": partner,
      "type": type
    }

    this.dialogRef.close(partnerObject);
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }

  isValid(): boolean {
    if (this.partnerFormControl.valid && this.partnerTypeFormControl.valid) {
      return true;
    } else {
      return false;
    }
  }

}
