import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { Project } from '../_shared/models/domain/project.model';
import { ApiService } from '../_shared/services/api.service';
import { saveAs } from 'file-saver-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormField } from '@angular/material/form-field';
import { ProjectFilterDialogComponent } from 'src/app/project-filter-dialog/project-filter-dialog.component';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss']
})
export class ProjectFilterComponent implements OnInit, AfterViewInit {

  pageSize = 10;
  displayedColumns: string[] = ['id', 'name','type','leadOrg','status','modified','view'];
  projects: Project[];
  dataSource = new MatTableDataSource<Project>([]);
  leadOrg: string;
  statuses: any[] = [{ name: "Planned" },
                  { name: "In Progress" },
                  { name: "Complete" },
                  { name: "Deferred" },
                  { name: "On Hold" }
                ];
  types: any[] = [{ name:"Forest Restoration"},
                  { name: "Watershed Health" },
                  { name: "Riparian Restoration" },
                  { name: "Community Protection" },
                  { name: "Timber or Forest Utilization" },
                  { name: "Range Improvement" },
                  { name: "Planning/Surveys" },
                  { name: "Other" }
                ];
  orgs: any[];
  focals: any[] = [];
  priorities: any[] = [];
  activityManagers: any = {};
  excelResult: any;
  exportFormat: string;
  maxDate: Date;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  // Currently not in AC may need later
  //searchFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  statusDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  orgDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  typeDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  focalDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  priorityDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  activityManagerDropdownFormControl: FormControl = new FormControl({ value: null, disabled: false });
  activityCompletionDateStartControl: FormControl = new FormControl({ value: null, disabled: false });
  activityCompletionDateEndControl: FormControl = new FormControl({ value: null, disabled: false });
  exportFormatControl: FormControl = new FormControl({ value: "excel", disabled: false });

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'modified', start: 'desc' }) as MatSortable);
    this.dataSource.sort = this.sort;
    this.exportFormat = "excel";
    this.maxDate = new Date();
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (sortHeaderId == 'name') {
        return data['name'].toLocaleLowerCase();
      }

      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    this.api.post<any>("Project", "GetAllSearch", { placeholder: 'request' }).subscribe(result => {
      this.projects = result;

      this.initDataSource();
    });

    this.api.get<any[]>("Organization", "GetAll").subscribe(result => {
      result.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      this.orgs = result;
    });

    this.api.get<any[]>("Focal", "GetAllNames").subscribe(result => {
      this.focals = result.map(e => { return { name: e } });
      this.focals.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    });

    this.api.get<any[]>("Landscape", "GetAllNames").subscribe(result => {
      this.priorities = result.map(e => { return { name: e } });
      this.priorities.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    });

    this.api.get<any[]>("Organization", "GetAllWorkAgents").subscribe(result => {
      result.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      this.activityManagers = result;
    });
  }

  ngAfterViewInit(): void {
    // (╯ ͠° ͟ʖ ͡°)╯┻━┻  https://github.com/angular/components/issues/15027
    setTimeout(() => this.formFields.forEach(ff => ff.updateOutlineGap()), 100);
  }

  initDataSource() {
    this.dataSource.data = this.projects;
  }

  viewSummary(id: any) {
    const url = `${location.origin}/#/app/project/view?id=${id}`;
    window.open(url, '_blank');
  }

  applyFilter() {
    let statusFilters = this.statusDropdownFormControl.value;
    let orgFilters = this.orgDropdownFormControl.value;
    let typeFilters = this.typeDropdownFormControl.value;
    let focalFilters = this.focalDropdownFormControl.value;
    let priorityFilters = this.priorityDropdownFormControl.value;
    let activityManagerFilters = this.activityManagerDropdownFormControl.value;
    let activityCompletionDateStart = this.activityCompletionDateStartControl.value;
    let activityCompletionDateEnd = this.activityCompletionDateEndControl.value;

    class FilterRequest {
      types: string[];
      orgs: string[];
      statuses: string[];
      focals: string[];
      priorities: string[];
      activityManagers: string[];
      activityCompletionDateRange: Date[];
    }

    let filters = new FilterRequest();

    if(Array.isArray(typeFilters) && typeFilters.length > 0) {
      filters.types = typeFilters || [];
    }

    if(Array.isArray(statusFilters) && statusFilters.length > 0) {
      filters.statuses = statusFilters || [];
    }

    if(Array.isArray(orgFilters) && orgFilters.length > 0) {
      filters.orgs = orgFilters || [];
    }

    if(Array.isArray(focalFilters) && focalFilters.length > 0) {
      filters.focals = focalFilters || [];
    }

    if(Array.isArray(priorityFilters) && priorityFilters.length > 0) {
      filters.priorities = priorityFilters || [];
    }

    if(Array.isArray(activityManagerFilters) && activityManagerFilters.length > 0) {
      filters.activityManagers = activityManagerFilters || [];
    }
 
    if (activityCompletionDateStart != null && activityCompletionDateEnd != null) {
      filters.activityCompletionDateRange = [activityCompletionDateStart, activityCompletionDateEnd]; //activityCompletionDateStart.toISOString();;
    }

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Applying Filter.",
      disableClose: true
    });
    this.api.post<any>("Project", "GetAllSearch", filters).subscribe(result => {
      this.projects = result;

      dialogRef.close();

      this.initDataSource();
    });
  }

  clearFilter() {
    this.statusDropdownFormControl.reset();
    this.orgDropdownFormControl.reset();
    this.typeDropdownFormControl.reset();
    this.focalDropdownFormControl.reset();
    this.priorityDropdownFormControl.reset();
    this.activityManagerDropdownFormControl.reset();
    this.activityCompletionDateStartControl.reset();
    this.activityCompletionDateEndControl.reset();

    this.api.post<any>("Project", "GetAllSearch", { placeholder: 'request' }, true).subscribe(result => {
      this.projects = result;

      this.initDataSource();
    });
  }

  public onToggle(val: string) {
    this.exportFormat = val;
  }

  search(data: any){
    this.projects = this.projects.filter(function (obj) {
      return obj.name !== data.name;
    });
  }

  export(){
    if (this.exportFormat == 'excel') {
      console.log("export excel");
      this.exportExcel();
    }
    else {
      console.log("export shapefile");
      this.exportShapefile();
    }
  }

  async exportExcel(){
    let statusFilters = this.statusDropdownFormControl.value
    let orgFilters = this.orgDropdownFormControl.value
    let typeFilters = this.typeDropdownFormControl.value
    let focalFilters = this.focalDropdownFormControl.value
    let priorityFilters = this.priorityDropdownFormControl.value
    let activityManagerFilters = this.activityManagerDropdownFormControl.value;
    let activityCompletionDateStart = this.activityCompletionDateStartControl.value;
    let activityCompletionDateEnd = this.activityCompletionDateEndControl.value;
    
    class FilterRequest {
      types: string[];
      orgs: string[];
      statuses: string[];
      focals: string[];
      priorities: string[];
      activityManagers: string[];
      activityCompletionDateRange: Date[];
    }

    let filters = new FilterRequest();

    if(Array.isArray(typeFilters) && typeFilters.length > 0) {
      filters.types = typeFilters || [];
    }

    if(Array.isArray(statusFilters) && statusFilters.length > 0) {
      filters.statuses = statusFilters || [];
    }

    if(Array.isArray(orgFilters) && orgFilters.length > 0) {
      filters.orgs = orgFilters || [];
    }

    if(Array.isArray(focalFilters) && focalFilters.length > 0) {
      filters.focals = focalFilters || [];
    }

    if(Array.isArray(priorityFilters) && priorityFilters.length > 0) {
      filters.priorities = priorityFilters || [];
    }

    if(Array.isArray(activityManagerFilters) && activityManagerFilters.length > 0) {
      filters.activityManagers = activityManagerFilters || [];
    }
 
    if (activityCompletionDateStart != null && activityCompletionDateEnd != null) {
      filters.activityCompletionDateRange = [activityCompletionDateStart, activityCompletionDateEnd]; //activityCompletionDateStart.toISOString();;
    }

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Exporting Tables.",
      disableClose: true
    });

    this.api.postGetBlob<any>("Excel", "Export", filters, true).subscribe(response => {
      console.log(response);
      saveAs(response, "ProjectExport.xlsx");

      dialogRef.close();
    })
  }

  async exportShapefile(){
    let statusFilters = this.statusDropdownFormControl.value
    let orgFilters = this.orgDropdownFormControl.value
    let typeFilters = this.typeDropdownFormControl.value
    let focalFilters = this.focalDropdownFormControl.value
    let priorityFilters = this.priorityDropdownFormControl.value
    let activityManagerFilters = this.activityManagerDropdownFormControl.value;
    let activityCompletionDateStart = this.activityCompletionDateStartControl.value;
    let activityCompletionDateEnd = this.activityCompletionDateEndControl.value;

    class FilterRequest {
      types: string[];
      orgs: string[];
      statuses: string[];
      focals: string[];
      priorities: string[];
      activityManagers: string[];
      activityCompletionDateRange: Date[];
    }

    let filters = new FilterRequest();

    if(Array.isArray(typeFilters) && typeFilters.length > 0) {
      filters.types = typeFilters || [];
    }

    if(Array.isArray(statusFilters) && statusFilters.length > 0) {
      filters.statuses = statusFilters || [];
    }

    if(Array.isArray(orgFilters) && orgFilters.length > 0) {
      filters.orgs = orgFilters || [];
    }

    if(Array.isArray(focalFilters) && focalFilters.length > 0) {
      filters.focals = focalFilters || [];
    }

    if(Array.isArray(priorityFilters) && priorityFilters.length > 0) {
      filters.priorities = priorityFilters || [];
    }

    if(Array.isArray(activityManagerFilters) && activityManagerFilters.length > 0) {
      filters.activityManagers = activityManagerFilters || [];
    }
 
    if (activityCompletionDateStart != null && activityCompletionDateEnd != null) {
      filters.activityCompletionDateRange = [activityCompletionDateStart, activityCompletionDateEnd]; 
    }
 
    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Exporting Shapefile.",
      disableClose: true
    });

    this.api.postGetBlob<any>("Shapefile", "Export", filters, true).subscribe(response => {
      console.log(response);
      saveAs(response, "ProjectExport.zip");

      dialogRef.close();
    })
  }
  openDialog() {
    const dialogRef = this.dialog.open(ProjectFilterDialogComponent, {
      width: '50rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
    this.openSnackBar('File export error.', 'Close');
  }

}
