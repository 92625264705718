import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeoStatContainerVm, GeoStatRecordVm } from './geo-stats.model';
import { ProjectGeoService } from './project-geo.service';

@Component({
  selector: 'app-project-geo-card',
  templateUrl: './project-geo-card.component.html',
  styleUrls: ['./project-geo-card.component.scss']
})
export class ProjectGeoCardComponent implements OnInit, OnDestroy {
  @Input() projectId: number;
  @Input() entityType: string = 'project';
  geoSub: Subscription;
  geoStats: GeoStatContainerVm = {};
  interval;

  constructor(
    public projectGeoService: ProjectGeoService,
  ) { }

  ngOnInit(): void {
    this.geoSub = this.projectGeoService.geoStats.subscribe((data) => {
      if (data) {
        this.geoStats = data;
      }
    });

    this.startStatTimer();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.projectGeoService.resetStats();
    this.geoSub.unsubscribe();
  }

  getStats(projectId: number) {
    if (projectId && this.entityType === 'project') {
      this.projectGeoService.requestStatData(projectId, 'Project', 'CheckProjectStats');
    } else if (projectId && this.entityType === 'activity') {
      this.projectGeoService.requestStatData(projectId, 'Activity', 'CheckActivityStats');
    }
  }

  startStatTimer() {
    this.interval = setInterval(() => {
      console.log(`Checking ${this.projectGeoService.hasStats.getValue()}`);
      if (this.projectGeoService.hasStats.getValue()) {
        console.log('Clear Interval');
        clearInterval(this.interval);
      }
      console.log(`Interval Check ${this.projectId}`);
      this.getStats(this.projectId);
    }, 10000);
  }

}
