<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-header class="appTrashCanHeaderBuffer">
        <mat-card-title class="appH3SecondaryHeader">{{funding.fundingName}}
        </mat-card-title>
        <div class="appTrashCan" [appRequireAclEapoPartner]="['Activity:Edit', projectLeadOrg, partners]">
          <mat-icon (click)="removeFunding($event)">delete</mat-icon>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div>
          <!-- WIP needs the view activity details page -->
          <p><span class="appFieldLabel">Funding Organization:</span><span class="appFieldContent">
              {{funding.fundingOrg}}</span></p>
          <p><span class="appFieldLabel">Is Match?:</span><span class="appFieldContent">
              {{funding.match ? 'Yes': 'No'}}</span></p>
          <p><span class="appFieldLabel">Planned Amount:</span><span class="appFieldContent">
              {{ funding.plannedAmount | currency }}</span></p>
          <p><span class="appFieldLabel">Actual Amount:</span><span class="appFieldContent">
              {{ funding.actualAmount | currency }}</span></p>
          <div>
            <div class="appClickable" [appRequireAclEapoPartner]="['Activity:Edit', projectLeadOrg, partners]" style="position: absolute; right: 1rem; bottom: .25rem">
              <p class="appViewColor" (click)="editFunding()"><strong>Edit</strong></p>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
