<div [id]="mainMapId" style="height: 100%; width: 100%;">
</div>

<div class="toggleLayerButton" *ngIf="!hideControls">
  <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onLayerSelect($event)" class="rounderToggles">
    <mat-button-toggle class="appBasemapToggle widerToggles" value="topo" checked>TOPOGRAPHIC</mat-button-toggle>
    <mat-button-toggle class="appBasemapToggle widerToggles" value="aerial">AERIAL</mat-button-toggle>
    <mat-button-toggle class="appBasemapToggle widerToggles" value="streets">STREETS</mat-button-toggle>
    <mat-button-toggle class="appBasemapToggle widerToggles" value="grey">LIGHT GREY</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div id="appDrawGuidance" *ngIf="editingBounds && showHelp">
  Click in the map to draw the {{ this.featureName }} boundary, add new vertices with each new click, and double-click
  to end.
  If needed, you can hold the 'shift' key while clicking and dragging to draw a curved line, click and drag the
  boundary to reshape it, and/or hold the 'alt' key and click on a corner of your shape to remove that point.
</div>

<div class="editBoundsButton" *ngIf="showEditCreateButtons">
  <button class="appSkinnyFontButton appSquareButton" mat-flat-button color="accent" (click)="dismissImport()"
    *ngIf="importEnabled">CANCEL SELECT</button>
  <button class="appSkinnyFontButton appSquareButton appImportButton" [appRequireACL]="'Project:Edit'" mat-flat-button color="accent"
    (click)="importShapeFile()" *ngIf="!isDrawing() && !hasArea() && !importEnabled">Import</button>
  <button class="appSkinnyFontButton appSquareButton" [appRequireACL]="'Project:Edit'" *ngIf="!editingBounds && !highlightLayer" mat-flat-button
    color="accent" (click)="drawNewAssessmentArea()">Draw</button>
  <button class="appSkinnyFontButton appSquareButton" *ngIf="editingBounds" mat-flat-button color="accent"
    (click)="stopDraw()">Cancel</button>
  <button class="appSkinnyFontButton appSquareButton" *ngIf="!editingBounds && highlightLayer" mat-flat-button
    color="accent" (click)="editBoundsClick()">Edit</button>
</div>

<img 
  *ngIf="editingBounds" 
  id="appMapIcon" 
  alt="tooltip" 
  src="/assets/images/info-icon.png" 
  class="appSecondaryIcon appCustomTooltip appFormTooltip"
  (mouseover)="showHelp = true"
  (mouseout)="showHelp = false">

<!-- Assessment overlay -->
<div *ngIf="editingBounds" class="appSlimMapOverlay">

  <a id="image-download" download="map.png"></a>
  <div mat-card id="assessmentEditOverlayContents">
    <mat-card class="assessmentEditOverlayContents" *ngIf="true">
      <mat-card-title class="title">
        <div fxFlex fxLayout="row" fxLayoutGap="0.75rem">
          <p>Acres: {{ this.drawnShape?.acres | number : '1.1-1' || '' }}</p>
          <button mat-button class="align-right buttonhax" color="primary" (click)="stopDraw()">X</button>
        </div>
      </mat-card-title>
      <mat-card-content class="title align-bottom">
        <div fxFlex fxLayout="column" fxLayoutGap="0.75rem" style="font-size: 0.75rem;">
          <p style="font-size: 1.25rem; text-align: center; color: #54200E;" *ngIf="!hasShape()">
            Boundary is required.</p>
          <button class="appSkinnyFontButton appSquareButton" [disabled]="!hasShape()" mat-flat-button color="accent"
            (click)="saveBoundsClick()">Keep</button>
          <button class="appSkinnyFontButton appSquareButton" *ngIf="!hasShape()" mat-flat-button color="primary"
            (click)="drawNewAssessmentArea()">Draw Shape</button>
          <button class="appSkinnyFontButton appSquareButton" *ngIf="hasShape()" mat-flat-button color="primary"
            (click)="drawNewAssessmentArea()">Draw Additional Shape</button>
          <button class="appSkinnyFontButton appSquareButton" *ngIf="hasShape()" mat-button color="primary"
            (click)="clearBounds()">Clear Feature</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>


<div id="assessmentEditOverlay">
</div>
<div id="importOverlay">
</div>
