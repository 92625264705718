<section class="layerListItem" *ngIf="layer.legend && layer.legend.length">
  <mat-expansion-panel #mainLegend [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="appH3SecondaryHeader">
        {{ layer.name }}
      </mat-panel-title>
      <span>
        <mat-icon class="appExpExpandIcon" *ngIf="!mainLegend.expanded">add_circle</mat-icon>
      </span>
      <span>
        <mat-icon class="appExpExpandIcon" *ngIf="mainLegend.expanded">remove_circle</mat-icon>
      </span>
    </mat-expansion-panel-header>
    <div *ngFor="let legendItem of layer.legend"
      style="display: flex; min-height: 1.5rem; flex-direction: row; padding: 0.25rem 0.2rem;">
      <img alt="Legend swatch" *ngIf="legendItem?.display?.url != undefined" [src]="legendItem.display.url"
        style="width: 1.8rem; height: 1.2rem; margin: auto 0; margin-right:0.35rem; border: 1px solid lightgray; padding: 0.1rem;" />
      <div *ngIf="legendItem?.display?.color" [style.background-color]="legendItem.display.color"
        style="width: 1.7rem; height: 1.1rem; margin: auto 0; margin-right:0.35rem; border: 1px solid lightgray; padding: 0.1rem;">
      </div>
      <div *ngIf="legendItem?.color"
        [style.background-color]="'rgb(' + legendItem.color.r + ',' + legendItem.color.g + ',' + legendItem.color.b + ')'"
        style="width: 1.7rem; height: 1.1rem; margin: auto 0; margin-right:0.35rem; border: 1px solid lightgray; padding: 0.1rem;">
      </div>
      <div *ngIf="legendItem?.display?.symbol" [style.background-color]="this.symbolColor"
        style="width: 1.7rem; height: 1.1rem; margin: auto 0; margin-right:0.35rem; border: 1px solid lightgray; padding: 0.1rem;">
      </div>
      <span class="appLegendLabel">{{legendItem.label}}</span>
    </div>
  </mat-expansion-panel>
</section>
