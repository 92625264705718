
import { Subscription, Observable, Subject } from 'rxjs';
import { ConfiguredIdentifySources, Result } from '../_shared/services/map/identify.service';

export enum ImportState {
    Idle,
    Searching,
    HasResults,
    NoResults
}

export class Import {
    private state: ImportState = ImportState.Idle;
    results: any[] = [];
    index: number = 0;

    sub: Subject<any>;

    constructor(private identify: ConfiguredIdentifySources) {
        this.sub = new Subject<any>();
    }

    search(coords: any) {
        this.state = ImportState.Searching;
    }

    getState(): ImportState {
        return this.state;
    }

    setState(state: ImportState) {
        this.state = state;
    }

    getIndex(): number {
        return this.index;
    }

    getResultCount(): number {
        return this.results.length;
    }

    getFocusedResult() {
        if (this.index < this.results.length)
            return this.results[this.index]

        return null;
    }

    hasNextResult() {
        return this.index < this.results.length;
    }

    hasPreviousResult() {
        return this.index > 0;
    }

    nextResult() {
        this.index++;
    }

    previousResult() {
        this.index--;
    }

    clear() {
        this.index = 0;
        this.state = ImportState.Idle;
        this.results = [];
    }

    onResult(): Observable<any> {
        return this.sub;
    }
}
