import { FixedLengthArray } from '../../types/fixed-length-array';
import { LegendItem } from './legend.model';

/**
 * App Map Config
 */
export interface MapCategory {
    category_key: string;
    opacity?: number;
    layers?: MapLayer[];
    selection?: MapSelection;
    name: string;
    multiphasic: boolean;
    openness?: string;
}

export interface Service {
    key: string;
    baseUrl: string;
    token: string;
}

export abstract class MapSelection {
    constructor(selectionType: string) {
        this.selection_type = selectionType;
    }

    abstract isSelected(key: string): boolean;
    abstract select(key: string): void;

    selection_type?: string;
}

export class MonoselectiveSelection extends MapSelection {
    selection_key: string;
    constructor(selection: string) {
        super(MapConfigConstants.monoselection);
        this.selection_key = selection;
    }

    public isSelected(key: string): boolean {
        return this.selection_key === key;
    }

    select(key: string): void {
        this.selection_key = key;
    }
}

export class PolyselectiveSelection extends MapSelection {
    selection_keys: string[] = [];
    constructor(selection: string[]) {
        super(MapConfigConstants.polyselection);
        this.selection_keys = selection;
    }

    public isSelected(key: string): boolean {
        return this.selection_keys.includes(key);
    }

    select(key: string): void {
        if (this.selection_keys.includes(key)) {
            this.selection_keys = this.selection_keys.filter(ky => ky !== key);
        }
        else {
            this.selection_keys.push(key);
        }
    }
}

export interface MapLayer {
    key: string;
    name?: string;
    opacity?: number;
    config?: MapLayerConfig;
    identify?: MapLayerIdentify;
    legend?: LegendItem[];
}

export interface MapLayerConfig {
    type?: string;
    value?: MapLayerEsriExportValue | MapLayerEsriFeatureServiceValue | MapLayerXYZValue | MapLayerWMTSValue | MapLayerWMSValue | MapLayerMVTValue;
}

export interface MapLayerEsriExportValue {
    endpoints?: MapLayerEndpoint[];
    extent?: FixedLengthArray<[number, number, number, number]>;
    layerDefs?: any;
}

export interface MapLayerEsriFeatureServiceValue {
    endpoints?: MapLayerEndpoint[];
    extent?: FixedLengthArray<[number, number, number, number]>;
    layerDefs?: any;
}

export interface MapLayerWMTSValue {
    endpoints?: any[];
    extent?: FixedLengthArray<[number, number, number, number]>;
}

export interface MapLayerWMSValue {
    endpoints?: any[];
    extent?: FixedLengthArray<[number, number, number, number]>;
}

export interface MapLayerMVTValue {
    endpoints?: any[];
}

export interface MapLayerXYZValue {
    endpoints?: MapLayerEndpoint[];
    maxZoom?: number;
    minZoom?: number;
}

export interface MapLayerEndpoint {
    url?: string;
    zIndex?: number;
    tokenKey?: string;
    layersToShow?: string;
    layerDefs?: any;
    bbox?: any;
}

export interface MapLayerIdentify {
    enabled?: boolean;
    import?: boolean;
    minZoom?: number;
    dataMapping?: MapLayerDataMapping;
    canViewDetails?: boolean;
    idTransform?: number;
    typeKey?: string;
    queryLayerIds?: number[];
    queryUrl?: string;
    tolerance?: any;
}

export interface MapLayerDataMapping {
    id?: MapLayerFieldName;
    title: MapLayerFieldName;
    fields?: MapLayerFieldName[];
}

export interface MapLayerFieldName {
    name?: string;
    query?: string;
    static?: string;
}

/**
 * DB Map Config
 */
export interface DBMapConfig {
    layers?: DBMapConfigLayers[];
    layerGroups?: DBMapConfigLayerGroups[];
    layerCategories?: DBMapConfigLayerCategories[];
}

export interface DBMapConfigLayers {
    key: string;
    xyz?: MapLayerXYZValue;
    esriExport?: MapLayerEsriExportValue;
    esriFeature?: MapLayerEsriExportValue;
    wmts?: MapLayerWMTSValue;
    mvt?: MapLayerMVTValue;
    wms?: MapLayerWMSValue;
    name?: string;
    opacity?: number;
    identify?: MapLayerIdentify;
    legend?: LegendItem[];
}

export interface DBMapConfigLayerGroups {
    key: string;
    layers?: string[];
    openness?: string;
}

export interface DBMapConfigLayerCategories {
    key: string;
    name?: string;
    hidden?: boolean;
    infoIcon?: DBMapConfigInfoIcon;
    layerGroups?: string[];
    multiphasic?: boolean;
    transparency?: number;
    selectiveness?: string;
    defaultSelection?: string[];
    usesRasterLegend?: boolean;
    mustHaveSelection?: boolean;
    openness?: string;
}

export interface DBMapConfigInfoIcon {
    iconClass?: string;
}

/**
 * Magic Strings
 */
export class MapConfigConstants {
    public static xyz: string = 'xyz';
    public static esriExport: string = 'esriExport';
    public static esriFeature: string = 'esriFeature';
    public static mvt: string = 'mvt';
    public static wmts: string = 'wmts';
    public static wms: string = 'wms';
    public static identify: string = 'identify';
    public static monoselective: string = 'monoselective';
    public static monoselection: string = 'monoselection';
    public static polyselective: string = 'polyselective';
    public static polyselection: string = 'polyselection';
}
