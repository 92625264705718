
  <div name="userGrid" class="mat-elevation-z0" style="font-size: 0.9rem;">
    <table aria-describedby="Users table" mat-table [dataSource]="dataSource" matSort style="width: 100%">

      <!-- Last Name -->
      <ng-container matColumnDef="last">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let user"> {{user.last}} </td>
      </ng-container>

      <!-- First Name -->
      <ng-container matColumnDef="first">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let user"> {{user.first}} </td>
      </ng-container>

      <!-- Email -->
      <ng-container matColumnDef="email">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>

      <!-- Organization -->
      <ng-container matColumnDef="organization">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
        <td mat-cell *matCellDef="let user"> {{ user.organization }} </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let user">
          <button class="appClickable" mat-button color="accent" (click)="updateOrganization(user)"> Update Organization </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>

