import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityCreateDialogComponent } from 'src/app/activity/activity-create-dialog/activity-create-dialog.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from 'src/app/map/error-dialog/error-dialog.component';
import { NoteCreateDialogComponent } from 'src/app/note-create-dialog/note-create-dialog.component';
import { PartnerAddDialogComponent } from 'src/app/partner/partner-add-dialog/partner-add-dialog.component';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { MapEditDialogComponent } from 'src/app/_shared/components/dialogs/map-edit-dialog/map-edit-dialog.component';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { CoreEntity } from 'src/app/_shared/models/core-entity.model';
import { Fields } from 'src/app/_shared/models/dynamic-forms/fields.model';
import { PamObject, PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AuthorizationService } from 'src/app/_shared/services/authorization.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';
import { ProjectGeoCardComponent } from '../project-geo-card/project-geo-card.component';
import { firstBy } from "thenby";

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent implements OnInit {

  @ViewChild('genInfo') genInfo: DynamicFormComponent;
  @ViewChild('narrative') narrative: DynamicFormComponent;
  @ViewChild('focus') focus: DynamicFormComponent;
  @ViewChild('slimMap') slimMap: SlimMapComponent;
  @ViewChild('projGeo') projGeo: ProjectGeoCardComponent;

  sources: any[] = [];
  target: any = { fields: {} };
  partners: any[] = [];
  project: any = {};
  activities: any[] = [];
  notes: any[] = [];
  opportunities: any[] = [];
  impediments: any[] = [];
  projectFocus: any = {}
  fields: any[];
  focusFields: any[];
  screenHeight: number;
  screenWidth: number;
  projectGeom: PamShapeData;
  projectLeadOrg: string;
  editModeOn = false;
  editMapOn = false;
  mapDialog: any;
  heightValue = '181rem;';
  projectFunding: any;
  get projectName() {
    return this.project[Fields.ProjectName] || '';
  }

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private fieldService: FieldService,
    private dialog: MatDialog,
    public authService: AuthorizationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let id = params["id"];
      if (id == undefined)
        return;

      let iid = parseInt(id);

      // Initial Project object
      this.api.post<any>("Project", "Get", [iid]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleLoad(target);
        }
      });

      // Activities
      this.api.post<any>("Activity", "ReadAll", { linkToId: iid }).subscribe(results => {
        this.activities = results;
        this.sortActivities();
      });

      // Project Funding Summary
      this.api.post<any>("Project", "FundingTotals", iid).subscribe(result => {
        this.projectFunding = result;
        this.processFundingSources(result);
      });

      // Impediments and Oppportunities API call
      this.getNotes(iid)


    });
    this.onResize();
  }

  routeToExplorer() {
    this.router.navigateByUrl(`/app/map?id=${this.target.id}&type=p`);
  }

  dateFormatEpoch(date: number) {
    var d = new Date(0)
    return d.setUTCSeconds(date);
  }

  handleLoad(target: any): void {
    this.target = target;

    this.target.created = this.dateFormatEpoch(this.target.created);
    this.target.modified = this.dateFormatEpoch(this.target.modified);

    this.project = {};
    this.project[Fields.ProjectName] = this.target.name;
    this.project[Fields.ProjectType] = this.target.fields.projectType;
    this.project[Fields.ProjectStatus] = this.target.fields.projectStatus;
    this.project[Fields.ProjectNarrative] = this.target.fields.projectNarrative;
    this.project[Fields.ProjectFocus] = this.target.fields.projectFocus;
    this.project[Fields.ProjectStrategies] = this.target.fields.projectStrategies;

    // Project focus fields
    this.projectFocus[Fields.ProjectFocus] = this.target.fields.projectFocus;
    this.projectFocus[Fields.ProjectStrategies] = this.target.fields.projectStrategies

    // Get editable fields
    this.fields = this.fieldService.getEditableFields(CoreEntity.Project, this.project);
    this.focusFields = this.fieldService.getEditableFocusFields(CoreEntity.Project, this.projectFocus)

    // Org
    this.projectLeadOrg = this.target?.fields?.leadOrganization || null;

    // Partners
    this.partners = this.target?.fields?.Partners || [];

    const narrativeFields = {};
    narrativeFields[Fields.ProjectNarrative] = this.project[Fields.ProjectNarrative];

    this.genInfo.refreshForm(this.fields);
    this.focus.refreshForm(this.focusFields);

    // Geom
    this.updateMapGeom();

    // sort partner cards
    this.sortPartners();

    // Update Project Stats
    this.projGeo.getStats(target?.id);
  }


  toggleEditMode() {
    if (this.editModeOn) {
      // Map data to DTO and update
      const payload = this.createPayload();
      this.update(payload);
    } else {
      this.editModeOn = true;
    }
  }

  processFundingSources(data: any) {
    this.sources = [];
    let container = data.container;


    for (var key in container) {

      container[key].forEach(sources => {

        let fundingSource = {
          fundingSource: "",
          fundingOrg: "",
          plannedAmount: 0,
          actualAmount: 0
        }

        sources.forEach(source => {
          fundingSource.fundingSource = source.fields.fundingName;
          fundingSource.fundingOrg = source.fields.fundingOrg;
          fundingSource.plannedAmount = fundingSource.plannedAmount + source.fields.plannedAmount;
          fundingSource.actualAmount = fundingSource.actualAmount + source.fields.actualAmount;
        });
        this.sources.push(fundingSource);
      });

    }
    this.sortSources();
  }

  toggleEditMap() {
    this.editMapOn = true;
    this.mapDialog = this.dialog.open(MapEditDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: { feature: this.projectGeom, fillColor: 'rgba(185, 232, 42, 0.5)', strokeColor: 'rgba(0, 19, 2, 0.5)' },
      disableClose: true
    });

    this.mapDialog.afterClosed().subscribe(data => {
      this.mapDialog = null;
      if (data) {
        // If there are data, save
        this.newShapeData(data);
        const payload = this.createPayload();
        this.update(payload);
      }

      this.cancelEditMap();
    });
  }

  cancelEdit() {
    this.editModeOn = false;
    this.genInfo.refreshForm(this.fields);
    this.focus.refreshForm(this.focusFields);
  }

  cancelEditMap() {
    this.editMapOn = false;
    this.rebootMap();
  }

  updateMapGeom() {
    if (this.target.fields && this.target.fields.Geometry && this.target.fields.Geometry.wkt && this.target.fields.Geometry.type && this.target.fields.Geometry.type === 'exact') {
      const geomObj: PamShapeData = {
        id: this.target.id,
        name: this.target.name,
        fields: {
          Geometry: { ... this.target.fields.Geometry }
        }
      }
      this.slimMap.update(geomObj);
    }
  }

  rebootMap() {
    setTimeout(() => {
      this.updateMapGeom();
    }, 250);
  }

  createPayload() {
    const genInfoData = this.genInfo.getPayload();
    const focusData = this.focus.getPayload();
    let formattedObj: PamObject = { id: this.target.id, name: this.target.name, fields: {} };
    formattedObj.name = genInfoData.PROJECT_NAME;
    formattedObj.fields.projectType = genInfoData.PROJECT_TYPE;
    formattedObj.fields.projectStatus = genInfoData.PROJECT_STATUS;
    formattedObj.fields.projectNarrative = genInfoData.PROJECT_NARRATIVE;
    formattedObj.fields.projectFocus = focusData.PROJECT_FOCUS;
    formattedObj.fields.projectStrategies = focusData.PROJECT_STRATEGIES;
    formattedObj.fields.Geometry = this.projectGeom?.fields?.Geometry || {};
    formattedObj.fields.Partners = this.partners || []

    return formattedObj;
  }

  addPartner() {
    let partnerDialog = this.dialog.open(PartnerAddDialogComponent, {
      width: '35rem',
      data: { leadOrg: this.projectLeadOrg, currentPartners: this.partners },
      disableClose: true
    });


    partnerDialog.afterClosed().subscribe(data => {
      if (data) {
        // If there are data, save
        this.newPartnerData(data);
        const payload = this.createPayload();
        this.update(payload);
      }
    });
  }

  removePartner(_) {
    if (_) {
      this.removePartnerData(_)
      const payload = this.createPayload();
      this.update(payload)
    }
  }

  removeActivity(_) {
    if (_) {
      this.removeActivityData(_)
      const payload = this.createPayload();
      this.update(payload)
    }
  }

  addActivity() {
    let activityDialog = this.dialog.open(ActivityCreateDialogComponent, {
      width: '35rem',
      disableClose: false,
      data: this.target.id
    });


    activityDialog.afterClosed().subscribe(data => {
      if (data) {
        this.newActivityData(data);
      }
    });
  }

  update(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Saving Project.",
      disableClose: true
    });

    this.api.post<any>("Project", "Update", obj).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleLoad(res.result)
      saveRef.close();

      this.editModeOn = false;
      this.sortActivities();
      this.projGeo.startStatTimer();

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  formsAreValid() {
    return this.focus.isValid() && this.genInfo.isValid();
  }

  newShapeData(data: PamShapeData) {
    this.projectGeom = data;
  }

  newPartnerData(data: any) {
    this.partners.push(data);
    this.sortPartners();
  }

  newActivityData(data: any) {
    this.activities.push(data)
    this.sortActivities();
  }

  removePartnerData(data: any) {
    this.partners = this.partners.filter(function (obj) {
      return obj.name !== data.name;
    });

    this.sortPartners();
  }

  removeActivityData(data: any) {
    this.activities = this.activities.filter(function (obj) {
      return obj.name !== data.name;
    });

    this.sortActivities();
  }

  sortSources() {
    this.sources.sort(function (a, b,) {
      return b.plannedAmount - a.plannedAmount;
    })
  }

  sortPartners() {
    this.partners.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }

  sortActivities() {
    function getStatusOrder(inVal) {
      switch (inVal) {
        case 'Proposed':
          return 0;
        case 'Planned':
          return 1;
        case 'In Progress':
          return 2;
        case 'Completed':
          return 3;
        case 'Cancelled':
          return 4;

        default:
          return 0;
      }
    }

    this.activities.sort(

      firstBy((a, b) => {
        return b['isSpatial'] - a['isSpatial'];
      })
        .thenBy((a, b) => {
          return (a === "" || a['activityUnit'] > b['activityUnit']) ? 1 : (b === "" || (b['activityUnit'] > a['activityUnit']) ? -1 : 0)
        })
        .thenBy((a, b) => {
          let aVal = getStatusOrder(a['activityStatus']);
          let bVal = getStatusOrder(b['activityStatus']);
          return aVal < bVal ? -1 : 1
        })
    );
  }

  isPartnersEmpty() {
    return this.partners.length === 0 ? true : false;
  }

  isActivitiesEmpty() {
    return this.activities.length === 0 ? true : false;
  }

  activityRemovalRequest(data: any) {
    console.log('removal reuqest');
    console.log(data);
    const foundData = this.activities.find((act) => { return act.id === data.id });
    if (foundData) {
      console.log('found remove');
      console.log(foundData);
    }
  }

  triggerActivityDelete(data: any) {
    const foundData = this.activities.find((act) => { return act.id === data.id });
    if (foundData) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35rem',
        data: { title: 'Confirm Delete', text: 'Deleting this Activity will permanently remove it and related details from the Project, and this cannot be undone. Are you sure you want to delete this Activity?', confirm: "Yes", cancel: "No" },
        disableClose: false
      });


      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Delete
          this.commitDelete(foundData);
        } else {
          // No action needed
        }
      });
    }
  }

  async commitDelete(data: any) {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Deleting Activity.",
      disableClose: true
    });

    let obj = { id: data.id }

    this.api.post<any>("Activity", "Delete", obj).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.updateActvities();
      delRef.close();
    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  updateActvities() {
    this.api.post<any>("Activity", "ReadAll", { linkToId: this.target.id }).subscribe(results => {
      if (results?.length) {
        this.activities = results;
        this.sortActivities();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    console.log(this.screenWidth);

    if (this.screenWidth <= 1366) {
      this.heightValue = '250rem;';
    } else if (this.screenWidth > 1366 && this.screenWidth <= 1600) {
      this.heightValue = '230rem;';
    } else {
      this.heightValue = '182rem;';
    }
  }

  // Opportunities And Impediments //
  formatDates(notes: any[]) {
    notes.forEach(note => {
      var d = new Date(0)
      return d.setUTCSeconds(note.lastModified);
    });
  }

  getNotes(iid: any) {
    this.api.post<any>("Note", "ReadAll", { linkToId: iid }).subscribe(results => {
      this.notes = results;

      //split notes into opp and impediment arrays
      this.opportunities = this.notes.filter(function (obj) {
        return obj.isOpportunity === true;
      });

      this.impediments = this.notes.filter(function (obj) {
        return obj.isOpportunity === false;
      });

      this.sortOpportunities();
      this.sortImpediments();

    });
  }

  isOpportunitiesEmpty() {
    return this.opportunities.length === 0 ? true : false;
  }

  isImpedimentsEmpty() {
    return this.impediments.length === 0 ? true : false;
  }

  addOpportunity() {
    let opportunityDialog = this.dialog.open(NoteCreateDialogComponent, {
      width: '35rem',
      disableClose: true,
      data: { "projectId": this.target.id, "isOpportunity": true }
    });


    opportunityDialog.afterClosed().subscribe(data => {
      if (data) {
        this.createNote(data);
      }
    });
  }

  addImpediment() {
    let impedimentDialog = this.dialog.open(NoteCreateDialogComponent, {
      width: '35rem',
      disableClose: true,
      data: { "projectId": this.target.id, "isOpportunity": false }
    });


    impedimentDialog.afterClosed().subscribe(data => {
      if (data) {
        this.createNote(data);
      }
    });
  }

  createNote(data: any) {
    let formattedObj: any = {};
    formattedObj.name = data.isOpportunity === true ? "Opportunity" : "Impediment";
    formattedObj.lastModified = new Date;
    formattedObj.fields = {};
    formattedObj.fields.note = data.note;
    formattedObj.fields.isOpportunity = data.isOpportunity;
    formattedObj.fields.displayName = data.displayName;


    this.api.post<any>("Note", "Create", { linkToId: this.target.id, newObject: formattedObj }).subscribe(result => {
      console.log('result');
      console.log(result);
      if (!result.valid) {
        //Backend validation didnt pass show the errors
        console.error('Error occured');
      }
      else {
        this.getNotes(this.target.id);
      }
    }, error => {
      //Backend gave us a non-200 response
      console.error('Error occured');
    })
  }

  triggerOpportunityDelete(data: any) {
    const foundData = this.opportunities.find((opp) => { return opp.id === data.id });
    if (foundData) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35rem',
        data: { title: 'Confirm Delete', text: 'Are you sure you want to delete this Opportunity?', confirm: "Yes", cancel: "No" },
        disableClose: false
      });


      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Delete
          this.commitNoteDelete(foundData);
        } else {
          // No action needed
        }
      });
    }
  }

  triggerImpedimentDelete(data: any) {
    const foundData = this.impediments.find((imp) => { return imp.id === data.id });
    if (foundData) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35rem',
        data: { title: 'Confirm Delete', text: 'Are you sure you want to delete this Impediment?', confirm: "Yes", cancel: "No" },
        disableClose: false
      });


      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Delete
          this.commitNoteDelete(foundData);
        } else {
          // No action needed
        }
      });
    }
  }

  async commitNoteDelete(data: any) {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Deleting Activity.",
      disableClose: true
    });

    let obj = { id: data.id }

    this.api.post<any>("Note", "Delete", obj).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.updateNotes();
      delRef.close();
    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  updateNotes() {
    this.api.post<any>("Note", "ReadAll", { linkToId: this.target.id }).subscribe(results => {
      this.getNotes(this.target.id)
    });
  }


  sortOpportunities() {
    this.opportunities.sort(function (a, b) {
      return Date.parse(b.lastModified) - Date.parse(a.lastModified);
    });
  }

  sortImpediments() {
    this.impediments.sort(function (a, b) {
      return Date.parse(b.lastModified) - Date.parse(a.lastModified);
    });
  }
}
