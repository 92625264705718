import { FieldBase } from './field-base';

export class DynamicDropdownField extends FieldBase<string> {
  controlType = 'dynamic-dropdown';
  options: { key: string, value: string }[] = [];
  triggersChange: boolean;
  changeFields: string[];
  dataObjName: string;
  dataAction: string;
  getField: string;
  dataAttributes: any[];
  orgDefault?: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.options = [];
    this.triggersChange = options['triggersChange'] || false;
    this.changeFields = options['changeFields'] || [];
    this.dataObjName = options['dataObjName'] || null;
    this.dataAction = options['dataAction'] || null;
    this.getField = options['getField'] || null;
    this.dataAttributes = options['dataAttributes'] || [];
    this.orgDefault = options['orgDefault'] || false;
  }
}
