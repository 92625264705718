import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent implements OnInit {

  @Input() activity: any;

  @Input() projectLeadOrg: any;

  @Input() projectId: any;

  @Output() activityToRemove: EventEmitter<any> = new EventEmitter();


  constructor(private router: Router){ }

  ngOnInit(): void {
    console.log(this.activity);
  }

  removeActivity(_: any){
    this.activityToRemove.emit(this.activity);
  }

  viewSummary(id: any) {
    this.router.navigateByUrl(`/app/activity/view?id=${id}&projectid=${this.projectId}&projectleadorg=${this.projectLeadOrg}`);
  }
}
