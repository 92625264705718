import { Injectable, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AssessmentReport, ConfiguredReport } from '../models/report.model';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService implements OnInit {

  collabPartners: string[];
  focalAreaStrategies: any;
  activeProjects: any[];
  associatedProjects: any[];
  completedProjects: any[];
  priorityLandscape: string;
  mapGeometry: any;

  constructor(){}

  ngOnInit(){

  }

  setPartners(data: any){
    this.collabPartners = data;
  }

  getPartners(){
    return this.collabPartners;
  }

  setStrategies(data: any){
    this.focalAreaStrategies = data[0];
  }

  getStrategies(){
    return this.focalAreaStrategies.value;
  }

  setActiveProjects(data: any){
    this.activeProjects = data;
  }

  getActiveProjects(){
    return this.activeProjects.length;
  }

  setCompletedProjects(data: any){
    this.completedProjects = data.filter(function (obj) {
      return obj.fields.projectStatus === "Complete"
    });
  }

  getCompletedProjects(){
    return this.completedProjects.length;
  }

  setPriorityLandscape(data: any){
    this.priorityLandscape = data;
  }

  getPriorityLandscape(){
    return this.priorityLandscape;
  }

  setAssociatedProjects(data: any){
    this.associatedProjects = data
  }

  getAssociatedProjects(){
    return this.associatedProjects;
  }

  setMapGeometry(data: any){
    this.mapGeometry = data;
  }

  getMapGeometry(){
    return this.mapGeometry;
  }

}
