<p mat-dialog-title color="primary" style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
    class="themePrimaryTextInverse">Please Wait</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="1.75rem" fxLayoutAlign="center center" style="width: 100%;">
        
            <mat-spinner class="centerX"></mat-spinner>
            <p style="font-size: 1.2rem; line-height: 1.5rem; text-overflow: ellipsis; overflow:hidden;">
                {{message}}
            </p>
        
    </div>
</div>
