import { Injectable } from '@angular/core';
import proj4 from 'proj4';

@Injectable({
  providedIn: 'root'
})
export class ProjectionService {

  public webMercatorWkt = 'PROJCS["WGS 84 / Pseudo-Mercator",GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]],PROJECTION["Mercator_1SP"],PARAMETER["central_meridian",0],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["X",EAST],AXIS["Y",NORTH],EXTENSION["PROJ4","+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"],AUTHORITY["EPSG","3857"]]';
  public wgs84Wkt = 'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';

  constructor() { }

  public reprojectPolyWgs84ToWeb(layer: any): any {
    const source = new proj4.Proj(this.wgs84Wkt);
    const dest = new proj4.Proj(this.webMercatorWkt);
    const inData = { ...layer };

    proj4.reportError = (msg) => {
        throw new Error('Whoops: ' + msg);
    };

    for (const feature of inData.features) {
      for (const coordinates of feature.geometry.coordinates) {
        for (let index = 0; index < coordinates.length; index++) {
          const coordPair = coordinates[index];
          const projCoordPair = proj4.transform(source, dest, coordinates[index]); 
          coordinates[index] = [projCoordPair.x, projCoordPair.y];
        }
      }
    }

    return inData;
  }
}
