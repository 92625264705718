import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MapLayer } from '../../../_shared/models/map/config.model';
import { Result } from '../../../_shared/services/map/identify.service';

import { IdentifyLayer, LayerState } from '../../layer-identify-result'

import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-layer-identify',
  templateUrl: './layer-identify.component.html',
  styleUrls: ['./layer-identify.component.scss']
})
export class LayerIdentifyComponent implements OnInit {

  constructor() { }

  @Input() layer: MapLayer;
  @Input() results: Result[];
  @Input() isCreating: boolean;
  @Input() status: string = 'N/A';
  @Input() error?: string = undefined;
  @Input() mapZoom?: number = 0;

  @Input() resultSet: IdentifyLayer;

  @Output() highlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() unhighlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() zoomToFeature: EventEmitter<Result> = new EventEmitter();
  @Output() importFeature: EventEmitter<Result> = new EventEmitter();

  @ViewChildren('panel') public panel: QueryList<any>;
  @ViewChildren('result') public resultsEles: QueryList<any>;

  ngOnInit(): void {
  }

  public expand() {
    this.panel.toArray().forEach(element => {
      element.open();
    });
  }

  scrollToResult(id: string) {
    this.expand();
    setTimeout(() => {
      this.resultsEles.toArray().forEach(element => {
        console.log(element)
        if (element.nativeElement.id === (id + ""))
        {
          element.nativeElement.scrollIntoView();
        }  
      })
    }, 500);
  }


  highlight(result: Result) {//asd
    this.highlightFeature.next(result);
  }

  unhighlight(result: Result) {
    this.unhighlightFeature.next(result);
  }

  zoomTo(result: Result) {
    this.zoomToFeature.next(result);
  }

  import(result: Result) {
    this.importFeature.next(result);
  }

  isSearching() : boolean {
    return this.status == 'Searching';
  }

  isIdle() : boolean {
    return this.status == 'Idle' && !this.minZoomCheck();
  }

  minZoomCheck() : boolean {
    return this.layer && this.layer.identify && this.layer.identify.minZoom && this.mapZoom < this.layer.identify.minZoom
  }

  hasResults() : boolean {
    return (this.results && this.results.length > 0)
  }

  floor(n) {
    return Math.floor(n);
  }
}
