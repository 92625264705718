<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <div>
        <span>Import</span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <h3 class="appTagLine">Select the kind of file you want to import</h3>
    <div style="width:100%">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field fxFill appearance="outline">
          <mat-select name="type" #importType [formControl]="importTypeFormControl"
            (selectionChange)="shapeFileGuidance()" required="true">
            <mat-option *ngFor="let type of importTypes" [value]="type.name">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <mat-form-field fxFlex="60" style="float: left" fxFill appearance="outline">
          <input name="file" #fileUpload matInput [formControl]="importFileFormControl" [ngModel]="this.fileName" />
        </mat-form-field>
        <div class="appUploadDeleteIconContainer">
          <button mat-icon-button color="warn" (click)="reset()">
            <mat-icon class="appUploadDeleteIcon">close</mat-icon>
          </button>
        </div>
        <div fxFlex="40" style="padding-bottom: 1rem;">
          <label class="custom-file-upload">
            <button *ngIf="this.isShapeFile" class="appConfirmButton appSquareButton appSkinnyFontButton"
              mat-flat-button color="accent" (click)="this.importShapeFile()">
              Select File
            </button>
            <input type="file" #kmlFile (change)="this.importKml($event)">
            <button *ngIf="this.isKml" class="appConfirmButton appSquareButton appSkinnyFontButton" mat-flat-button
              color="accent" (click)="this.selectKml()">
              Select File
            </button>
          </label>
        </div>
      </div>
    </div>
    <div>
      <label *ngIf="isShapeFile">

        <body>
          {{shapeFileMessage}}
        </body>
      </label>
      <label *ngIf="isKml">

        <body>
          {{kmlMessage}}
        </body>
      </label>
    </div>
    <div>
      <label *ngIf="error">

        <body style="color:crimson">
          {{errorMessage}}
        </body>
      </label>
      <label *ngIf="invalidPolygon">

        <body style="color:crimson">
          {{invalidPolygonMessage}}
        </body>
      </label>
      <label *ngIf="invalidFileType">

        <body style="color:crimson">
          {{invalidFileTypeMessage}}
        </body>
      </label>
      <label *ngIf="invalidFileTypeKml">

        <body style="color:crimson">
          {{invalidFileKml}}
        </body>
      </label>
      <label *ngIf="tooManyKmlFeatures">

        <body style="color:crimson">
          {{tooManyKmlFeaturesMessage}}
        </body>
      </label>
    </div>

    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="cancelClicked()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" target="_blank" (click)="this.closeSelf()"
          [disabled]="!this.userCanContinue">Import</button>
      </div>
    </div>
  </div>
</div>
