<p mat-dialog-title color="primary"
  style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
  class="themePrimaryTextInverse">About Export</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        The export toggle allows users to select how they want data to be exported. Excel will generate a file with all the project information in tabular format. Shapefile will generate a Zip file with the shape and attribute information related to Projects and Activities in a standard Esri Shapefile format.
      </p>

    </div>
  </div>
</div>
<div mat-dialog-actions style="width: 100%;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
    <button mat-button color="primary" (click)="onNoClick()" style="width: 12rem;">CLOSE</button>
  </div>
</div>
