import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { RegisterComponent } from './register/register.component';
import { MapComponent } from './map/map.component';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './admin/users/users.component';
import { AuthorizingComponent } from './authorizing/authorizing.component';
import { AllCardsViewComponent } from './dashboard/all-cards-view/all-cards-view.component';
import { CardViewComponent } from './dashboard/card-view/card-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MainComponent } from './main/main.component';
import { MockauthComponent } from './mockauth/mockauth.component';
import { RouteGuardService } from './route-guard.service';
import { TestComponent } from './test/test.component';
import { HomeMainComponent } from './home-main/home-main.component';
import { ProjectComponent } from './project/project.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ProjectViewComponent } from './project/project-view/project-view.component';
import { ActivityComponent } from './activity/activity.component';
import { ActivityCreateComponent } from './activity/activity-create/activity-create.component';
import { ActivityViewComponent } from './activity/activity-view/activity-view.component';
import { FocalComponent } from './focal/focal.component';
import { FocalViewComponent } from './focal/focal-view/focal-view.component';
import { ProjectFilterComponent } from './project-filter/project-filter.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { FocalReportComponent } from './focal/focal-report/focal-report.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

export const isMock = environment.mock;


const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'app', component: MainComponent, children:
      [
        { path: 'map', component: MapComponent },
        {
          path: 'home', component: HomeMainComponent, canActivate: [RouteGuardService], data: { acls: ["Home:Access"], mustHaveOrg: true }
        },
        {
          path: 'project', component: ProjectComponent,
          children: [
            { path: 'create', component: ProjectCreateComponent, canActivate: [RouteGuardService], data: { acls: ["Project:Create"], mustHaveOrg: true } },
            { path: 'view', component: ProjectViewComponent, canActivate: [RouteGuardService], data: { mustHaveOrg: true } }
          ]
        },
        {
          path: 'focal', component: FocalComponent,
          children: [
            { path: 'view', component: FocalViewComponent },
            { path: 'report', component: FocalReportComponent }
          ]
        },
        {
          path: 'activity', component: ActivityComponent,
          children: [
            { path: 'create', component: ActivityCreateComponent, canActivate: [RouteGuardService], data: { acls: ["Activity:Create"], mustHaveOrg: true } },
            { path: 'view', component: ActivityViewComponent, canActivate: [RouteGuardService], data: { mustHaveOrg: true } }
          ]
        },
        {
          path: 'manage', component: ProjectFilterComponent,
          children: [
            { path: 'all', component: ProjectFilterComponent },
            { path: 'view', component: ProjectFilterComponent },
          ]
        },
        {
          path: 'admin', component: AdminComponent, children:
            [{ path: 'users', component: UsersComponent },
            { path: 'orgs', component: OrganizationsComponent }]
        },
        {
          path: 'dashboard', component: DashboardComponent, children:
            [{ path: 'all', component: AllCardsViewComponent }
              , { path: 'view', component: CardViewComponent }
            ]
        }
      ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'authorizing', component: AuthorizingComponent },
  { path: 'test', component: TestComponent },
  { path: '**', component: NotFoundComponent },
];


const mockRoutes: Routes = [
  { path: 'mockauth', component: MockauthComponent }
]

if (isMock) {
  for (let rte of mockRoutes)
    routes.unshift(rte);
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
