import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from 'src/app/_shared/models/dynamic-forms/field-base';
import { DynamicDropdownField } from 'src/app/_shared/models/dynamic-forms/field-dynamic-dropdown';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AuthorizationService } from 'src/app/_shared/services/authorization.service';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() form: FormGroup;
  @Input() activeEdit: boolean;
  @Input() largeLabel = false;
  @Output() newOptions = new EventEmitter<any>();
  @Output() newValue = new EventEmitter<any>();
  elementRef: ElementRef;
  get isValid() {
    if (this.form && this.form.controls[this.field.key]) {
      return this.form.controls[this.field.key].valid;
    } else {
      return false;
    }
  }
  get isDirty() {
    if (this.form && this.form.controls[this.field.key]) {
      return this.form.controls[this.field.key].dirty;
    } else {
      return false;
    }
  }
  get errors() {
    return this.form.controls[this.field.key].errors;
  }
  get fieldValue() {
    if (this.form && this.form.controls[this.field.key]) {
      return this.form.controls[this.field.key].value;
    } else {
      return null;
    }
  }

  constructor(
    private api: ApiService,
    private auth: AuthorizationService,
  ) { }

  ngOnInit(): void {
    if (this.field.triggersChange && this.field.value !== null) {
      this.triggerChange(null);
    }
  }

  ngAfterViewInit() {
    if (this.field.controlType === 'dynamic-dropdown') {
      this.getInitial();
    }
  }

  public async triggerChange(_) {
    const startingValue = this.field.value;

    // @ts-ignore
    if (!this.field.triggersChange) {
      console.log('Exit');
      return;
    }

    const fieldData = this.field as DynamicDropdownField;

    // Clear old data
    this.newOptions.emit({ key: fieldData.changeFields, data: [] });

    let data;
    let objName = this.field.dataObjName;
    let actionName = this.field.dataAction;
    const getField = this.field.getField;
    const resp = await this.api.get<any[]>(objName, actionName, true).toPromise()
      .then(res => {
        data = res.map(item => {
          return { key: item[getField], value: item[getField] };
        });
        data.sort((a, b) => (a.key > b.key) ? 1 : -1);
      }).catch(err => {
        console.error('Request failure: ', err);
        data = [];
      });

    this.newOptions.emit({ key: fieldData.changeFields, data: data });
  }

  public async getInitial() {
    const fieldData = this.field as DynamicDropdownField;

    let objName = this.field.dataObjName;
    let actionName = this.field.dataAction;
    const getField = this.field.getField;
    this.api.get<any[]>(objName, actionName, true).subscribe(result => {
      const data = result.map(item => {
        return { key: item[getField], value: item[getField] };
      });
      data.sort((a, b) => (a.key > b.key) ? 1 : -1);
      this.newOptions.emit({ key: fieldData.key, data: data });

      if (this.field?.orgDefault) {
        const org = this.auth.login.orgs.organization;
        if (org) {
          this.newValue.emit({ key: fieldData.key, data: org });
        }
      }
    });
  }

  public async setDefaultOrg() {
    const org = await this.auth.login.orgs.organization;

    if (!org)
      return;
    
    this.field.value = org;
  }

}
