import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MapLayer } from '../../../_shared/models/map/config.model';

import { Subscription } from 'rxjs';
import { LegendSimpleSymbol } from 'src/app/_shared/models/map/legend.model';

@Component({
  selector: 'app-layer-legend',
  templateUrl: './layer-legend.component.html',
  styleUrls: ['./layer-legend.component.scss']
})
export class LayerLegendComponent implements OnInit, OnDestroy {

  @Input() layer: MapLayer;

  legendDataRaw: any[] = [];
  subs: Subscription[] = [];
  symbolColor: string;

  constructor() { }

  ngOnInit(): void {
    if (this.layer.legend && this.layer.legend.length > 0) {
      const sym = this.layer.legend[0].display as LegendSimpleSymbol;
      this.symbolColor = `rgba(${sym?.symbol?.color[0]}, ${sym?.symbol?.color[1]}, ${sym?.symbol?.color[2]}, ${sym?.symbol?.color[3] / 255})`;
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => { sub.unsubscribe() });
  }

}
