import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-funding',
  templateUrl: './project-funding.component.html',
  styleUrls: ['./project-funding.component.scss']
})
export class ProjectFundingComponent implements OnInit {

  @Input() projectFunding: any;

  constructor() { }

  ngOnInit(): void {
    console.log("Project Funding Object",this.projectFunding);
  }

}
