import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Import, ImportState } from 'src/app/map/import';
import { ApiService } from 'src/app/_shared/services/api.service';
import { ConfiguredIdentifySources } from 'src/app/_shared/services/map/identify.service';
import { PlugmapAPIService } from 'src/app/_shared/services/map/plugmap-api.service';
import { promptAndProcessShapefile } from '../../../../node_modules/geometry-web-worker'
import { kml } from "@tmcw/togeojson";
import { ProjectionService } from 'src/app/_shared/services/projection.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {

  fields: any[];
  fieldsConfig: any;
  configSub: any;
  editMode = false;
  importTypes: any[] = [{ name: 'Shapefile' }, { name: 'KML file' }];
  importEnabled: boolean = false;
  import: Import = new Import(this.identify);
  ImportState = ImportState;
  allFeats: any[] = [];
  fileName: string;
  userCanContinue = false;
  isShapeFile = false;
  isKml = false;
  error = false;
  invalidPolygon = false;
  invalidFileType = false;
  invalidFileTypeKml = false;
  tooManyKmlFeatures = false;
  errorMessage = "Error uploading file. Please make sure the file type is correct.";
  invalidPolygonMessage = "Your selected file is not a polygon or multipolygon type. Please retry with a file whose area is one of those shape types.";
  invalidFileTypeMessage = "Invalid file type. Please select a .zip file."
  invalidFileKml = 'Please select a .kml file.';
  shapeFileMessage = "If using the shapefile format, you must select a .zip compressed file that contains all of the component files with these extensions:" +
    " .shp, .shx, .dbf, .prj. The shapefile must be a polygon or multipolygon type. A file containing multiple shapes will be combined into a single multipart shape.";
  kmlMessage = `If using the KML format, you must select a .kml file. The file must be a polygon or multipolygon type.`;
  tooManyKmlFeaturesMessage = `Your selected file contains more than one area. Please retry with a file that contains only one polygon or multipolygon.`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImportDialogComponent>,
    private api: ApiService,
    private identify: ConfiguredIdentifySources,
    private mapAPI: PlugmapAPIService,
    private proj: ProjectionService,
  ) { }

  @ViewChild('kmlFile') kmlFileInput: ElementRef;


  importTypeFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  importFileFormControl: FormControl = new FormControl({ value: null, disabled: true, readOnly: true }, Validators.required);

  ngOnInit(): void {
    this.setFormValues();
    this.shapeFileGuidance();
  }

  ngOnDestroy() {
  }

  async setFormValues() {
    this.importTypeFormControl.setValue("Shapefile");
  }

  shapeFileGuidance() {
    if (this.importTypeFormControl.value === 'Shapefile') {
      this.reset();
      this.isShapeFile = true;
      this.isKml = false;
    } else if (this.importTypeFormControl.value === 'KML file') {
      this.reset();
      this.isShapeFile = false;
      this.isKml = true;
    }
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }

  reset() {
    this.error = false;
    this.invalidPolygon = false;
    this.invalidFileType = false;
    this.invalidFileTypeKml = false;
    this.userCanContinue = false;
    this.tooManyKmlFeatures = false;
    this.fileName = "";
    this.allFeats = [];
  }

  isValid(): boolean {
    return true;
  }

  importShapeFile() {

    promptAndProcessShapefile().then(features => {
      this.userCanContinue = false;

      this.reset();
      //Features is an array of features
      // Not sure how/if this is actually being used, we can probably remove
      this.import.setState(ImportState.Searching);

      var featuresImported = features.features.length;
      this.mapAPI.setImportCount(0);
      this.mapAPI.setImporting(true);
      this.fileName = features?.fileInfo?.name;

      let skip = false;
      features.features.forEach((feat, idx) => {
        if (skip) {
          return;
        }
        if (feat.geometry.type === "Point") {
          skip = true;
          this.invalidPolygon = true;
          return
        }
        if (feat.geometry.type === "LineString") {
          skip = true;
          this.invalidPolygon = true;
          return
        }
        this.handleImportResult(feat);
        this.mapAPI.setImportCount(idx + 1);
      }
      )

      var id = setInterval(() => {
        if (!(this.mapAPI.getImportCount() < featuresImported)) {
          this.dismissImport();
          this.mapAPI.setImporting(false);
          clearInterval(id);
          this.userCanContinue = true;
        }
      }, 1000);
    }).catch((err) => {
      this.error = true;
    });
  }

  selectKml() {
    this.kmlFileInput.nativeElement.click();
  }

  importKml(evt) {
    this.userCanContinue = false;

    this.reset();
    //Features is an array of features
    // Not sure how/if this is actually being used, we can probably remove
    this.import.setState(ImportState.Searching);

    const files = evt.target.files;

    if (files.length > 1) {
      this.invalidFileTypeKml = true;
      return;
    }

    const mainFile = files[0];

    if (!(mainFile.type === 'application/vnd.google-earth.kml+xml' || mainFile.name.substr(mainFile.name.lastIndexOf('.') + 1).toLowerCase() === 'kml')) {
      this.invalidFileTypeKml = true;
      return;
    }

    this.fileName = mainFile.name

    const reader = new FileReader();
    reader.readAsText(mainFile);

    reader.onload = () => {
      console.log(reader.result);
      const stringResult = reader.result as string;
      const kmlData = kml(new DOMParser().parseFromString(stringResult, "text/xml"));
      console.log(kmlData);

      const notPolygon = kmlData.features.some(e => { return e.geometry.type !== 'Polygon' });
      if (notPolygon) {
        this.invalidPolygon = true;
        return;
      }

      if (kmlData.features.length > 1) {
        this.tooManyKmlFeatures = true;
        return;
      }

      const geoJson = this.proj.reprojectPolyWgs84ToWeb(kmlData)
      geoJson.features.forEach(feature => {
        const feat = {
          geometry: {
            bbox: null,
            coordinates: feature.geometry.coordinates,
            type: 'Polygon'
          },
          properties: {FID: 0},
          type: 'Feature'
        };
        this.handleImportResult(feat);
      });

      this.dismissImport();
      this.mapAPI.setImporting(false);
      this.userCanContinue = true;
    };
  
    reader.onerror = () => {
      console.log(reader.error);
    };
  }


  handleImportResult(newShape) {
    //The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;
    this.allFeats.push(newShape);
  }

  dismissImport() {
    this.importEnabled = false;
    this.import.clear();
  }

  closeSelf() {
    this.dialogRef.close(this.allFeats);
  }

}
