// Set constants for field names in the app

export class Fields {
    /**
     * Projects
     */

    // Project Name
    public static ProjectName = 'PROJECT_NAME';

    // Project Created Date
    public static ProjectCreated = 'PROJECT_CREATED'

    // Project Modified Date
    public static ProjectModified = 'PROJECT_MODIFIED'

    // Project Type
    public static ProjectType = 'PROJECT_TYPE';

    // Priority Landscape
    public static ProjectStatus = 'PROJECT_STATUS';

    // Project Narrative
    public static ProjectNarrative = 'PROJECT_NARRATIVE';

    // Project Focus
    public static ProjectFocus = 'PROJECT_FOCUS';

    //
    public static ProjectStrategies = 'PROJECT_STRATEGIES'

    /**
     * Activities
     */

    // Activity Name
    public static ActivityName = "ACTIVITY_NAME";

    // Activity Type
    public static ActivityType = "ACTIVITY_TYPE";

    // Activity Type
    public static ActivityFocus = "ACTIVITY_FOCUS";

    // Activity Status
    public static ActivityStatus = "ACTIVITY_STATUS";

    // Planned Completion Date
    public static PlannedCompletionDate = "PLANNED_COMPLETION_DATE";

    // Actual Completion Date
    public static ActualCompletionDate = "ACTUAL_COMPLETION_DATE";

    // Work Agent
    public static ActivityWorkAgent = "ACTIVITY_WORK_AGENT";

    //Unit
    public static ActivityUnit = "ACTIVITY_UNIT";

    //Reported Acres
    public static ActivityAcres = "ACTIVITY_ACRES";

    // Risk Adjacent
    public static RiskAdjacent = "RISK_ADJACENT";

    /**
    * Opportunities
    */

    public static ProjectOpportunity = "PROJECT_OPPORTUNITY";

    /**
    * Impediments
    */

    public static ProjectImpediment = "PROJECT_IMPEDIMENT";

    /**
     * Fundings
     */

    //Funding ID
    public static FundingId = "FUNDING_ID"
    // Funding Name
    public static FundingName = "FUNDING_NAME";

    // Funding Org
    public static FundingOrg = "FUNDING_ORG";

    // Funding Match
    public static FundingMatch = "FUNDING_MATCH";

    // Planned Amount
    public static PlannedAmount = "PLANNED_AMOUNT";

    // Actual Amount
    public static ActualAmount = "ACTUAL_AMOUNT";


    /**
     * Focal
     */
    // Name
    public static FocalName = "FOCAL_NAME";

    // Prio Landscape
    public static PrioLandscape = "PRIO_LANDSCAPE";

    // Focal Area Strategies
    public static FocalStrats = "FOCAL_STRATS";

}
