import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/_shared/services/api.service';
import { ConfigurationService } from 'src/app/_shared/services/configuration.service';
import { GeoStatConstants, GeoStatContainerVm, GeoStatRecordVm, GeoStatVm } from './geo-stats.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectGeoService {
  geoStats: BehaviorSubject<GeoStatContainerVm> = new BehaviorSubject<GeoStatContainerVm>(this.getPendingData());
  hasStats: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  watersheds: any;

  constructor(
    private decimalPipe: DecimalPipe,
    private api: ApiService,
    private config: ConfigurationService,
  ) { }

  requestStatData(projectId: number, entity: string, action: string) {
    this.api.post<any>(entity, action, [projectId], true).subscribe(res => {
      if (res && res?.fields?.stats?.status === GeoStatConstants.Success) {
        // Unpack actual object
        this.getStatData(res?.fields);
        this.hasStats.next(true);
      } else if (res && res?.fields?.stats?.status === GeoStatConstants.Running) {
        // Pending Object and Set Timer for Checks
        this.hasStats.next(false);
      } else {
        // Error object
        this.hasStats.next(false);
      }
    }, err => {
      console.error('ERROR');
      console.error(err);
      // Error object
      this.hasStats.next(false);
    });
  }

  async getStatData(rawData: any) {
    const procData: GeoStatContainerVm = {};

    // Landscape Data
    const landscapeRow = await this.unpackStatData(rawData,
      GeoStatConstants.Landscapes,
      GeoStatConstants.Name,
      GeoStatConstants.ViewTitles.Landscape
    );

    // Focal Data
    const focaleRow = await this.unpackStatData(rawData,
      GeoStatConstants.FocalAreas,
      GeoStatConstants.Name,
      GeoStatConstants.ViewTitles.Focal
    );

    // Watershed
    const watershedRow = await this.unpackStatData(rawData,
      GeoStatConstants.Watersheds,
      GeoStatConstants.Name,
      GeoStatConstants.ViewTitles.Watershed
    );

    // Ownership
    const ownershipRow = await this.unpackStatData(rawData,
      GeoStatConstants.Ownership,
      GeoStatConstants.Name,
      GeoStatConstants.ViewTitles.Ownership
    );

    // Hydrate full VM
    procData.landscape = landscapeRow;
    procData.focal = focaleRow;
    procData.watershed = watershedRow;
    procData.ownership = ownershipRow;

    this.geoStats.next(procData);
  }

  private async unpackStatData(rawData: any, statKey: string, statName: string, title: string): Promise<GeoStatRecordVm> {
    // Unpack the raw data - expects certain properties to be in place
    const rawStats = rawData?.
    [GeoStatConstants.Stats]?.
    [GeoStatConstants.Data]?.
    [statKey]?.
    [GeoStatConstants.Stats]?.
    [statName] || null;

    // Setup a record and stat array to be hydrated
    const statRow: GeoStatRecordVm = { title };
    const stats: GeoStatVm[] = [];

    // Cycle through the raw stats and map to VM
    if (rawStats) {
      for (const key in rawStats) {
        const element = rawStats[key];
        let stat: GeoStatVm;
        if (statKey === GeoStatConstants.Watersheds) {
          const tempName = await this.getWatershedAlias(key);
          stat = { title: tempName, acres: this.convertSqmToAcres(element?.[GeoStatConstants.TotalArea] || null) };
        } else {
          stat = { title: key, acres: this.convertSqmToAcres(element?.[GeoStatConstants.TotalArea] || null) };
        }
        stats.push(stat);
      }
    }

    // Hydrate the record with the stats
    statRow.stat = stats;

    return statRow;
  }

  resetStats() {
    this.geoStats.next(this.getPendingData());
    this.hasStats.next(false);
  }

  private convertSqmToAcres(inSqm: number) {
    return inSqm ? this.decimalPipe.transform(inSqm * 0.000247105, '1.1-1') : null;
  }

  private getPendingData() {
    const procData: GeoStatContainerVm = {
      landscape: { title: GeoStatConstants.ViewTitles.Landscape, stat: [] },
      focal: { title: GeoStatConstants.ViewTitles.Focal, stat: [] },
      watershed: { title: GeoStatConstants.ViewTitles.Watershed, stat: [] },
      ownership: { title: GeoStatConstants.ViewTitles.Ownership, stat: [] }
    };

    return procData;
  }

  async getWatershedAlias(watershedId: string): Promise<string> {
    let outName: string;
    const isTop500 = await this.isTop500Watershed(watershedId);
    if (isTop500) {
      outName = `${watershedId} (Top 500)`;
    } else {
      outName = watershedId;
    }

    return outName;
  }

  async isTop500Watershed(watershedId: string) {
    if (!this.watersheds) {
      const res = await this.config.getConfigAsync(this.config.watershedConfigUrl);
      this.watersheds = res['watersheds']['watershed'];
    }

    const found = this.watersheds.find((data) => {
      return data['name'] === watershedId;
    });

    if (found && found['top_500'] === 'Yes') {
      return true;
    } else {
      return false;
    }
  }

}
