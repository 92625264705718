import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  currentYear = (new Date()).getFullYear();

  constructor(private auth: AuthorizationService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    let loginSub = this.auth.onFutureLogin().subscribe(login =>
      {
        if(login.acls.includes("Home:RedirectToMapAfterLogin"))
        {
          this.router.navigate(['app', 'map'])
        }
        if (login.orgs.organization != "None - General Public"){
          console.log(login.orgs)
          this.router.navigate(['app', 'home'])
        }
      });

    this.subs.push(loginSub);
  }

  ngOnDestroy(): void {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  loggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  routeToPortal(): void {
    if(this.auth.isLoggedIn()) {
      let login = this.auth.getLogin();
      if (!login.orgs.organization || login.orgs.organization === 'None - General Public') {
        this.router.navigate(['/app/map']);
      }
      else {
        this.router.navigate(['/app/home']);
      }
    }
  }

  openContactUs() {
    console.log('open');
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '30rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {


    });
  }

  routeToRegister() {
    this.router.navigate(['/register'])
  }

  routeToForgot() {
    this.router.navigate(['/forgot'])
  }

}
