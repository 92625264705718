<p mat-dialog-title color="primary"
  style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
  class="themePrimaryTextInverse">About Projects</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        A project is an overall effort to achieve some specific objective in a defined area. Projects may be complex and include many activities
        such as planning, surveys, treatments, and monitoring. Or a project may be focused, including only one activity. Project areas are small
        enough that they fit within a focal area, where focal areas are defined.
      </p>

      <p>
        However, projects entered into the portal do not have to be within Shared Stewardship Priority Areas or Focal Areas. Projects may have
        multiple partners who are responsible for specific activities within the project boundaries. Projects may span multiple years and in some cases, decades.
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions style="width: 100%;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
    <button mat-button color="primary" (click)="onNoClick()" style="width: 12rem;">CLOSE</button>
  </div>
</div>
