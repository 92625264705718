import { Injectable } from '@angular/core';
import { CoreEntity } from '../../models/core-entity.model';
import { EditableFields } from '../../models/dynamic-forms/editable-fields.model';
import { Fields } from '../../models/dynamic-forms/fields.model';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor() { }

  getFields(objectName: string) {
    let fields;

    switch (objectName) {
      case CoreEntity.Project:
        fields = EditableFields.ProjectFields;
        break;
      case CoreEntity.Activity:
        fields = EditableFields.ActivityFields;
        break;
      case CoreEntity.ActivityWork:
        fields = EditableFields.ActivityWorkFields;
        break;
      case 'ProjectNarrative':
        fields = EditableFields.ProjectNarrativeFields;
        break;
      case 'ProjectFocus':
        fields = EditableFields.ProjectFocusFields;
        break;
      case 'ActivityModalFields':
        fields = EditableFields.ActivityModalFields;
        break;
      case 'ActivityFields':
        fields = EditableFields.ActivityFields
        break;
      case 'ActivityWorkFields':
        fields = EditableFields.ActivityWorkFields
        break;
      case CoreEntity.Opportunity:
        fields = EditableFields.OppoortunityFields
        break;
      case CoreEntity.Impediment:
        fields = EditableFields.ImpedimentFields
        break;
      case 'FundingModalFields':
        fields = EditableFields.FundingModalFields
        break;
      case CoreEntity.Focal:
        fields = EditableFields.FocalFields
        break;
      // Add additional entities as needed

      default:
        break;
    }

    fields = this.deepClone(fields);

    // Set default values
    fields.forEach(field => {
      if (field.default) {
        const foundOption = field.options.find(item => {
          return item.value === field.default;
        });

        // If a selected item is found set the value
        if (foundOption) {
          field.value = foundOption.key;
        }
      }
    });

    return fields.sort((a, b) => a.order - b.order);
  }

  getEditableFields(layerName: string, values: any) {
    // TODO need to refactor this - got stuck while trying to figure out the issue in dynamic forms though
    const genFields = this.getFields(layerName);
    const narrFields = this.getFields('ProjectNarrative');
    const fields = genFields.concat(narrFields);

    fields.forEach(field => {
      const fieldKey = field.key;
      const foundValue = values[fieldKey] || null;

      if (foundValue) {
        field.value = foundValue;

        // Check drop down value
        if (field.controlType === 'dropdown') {
          const foundOption = field.options.find(item => {
            return item.value === foundValue;
          });

          // If a selected item is found set the value
          if (foundOption) {
            field.value = foundOption.key;
          }
        }

        // TODO WIP
        if (field.controlType === 'multi-select') {
          const foundOptions = field.options.find(item => {
            return item.value === foundValue;
          });

          if (foundOptions) {
            field.value = foundOptions.key;
          }

        }
      }

      // Add any additional field specific biz logic
    });

    return fields;
  }

  getEditableFocusFields(layerName: string, values: any, removeFocus?: boolean) {
    // TODO need to refactor this - got stuck while trying to figure out the issue in dynamic forms though
    let fields = this.getFields('ProjectFocus');

    if (removeFocus) {
      fields = fields.filter(e => { return e.key !== Fields.ProjectFocus });
    }

    fields.forEach(field => {
      const fieldKey = field.key;
      const foundValue = values[fieldKey] || null;

      if (foundValue) {
        field.value = foundValue;

        if (field.controlType === 'multi-select') {
          const foundOptions = field.options.find(item => {
            return item.value === foundValue;
          });

          if (foundOptions) {
            field.value = foundOptions.key;
          }

        }
      }
    });

    return fields;
  }

  getEditableFieldsGeneric(layerName: string, values: any) {
    const fields = this.getFields(layerName);

    fields.forEach(field => {
      const fieldKey = field.key;
      const foundValue = values[fieldKey] || null;

      if (foundValue) {
        field.value = foundValue;

        // Check drop down value
        if (field.controlType === 'dropdown') {
          const foundOption = field.options.find(item => {
            return item.value === foundValue;
          });

          // If a selected item is found set the value
          if (foundOption) {
            field.value = foundOption.key;
          }
        }

        if (field.controlType === 'multi-select') {
          const foundOptions = field.options.find(item => {
            return item.value === foundValue;
          });

          if (foundOptions) {
            field.value = foundOptions.key;
          }

        }
      }
    });

    return fields;
  }

  // Deep clone an object
  private deepClone(obj: Object) {
    return JSON.parse(JSON.stringify(obj));
  }
}
