<div fxLayout="column" class="bgBar">
  <div class="background"
    style="background-image:url('https://timmons-branding.s3.amazonaws.com/NewMexico/Taos.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover;">
    <div class="background">
    </div>
  </div>
</div>

<div fxFlex fxLayout="column" style="z-index: 2; position:absolute; width: 100%; height: 100%; overflow:hidden;">
  <div fxLayout="row" class="slim-header" style="font-size:1.25rem; line-height: 1.5rem; min-height: 2.5rem;">
    <div class="appCopyrightText">All content is &#169;{{ this.currentYear }} New Mexico Energy, Minerals and Natural
      Resources Department
    </div>
    <app-user-menu class="align-right centerY homeMenu"></app-user-menu>
  </div>

  <div fxFlex="45" fxLayoutAlign="end center" style="padding: 1em; padding-bottom: 0;">

    <div fxFill fxFlex fxLayout="column" fxLayoutAlign="start center">
      <div fxFlex></div>
      <div fxFlex="60">
        <div fxFill fxFlex fxLayout="row" fxLayoutAlign="start center">
          <img alt="NM Icon" src="https://timmons-branding.s3.amazonaws.com/NewMexico/RoundIcon.png"
            style="width: auto; height: 100%; margin: auto; align-self: center;" />
        </div>
      </div>
      <div class="title" fxFlex="100" id="appSplashTitleContainer">
        <div class="appTitleSpacer">
          <h1>Welcome to the NM Shared Stewardship Portal!</h1>
          <h2>A tool to coordinate forest and watershed management on all lands in New Mexico</h2>
        </div>
      </div>
    </div>

  </div>



  <div fxFlex="43" fxLayoutAlign="start end" class="appSplashContent">
    <div fxFill fxLayout="row" fxLayoutGap="0.5rem"
      style="background-color: #fafafa; padding: 0.5em 1em; box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5);">
      <div fxLayout="column" class="centerY" fxFlex="70" style="height:100%">
        <h2 id="appSplashAbout" class="appPrimaryColorText">About This Tool
        </h2>
        <div style="overflow:auto; width: 100%; height: 100%">
          <p class="tertirary-text small">The New Mexico Shared Stewardship Portal aims to ensure all natural resource
            and land management agencies in New Mexico that have interest in reduced wildfire and post-wildfire risk and
            increased landscape resiliency through active fuels/vegetation management have the goals and tools to assist
            in coordination, collaboration, and identification of shared priorities on the landscape. Further, the goal
            is also that these agencies use their shared priorities before making investments on the landscape, to
            combine forces and leverage investments to achieve greater returns on investment and reduced risk.</p>
          <p class="tertirary-text small">The portal helps partners better plan and track critical forest restoration
            accomplishments on the ground. It will add quantitative analysis to efficiently plan potential projects and
            the ability to track project status from planning on through to implementation and monitoring. We will also
            work to align data standards with federal standards to facilitate reporting.</p>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxLayout="column" fxLayoutGap="0.75rem" class="centerY" fxFlex="30" style="height:100%; padding: 0 0.5rem;"
        fxLayoutAlign="center center">
        <h2 class="appPrimaryColorText" id="appSplashInterested"
          style="margin-block-end: 0.25em; margin-block-start: 0.25em; text-align: center;">
          Are you interested in Shared Stewardship in New Mexico?
        </h2>
        <a *ngIf="!loggedIn()" mat-flat-button color="accent" class="widthFill centerText" (click)="routeToRegister()">SIGN UP</a>
        <a *ngIf="loggedIn()" [appRequireACL]="'Map:Access'" mat-flat-button color="accent" (click)="routeToPortal()" class="widthFill centerText">ENTER PORTAL</a>
        <a mat-flat-button color="accent" class="widthFill centerText" (click)="openContactUs()">CONTACT US</a>
        <a mat-flat-button color="accent" class="widthFill centerText" (click)="routeToForgot()">FORGOT PASSWORD</a>
      </div>
    </div>
  </div>
</div>
