export class GeoStatConstants {
    public static Landscapes = 'landscapes';
    public static Ownership = 'ownership';
    public static Watersheds = 'watersheds';
    public static FocalAreas = 'focal';
    public static Name = 'name';
    public static Top500 = 'top_500';
    public static Status = 'status';
    public static JobId = 'jobId';
    public static Stats = 'stats';
    public static Data = 'data';
    public static TotalArea = 'totalArea';
    public static Running = 'RUNNING';
    public static Success = 'SUCCESS';
    public static Fail = 'FAIL';
    public static ViewTitles = {
        Landscape: 'Priority Landscape',
        Focal: 'Focal Area',
        Ownership: 'Ownership',
        Watershed: 'Watershed'
    };
    public static NonFocalArea = 'Non-Focal Area';
}

export interface GeoStatContainerVm {
    landscape?: GeoStatRecordVm;
    focal?: GeoStatRecordVm;
    watershed?: GeoStatRecordVm;
    ownership?: GeoStatRecordVm;
}

export interface GeoStatRecordVm {
    title?: string;
    stat?: GeoStatVm[];
}

export interface GeoStatVm {
    title?: string;
    acres?: number | string;
}