<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-content>
        <div class="appBottomBorder">
          <p class="appGenCardHeader appH3SecondaryHeader">{{data.header}}</p>
          <p class="appGenCardSubtext"><span class="appGenCardSubtextTitle appFieldLabel">{{data.subTextTitle}}:
            </span><span class="appFieldContent">{{data.subTextValue}}</span></p>
          <p class="appGenCardSubtext" *ngIf="data?.subTextTitle2"><span
              class="appGenCardSubtextTitle appFieldLabel">{{data.subTextTitle2}}: </span><span
              class="appFieldContent">{{data.subTextValue2}}</span></p>
        </div>
        <div style="margin-top: .35rem;">
          <div>
            <div style="position: relative; float: right">
              <span *ngIf="data?.zoomAction" class="appGenCardLink" (click)="this.zoomRequest()">
                <mat-icon [matTooltip]="'Zoom to Feature'">zoom_in</mat-icon>
              </span>
              <span *ngIf="data?.exploreAction" class="appGenCardLink" (click)="this.exploreRequest()">
                <mat-icon [matTooltip]="'View in Explorer'">format_list_bulleted</mat-icon>
              </span>
              <span *ngIf="data?.detailsIcon" class="appGenCardLink" (click)="this.linkTo()">
                <mat-icon [matTooltip]="'Go to Details'">library_books</mat-icon>
              </span>
              <span *ngIf="!data?.detailsIcon" class="appGenCardLink" (click)="this.linkTo()">Go to Details</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
