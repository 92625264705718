import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DBMapConfig, DBMapConfigLayers, MapCategory, MapLayer, MapConfigConstants, Service, MapSelection, MonoselectiveSelection, PolyselectiveSelection } from '../../models/map/config.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { LegendColor, LegendUrl } from '../../models/map/legend.model'
import { PlugmapAPIService } from './plugmap-api.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private mapConfig$: BehaviorSubject<MapCategory[]> = new BehaviorSubject(null);
  private slimMapConfig$: BehaviorSubject<MapCategory[]> = new BehaviorSubject(null);
  private mapService$: BehaviorSubject<Service[]> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private plugmap: PlugmapAPIService,
  ) { }

  public getMapCategories(): MapCategory[] {
    return this.plugmap.getMapCategories();
  }

  public getMapConfig(): Observable<MapCategory[]> {
    // Check if there is an existing map config, else retrieve it
    if (!this.mapConfig$.getValue()) {
      // This method will fire a "next" event for subscribers
      this.getDbMapConfig();
    }
    return this.mapConfig$.asObservable();
  }

  public getSlimMapConfig(): Observable<MapCategory[]> {
    // Check if there is an existing map config, else retrieve it
    if (!this.slimMapConfig$.getValue()) {
      // This method will fire a "next" event for subscribers
      this.getDbSlimMapConfig();
    }
    return this.slimMapConfig$.asObservable();
  }

  public getServices(): Observable<Service[]> {
    // Check if there is an existing map config, else retrieve it
    if (!this.mapConfig$.getValue()) {
      // This method will fire a "next" event for subscribers
      this.getAPIMapService();
    }
    return this.mapService$.asObservable();
  }

  private setMapConfig(inMapConfig: MapCategory[]) {
    this.mapConfig$.next(inMapConfig);
  }

  private setSlimMapConfig(inMapConfig: MapCategory[]) {
    this.slimMapConfig$.next(inMapConfig);
  }

  private setServices(svcs: Service[]) {
    this.mapService$.next(svcs);
  }

  private getDbMapConfig() {
    this.api.get<DBMapConfig>("app", "GetMapConfig").subscribe(res => {
      const flatConfig = this.convertDbMapConfig(res as DBMapConfig);
      this.setMapConfig(flatConfig);
    }, error => {
      // If we need more error handling we can add here
      console.error(`Error: ${error} Unable to load map config from external resource`);
    });
  }

  private getDbSlimMapConfig() {
    this.api.get<DBMapConfig>("app", "GetSlimMapConfig").subscribe(res => {
      const flatConfig = this.convertDbMapConfig(res as DBMapConfig);
      this.setSlimMapConfig(flatConfig);
    }, error => {
      // If we need more error handling we can add here
      console.error(`Error: ${error} Unable to load map config from external resource`);
    });
  }

  private getAPIMapService() {
    this.api.get<Service[]>("app", "GetMapServices").subscribe(res => {
      this.setServices(res);
    }, error => {
      // If we need more error handling we can add here
      console.error(`Error: ${error} Unable to load map services from external resource`);
    });
  }

  private convertDbMapConfig(dbConfig: DBMapConfig): MapCategory[] {
    // The array which will hold map category objects
    const appMapConfig: MapCategory[] = [];

    // Cycle through categories and create base objects
    dbConfig.layerCategories.forEach(cat => {
      const newCat: MapCategory = {
        category_key: cat.key,
        opacity: cat.transparency,
        layers: [],
        name: cat.name,
        multiphasic: cat.multiphasic,
        openness: cat.openness
      };

      // Check selectiveness
      if (cat.selectiveness === MapConfigConstants.monoselective) {
        newCat.selection = new MonoselectiveSelection(cat.defaultSelection[0]);
      } else if (cat.selectiveness === MapConfigConstants.polyselective) {
        newCat.selection = new PolyselectiveSelection(cat.defaultSelection);
      }

      // Get layer group list for given category and find those in the DB config
      const layerGroups = cat.layerGroups;
      const foundLayerGroup = dbConfig.layerGroups.filter(layerGroup => {
        return layerGroups.includes(layerGroup.key);
      });

      // Cycle through select layer groups to unpack layers
      foundLayerGroup.forEach(lyrGrp => {
        // Get layer keys and cycle through those in the DB config
        const layerKeys = lyrGrp.layers;
        layerKeys.forEach(layerKey => {
          const currentLayer = dbConfig.layers.find(lyr => {
            return lyr.key === layerKey;
          });

          // If any layers are found
          if (currentLayer) {
            // Create a base map config
            const newLayer: MapLayer = {
              key: currentLayer.key,
              name: currentLayer.name,
              opacity: currentLayer.opacity
            };

            // Check if the obj has an identify property
            if (MapConfigConstants.identify in currentLayer) {
              this.configureIdentify(currentLayer, newLayer);
            }

            if ("legend" in currentLayer) {
              newLayer.legend = currentLayer.legend;
            }


            // Crosswalk the map types TODO: Support more types as needed
            newLayer.config = { type: null, value: null };
            if (MapConfigConstants.xyz in currentLayer) {
              newLayer.config.type = MapConfigConstants.xyz;
              newLayer.config.value = currentLayer.xyz;
            } else if (MapConfigConstants.esriExport in currentLayer) {
              newLayer.config.type = MapConfigConstants.esriExport;
              newLayer.config.value = currentLayer.esriExport;

              if (!currentLayer.legend || currentLayer.legend.length == 0) {
                this.configureLegendEsri(currentLayer, newLayer);
              } else {
                newLayer.legend = currentLayer.legend;
              }

            } else if (MapConfigConstants.esriFeature in currentLayer) {
              newLayer.config.type = MapConfigConstants.esriFeature;
              newLayer.config.value = currentLayer.esriFeature;

              if (!currentLayer.legend || currentLayer.legend.length == 0) {
                this.configureLegendEsri(currentLayer, newLayer);
              }
            } else if (MapConfigConstants.wmts in currentLayer) {
              newLayer.config.type = MapConfigConstants.wmts;
              newLayer.config.value = currentLayer.wmts;
            } else if (MapConfigConstants.mvt in currentLayer) {
              newLayer.config.type = MapConfigConstants.mvt;
              newLayer.config.value = currentLayer.mvt;
            } else if (MapConfigConstants.wms in currentLayer) {
              newLayer.config.type = MapConfigConstants.wms;
              newLayer.config.value = currentLayer.wms;
            }

            // Add to the category's layer list
            newCat.layers.push(newLayer);
          }
        });
      });

      // Add to the main array's categories list
      appMapConfig.push(newCat);
    });

    return appMapConfig;
  }

  configureIdentify(currentLayer: DBMapConfigLayers, newLayer: MapLayer) {
    newLayer.identify = currentLayer.identify; // new GenericIdentifyService(newLayer);    
  }

  configureLegendEsri(currentLayer: DBMapConfigLayers, newLayer: MapLayer) {
    newLayer.legend = [];

    let cfg;
    if (currentLayer?.esriExport) {
      cfg = currentLayer.esriExport || { endpoints: [] };
    } else if (currentLayer?.esriFeature) {
      cfg = currentLayer.esriFeature || { endpoints: [] };
    } else {
      cfg = { endpoints: [] };
    }

    cfg.endpoints.forEach((ep) => {

      let filters = undefined;
      if (ep.layersToShow) {
        if (ep.layersToShow.indexOf("show") == 0) {
          let is = ep.layersToShow.replace("show:", "").replace(" ", "").split(",");
          if (is.length)
            filters = is;
        }

      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };

      if (ep.url.endsWith('FeatureServer', ep.url.length - 3)) {
        let url = ep.url + "?f=json";

        this.http.get<any>(url, httpOptions).subscribe(res => {
          if (!res.drawingInfo || !res.drawingInfo.renderer || !res.drawingInfo.renderer.type || res.drawingInfo.renderer.type !== 'simple') {
            console.log(url);
            return;
          }

          newLayer.legend.push(
            {
              label: currentLayer.name,
              display: { symbol: res?.drawingInfo?.renderer?.symbol }
            });
        }, err => {
          newLayer.legend = undefined;
        })
      } else {
        let url = ep.url + "legend?f=json&size=30,20";

        this.http.get<any>(url, httpOptions).subscribe(res => {
          if (!res.layers) {
            console.log(url);
            return;
          }

          res.layers.forEach(layer => {

            if (filters && !(filters.includes(layer.layerId + ""))) {
              return;
            }

            layer.legend.forEach(legendItem => {
              if (!newLayer.legend)
                newLayer.legend = [];
              newLayer.legend.push(
                {
                  label: legendItem.label ||
                    layer.name ||
                    currentLayer.name,
                  display: { url: `data:image/png;base64,${legendItem.imageData}` }
                });
            })
          })
        }, err => {
          newLayer.legend = undefined;
        })
      }
      // let url = ep.url + "legend?f=json&size=30,20";

      // this.http.get<any>(url, httpOptions).subscribe(res => {
      //   if (!res.layers) {
      //     console.log(url);
      //     return;
      //   }

      //   res.layers.forEach(layer => {

      //     if (filters && !(filters.includes(layer.layerId + ""))) {
      //       return;
      //     }

      //     layer.legend.forEach(legendItem => {
      //       if (!newLayer.legend)
      //         newLayer.legend = [];
      //       newLayer.legend.push(
      //         {
      //           label: legendItem.label ||
      //             layer.name ||
      //             currentLayer.name,
      //           display: { url: `data:image/png;base64,${legendItem.imageData}` }
      //         });
      //     })
      //   })
      // }, err => {
      //   newLayer.legend = undefined;
      // })

    })
  }

}
