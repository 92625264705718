<!DOCTYPE html>
<div id="appScroller">

  <!-- Main page container -->
  <div fxFlex fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem" [style]="'height:' + heightValue">

    <!-- Header bar and submit buttons -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth"
      style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">

      <!-- Title -->
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth">
        <h1 class="appProjectHeader">{{ this.projectName }}</h1>
      </div>

      <!-- Back to Project buttons -->
      <button mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
        (click)="routeToExplorer()">View in Explorer</button>

      <!-- End Header bar and submit buttons -->
    </div>

    <!-- Map/Details -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard appSolidCard" fxFlex="100"
        fxLayoutGap="0.65rem">

        <div style="margin-left: 0.5rem;" fxFlex="50" fxFill fxLayout="column"
          class="appFullWidth appFullHeight appCardContainer appBottomRounded" fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard" fxFlex="100">
            <mat-card-header>
              <mat-card-title style="margin-left: 1rem;" class="appH1Title">Project Map</mat-card-title>
              <div class="appEditIconContainer" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
                <app-edit-button-cluster [editModeOn]="this.editMapOn" [formsAreValid]="this.formsAreValid"
                  (saveClicked)="this.toggleEditMap()" (cancelClicked)="this.cancelEditMap()"
                  (editClicked)="this.toggleEditMap()" [disabledEdit]="this.editModeOn"
                  [optionalEditTooltip]="this.editModeOn ? 'You must end the active edits underway on the page before editing the Project boundary.' : ''">
                </app-edit-button-cluster>
              </div>
            </mat-card-header>
            <mat-card-content class="appMapCardContent">
              <app-slim-map *ngIf="!editMapOn" #slimMap [showEditCreateButtons]="false"
                (shapeUpdated)="newShapeData($event)" [strokeColor]="'rgba(0, 19, 2, 0.5)'"
                [fillColor]="'rgba(185, 232, 42, 0.5)'">
              </app-slim-map>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- End map -->

        <!-- Project Details -->
        <div fxFlex="50" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer appBottomRounded"
          fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0" fxFlex="100">
            <mat-card-header class="appProjectHeaderContainer">
              <mat-card-title class="appH1Title">General Information
              </mat-card-title>
              <div class="appEditIconContainer" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
                <app-edit-button-cluster [editModeOn]="this.editModeOn" [formsAreValid]="this.formsAreValid"
                  (saveClicked)="this.toggleEditMode()" (cancelClicked)="this.cancelEdit()"
                  (editClicked)="this.toggleEditMode()">
                </app-edit-button-cluster>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="!this.editModeOn">
                <div style="margin-top:1rem;margin-bottom: 0.1rem;" class="appFieldLabel">Project ID</div>
                <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.id || '' }}</div>
                <div style="margin-top:1rem;margin-bottom: 0.1rem;" class="appFieldLabel">Coordinating Organization</div>
                <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.projectLeadOrg || '' }}
                </div>
              </div>
              <div class="appSpacer" *ngIf="this.editModeOn"></div>
              <app-dynamic-form #genInfo [activeEdit]="this.editModeOn" [fields]="this.fields"></app-dynamic-form>
              <div *ngIf="!this.editModeOn">
                <div class="appFieldLabel">GIS Acres</div>
                <div style="margin-bottom: 1rem;margin-bottom: 0.1rem;" class="appFieldContent">
                  {{ this.target?.fields?.Geometry?.acres | number : '1.1-1' || '' }}</div>
              </div>
            </mat-card-content>
          </mat-card>
          <div *ngIf="!this.editModeOn" style="position: absolute; right: 1rem; top: 4rem">
            <div class="appFieldLabel">Date Created</div>
            <div style="margin-bottom: 0.25rem;" class="appFieldContent">{{this.target.created | date: 'shortDate'}}</div>
            <div class="appFieldLabel">Last Modified</div>
            <div style="margin-bottom: 0.75rem;" class="appFieldContent">{{this.target.modified | date: 'shortDate'}}</div>
          </div>
        </div>
      </mat-card>
    </div>

    <!-- Activities -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appFullWidth appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Activities
            </mat-card-title>
            <div class="appCollapseIconContainer"
              [appRequireAclEapoPartner]="['Project:Edit', projectLeadOrg, partners]">
              <button mat-flat-button class="appAddActivitiesText appBrownActionButton" (click)="addActivity()">+
                Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div [ngClass]="{appActivityOverflow: this.activities.length >= 3}">
              <ul id="partners" *ngIf="!isActivitiesEmpty()">
                <li *ngFor="let activity of activities">
                  <app-activity-card #activity [activity]="activity" [projectLeadOrg]="projectLeadOrg"
                    [projectId]="this.target.id" (activityToRemove)="triggerActivityDelete($event)">
                  </app-activity-card>
                </li>
              </ul>
            </div>
            <div *ngIf="isActivitiesEmpty()">
              <div style="margin-top: 1rem;" class="appFieldContent">No activities have been added for this Project.
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Project Focus -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullHeight appCardContainer appBottomRounded"
        fxLayoutGap="0.5rem">
        <mat-card class="appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Project Focus & Strategies
            </mat-card-title>
            <div class="appEditIconContainer" style="margin-top: 0.25rem;"
              [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
              <app-edit-button-cluster [editModeOn]="this.editModeOn" [formsAreValid]="this.formsAreValid"
                (saveClicked)="this.toggleEditMode()" (cancelClicked)="this.cancelEdit()"
                (editClicked)="this.toggleEditMode()">
              </app-edit-button-cluster>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div
              [ngClass]="{ appStratOverflow: this.focusFields && (this.focusFields[0].value.length + this.focusFields[1].value.length) >= 7 }">
              <div class="appSpacer" *ngIf="this.editModeOn"></div>
              <app-dynamic-form #focus [activeEdit]="this.editModeOn" [fields]="this.focusFields"></app-dynamic-form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <!-- Project Partners -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Project Partners
            </mat-card-title>
            <div class="appCollapseIconContainer appEditIconContainer"
              [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
              <button mat-flat-button class="appAddActivitiesText appBrownActionButton" (click)="addPartner()">+
                Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div [ngClass]="{appPartnerOverflow: this.partners.length >= 4}">
              <ul id="partners" *ngIf="!isPartnersEmpty()">
                <li *ngFor="let partner of partners">
                  <app-partner #partner [partner]="partner" [projectLeadOrg]="projectLeadOrg"
                    (partnerToRemove)="removePartner($event)"></app-partner>
                </li>
              </ul>
            </div>
            <div *ngIf="isPartnersEmpty()">
              <div style="margin-top: 1rem;" class="appFieldContent">No partners have been added for this Project.</div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Project Geography -->
      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Project Geography
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="appGeogOverflow">
              <app-project-geo-card #projGeo [projectId]="this.target?.id || null"></app-project-geo-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Row Container -->
    </div>

    <!-- Opportunities And Impediments -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <!-- Opportunities -->
      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appFullWidth appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header>
            <mat-card-title class="appH1Title">Opportunities
            </mat-card-title>
            <div class="appCollapseIconContainer"
              [appRequireAclEapoPartner]="['Project:Edit', projectLeadOrg, partners]">
              <button mat-flat-button class="appAddActivitiesText appBrownActionButton" (click)="addOpportunity()">+
                Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="appNotesOverflow">
              <ul id="partners" *ngIf="!isOpportunitiesEmpty()">
                <li *ngFor="let opp of opportunities">
                  <app-opportunity #opportunity [opportunity]="opp" [projectLeadOrg]="projectLeadOrg"
                    [projectId]="this.target.id" (opportunityToRemove)="triggerOpportunityDelete($event)">
                  </app-opportunity>
                </li>
              </ul>
              <div *ngIf="isOpportunitiesEmpty()">
                <div style="margin-top: 1rem;" class="appFieldContent">No Opportunities have been added for this
                  Project.</div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Impediments -->
      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appFullWidth appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header>
            <mat-card-title class="appH1Title">Impediments
            </mat-card-title>
            <div class="appCollapseIconContainer"
              [appRequireAclEapoPartner]="['Project:Edit', projectLeadOrg, partners]">
              <button mat-flat-button class="appAddActivitiesText appBrownActionButton" (click)="addImpediment()">+
                Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="appNotesOverflow">
              <ul id="partners" *ngIf="!isImpedimentsEmpty()">
                <li *ngFor="let imp of impediments">
                  <app-impediment #impediment [impediment]="imp" [projectLeadOrg]="projectLeadOrg"
                    [projectId]="this.target.id" (impedimentToRemove)="triggerImpedimentDelete($event)">
                  </app-impediment>
                </li>
              </ul>
              <div *ngIf="isImpedimentsEmpty()">
                <div style="margin-top: 1rem;" class="appFieldContent">No Impediments have been added for this Project.
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>

    <!-- Project Funding Summary -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appFullWidth appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Funding Summary
              <div style="float: right">
                <img id="appMapIcon" alt="tooltip" src="/assets/images/info-icon.png"
                  [matTooltip]="'Values shown are calculated from the funding details entered for Activities associated to this Project. Activity funding source information is managed by this Project\'s Coordinating Organization and its Partners.'"
                  class="appSecondaryIcon">
              </div>
            </mat-card-title>
          </mat-card-header>
          <div class="appFinancialsOverflow">
            <mat-card-content>
              <div *ngIf="sources.length > 0">
                <div class="appH2Header appTopProject">Project Summary</div>
                <div>
                  <app-project-funding #prjFund [projectFunding]="projectFunding">
                  </app-project-funding>
                </div>
                <div>
                  <div class="appH2Header">Funding Sources</div>
                  <ul id="partners">
                    <li *ngFor="let source of sources">
                      <app-project-funding-source #prjSource [source]="source">
                      </app-project-funding-source>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="!sources.length">
                <div style="margin-top: 1rem;" class="appFieldContent">No Funding Sources have been added for this
                  Project.
                </div>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>

    </div>
    <!-- End Main page container -->
  </div>
