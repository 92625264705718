export interface ResourceConcern {
    id: number;
    category: string;
    group: string;
    value: string;
  }

  export var allConcerns: ResourceConcern[] = [
    { id:10, "value": "Sheet and Rill Erosion", "group": "Sheet, Rill, & Wind Erosion", "category": "Soil Erosion" },
    { id:11, "value": "Wind Erosion", "group": "Sheet, Rill, & Wind Erosion", "category": "Soil Erosion" },
    { id:12, "value": "Ephemeral Gully Erosion", "group": "Concentrated Flow Erosion", "category": "Soil Erosion" },
    { id:13, "value": "Classic Gully Erosion", "group": "Concentrated Flow Erosion", "category": "Soil Erosion" },
    { id:14, "value": "Streambank, Shoreline, Water Conveyance Channels", "group": "Excessive Bank Erosion from Streams Shorelines or Water Conveyance Channels", "category": "Soil Erosion" },
    { id:15, "value": "Organic Matter Depletion", "group": "Organic Matter Depletion", "category": "Soil Quality Degradation" },
    { id:16, "value": "Compaction", "group": "Compaction", "category": "Soil Quality Degradation" },
    { id:17, "value": "Subsidence", "group": "Subsidence", "category": "Soil Quality Degradation" },
    { id:18, "value": "Concentration of Salts or Other Chemicals", "group": "Concentration of Salts or Other Chemicals", "category": "Soil Quality Degradation" },
    { id:19, "value": "Seeps", "group": "Ponding, Flooding, Seasonal High Water Table, Seeps and Drifted Snow", "category": "Excess Water" },
    { id:21, "value": "Runoff, Flooding, or Ponding", "group": "Ponding, Flooding, Seasonal High Water Table, Seeps and Drifted Snow", "category": "Excess Water" },
    { id:22, "value": "Seasonal High Water Table", "group": "Ponding, Flooding, Seasonal High Water Table, Seeps and Drifted Snow", "category": "Excess Water" },
    { id:23, "value": "Drifted Snow", "group": "Ponding, Flooding, Seasonal High Water Table, Seeps and Drifted Snow", "category": "Excess Water" },
    { id:24, "value": "Inefficient Use of Irrigation Water", "group": "Inefficient Use of Irrigation Water", "category": "Insufficient Water" },
    { id:25, "value": "Inefficient Moisture Management", "group": "Inefficient Moisture Management", "category": "Insufficient Water" },
    { id:26, "value": "Pesticides in Surface Water", "group": "Pesticides Transported to Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:27, "value": "Pesticides in Groundwater", "group": "Pesticides Transported to Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:28, "value": "Nutrients in Surface Water", "group": "Excess Nutrients in Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:29, "value": "Nutrients in Groundwater", "group": "Excess Nutrients in Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:30, "value": "Salts in Surface Water", "group": "Salts in Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:31, "value": "Salts in Groundwater", "group": "Salts in Surface and Groundwater", "category": "Water Quality Degradation" },
    { id:32, "value": "Excess Pathogens and Chemicals from Manure, Bio-solids or Compost Applications in Surface Water", "group": "Excess Pathogens and Chemicals from Manure, Bio-solids or Compost Applications", "category": "Water Quality Degradation" },
    { id:33, "value": "Excess Pathogens and Chemicals from Manure, Bio-solids or Compost Applications in Groundwater", "group": "Excess Pathogens and Chemicals from Manure, Bio-solids or Compost Applications", "category": "Water Quality Degradation" },
    { id:34, "value": "Petroleum, Heavy Metals and Other Pollutants Transported to Surface Water", "group": "Petroleum, Heavy Metals and Other Pollutants Transported to Receiving Waters", "category": "Water Quality Degradation" },
    { id:35, "value": "Petroleum, Heavy Metals and Other Pollutants Transported to Groundwater", "group": "Petroleum, Heavy Metals and Other Pollutants Transported to Receiving Waters", "category": "Water Quality Degradation" },
    { id:36, "value": "Excessive Sediment in Surface Water", "group": "Excessive Sediment in Surface Water", "category": "Water Quality Degradation" },
    { id:37, "value": "Elevated Water Temperature", "group": "Elevated Water Temperature", "category": "Water Quality Degradation" },
    { id:38, "value": "Emissions of Particulate Matter (PM) and PM Precursors", "group": "Emissions of Particulate Matter (PM) and PM Precursors", "category": "Air Quality Impacts" },
    { id:39, "value": "Emissions of Ozone Precursors", "group": "Emissions of Ozone Precursors", "category": "Air Quality Impacts" },
    { id:40, "value": "Emissions of Greenhouse Gases (GHGs)", "group": "Emissions of Greenhouse Gases (GHGs)", "category": "Air Quality Impacts" },
    { id:41, "value": "Objectionable Odors", "group": "Objectionable Odors", "category": "Air Quality Impacts" },
    { id:42, "value": "Undesirable Plant Productivity and Health", "group": "Undesirable Plant Productivity and Health", "category": "Degraded Plant Condition" },
    { id:43, "value": "Inadequate Structure and Composition", "group": "Inadequate Structure and Composition", "category": "Degraded Plant Condition" },
    { id:44, "value": "Excessive Plant Pest Pressure", "group": "Excessive Plant Pest Pressure", "category": "Degraded Plant Condition" },
    { id:45, "value": "Wildfire Hazard, Excessive Biomass Accumulation", "group": "Wildfire Hazard, Excessive Biomass Accumulation", "category": "Degraded Plant Condition" },
    { id:46, "value": "Inadequate Habitat - Food", "group": "Habitat Degradation", "category": "Fish and Wildlife - Inadequate Habitat" },
    { id:47, "value": "Inadequate Habitat - Cover/Shelter", "group": "Habitat Degradation", "category": "Fish and Wildlife - Inadequate Habitat" },
    { id:48, "value": "Inadequate Habitat - Water", "group": "Habitat Degradation", "category": "Fish and Wildlife - Inadequate Habitat" },
    { id:49, "value": "Inadequate Habitat - Habitat Continuity (Space)", "group": "Habitat Degradation", "category": "Fish and Wildlife - Inadequate Habitat" },
    { id:50, "value": "Inadequate Feed and Forage", "group": "Inadequate Feed and Forage", "category": "Livestock Production Limitation" },
    { id:51, "value": "Inadequate Shelter", "group": "Inadequate Shelter", "category": "Livestock Production Limitation" },
    { id:52, "value": "Inadequate Water", "group": "Inadequate Water", "category": "Livestock Production Limitation" },
    { id:53, "value": "Equipment and Facilities", "group": "Equipment and Facilities", "category": "Inefficient Energy Use" },
    { id:54, "value": "Farming/Ranching Practices and Field Operations", "group": "Farming/Ranching Practices and Field Operations", "category": "Inefficient Energy Use" }
  ]