<div id="appScroller">

  <!-- Main page container -->
  <div fxFlex fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem">

    <!-- Header bar and submit buttons -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth"
      style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">

      <!-- Title -->
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth">
        <h1 class="appProjectHeader">Create New Activity
        </h1>
      </div>

      <!-- Confirmation buttons -->
      <button mat-flat-button color="warn" class="align-right appConfirmButton appSquareButton"
        style="margin-right: 1rem;" (click)="cancel()">Cancel</button>
      <div [matTooltip]="saveHint()">
        <button mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
          (click)="trySave($event)" [disabled]="!this.isValid()">Save</button>
      </div>
      <!-- End Header bar and submit buttons -->
    </div>

    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center"
      class="appFlexShrink appFullWidth appProjectHeaderContainer">
      <!-- End Header bar and submit buttons -->
    </div>

    <!-- Map -->
    <div *ngIf="isSpatial" fxFlex="35" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer">

      <div fxFlex="100" fxFill fxLayout="column"
        class="appFullWidth appFullHeight appCardContainer appSolidCard appBottomRounded" fxLayoutGap="0.5rem">

        <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard appSolidCard" fxFlex="100">
          <mat-card-header>
            <mat-card-title class="appH1Title" style="margin-left: 1rem;">
              <span>Map</span>
              <img id="appMapIcon" alt="tooltip" src="/assets/images/info-icon.png"
                [matTooltip]="'Click the Draw button to draw the Activity boundary. If you are uncertain of your area, map the approximate expected area, and you can update it later, as needed.'"
                class="appSecondaryIcon appCustomTooltip appFormTooltip">
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="appMapCardContent">
            <app-slim-map #slimMap [featureName]="'Activity'" [showEditCreateButtons]="true"
              (shapeUpdated)="newShapeData($event)" [strokeColor]="'rgba(48, 0, 0, 0.5)'"
              [fillColor]="'rgba(255, 42, 42, 0.5)'"></app-slim-map>
          </mat-card-content>
        </mat-card>

      </div>
      <!-- End map -->
    </div>


    <!-- Activity Fields -->
    <div fxFlex="50" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer">

      <div fxFlex="50" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer"
        fxLayoutGap="0.5rem">
        <mat-card fxFlex=" 100" class="appFullWidth appFullHeight mat-elevation-z0 appSolidCard">

          <div class="appFormContainer">
            <mat-card class="mat-elevation-z0">
              <mat-card-header class="appCardHeaderUnderline">
                <mat-card-title class="appH1Title">Core Details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div style="font-weight: bold; margin-top:1rem;" class="appIdText appFieldLabel">Activity Type</div>
                <div style="margin-bottom: 1rem;" class="appIdText appFieldContent">{{ this.primaryType || 'N/A' }}</div>
                <div style="font-weight: bold; margin-top:1rem;" class="appIdText appFieldLabel">Primary Activity Focus</div>
                <div style="margin-bottom: 1rem;" class="appIdText appFieldContent">{{ this.primaryFocus || 'N/A' }}</div>
                <app-dynamic-form #activityPlanning [fields]="fields"></app-dynamic-form>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card>
      </div>

      <!-- Activity Work Fields -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer"
        fxLayoutGap="0.5rem">
        <mat-card fxFlex="100" class="appFullWidth appFullHeight mat-elevation-z0 appSolidCard">
          <div class="appFormContainer">
            <mat-card class="mat-elevation-z0">
              <mat-card-header class="appCardHeaderUnderline">
                <mat-card-title class="appH1Title">Additional Details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-dynamic-form #activityWork [fields]="fieldsWork"></app-dynamic-form>
                <div><a href="https://nm-emnrd.maps.arcgis.com/apps/webappviewer/index.html?id=2729c558d3fe42198d7740c8e2a195f8" target="_blank">CAR 5-Mi Buffer Web Mapping Application</a></div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card>
      </div>
      <!-- End Main page container -->
    </div>
  </div>
