import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Import, ImportState } from '../map/import';
import { MapCategory } from '../_shared/models/map/config.model';
import { FeatureTypes } from '../_shared/models/map/feature-type.model';
import { PamShapeData } from '../_shared/models/pam-object.model';
import { ConfiguredIdentifySources } from '../_shared/services/map/identify.service';
import { MapService } from '../_shared/services/map/map.service';
import { PlugmapAPIService } from '../_shared/services/map/plugmap-api.service';
import { FixedLengthArray } from '../_shared/types/fixed-length-array';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from '../map/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ImportDialogComponent } from '../import/import-dialog/import-dialog.component';

declare var ol: any;
declare var famMap: any;
declare var window: any;

@Component({
  selector: 'app-slim-map',
  templateUrl: './slim-map.component.html',
  styleUrls: ['./slim-map.component.scss']
})
export class SlimMapComponent implements OnInit {
  // Map initial config
  @Input() mapCenter: FixedLengthArray<[number, number]> = [-106.1895148695699, 34.51098276276389]; // Default to centroid of NM
  @Input() defaultZoom: number = 6;
  @Input() maxZoom: number = 22;
  @Input() minZoom: number = 3;
  @Input() defaultExtent: FixedLengthArray<[number, number, number, number]> = [-13668904.318687698, 3470227.515307423, -9973021.127042854, 4725017.771636881];
  @Input() showEditCreateButtons: boolean = false;
  @Input() mainMapId = 'mgmap';
  @Input() featureName: string = 'Project';
  @Input() fillColor: string = 'rgba(0,77,168,0.8)';
  @Input() strokeColor: string = 'rgba(0,77,168,0.95)';
  @Input() hideControls = false;

  // Outputs
  @Output() shapeUpdated = new EventEmitter<PamShapeData>();
  @Output() editModeChanged = new EventEmitter<boolean>();

  // Map data
  private mapConfigSub: Subscription;
  private mapDrawSub: Subscription;
  private mapConfigLoaded = false;

  // Import data
  importEnabled: boolean = false;
  import: Import = new Import(this.identify);

  ImportState = ImportState;

  // Layer and feature data
  mapCategories: MapCategory[];
  highlightLayer: any;
  refLayer: any;
  wkt: any;
  drawnShape: any;
  drawLayer: any;
  featureType: string;
  editingName = false;
  editingBounds = false;
  target: any = { fields: {} };
  estAcres: number;

  // Overlays
  private assessmentEditOverlay: any;
  private importOverlay: any;
  assessmentOverlayContainerHeight: number;
  importOverlayContainerHeight: number;
  showHelp = false;

  constructor(
    private mapService: MapService,
    private mapAPI: PlugmapAPIService,
    private identify: ConfiguredIdentifySources,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.bootMap();

    this.mapConfigSub = this.mapService.getSlimMapConfig().subscribe(config => {
      if (config && !this.mapConfigLoaded) {
        // On first map theme load
        this.mapCategories = config;
        this.mapConfigLoaded = true;
        this.mapAPI.setCategories(config);
        this.mapAPI.turnLayerOnByKey('lyr_esri_topo');
      } else if (config) {
        // Add any routines that need to listen for other theme changes
      }
    });
  }

  refreshMap() {
    this.mapAPI.refreshMap();
  }

  isDrawing(): boolean {
    return this.mapAPI.isDrawing();
  }

  hasArea(): boolean {
    return this.mapAPI.hasShape();
  }

  bootMap(): void {
    this.mapAPI.createMap(this.mainMapId, this.mapCenter, this.defaultZoom, this.maxZoom, this.minZoom);
    setTimeout(() => { famMap.getMap().handleResize_() }, 500);

    this.installExtensions();
  }

  private installExtensions(): void {
    this.mapAPI.initDraw();

    let map = famMap.getMap();

    let zte = new ol.control.ZoomToExtent({
      extent: this.defaultExtent,
    });

    zte.handleClickOld = zte.handleClick_;
    let that = this;

    // Zoom workaround
    zte.handleZoomToExtent = function () {
      let sd = document.getElementsByName('map-drawer');
      let mp = this.getMap();
      let vw = mp.getView();

      let pudding = [0, 0, 0, 0];
      if (sd.length)
        pudding[3] += sd[0].offsetWidth;

      vw.fit(that.defaultExtent, {
        size: mp.getSize(),
        duration: 1000,
        padding: pudding
      });


      // Override if there is a feature
      if (that.target.fields && that.target.fields.Geometry && that.target.fields.Geometry.wkt) {
        let obj = { wkt: that.target.fields.Geometry.wkt, left: 0, bottom: 0 };
        that.mapAPI.fitToWKT(obj);
      }
    }

    let sl = new ol.control.ScaleLine({ units: 'us' });
    map.controls.extend([zte, sl]);

    let assessmentEditOverlayContainer = document.getElementById('assessmentEditOverlay');
    this.assessmentOverlayContainerHeight = assessmentEditOverlayContainer.offsetHeight;

    let importOverlayContainer = document.getElementById('importOverlay');
    this.importOverlayContainerHeight = importOverlayContainer.offsetHeight;

    this.assessmentEditOverlay = new ol.Overlay({
      element: assessmentEditOverlayContainer,
      positioning: 'bottom-center',
      autoPan: {
        margin: 20
      }
    });

    this.importOverlay = new ol.Overlay({
      element: importOverlayContainer,
      positioning: 'bottom-center',
      autoPan: {
        margin: 20
      }
    });

    map.addOverlay(this.assessmentEditOverlay);
    map.addOverlay(this.importOverlay);

  }

  onLayerSelect(e) {
    switch (e.value) {
      case 'topo':
        this.mapAPI.turnLayerOnByKey('lyr_esri_topo');
        break;
      case 'aerial':
        this.mapAPI.turnLayerOnByKey('lyr_esri_world_image');
        break;
      case 'streets':
        this.mapAPI.turnLayerOnByKey('lyr_esri_streets');
        break;
      case 'grey':
        this.mapAPI.turnLayerOnByKey('lyr_esri_light_gray');
        break;
      default:
        this.mapAPI.turnLayerOnByKey('lyr_esri_topo');
        break;
    }
  }

  activateEditing() {
    this.editingBounds = true;
    this.editModeChanged.emit(this.editingBounds);
  }

  deactivateEditing() {
    this.editingBounds = false;
    this.editModeChanged.emit(this.editingBounds);
  }

  drawNewAssessmentArea() {

    this.dismissImport();

    this.activateEditing();

    let dblClickInteraction;
    // Find DoubleClickZoom interaction
    famMap.getMap().getInteractions().getArray().forEach(function (interaction) {
      if (interaction instanceof ol.interaction.DoubleClickZoom) {
        dblClickInteraction = interaction;
      }
    });

    // Remove from map
    if (dblClickInteraction)
      famMap.getMap().removeInteraction(dblClickInteraction);


    let draw = this.mapAPI.startDrawingPolygon();

    if (!this.mapDrawSub)
      this.mapDrawSub =
        draw.subscribe(this.handleDrawResult.bind(this));
    this.featureType = FeatureTypes.Custom;
  }

  importShapeFile() {

    let dialogRef = this.dialog.open(ImportDialogComponent, {
      width: '35rem',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        data.forEach(element => {
          let draw = this.mapAPI.setFeatsGeoJSON(element);

          if (this.mapDrawSub)
            this.mapDrawSub.unsubscribe();

          this.mapDrawSub = draw.subscribe(this.handleImportResult.bind(this));
        });
      }
    });
  }

  handleImportResult(newShape) {
    // The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;

    if (this.drawnShape)
      this.clearBounds();

    this.drawnShape = newShape;

    this.populateDrawLayer();

    this.activateEditing();

    if (!this.drawLayer) return; // Something bork

    let feat = this.drawLayer.getSource().getFeatureById(newShape.olUID);

    if (!feat)
      // Something bad happened
      feat = this.drawLayer.getSource().getFeatures()[0];

    if (!feat) {
      return;
    }

    if (this.mapDrawSub)
      this.mapDrawSub.unsubscribe();

    this.mapDrawSub =
      this.mapAPI.getShapeChange().subscribe(this.handleDrawResult.bind(this));

    this.zoomToFeature(feat);

  }

  zoomToFeature(feat: any, pudding?: number[]) {
    let sd = document.getElementsByName("map-drawer");
    let map = famMap.getMap();
    let view = map.getView();

    if (!pudding)
      pudding = [200, 200, 200, 200];
    if (sd.length)
      pudding[3] += sd[0].offsetWidth;

    view.fit(feat.getGeometry(), {
      size: map.getSize(),
      duration: 1000,
      padding: pudding
    });
  }

  dismissImport() {
    this.importEnabled = false;
    this.import.clear();
    this.importOverlay.setPosition(undefined);
    if (this.highlightLayer)
      this.highlightLayer.getSource().clear();
  }

  handleDrawResult(newShape) {
    //The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;

    this.drawnShape = newShape;

    this.featureType = FeatureTypes.Custom;

    this.populateDrawLayer();

    if (!this.drawLayer) return; //Something bork

    let feat = this.drawLayer.getSource().getFeatureById(newShape.olUID);

    if (!feat)
      //Something bad happened
      feat = this.drawLayer.getSource().getFeatures()[0];

    if (!feat) {
      console.log('Error occured in slim map, no feature'); // We shouldnt get here
      return;
    }
  }

  grabSnapshotOfDrawnShape(targetSize: number[]): Observable<any> {
    // Show a loading screen when we are getting a screenshot
    const snapshotRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Generating Snapshot",
      disableClose: true
    });

    var ds = new Subject<any>();

    let map = famMap.getMap();
    let origSize = map.getSize();
    let that = this;

    map.setSize(targetSize);

    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    };

    let pudding = [20, 20, 20, 20];
    let ext = [];

    try {
      ext = ml.getSource().getFeatureById(this.drawnShape.olUID).getGeometry().getExtent();
    }
    catch (ex) {
      snapshotRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["Error Generating Snapshot", "An error occured. Please try again. If this happens again, modify the shape slightly and click save."],
        disableClose: true
      });
      throw ex;
    }

    let vw = map.getView();
    vw.fit(ext, {
      size: targetSize,
      nearest: false,
      padding: pudding
    });

    map.once('rendercomplete', function () {
      var mapCanvas = document.createElement('canvas');

      mapCanvas.width = targetSize[0];
      mapCanvas.height = targetSize[1];
      var mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );

      map.setSize(origSize);

      snapshotRef.close();

      setTimeout(() => { ds.next(mapCanvas.toDataURL()); }, 100);
    });

    // Again there is some weirdness with resizing the map, so manually call renderSync after a delay of like 500ms or so
    setTimeout(() => { map.renderSync(); }, 500);

    return ds.asObservable();
  }

  populateDrawLayer() {
    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_;
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    }

    if (!ml)
      console.log('Error occured in slim map'); // We shouldnt get here

    this.drawLayer = ml;
  }

  setImportOverlayPositionAndContents(center: number[]) {

    let overlay = this.importOverlay;

    //Set the position
    setTimeout(() => { overlay.setPosition(center); }, 250);

    //Populate contents
    let container = document.getElementById("importOverlay");

    //Only append if its not already there
    if (container.children.length == 0) {
      let cnt = document.getElementById("importOverlayContents");
      container.appendChild(cnt);
    }
  }

  setOverlayPositionAndContents(center: number[]) {

    let overlay = this.assessmentEditOverlay;

    //Set the position
    setTimeout(() => { overlay.setPosition(center); }, 250);

    //Populate contents
    let container = document.getElementById("assessmentEditOverlay");

    //Only append if its not already there
    if (container.children.length == 0) {
      let cnt = document.getElementById("assessmentEditOverlayContents");
      container.appendChild(cnt);
    }
  }

  hasShape() {
    return this.drawnShape != undefined;
  }

  dismissAssessmentEdit() {
    this.stopDraw();
  }

  saveBoundsClick() {
    let obj: PamShapeData = {
      id: this.target.id, name: this.target.name, fields: {
        Geometry: {
          wkt: this.drawnShape.wkt,
          acres: this.drawnShape.acres
        }
      }
    };

    this.update(obj);
  }

  clearBounds() {
    this.mapAPI.clearShape();
    this.drawnShape = undefined;
  }

  stopDraw(): void {
    this.mapAPI.stopDrawing();
    this.drawnShape = undefined;
    this.assessmentEditOverlay.setPosition(undefined);
    this.deactivateEditing();
    if (this.highlightLayer) this.highlightLayer.setVisible(true);
  }

  update(obj: PamShapeData) {
    console.log('UPDATE');
    console.log(obj);
    this.target = obj;
    this.shapeUpdated.emit(obj);
    this.addShapeWKT(obj.fields.Geometry['wkt']);
    this.stopDraw();
    this.mapAPI.fitToWKT({ wkt: obj.fields.Geometry.wkt, left: 0, bottom: 0 });
  }

  updateRefShape(obj: PamShapeData, fillColor?: any, strokeColor?: any, noZoom?: boolean) {
    let reader = new ol.format.WKT();
    let f = reader.readFeature(obj.fields.Geometry['wkt']);

    this.setupRefLayer(fillColor, strokeColor);

    let ext = ol.extent.createEmpty();
    ol.extent.extend(ext, f.getGeometry().getExtent());

    this.refLayer.getSource().clear();
    this.refLayer.getSource().addFeature(f);

    if (!noZoom)
      this.mapAPI.fitToWKT({ wkt: obj.fields.Geometry.wkt, left: 0, bottom: 0 });
  }

  editBoundsClick() {
    this.activateEditing();

    let draw = this.mapAPI.setWKTs([this.target.fields.Geometry.wkt]);


    if (this.mapDrawSub)
      this.mapDrawSub.unsubscribe();

    this.mapDrawSub =
      draw.subscribe(this.handleDrawResult.bind(this));

    this.highlightLayer.setVisible(false);

  }

  addShape(shape: any) {
    let reader = new ol.format.GeoJSON();
    let f = reader.readFeature(shape);

    this.setFeat(f);
  }

  addShapeWKT(wkt: any) {
    let reader = new ol.format.WKT();
    let f = reader.readFeature(wkt);

    this.setFeat(f);
  }

  setFeat(f: any) {

    this.setupHighlightLayer();

    let ext = ol.extent.createEmpty();

    ol.extent.extend(ext, f.getGeometry().getExtent());

    this.highlightLayer.getSource().clear();
    this.highlightLayer.getSource().addFeature(f);
  }

  setupHighlightLayer() {
    if (this.highlightLayer)
      return;
    this.highlightLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    this.highlightLayer.setSource(src);

    let sty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: this.fillColor
      }),
      stroke: new ol.style.Stroke({
        color: this.strokeColor,
        width: 1
      })
    });
    this.highlightLayer.setStyle(sty);

    this.highlightLayer.setZIndex(9999);

    famMap.getMap().addLayer(this.highlightLayer);
  }

  setupRefLayer(fillColor?: any, strokeColor?: any) {
    if (this.refLayer)
      return;
    this.refLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    this.refLayer.setSource(src);

    let sty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: fillColor || this.fillColor
      }),
      stroke: new ol.style.Stroke({
        color: strokeColor || this.strokeColor,
        width: 1
      })
    });
    this.refLayer.setStyle(sty);

    this.refLayer.setZIndex(8888);

    famMap.getMap().addLayer(this.refLayer);
  }

  isInActiveEdit() {
    return this.editingBounds;
  }

}
