export interface Role {
    id: number;
    name: string;
}

export function allRoles() : Role[] {
    return [
            { id: 1, name: "Browser" },
            { id: 2, name: "Editor" }
        ];
}
