import { Injectable } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FieldBase } from '../../models/dynamic-forms/field-base';

@Injectable({
  providedIn: 'root'
})
export class FieldControlService {

  public formSubmitted = new Subject<string>();

  constructor() { }

  toFormGroup(questions: FieldBase<any>[]) {
    const group: any = {};

    if (!questions) {
      questions = [];
    }
    questions.forEach(question => {
      if (question.controlType === 'inline-field') {
        question.elements.forEach(element => {
          group[element.key] = element.required ? new FormControl(element.value || '', Validators.required)
            : new FormControl(element.value || '');
        });
      } else {
        group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
          : new FormControl(question.value || '');
      }
    });

    return new FormGroup(group);
  }

}
