import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { DirectiveLogic } from './directive-logic';

@Directive({
  selector: '[minMaxDirective]'
})
export class MinMaxDirective {

  @Input()
  public min: number;

  @Input()
  public max: number;

  constructor(private ref: ElementRef) { }

  @HostListener('input', [ '$event' ])
  public onInput(a_Event: InputEvent): void {
    if (this.ref.nativeElement?.value && this.ref.nativeElement.value !== '')
      this.ref.nativeElement.value = DirectiveLogic.MinMax(this.ref.nativeElement.value, this.min, this.max);
    else
      this.ref.nativeElement.value = null;
  }

}