<div id="appScroller">

  <!-- Main page container -->
  <div fxFlex fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem" [style]="'height:' + heightValue">

    <!-- Header bar and submit buttons -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth"
      style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">

      <!-- Title -->
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth">
        <h1 class="appProjectHeader">{{ this.target.name || '' }}</h1>
      </div>

      <button mat-flat-button color="accent" style="right: 1rem"class="align-right appConfirmButton appSquareButton"
        (click)="routerNavigate()">Print Report</button>

      <!-- Back to Project buttons -->
      <button mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
        (click)="routeToExplorer()">View in Explorer</button>

      <!-- End Header bar and submit buttons -->
    </div>

    <!-- Map/Details -->
    <div fxFlex="40" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard appSolidCard" fxFlex="100"
        fxLayoutGap="0.65rem">

        <div style="margin-left: 0.5rem;" fxFlex="50" fxFill fxLayout="column"
          class="appFullWidth appFullHeight appCardContainer appBottomRounded" fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard" fxFlex="100">
            <mat-card-header>
              <mat-card-title style="margin-left: 1rem;" class="appH1Title">Focal Area Map</mat-card-title>
            </mat-card-header>
            <mat-card-content class="appMapCardContent">
              <app-slim-map #slimMap [showEditCreateButtons]="false" [strokeColor]="'rgba(0, 34, 76, 0.5)'"
                [fillColor]="'rgba(32, 119, 255, 0.5)'">
              </app-slim-map>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- End map -->

        <!-- Details -->
        <div fxFlex="50" fxFill fxLayout="column" class="appFullWidth appFullHeight appCardContainer appBottomRounded"
          fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0" fxFlex="100">
            <mat-card-header class="appProjectHeaderContainer">
              <mat-card-title class="appH1Title">General Details
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Focal Area Name</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.name || '' }}</div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Priority Landscape</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.priorityLandscape || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appH2Header">Key Points of Contact</div>

              <div style="font-weight: bold; margin-top:1rem;" class="appH3SecondaryHeader">New Mexico Forestry Division
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Name</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.nmfdContactName || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Email</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.nmfdContactEmail || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Phone</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.nmfdContactPhone || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appH3SecondaryHeader">US Forest Service
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Name</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.usfsContactName || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Email</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.usfsContactEmail || '' }}
              </div>

              <div style="font-weight: bold; margin-top:1rem;" class="appFieldLabel">Phone</div>
              <div style="margin-bottom: 1rem;" class="appFieldContent">{{ this.target.fields.usfsContactPhone || '' }}
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card>
    </div>

    <!-- Assocaited Projects -->
    <div fxFlex="30" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appFullWidth appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Projects in This Area
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="appAssocProjOverflow">
              <div class="appH2Header appTopProject">Active Projects</div>
              <div *ngIf="this.activeProjectsVm && this.activeProjectsVm.length > 0">
                <div *ngFor="let proj of this.activeProjectsVm">
                  <app-generic-card [data]="proj"></app-generic-card>
                </div>
              </div>
              <div class="appFieldContent" *ngIf="!this.activeProjectsVm || this.activeProjectsVm.length === 0">
                There are no Active Projects in this Area
              </div>
              <div class="appH2Header">Inactive Projects</div>
              <div *ngIf="this.inactiveProjectsVm && this.inactiveProjectsVm.length > 0">
                <div *ngFor="let proj of this.inactiveProjectsVm">
                  <app-generic-card [data]="proj"></app-generic-card>
                </div>
              </div>
              <div class="appFieldContent" *ngIf="!this.inactiveProjectsVm || this.inactiveProjectsVm.length === 0">
                There are no Inactive Projects in this Area
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Project Focus -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullHeight appCardContainer appBottomRounded"
        fxLayoutGap="0.5rem">
        <mat-card class="appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Focal Area Strategies
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="appStratOverflow">
              <app-dynamic-form #focus [largeLabel]="true" [activeEdit]="false" [fields]="this.focusFields">
              </app-dynamic-form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>

    <!-- Bottom row -->
    <div fxFlex="30" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <!-- Partners -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullHeight appCardContainer appBottomRounded"
        fxLayoutGap="0.5rem">
        <mat-card class="appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Collaborating Partners
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="appAssocPartnerOverflow">
              <div class="appPartnerContainer" *ngIf="this.partnersVm && this.partnersVm.length > 0">
                <div *ngFor="let partner of this.partnersVm">
                  <div>
                    <p class="appH3SecondaryHeader">{{partner.name}}</p>
                  </div>
                </div>
              </div>
              <div class="appFieldContent" *ngIf="!this.partnersVm || this.partnersVm.length === 0">
                There are no Collaborating Partners
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Helpful Links -->
      <div fxFlex="50" fxFill fxLayout="column" class="appFullHeight appCardContainer appBottomRounded"
        fxLayoutGap="0.5rem">
        <mat-card class="appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Helpful Links
            </mat-card-title>
            <div class="appCollapseIconContainer appEditIconContainer" [appRequireACL]="'Focal:Create'">
              <button mat-flat-button class="appAddText appBrownActionButton" (click)="addLink()">+ Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="appLinksOverflow">
              <div *ngIf="this.links && this.links.length > 0">
                <div *ngFor="let link of this.links">
                  <app-link #linkData [link]="link" (linkToRemove)="triggerLinkDelete($event)"></app-link>
                </div>
              </div>
              <div class="appFieldContent" *ngIf="!this.links || this.links.length === 0">
                No links added
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>


    <!-- End Main page container -->
  </div>
</div>
