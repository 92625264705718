import { FieldBase } from './field-base';

export class DropdownField extends FieldBase<string> {
  controlType = 'dropdown';
  options: { key: string, value: string }[] = [];
  default: any;
  orgDefault?: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.default = options['default'] || null;
    this.orgDefault = options['orgDefault'] || false;
  }
}
