<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlx="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded appUnderlineCard"
    fxLayoutGap="0.5rem" [ngClass]="{ 'appLastUnderline': this.isLastElement }">
    <mat-card class="appFullWidth appFullHeight mat-elevation-z0" fxFlex="100">
      <mat-card-header>
        <mat-card-title class="appH3SecondaryHeader">
          {{ this.statRow?.title || '' }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="appFieldContent">
        <p *ngIf="this.isFocal === false">{{ this.hasStats ? this.getStringStat() : 'Pending calculation' }}</p>
        <p *ngIf="this.isFocal === true && this.hasStats" [innerHTML]="this.getStringStat()"></p>
        <p *ngIf="this.isFocal === true && !this.hasStats">Pending calculation</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
