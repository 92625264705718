import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { MapService } from './map.service';
import { MapLayerEsriExportValue, MapLayer, MapConfigConstants, MapLayerEsriFeatureServiceValue } from '../../models/map/config.model'
import { HttpClient } from '@angular/common/http';
import { CoreExplorerLayers } from 'src/app/map/sidebar/layer-explorer-container/layer-explorer.model';

declare var famMap: any;
declare var window: any;
declare var ol: any;

export interface LayerResult {
  layer: string;
  results: Result[];
  error?: string;
}

export interface Result {
  id: string;
  name: string;
  data: any;
  raw: any;
  highlight: boolean;
}

export interface Point {
  x: number;
  y: number;
  tolerance: number;
}

export interface Extent {
  topLeft: number;
  bottomLeft: number;
  topRight: number;
  bottomRight: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConfiguredIdentifySources {

  public newIdentifyFromQuery: Subject<void> = new Subject<void>();

  constructor(
    private map: MapService,
    private http: HttpClient,
    private config: ConfigurationService,
    ) {

  }

  public FromExtent(extent: any, returnGeometry: boolean = false): Observable<LayerResult> {
    let sub = new Subject<LayerResult>();

    this.map.getMapConfig().subscribe(conf => {
      let layers = [];

      conf.forEach(cat => {
        cat.layers.forEach(layer => {
          if (layer.identify && cat.selection.isSelected(layer.key)) {
            layers.push(layer);
          }
        })
      })

      if (layers.length == 0) {
        setTimeout(() => { sub.complete(); }, 500);
        console.log("No layers!");
      }

      layers.forEach(layer => {
        this.GenericFromExtent(extent, layer, returnGeometry).subscribe(res => { sub.next(res) });
      })

    })
    return sub;
  }

  public FromPoint(point: any, onlyImport: boolean = false, returnGeometry: boolean = false): Observable<LayerResult> {
    let sub = new Subject<LayerResult>();
    
    let layers = [];

    this.map.getMapCategories().forEach(cat => {
        cat.layers.forEach(layer => {
          if (layer.identify && cat.selection.isSelected(layer.key) && (onlyImport ? layer.identify.import : true)) {
            layers.push(layer);
          }
        })
      })

      if (layers.length == 0) {
        setTimeout(() => { sub.next({ layer: '', results: null }) }, 500);
      }
      let sdalkj = [];
      layers.forEach(layer => {
        this.GenericFromPoint(point, layer, returnGeometry).subscribe(res => { sub.next(res) });
      })

    return sub;

  }

  public FromQuery(query: string, onlyImport: boolean = false, returnGeometry: boolean = false): Observable<LayerResult> {
    let sub = new Subject<LayerResult>();

    this.map.getMapConfig().subscribe(conf => {
      let layers = [];

      conf.forEach(cat => {
        cat.layers.forEach(layer => {
          if (layer.identify && cat.selection.isSelected(layer.key) && (onlyImport ? layer.identify.import : true)) {
            layers.push(layer);
          }
        })
      })

      if (layers.length == 0) {
        setTimeout(() => { sub.next({ layer: '', results: null }) }, 500);
        return;
      }
      let sdalkj = [];
      layers.forEach(layer => {
        this.GenericFromQuery(query, layer, returnGeometry).subscribe(res => { sub.next(res) });
      })
    })

    return sub;

  }

  public async getExtentFromEsriService(url: string, query: string) {
    const completeUrl = `${url}query?where=${query}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=true&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=pjson`; // assumes srs is web mercator
    const res: any = await this.http.get<any>(completeUrl).toPromise();

    if (res && res?.extent) {
      return [res.extent.xmin, res.extent.ymin, res.extent.xmax, res.extent.ymax];
    } else {
      return null;
    }
  }

  public async getGeomFromEsriService(url: string, query: string) {
    const completeUrl = `${url}query?where=${query}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=3857&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`; // assumes srs is web mercator
    const res: any = await this.http.get<any>(completeUrl).toPromise();

    if (res && res?.features?.[0]?.geometry) {
      return res?.features?.[0]?.geometry;
    } else {
      return null;
    }
  }

  private GenericFromExtent(extent: any, layer: MapLayer, returnGeometry: boolean = false): Observable<LayerResult> {
    console.log("Extent: ", extent)
    let sub = new Subject<LayerResult>();

    let size = famMap.getMap().getView().viewportSize_;

    let width = size[0];
    let height = size[1];

    let curZoom = famMap.getMap().getView().getZoom();
    if (layer.identify.minZoom && (curZoom < layer.identify.minZoom)) {
      setTimeout(() => {
        let res = []
        sub.next({ layer: layer.key, results: res, error: "Layer has a zoom restriction. Zoom in further to use this feature." });
        sub.complete();
      }, 250);
    }
    else {
      setTimeout(() => {
        switch (layer.config.type) {
          case MapConfigConstants.esriExport:
            {
              (layer.config.value as MapLayerEsriExportValue).endpoints.forEach(ep => {
                this.http.get<any>(ep.url + (layer.identify.queryLayerIds != undefined && layer.identify.queryLayerIds.length ? "/" + layer.identify.queryLayerIds[0] : "") + "?f=json").subscribe(meta => {
                  this.esriID(sub, ep, returnGeometry, extent, layer, meta, width, height);
                },
                  err => {
                    this.esriID(sub, ep, returnGeometry, extent, layer, {}, width, height);
                  })

              })
            }
            break;
          case MapConfigConstants.esriFeature:
          case MapConfigConstants.mvt:
            {
              if (window.layerMap && window.layerMap[layer.key]) {
                let lyr = window.layerMap[layer.key]
                let features = this.getFeaturesFromMVT(lyr);
                let res = this.mapMVTToResult(features, layer);
                res.forEach(i => {
                  i.raw.forEach(feat => {
                    feat.set('layer', layer.key);
                    feat.set('tfid', i.id);
                  })
                })
                sub.next({ layer: layer.key, results: res });
                sub.complete();

              }
            }
            break;
        }
      }, 250);
    }

    return sub;
  }

  private esriID(sub, ep, returnGeometry, extent, layer, meta, width, height) {
    let fieldMapperNameAlias = {};
    let fieldMapperAliasName = {};
    if (meta.fields) {
      meta.fields.forEach(element => {
        fieldMapperNameAlias[element.name] = element.alias;
        fieldMapperAliasName[element.alias] = element.name;
      });
    }

    let url = ep.url + `identify?f=json&sr=3857&geometryPrecision=0&geometryType=esriGeometryEnvelope&geometry=${extent[0]},${extent[1]},${extent[2]},${extent[3]}&mapExtent=${extent[0]},${extent[1]},${extent[2]},${extent[3]}&imageDisplay=${width},${height},96&tolerance=1&returnGeometry=${returnGeometry}`
    if (layer.identify.queryLayerIds && layer.identify.queryLayerIds.length)
      url += "&layers=visible:" + (layer.identify.queryLayerIds.join(","));
    this.http.get<any>(url).subscribe(resp => {
      if (resp.results) {
        let features = resp.results;
        let res = [];
        let resMap = {};
        features.forEach(feature => {
          if (resMap[feature.attributes[layer.identify.dataMapping.id.query]])
            return;

          let mappedName = feature.attributes[layer.identify.dataMapping.title.query];

          if (!mappedName) {
            mappedName = feature.displayFieldName ? feature.attributes[feature.displayFieldName] : feature.value
          }

          let newf =
          {
            id: feature.attributes["OBJECTID"] || feature.attributes[layer.identify.dataMapping.id.query],
            name: mappedName,
            data: [],
            raw: {}
          };

          if (returnGeometry && feature.geometry) {
            let feats = new ol.format.EsriJSON().readFeatures(feature)

            feats.forEach(feat => {
              feat.set('layer', layer.key);
              feat.set('tfid', newf.id);
            });

            newf.raw = feats;
          }

          layer.identify.dataMapping.fields.forEach(field => {
            if (field.query) {
              let dat = undefined;

              if (feature.attributes[field.query])
                dat = feature.attributes[field.query];
              else if (feature.attributes[fieldMapperNameAlias[field.query]])
                dat = feature.attributes[fieldMapperNameAlias[field.query]];
              else if (feature.attributes[fieldMapperAliasName[field.query]])
                dat = feature.attributes[fieldMapperAliasName[field.query]];

              newf.data.push({ name: field.name, value: dat });
            }
            else if (field.static)
              newf.data.push({ name: field.name, value: field.static });
          });

          resMap[newf.id] = true;
          res.push(newf);
        })

        sub.next({ layer: layer.key, results: res });
        sub.complete();
      }
    })
  }

  private GenericFromPoint(point: any, layer: MapLayer, returnGeometry: boolean = false): Observable<LayerResult> {
    let sub = new Subject<LayerResult>();

    let size = famMap.getMap().getView().viewportSize_;
    let width = size[0];
    let height = size[1];

    setTimeout(() => {
      switch (layer.config.type) {
        case MapConfigConstants.esriExport:
          {
            let extent = famMap.getMap().getView().calculateExtent();

            (layer.config.value as MapLayerEsriExportValue).endpoints.forEach(ep => {
              let url = ep.url + `identify?f=json&sr=3857&geometryType=esriGeometryPoint&geometry=${point[0]},${point[1]}&mapExtent=${extent[0]},${extent[1]},${extent[2]},${extent[3]}&imageDisplay=${width},${height},96&tolerance=1&returnGeometry=${returnGeometry}`

              if (layer.identify.queryLayerIds && layer.identify.queryLayerIds.length)
                url += "&layers=visible:" + (layer.identify.queryLayerIds.join(","));

              this.http.get<any>(url).subscribe(resp => {
                if (resp.results) {
                  let features = resp.results;
                  let res = [];
                  let resMap = {};
                  features.forEach(feature => {
                    let newf =
                    {
                      id: feature.attributes["OBJECTID"] || feature.attributes[layer.identify.dataMapping.id.query],
                      name: feature.displayFieldName ? feature.attributes[feature.displayFieldName] : feature.value || feature.attributes[layer.identify.dataMapping.title.query],
                      data: [],
                      raw: {} //feature.attributes
                    };

                    if (returnGeometry && feature.geometry) {
                      let feats = new ol.format.EsriJSON().readFeatures(feature)
                      newf.raw = feats;
                    }

                    layer.identify.dataMapping.fields.forEach(field => {
                      if (field.query)
                        newf.data.push({ name: field.name, value: feature.attributes[field.query] });
                      else if (field.static)
                        newf.data.push({ name: field.name, value: field.static });
                    });

                    resMap[newf.id] = true;
                    res.push(newf);
                  })

                  sub.next({ layer: layer.key, results: res });
                  sub.complete();
                }
              })

            });
          }
          break;
        case MapConfigConstants.esriFeature:
          {
            (layer.config.value as MapLayerEsriFeatureServiceValue).endpoints.forEach(ep => {
              let url = ep.url + `query?where=&objectIds=&time=&geometry=${point[0]},${point[1]}&geometryType=esriGeometryPoint&inSR=3857&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=${returnGeometry}&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson`;

              this.http.get<any>(url).subscribe(resp => {
                if (resp.features) {
                  let features = resp.features;
                  let res = [];
                  let resMap = {};
                  features.forEach(feature => {
                    let newf =
                    {
                      id: feature.attributes["OBJECTID"] || feature.attributes[layer.identify.dataMapping.id.query],
                      name: feature.displayFieldName ? feature.attributes[feature.displayFieldName] : feature.value || feature.attributes[layer.identify.dataMapping.title.query],
                      data: [],
                      raw: {} //feature.attributes
                    };

                    if (returnGeometry && feature.geometry) {
                      let feats = new ol.format.EsriJSON().readFeatures(feature)
                      newf.raw = feats;
                    }

                    layer.identify.dataMapping.fields.forEach(field => {
                      if (field.query)
                        newf.data.push({ name: field.name, value: feature.attributes[field.query] });
                      else if (field.static)
                        newf.data.push({ name: field.name, value: field.static });
                    });

                    resMap[newf.id] = true;
                    res.push(newf);
                  })

                  sub.next({ layer: layer.key, results: res });
                  sub.complete();
                }
              })

            });
          }
          break;
        case MapConfigConstants.mvt:
          {
            if (window.layerMap && window.layerMap[layer.key] && !layer.identify?.queryUrl) {
              let lyr = window.layerMap[layer.key]
              let features = this.getFeaturesFromMVT(lyr);
              features = this.filterFeaturesByPoint(features, point)
              let res = this.mapMVTToResult(features, layer);
              sub.next({ layer: layer.key, results: res });
              sub.complete();
            }

            if (layer.identify?.queryUrl) {
              // TODO should refactor as this is a repeat of above
                let url = layer.identify?.queryUrl + `query?where=&objectIds=&time=&geometry=${point[0]},${point[1]}&geometryType=esriGeometryPoint&inSR=3857&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=${returnGeometry}&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson`;
  
                this.http.get<any>(url).subscribe(resp => {
                  if (resp.features) {
                    let features = resp.features;
                    let res = [];
                    let resMap = {};
                    features.forEach(feature => {
                      let newf =
                      {
                        id: feature.attributes["OBJECTID"] || feature.attributes[layer.identify.dataMapping.id.query],
                        name: feature.displayFieldName ? feature.attributes[feature.displayFieldName] : feature.value || feature.attributes[layer.identify.dataMapping.title.query],
                        data: [],
                        raw: {} //feature.attributes
                      };
  
                      if (returnGeometry && feature.geometry) {
                        let feats = new ol.format.EsriJSON().readFeatures(feature)
                        newf.raw = feats;
                      }
  
                      layer.identify.dataMapping.fields.forEach(field => {
                        if (field.query)
                          newf.data.push({ name: field.name, value: feature.attributes[field.query] });
                        else if (field.static)
                          newf.data.push({ name: field.name, value: field.static });
                      });
  
                      resMap[newf.id] = true;
                      res.push(newf);
                    })
  
                    sub.next({ layer: layer.key, results: res });
                    sub.complete();
                  }
                })
            }
          }
          break;
      }
    }, 250);

    return sub;
  }

  private GenericFromQuery(query: string, layer: MapLayer, returnGeometry: boolean = false): Observable<LayerResult> {
    let sub = new Subject<LayerResult>();

    let size = famMap.getMap().getView().viewportSize_;
    let width = size[0];
    let height = size[1];

    setTimeout(() => {
      switch (layer.config.type) {
        case MapConfigConstants.esriExport:
          {
            let extent = famMap.getMap().getView().calculateExtent();

            (layer.config.value as MapLayerEsriExportValue).endpoints.forEach(ep => {
              let url = ep.url + `/${layer.identify.queryLayerIds[0]}/query?f=json&sr=3857&where=${query}&returnGeometry=${returnGeometry}&outFields=*`

              this.http.get<any>(url).subscribe(resp => {
                if (resp.features) {
                  let features = resp.features;
                  let res = [];
                  let resMap = {};
                  features.forEach(feature => {

                    let newf =
                    {
                      id: feature.attributes["OBJECTID"] || feature.attributes[layer.identify.dataMapping.id.query],
                      name: feature.displayFieldName ? feature.attributes[feature.displayFieldName] : feature.attributes[layer.identify.dataMapping.title.query],
                      data: [],
                      raw: {} //feature.attributes
                    };

                    if (returnGeometry && feature.geometry) {
                      let feats = new ol.format.EsriJSON().readFeatures(feature)
                      newf.raw = feats;
                    }

                    layer.identify.dataMapping.fields.forEach(field => {
                      if (field.query)
                        newf.data.push({ name: field.name, value: feature.attributes[field.query] });
                      else if (field.static)
                        newf.data.push({ name: field.name, value: field.static });
                    });

                    resMap[newf.id] = true;
                    res.push(newf);
                  })

                  sub.next({ layer: layer.key, results: res });
                  sub.complete();
                }
              })

            });
          }
          break;
      }
    }, 250);

    return sub;
  }

  getFeaturesFromMVT(layer): any[] {
    let src = layer.getSource();
    //let ptGeom = new ol.geom.Point(point);
    let vp = famMap.getMap().getView().calculateExtent();
    let features = src.getFeaturesInExtent(vp);
    features = features.map(feat => {
      if (feat.getOrientedFlatCoordinates) {
        return { raw: feat, pg: new ol.Feature(new ol.geom.Polygon(feat.getOrientedFlatCoordinates(), 'XY', feat.getEnds())) }
      }
      else {
        return { raw: feat, pg: feat }
      }
    })

    return features;
  }

  filterFeaturesByPoint(features, point): any[] {
    return features.filter(feat => {
      var g = feat.pg.getGeometry();
      return g.intersectsCoordinate(point);
    });
  }

  mapMVTToResult(features, layer): any[] {
    let res = [];
    let resMap = {};
    features.forEach(fm => {
      let feature = fm.raw;
      if (!feature.properties_)
        feature.properties_ = feature.values_;

      if (resMap[feature.properties_[layer.identify.dataMapping.id.query]]) {
        resMap[feature.properties_[layer.identify.dataMapping.id.query]].raw.push(fm.pg);
        return;
      }

      let newf =
      {
        id: feature.properties_[layer.identify.dataMapping.id.query],
        name: feature.properties_[layer.identify.dataMapping.title.query],
        data: [],
        raw: [fm.pg],
        highlight: false
      };

      layer.identify.dataMapping.fields.forEach(field => {
        if (field.query)
          newf.data.push({ name: field.name, value: feature.properties_[field.query] });
        else if (field.static)
          newf.data.push({ name: field.name, value: field.static });
      });

      resMap[newf.id] = newf;
      res.push(newf);
    })

    return res;
  }

  getLayerByKey(key: string) {
    // Assumes no repeated keys (which there should not be any anyways)
    const layers = famMap.getMap().getLayers().getArray();
    const expLayers = layers.find(e => e?.values_?.id === 'cat_explorer');
    const refLayers = layers.find(e => e?.values_?.id === 'cat_reference');
    const foundLayerInExp = expLayers.values_.layers.array_.find(e => e?.values_?.id === key);
    const foundLayerInRef = refLayers.values_.layers.array_.find(e => e?.values_?.id === key);
    const foundLayer = foundLayerInExp ? foundLayerInExp : foundLayerInRef || null;
    return foundLayer;
  }

  async requestFeatureExtent(featureKey, featureId) {
    let url, query;

    const res = await this.config.getConfigAsync(this.config.fieldsConfigUrl);
    const urlConfig = res['MapZoom'];

    let key, subLayerId;
    switch (featureKey) {
      case CoreExplorerLayers.ProjectKey:
        // key = projLayer.values_.source.key_;
        // subLayerId = projLayer.values_.source.params_.LAYERS.replace(/^\D+/g, '');
        url = `${urlConfig[CoreExplorerLayers.ProjectKey]}/`;
        query = `objectid=${featureId}`;
        break;
      case CoreExplorerLayers.ActivityKey:
        // key = actLayer.values_.source.key_;
        // subLayerId = actLayer.values_.source.params_.LAYERS.replace(/^\D+/g, '');
        url = `${urlConfig[CoreExplorerLayers.ActivityKey]}/`;
        query = `objectid=${featureId}`;
        break;
      case CoreExplorerLayers.FocalKey:
        // key = focalLayer.values_.source.key_;
        // subLayerId = focalLayer.values_.source.params_.LAYERS.replace(/^\D+/g, '');
        url = `${urlConfig[CoreExplorerLayers.FocalKey]}/`;
        query = `name='${featureId}'`;
        break;
      case CoreExplorerLayers.PrioLandKey:
        // key = prioLayer.values_.source.key_;
        // subLayerId = prioLayer.values_.source.params_.LAYERS.replace(/^\D+/g, '');
        url = `${urlConfig[CoreExplorerLayers.PrioLandKey]}/`;
        query = `name='${featureId}'`;
        break;
      case CoreExplorerLayers.WatershedKey:
        // key = waterLayer.values_.source.key_;
        // subLayerId = waterLayer.values_.source.params_.LAYERS.replace(/^\D+/g, '');
        url = `${urlConfig[CoreExplorerLayers.WatershedKey]}/`;
        query = `name='${featureId}'`;
        break;

      default:
        break;
    }

    const extent = await this.getExtentFromEsriService(url, query);
    
    return extent;
  }

  async requestFeatureGeom(featureKey, featureId) {
    let url, query;

    const res = await this.config.getConfigAsync(this.config.fieldsConfigUrl);
    const urlConfig = res['MapZoom'];

    switch (featureKey) {
      case CoreExplorerLayers.ProjectKey:
        url = `${urlConfig[CoreExplorerLayers.ProjectKey]}/`;
        query = `objectid=${featureId}`;
        break;
      case CoreExplorerLayers.ActivityKey:
        url = `${urlConfig[CoreExplorerLayers.ActivityKey]}/`;
        query = `objectid=${featureId}`;
        break;
      case CoreExplorerLayers.FocalKey:
        url = `${urlConfig[CoreExplorerLayers.FocalKey]}/`;
        query = `name='${featureId}'`;
        break;
      case CoreExplorerLayers.PrioLandKey:
        url = `${urlConfig[CoreExplorerLayers.PrioLandKey]}/`;
        query = `name='${featureId}'`;
        break;
      case CoreExplorerLayers.WatershedKey:
        url = `${urlConfig[CoreExplorerLayers.WatershedKey]}/`;
        query = `name='${featureId}'`;
        break;

      default:
        break;
    }

    const featureGeom = await this.getGeomFromEsriService(url, query);
    
    return featureGeom;
  }
}