<div
  id="appRegisterBackground"
  style="padding: 1em; background-color: rgb(200, 200, 200)"
>
  <mat-card
    style="
      width: 25rem;
      min-width: 500px;
      overflow: hidden;
      padding: 2em !important;
      margin: auto;
    "
  >

    <div
      class="appRegisterContainer"
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="1em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Enter"
    >
    <div fxFlex="50" fxLayoutAlign="end center" style="padding: 1em; padding-bottom: 0;">

      <div fxFill fxFlex fxLayout="column" fxLayoutAlign="start center">
        
        <div fxFlex="70">
          <div  fxFlex fxLayout="row" fxLayoutAlign="start center">  
            <img alt="NM Icon" src="https://timmons-branding.s3.amazonaws.com/NewMexico/RoundIcon.png"
              style="width: auto; height: 200px; margin: 1px; align-self: center;" />
          </div> 
        </div>
        <div class="title"  id="appSplashTitleContainer">
          <div class="appTitleSpacer" >
            <h1>NEW MEXICO</h1>            
          </div>
        </div>
        <div class="title"  id="appSplashTitleContainer">
          <div class="appTitleSpacer" >
            <h3>SHARED STEWARDSHIP PORTAL</h3>            
          </div>
        </div>
      </div>
  
    </div>

      <div fxFlex fxFlex="10" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <h4 class="primary-dark-text header-text">Forgot your password?</h4>
      </div>
 
      
      <div 
        fxFlex
        fxFlex="10"
        fxLayoutGap="0.25em"
        fxLayoutAlign="end start"
      >
        <div>
          Enter your Email below and we will send a message to reset your password.
        </div>
        <div class="appRegisterSpacer"></div>
      </div>

      <div fxFlex fxFlex="15" fxLayoutAlign="end center">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Email</mat-label>
          <input
            name="email"
            #email
            matInput
            [formControl]="emailFormControl"
            placeholder="Ex. pat@example.com"
            required="true"
          />
        </mat-form-field>
      </div>
     
      <div class="appRegisterSpacer"></div>


      <div fxFlex="10" fxLayoutAlign="end center" fxLayout="column">
        <div
          style="padding-top: 3em;"
          fxFlex
          fxLayout="row"
          fxLayoutGap="2em"
          matTooltip="Please complete the fields above to reset your password."
          matTooltipPosition="above"
          [matTooltipDisabled]="isValid()"
        >

          <button 
            mat-flat-button           
            class="widthFill centerText"
            color="accent"
            name="register"
            fxFlex="100"
            [disabled]="!isValid()"
            (click)="register()"
          >
            Reset my password
          </button>
        </div>
        <div fxFlex fxLayoutAlign="center center" fxLayoutGap="2em">
          <a style="padding-top: 1em;" routerLink="/" class="widthFill centerText">Back to Home</a>
        </div>
      </div>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Communicating"
      fxLayoutAlign="center center"
    >
      <h3 class="primary-dark-text header-text">Submitting Request...</h3>
      <mat-spinner></mat-spinner>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em;"
      *ngIf="step == Steps.Next"
    >
      <div fxFlex fxLayoutAlign="center center">
        <h3 class="primary-dark-text header-text">Success!</h3>
      </div>

      <div fxFlex fxLayoutAlign="center center">
        <h4>You should receive an email with your temporary credentials. Please use the provided information to access the application. </h4> 
      </div>
      <div fxFlex fxLayoutAlign="center center">
        <h4> 
         If you do not see a email in your inbox within a few minutes please check your spam or junk folder. If no email is received please reach out to gissupport@timmons.com
        </h4>
         <!-- <a href="mailto:gissupport@timmons.com">Support</a>-->

          
      </div>
      <div fxFlex fxLayoutAlign="center center">
        <a routerLink="/" class="widthFill centerText">Back to Home</a>
      </div>
 
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="2em"
      style="padding: 0.25em"
      *ngIf="step == Steps.Error"
    >
      <div fxFlex fxLayoutAlign="center center">
        <h3 class="primary-dark-text header-text">Something went wrong!</h3>
      </div>

      <p>
        Please retry.
      </p>

      <p>
        If the issue persists please reach out to <a
        href="mailto:gissupport@timmons.com">Support</a>.
      </p>

      <div fxFlex fxLayoutAlign="center center">
        <button mat-button (click)="return()">Retry</button>
      </div>

      <div fxFlex fxLayoutAlign="center center">
        <a routerLink="/" class="widthFill centerText">Back to Home</a>
      </div>
    </div>
 
  </mat-card>
</div>
