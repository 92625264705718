import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService, AuthConfig } from './configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { User as UserInfo } from '../models/user.model';
import { Router } from '@angular/router';


//This class represents a login state
//We keep the user and profile separated
export class Login {
  user: User;
  profile: Profile;
  acls: string[];
  orgs: any;

  //Validate that a user is logged in
  //Will be true when a user is set, a profile is set, and the expiration has not lapsed
  isLoggedIn(): boolean {

    return (this.user != undefined) &&
      (this.profile != undefined) &&
      (this.user.expires > new Date());
  }

  getFullName() : string {
    if(!this.isLoggedIn())
      return "Not Logged In"
    else{
      if (this.profile && this.profile.firstname && this.profile.lastname)
        return this.profile.firstname + " " + this.profile.lastname;
      else {
        return "User";
      }
    }
  }

  //Returns a representation of the user's name
  //In some cases the user could have a first name, an email, or neither.
  //In most cases the First name will be set but in the case it is not we default to email and then to "User"
  getName(): string {
    if (!this.isLoggedIn())
      return "Not Logged In"
    else
      if (this.profile && this.profile.firstname)
        return this.profile.firstname;
      else if (this.profile.email)
        return this.profile.email;
      else
        return "User"
  }
}


//Represents a "User" in the system (not the associated profile)
//Will also contain the expiration of the current session
//This info should come exclusively from the token
export class User {
  id: string;
  expires: Date;
}

//Represents a user's profile
//We need to know the first, last, and email
//At a later date we may have stuff like picture, phone number, etc
export class Profile {
  firstname: string;
  lastname: string;
  email: string;
}

//This service provides authorization app wide
//It handles an oAuth flow (Tested against cognito)
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.aclsub) {
      this.aclsub.unsubscribe();
    }

    if (this.nextValidationTick) {
      window.clearTimeout(this.nextValidationTick);
    }
  }
  ngOnInit(): void {
  }

  configSub: Subscription;
  //References to our token parts
  token: any;
  refreshToken: any;
  idToken: any;

  //Keps track if we open login in a tab so we can communicate
  openTab: any;

  //Used to track if we are in the middle of loggin in
  //There is a bit of back and fourth with the server so keeping a
  //  boolean state makes it easier down the road
  isLoggingIn: boolean = false;
  isRefreshing: boolean = false;

  //A copy of our auth config.
  //In the future we should only reference the getConfig version
  //  instead of caching it here
  auth: AuthConfig;

  //A copy of our current login
  login: Login = new Login();

  nextValidationTick: number;

  aclsub: Subscription;

  orgSub: Subscription;


  //This is how we communicate to components that need to also keep track of changes to the login
  private replaySubject: ReplaySubject<Login> = new ReplaySubject<Login>(1);
  private liveSubject: Subject<Login> = new Subject<Login>();

  //We need http to talk to the auth services and config to know who to talk to
  constructor(private http: HttpClient, private config: ConfigurationService, private router: Router) {
    this.isLoggingIn = true;
    //Listen for events from other windows in case we open a popup
    window.addEventListener("message", this.receiveMessage.bind(this), false);

    //Attempt to retreive the stored tokens in the session
    this.retrieveTokens();

    config.getConfig().subscribe((res) => {
      this.auth = res.authorization;
    })


    //Grab the get params from the url
    let paramsUrl = window.location.search.substr(1).split("&")
    let params = {};

    //Put them into a map
    paramsUrl.forEach(p => {
      let vs = p.split("=");
      params[vs[0]] = vs[1];
    })

    //If we have a code we are in the middle of an authorization
    //Otherwise we should validate our token if it is set
    if (params["code"]) {
      this.retreiveToken(params["code"]);
    }
    else if (this.token) {
      this.validateToken();
    }
    else {
      this.isLoggingIn = false;
    }
  }

  //Grab the tokens from the session storage if they are set
  retrieveTokens() {
    try {
      this.token = JSON.parse(sessionStorage.getItem("nmss:token"));
    } catch { }

    try {
      this.refreshToken = JSON.parse(sessionStorage.getItem("nmss:refreshToken"));
    } catch { }

    try {
      this.idToken = JSON.parse(sessionStorage.getItem("nmss:idToken"));
    } catch { }

    if (!this.token && !this.refreshToken && !this.idToken) {
      try {
        this.token = JSON.parse(localStorage.getItem("nmss:token"));
      } catch { }
  
      try {
        this.refreshToken = JSON.parse(localStorage.getItem("nmss:refreshToken"));
      } catch { }
  
      try {
        this.idToken = JSON.parse(localStorage.getItem("nmss:idToken"));
      } catch { }
    }
  }


  //This handles incomming communication
  receiveMessage(msg) {
    //Validate there is data
    if (!msg.data)
      return;

    //Ideally any message we need would have the type tagged with auth
    if (msg.data.type === "auth") {
      switch (msg.data.status) {
        case "code":
          this.retreiveToken(msg.data.code);
          this.closeOpenTab();
          break;
        case "complete":
          //We have completed login from another tab. We should update our info

          if (msg.data.token)
            sessionStorage.setItem("nmss:token", JSON.stringify(msg.data.token));
            localStorage.setItem("nmss:token", JSON.stringify(msg.data.token));
          if (msg.data.refreshToken)
            sessionStorage.setItem("nmss:refreshToken", JSON.stringify(msg.data.refreshToken));
            localStorage.setItem("nmss:refreshToken", JSON.stringify(msg.data.refreshToken));
          if (msg.data.idToken)
            sessionStorage.setItem("nmss:idToken", JSON.stringify(msg.data.idToken));
            localStorage.setItem("nmss:idToken", JSON.stringify(msg.data.idToken));

          this.retrieveTokens();

          this.closeOpenTab();

          if (this.token) {
            this.validateToken();
          }
          break;

        case "close":
          window.close();
          break;

        case "fail":
          this.replaySubject.error("login failed");

          this.closeOpenTab();
          break;

        default:
          break;
      }
    }
  }

  //This will close any tab we have opened for the login process
  closeOpenTab(): void {
    if (this.openTab)
      setTimeout(() => { 
        this.openTab.postMessage({ type: "auth", status: "close" }, window.location.origin) 
      }, 5000);
  }

  //Ideally this will detect when the token is expired, grab the refresh token, and make the calls but for this sprint this works
  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }


  //THis validates and pulls out user info from the token
  validateToken() {
    this.isLoggingIn = true;

    if (this.nextValidationTick) {
      window.clearTimeout(this.nextValidationTick);
      this.nextValidationTick = undefined;
    }

    if (this.configSub)
      this.configSub.unsubscribe();
    //Ensure we have our configuration setup
    this.configSub =
      this.config.getConfig().subscribe(config => {
        //Parse the parts
        let token = this.parseJwt(this.token);
        let idToken = this.parseJwt(this.idToken);

        //Tokens should always have subs ... leave this here for debugging in the short term
        //To be removed later
        if (!token.sub) {
          this.isLoggingIn = false;
          this.logout();
          return;
        }

        if (token.sub !== idToken.sub)
          console.log(token);

        //Calculate the tokens expiration based on epoch time
        let tokenExp = new Date(0);
        tokenExp.setUTCSeconds(token.exp);

        let now = new Date();

        //If our token has expired we should clear this out and "logout"
        if (tokenExp <= now) {
          this.isLoggingIn = false
          this.tryRefresh();
          return;
        }

        //Translate the token into a user
        let u = new User();
        u.id = token.sub;
        u.expires = tokenExp;

        //Setup the email for the short term
        let p = new Profile();
        p.email = idToken.email;
        p.firstname = idToken.firstName || idToken["custom:firstName"] || "";
        p.lastname = idToken.lastName || idToken["custom:lastName"] || "";

        this.login.user = u;
        this.login.profile = p;

        this.getAcls(config.apiRoot, idToken);

      });
  }

  async getAcls(apiRoot:String, idToken){
    let error = false;

    let headers = {
      headers: new HttpHeaders()
        .set('Authorization', "Bearer " + this.token)
    }

    let searchRequest: any = {
      searchString: idToken.email
    }

    try {
      this.login.acls = await this.http.get<string[]>(apiRoot + "User/GetACLs", headers).toPromise();
      if (!this.nextValidationTick)
              this.nextValidationTick = window.setTimeout(() => { this.validateToken() }, 65 * 1000)
    } catch (err) {
      error = true;
      console.log("Error grabbing user acls: " + err);
    }

    try {
      this.login.orgs = await this.http.post<any>(apiRoot + "Users/Get", searchRequest, headers).toPromise();
    } catch (err) {
      error = true;
      console.log("Error grabbing user org: " + err);
    }

    if(error){
      this.logout();
    } else {
      this.replaySubject.next(this.login);
      this.liveSubject.next(this.login);
    }

  }

  //Attempt to parse a jwt into an object
  //If it fails we return an empty object
  parseJwt(token) {
    try {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
    catch {
      return {};
    }
  }

  // Return an observable to handle when login has changed
  onLogin(): Observable<Login> {
    return this.replaySubject;
  }

  onFutureLogin(): Observable<Login> {
    return this.liveSubject; //TODO: We need to return a different observable
  }

  // Return the raw login object
  getLogin(): Login {
    return this.login;
  }

  // Starts an authorization process
  authorize(): Observable<Login> {
    if (!this.login.isLoggedIn() && !this.isLoggingIn)
      this.startLogin(this.auth && this.auth.inline);

    return this.replaySubject;
  }

  // Starts a login process
  // Set inline to avoid opening a new window
  startLogin(inline: boolean) {
    if (!this.auth) {
      setTimeout(() => { this.startLogin(inline); }, 1000);
    }
    else {
      let host = this.getAppPath();
      let redirectUrl = host + "/assets/oAuthHelper.html";
      let loginUrl = "http" + (this.auth.useHttps ? "s" : "") + "://" + this.auth.host + "/" + this.auth.authorizationEndpoint + "?client_id=" + this.auth.clientId + "&response_type=code&scope=openid+profile&redirect_uri=" + redirectUrl;

      if (inline) {
        window.location.href = loginUrl
      }
      else {
        this.openTab = window.open(loginUrl)
      }
    }

    return this.replaySubject;
  }

  tryRefresh() {
    this.isRefreshing = true;
    if (!this.auth) {
      setTimeout(() => { this.tryRefresh(); }, 1000);
    }
    else {
      // TODO: The backend should handle this part but I dont have the time to implement it

      //Create the url with the values encoded
      let host = this.getAppPath();
      let redirectUrl = host + "/assets/oAuthHelper.html";
      let refreshUrl = "http" + (this.auth.useHttps ? "s" : "") + "://" + this.auth.host + this.auth.tokenEndpoint;


      //Setup the body
      let request = new URLSearchParams();
      request.set("grant_type", "refresh_token");
      request.set("client_id", this.auth.clientId);

      if (this.refreshToken == undefined)
        console.log(this.refreshToken);

      request.set("refresh_token", this.refreshToken);

      let header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Basic ${btoa(this.auth.clientId + ":" + this.auth.clientSecret)}`)
      }
      // Post the code to get the token
      // Handle errors below
      this.http.post<any>(refreshUrl, request.toString(), header).subscribe((response) => {
        this.isRefreshing = false;

        sessionStorage.setItem("nmss:token", response.access_token);
        localStorage.setItem("nmss:token", response.access_token);
        if (response.refresh_token)
          sessionStorage.setItem("nmss:refreshToken", response.refresh_token);
          localStorage.setItem("nmss:refreshToken", response.refresh_token);
        if (response.id_token)
          sessionStorage.setItem("nmss:idToken", response.id_token);
          localStorage.setItem("nmss:idToken", response.id_token);

        let opener = this.getMainWindow()

        opener.postMessage({ type: "auth", status: "complete", token: response.access_token, refreshToken: response.refresh_token, idToken: response.id_token }, window.location.origin);
      }, err => {
        this.isRefreshing = false;
      }
      );

    }
  }

  // Take a code and attempt to retreive a token from it
  retreiveToken(code: string) {

    //If our config isnt set try again in a sec
    // TODO we should subscribe to the config
    if (!this.auth) {
      setTimeout(() => { this.retreiveToken(code); }, 1000);
    }
    else {
      // TODO: The backend should handle this part but I dont have the time to implement it

      //Create the url with the values encoded
      let host = this.getAppPath();
      let redirectUrl = host + "/assets/oAuthHelper.html";
      let tokenUrl = "http" + (this.auth.useHttps ? "s" : "") + "://" + this.auth.host + this.auth.tokenEndpoint;


      // Setup the body
      let request = new URLSearchParams();
      request.set("grant_type", "authorization_code");
      request.set("client_id", this.auth.clientId);
      request.set("redirect_uri", redirectUrl);
      request.set("code", code);

      // Set the headers to make the request
      let header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Basic ${btoa(this.auth.clientId + ":" + this.auth.clientSecret)}`)
      }

      //Post the code to get the token
      // TODO: Handle errors below
      this.http.post<any>(tokenUrl, request.toString(), header).subscribe((response) => {
        sessionStorage.setItem("nmss:token", response.access_token);
        sessionStorage.setItem("nmss:refreshToken", response.refresh_token);
        sessionStorage.setItem("nmss:idToken", response.id_token);
        localStorage.setItem("nmss:token", response.access_token);
        localStorage.setItem("nmss:refreshToken", response.refresh_token);
        localStorage.setItem("nmss:idToken", response.id_token);

        let opener = this.getMainWindow();

        opener.postMessage({ type: "auth", status: "complete", token: response.access_token, refreshToken: response.refresh_token, idToken: response.id_token }, window.location.origin);
      }
      );

    }

  }

  getMainWindow() {
    let opener = undefined;

    let useWindow = false;

    try {
      if (window.opener)
        useWindow = window.opener.location.host == window.location.host;
      else
        useWindow = true;
    }
    catch
    {
      useWindow = true;
    }

    if (useWindow)
      opener = window;
    else
      opener = window.opener;

    return opener;
  }

  // Get the root path of the app from the url
  getAppPath(): string {
    let host = window.location.origin + window.location.pathname;
    if (host.endsWith("/"))
      host = host.substr(0, host.length - 1);
    return host;
  }

  // Returns true if the suer is logged in
  isLoggedIn() {
    return this.login.isLoggedIn();
  }

  // Clear the token and idtoken
  clearToken(): void {

    this.login = new Login();
    this.token = undefined;
    this.idToken = undefined;
    sessionStorage.removeItem("nmss:token");
    sessionStorage.removeItem("nmss:idToken");
    localStorage.removeItem("nmss:token");
    localStorage.removeItem("nmss:idToken");

  }

  // Clear the refresh token
  clearRefresh(): void {
    this.refreshToken = undefined;
    sessionStorage.removeItem("nmss:refreshToken");
    localStorage.removeItem("nmss:refreshToken");
  }

  // Log out the current session
  logout(): Observable<Login> {

    if (this.nextValidationTick !== undefined)
      clearTimeout(this.nextValidationTick);

    setTimeout(() => {
      this.isLoggingIn = false;
      this.isRefreshing = false;

      let host = this.getAppPath();
      let redirectUrl = host + "/assets/oAuthHelper.html";
      let logoutUrl = "http" + (this.auth.useHttps ? "s" : "") + "://" + this.auth.host + "/" + this.auth.logoutEndpoint + "?client_id=" + this.auth.clientId + "&response_type=code&scope=openid+profile&logout_uri=" + redirectUrl;

      this.openTab = window.open(logoutUrl);
      setTimeout(() => {
        // TODO - May want to revisit this workaround
        this.clearToken();
        this.clearRefresh();
        this.router.navigate(["/"]);
      }, 50)
    }, 100);

    return this.replaySubject;
  }

}
