import { Component, Inject, OnDestroy, OnInit, ViewChild, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { ConfigurationService } from 'src/app/_shared/services/configuration.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';
import { ActivityQueryParamData } from './activity-view-model';

@Component({
  selector: 'app-activity-create-dialog',
  templateUrl: './activity-create-dialog.component.html',
  styleUrls: ['./activity-create-dialog.component.scss']
})
export class ActivityCreateDialogComponent implements OnInit, OnDestroy {

  fields: any[];
  fieldsConfig: any;
  configSub: any;

  constructor(
    private dialogRef: MatDialogRef<ActivityCreateDialogComponent>,
    private fieldService: FieldService,
    private router: Router,
    private config: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public projectId: number
  ) { }

  @ViewChild('activityInfo') activityInfo: DynamicFormComponent

  activityFocusFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  activityTypeFormControl: FormControl = new FormControl({ value: null, disabled: true }, Validators.required);

  foci: any[] = [{ name: '' }];
  types: any[] = [{ name: '' }];

  ngOnInit(): void {
    this.fields = this.fieldService.getFields('ActivityModalFields');

    this.configSub = this.config.getConfig().subscribe((res) => {
      this.fieldsConfig = res.fields;
      this.generateFocusDropdowns();
    });
  }

  ngOnDestroy() {
    this.configSub.unsubscribe();
  }

  continue(): void {
    // TODO will need to setup query params to unpack these data
    const data: any = this.getQueryParamData();
    this.dialogRef.close();
    this.router.navigateByUrl(`/app/activity/create?focus=${data.focus}&type=${data.type}&spatial=${data.spatial}&projectid=${data.projectid}`); // TODO add query params
  }

  isValid(): boolean {
    if (this.activityFocusFormControl && this.activityFocusFormControl.valid &&
      this.activityTypeFormControl && this.activityTypeFormControl.valid) {
      return true;
    } else {
      return false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearType() {
    this.activityTypeFormControl.reset();
    this.generateTypes();
    this.activityTypeFormControl.enable();
  }

  // TODO may want to pull this out into a separate fields manip service at some point
  generateFocusDropdowns() {
    const focusNames = this.fieldsConfig.Activities.FocusTypes.map((obj => {
      return { name: obj.FocusName };
    }));
    this.foci = focusNames;
    this.foci.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }

  generateTypes() {
    const currentFocus = this.fieldsConfig.Activities.FocusTypes.find((obj => {
      return obj.FocusName === this.activityFocusFormControl.value;
    }));

    if (!currentFocus) {
      this.types = [{ name: '' }];
      return;
    }

    const types = currentFocus.Activities.map((obj) => {
      return { name: obj.TypeName };
    });
    this.types = types;
    this.types.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }

  private getQueryParamData() {
    const currentFocus = this.fieldsConfig.Activities.FocusTypes.find((obj => {
      return obj.FocusName === this.activityFocusFormControl?.value;
    }));
    const currentType = currentFocus.Activities.find((obj => {
      return obj.TypeName === this.activityTypeFormControl?.value;
    }));

    const data: ActivityQueryParamData = {
      focus: this.activityFocusFormControl?.value || null,
      type: this.activityTypeFormControl?.value || null,
      spatial: currentType?.Mapped,
      projectid: this.projectId
    };

    return data;
  }

}
