<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-header class="appTrashCanHeaderBuffer">
      </mat-card-header>
      <mat-card-content>
        <div>
          <div>
            <p class="appLinkDesc appFieldContent">{{link.linkDesc}}</p>
          </div>
          <div>
            <div style="float:left">
              <a class="appLink" [attr.href]="getLinkUrl()" target="_blank">{{link.linkUrl}}</a>
            </div>
            <div class="appTrashCan" [appRequireACL]="'Focal:Create'">
              <mat-icon (click)="removeLink($event)">delete</mat-icon>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
