import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FundingAddDialogComponent } from 'src/app/funding/funding-add-dialog/funding-add-dialog.component';
import { FundingComponent } from 'src/app/funding/funding.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from 'src/app/map/error-dialog/error-dialog.component';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { MapEditDialogComponent } from 'src/app/_shared/components/dialogs/map-edit-dialog/map-edit-dialog.component';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { CoreEntity } from 'src/app/_shared/models/core-entity.model';
import { Fields } from 'src/app/_shared/models/dynamic-forms/fields.model';
import { PamObject, PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';
import { ProjectGeoCardComponent } from 'src/app/project/project-geo-card/project-geo-card.component';
import { DirectiveLogic } from 'src/app/_shared/directives/directive-logic';

@Component({
  selector: 'app-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrls: ['./activity-view.component.scss']
})
export class ActivityViewComponent implements OnInit, AfterViewInit {
  @ViewChild('coreDetails') coreDetails: DynamicFormComponent;
  @ViewChild('additionalDetails') additionalDetails: DynamicFormComponent;
  @ViewChild('slimMap') slimMap: SlimMapComponent;
  @ViewChild('funding') fundingDetails: FundingComponent;
  @ViewChild('projGeo') projGeo: ProjectGeoCardComponent;

  heightValue = '90rem';
  financialsHeightValue = '80rem;'
  fundingTarget: any = { fields: {} };
  dynamicFlexContainerWidth: number = 100;
  dynamicFlexChildWidth: number = 100;
  screenHeight: number;
  screenWidth: number;
  target: any = { fields: {} };
  partners: any[] = [];
  fields: any[];
  fieldsWork: any[];
  editModeOn = false;
  isSpatial = false;
  primaryFocus: string;
  activityType: string;
  activityId: number;
  projectId: number;
  leadOrg: string;
  editMapOn = false;
  mapDialog: any;
  featureGeom: PamShapeData;
  fundings: any[] = [];
  projectLeadOrg: any;
  amountTotals: any;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
    private fieldService: FieldService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log("Activity View: Project Lead Org", this.projectLeadOrg)
    this.route.queryParams.subscribe(params => {
      let id = params["id"];
      let projectid = params["projectid"];
      this.projectLeadOrg = params["projectleadorg"]
      if (id == undefined)
        return;

      let iid = parseInt(id);
      this.activityId = iid;
      this.projectId = parseInt(projectid);

      this.api.post<any>("Activity", "LongRead", iid, true).subscribe(results => {
        if (results && results.valid) {
          let target = results.result;
          this.handleLoad(target);
        }
        this.getFundingRecords(iid);
      });

      this.hydrateLeadOrg();
    });
    this.onResize();
  }

  ngAfterViewInit() {
    if (this.isSpatial && this.projGeo && this.target) {
      this.projGeo.getStats(this.target?.id);
    }
  }

  getFundingRecords(iid: any) {
    this.api.post<any>("Funding", "ReadAll", { linkToId: iid }, true).subscribe(results => {
      let target = results;
      this.handleFundingLoad(target);
      this.sortFundings();
    });
  }

  formsAreValid() {
    return this.coreDetails.isValid() && this.additionalDetails.isValid();
  }

  cancelEdit() {
    this.editModeOn = false;
    this.coreDetails.refreshForm(this.fields);
    this.additionalDetails.refreshForm(this.fieldsWork);
  }

  update(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Saving Activity.",
      disableClose: true
    });

    this.api.post<any>("Activity", "Update", obj, true).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleLoad(res.result)
      saveRef.close();

      this.editModeOn = false;

      if (this.isSpatial) {
        this.projGeo.startStatTimer();
      }

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  createFunding(data: any) {
    let formattedObj: any = {};
    formattedObj.name = data.name;
    formattedObj.fields = {};
    formattedObj.fields.fundingOrg = data.orgName;
    formattedObj.fields.fundingName = data.name;
    formattedObj.fields.plannedAmount = data.plannedAmount;
    formattedObj.fields.actualAmount = data.actualAmount
    formattedObj.fields.match = data.match;

    this.api.post<any>("Funding", "Create", { linkToId: this.activityId, newObject: formattedObj }, true).subscribe(result => {
      console.log('result');
      console.log(result);
      if (!result.valid) {
        //Backend validation didnt pass show the errors``
        //this.showErrors(result.messages);
      }
      else {
        this.getFundingRecords(this.activityId);
      }
    }, error => {
      //Backend gave us a non-200 response
      //this.showErrors(["The api responded incorrectly.", JSON.stringify(error, undefined, 2)]);
    })
  }


  updateFunding(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      //pass object here (edit mode and other things)
      data: "Saving Record.",
      disableClose: true
    });

    this.api.post<any>("Funding", "Update", obj, true).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.getFundingRecords(this.activityId);
      saveRef.close();

      this.editModeOn = false;

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  createPayload() {
    const coreDetailsData = this.coreDetails.getPayload();
    const additionalDetailsData = this.additionalDetails.getPayload();
    let formattedObj: PamObject = { id: this.target.id, name: this.target.name, fields: {} };
    formattedObj.name = coreDetailsData.ACTIVITY_NAME;
    formattedObj.fields.activityStatus = coreDetailsData.ACTIVITY_STATUS;
    formattedObj.fields.activityWorkAgent = coreDetailsData.ACTIVITY_WORK_AGENT;
    formattedObj.fields.plannedCompletionDate = coreDetailsData.PLANNED_COMPLETION_DATE;
    formattedObj.fields.actualCompletionDate = additionalDetailsData.ACTUAL_COMPLETION_DATE;
    formattedObj.fields.activityUnit = additionalDetailsData.ACTIVITY_UNIT;
    formattedObj.fields.activityAcres = DirectiveLogic.DecimalCap(additionalDetailsData.ACTIVITY_ACRES, 1);
    formattedObj.fields.riskAdjacent = additionalDetailsData.RISK_ADJACENT;
    formattedObj.fields.Geometry = this.featureGeom?.fields?.Geometry || {};

    return formattedObj;
  }

  toggleEditMode() {
    if (this.editModeOn) {
      // Map data to DTO and update
      const payload = this.createPayload();
      this.update(payload);
    } else {
      this.editModeOn = true;
    }
  }

  routerNavigate() {
    this.router.navigateByUrl(`/app/project/view?id=${this.projectId}`);
  }

  dateFormat(date: string) {
    if (!date) {
      return date = "";
    } else {
      return new Date(date)
    }
  }

  async hydrateLeadOrg() {
    this.api.post<any>("Project", "Get", [this.projectId], true).subscribe(results => {
      if (results && results.length) {
        let target = results[0];
        this.leadOrg = target?.fields?.leadOrganization || null;
        this.partners = target?.fields?.Partners || [];
      }
    });
  }

  handleLoad(target: any): void {
    this.target = target;
    console.log('got it');
    console.log(this.target);
    const activityFields: any = {};
    const activityWorkFields: any = {};

    activityFields[Fields.ActivityName] = this.target.name;
    activityFields[Fields.ActivityStatus] = this.target.activityStatus;
    activityFields[Fields.ActivityWorkAgent] = this.target.activityWorkAgent;
    activityFields[Fields.PlannedCompletionDate] = this.dateFormat(this.target.plannedCompletionDate);

    activityWorkFields[Fields.ActualCompletionDate] = this.dateFormat(this.target.actualCompletionDate);
    activityWorkFields[Fields.ActivityUnit] = this.target.activityUnit;
    activityWorkFields[Fields.ActivityAcres] = this.target.activityAcres;
    activityWorkFields[Fields.RiskAdjacent] = this.target.riskAdjacent;

    this.primaryFocus = this.target.primaryFocus;
    this.activityType = this.target.activityType;
    this.isSpatial = this.target.isSpatial;
    this.updateDynamicFlex(this.isSpatial);

    this.fields = this.fieldService.getEditableFieldsGeneric(CoreEntity.Activity, activityFields);
    this.fieldsWork = this.fieldService.getEditableFieldsGeneric(CoreEntity.ActivityWork, activityWorkFields);

    this.coreDetails.refreshForm(this.fields);
    this.additionalDetails.refreshForm(this.fieldsWork);

    // Geom
    this.updateMapGeom();

    // Remove spatial fields if needed
    if (!this.isSpatial) {
      this.removeSpatialFields();
    }

    // Update Project Stats
    if (this.isSpatial && this.projGeo) {
      this.projGeo.getStats(target?.id);
    }
  }

  toggleEditMap() {
    this.editMapOn = true;
    this.mapDialog = this.dialog.open(MapEditDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: { feature: this.featureGeom, fillColor: 'rgba(255, 42, 42, 0.5)', strokeColor: 'rgba(48, 0, 0, 0.5)', refId: this.projectId },
      disableClose: true,
    });

    this.mapDialog.afterClosed().subscribe(data => {
      this.mapDialog = null;
      if (data) {
        // If there are data, save
        this.newShapeData(data);
        const payload = this.createPayload();
        this.update(payload);
      }

      this.cancelEditMap();
    });
  }

  cancelEditMap() {
    this.editMapOn = false;
    this.rebootMap();
  }

  rebootMap() {
    setTimeout(() => {
      this.updateMapGeom();
    }, 250);
  }

  newShapeData(data: PamShapeData) {
    this.featureGeom = data;
  }

  updateMapGeom() {
    if (this.target && this.target?.geometry && this.target?.geometry?.wkt) {
      const geomObj: PamShapeData = {
        id: this.target.id,
        name: this.target.name,
        fields: {
          Geometry: { ... this.target.geometry }
        }
      }
      if (this.slimMap) {
        this.slimMap.update(geomObj);
      } else {
        // First page load, isSpatial check is likely underway and DOM has not
        // rendered the slim map comp yet.  OpenLayers needs a moment.
        const that = this;
        setTimeout(() => {
          that.slimMap.update(geomObj);
        }, 1000);
      }
    }

    // Load reference shape form project
    if (this.isSpatial) {
      this.api.post<any>("Project", "Get", [this.projectId]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleProjectLoad(target);
        }
      });
    }
  }

  triggerDelete() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35rem',
      data: { title: 'Confirm Delete', text: 'Deleting this Activity will permanently remove it and related details from the Project, and this cannot be undone. Are you sure you want to delete this Activity?', confirm: "Yes", cancel: "No" },
      disableClose: false
    });


    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // Delete
        this.commitDelete();
        console.log('Delete');
      } else {
        // No action needed
      }
    });
  }

  async commitFundingDelete(data: any) {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Deleting Funding.",
      disableClose: true
    });

    let obj = { id: data.id }

    this.api.post<any>("Funding", "Delete", obj, true).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.getFundingRecords(this.activityId);
      delRef.close();
    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  updateFundings() {
    this.api.post<any>("Funding", "ReadAll", { linkToId: this.target.id }, true).subscribe(results => {
      this.fundings = results;
      if (results?.length > 0) {
        this.sortFundings();
        this.calculateTotals();
      }
    });
  }

  async commitDelete() {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Deleting Activity.",
      disableClose: true
    });

    let obj = { id: this.target.id }

    this.api.post<any>("Activity", "Delete", obj, true).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.target = res.result;
      delRef.close();

      this.router.navigateByUrl(`/app/project/view?id=${this.projectId}`);
    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  updateDynamicFlex(isSpatial: boolean) {
    if (isSpatial) {
      this.dynamicFlexContainerWidth = 100;
      this.dynamicFlexChildWidth = 50;
    } else {
      this.dynamicFlexContainerWidth = 50;
      this.dynamicFlexChildWidth = 100;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1366) {
      this.heightValue = '109rem;';
    } else {
      this.heightValue = '90rem;';
    }
  }

  removeSpatialFields() {
    const spatialFields = [Fields.ActivityAcres];

    spatialFields.forEach(element => {
      const foundIdx = this.fieldsWork.findIndex((data) => {
        return data.key === element;
      });
      if (foundIdx) {
        this.fieldsWork.splice(foundIdx, 1);
      }
    });
  }

  handleFundingLoad(target: any): void {
    this.fundingTarget = target;
    console.log('funding load');
    console.log(this.fundingTarget);
    const fundingFields: any = {};

    this.fundingTarget.forEach(funding => {
      fundingFields[Fields.FundingId] = funding.id;
      fundingFields[Fields.FundingOrg] = funding.fundingOrg;
      fundingFields[Fields.FundingName] = funding.fundingName;
      fundingFields[Fields.FundingMatch] = funding.match;
      fundingFields[Fields.PlannedAmount] = funding.plannedAmount;
      fundingFields[Fields.ActualAmount] = funding.actualAmount;
    });

    this.fundings = this.fundingTarget;

    this.updateFundings();

  }

  calculateTotals() {

    if (this.fundings) {
      this.amountTotals = this.fundings.reduce((a, b) => {
        return {
          plannedAmount: a.plannedAmount + b.plannedAmount,
          actualAmount: a.actualAmount + b.actualAmount
        };
      })
    }

    console.log(this.amountTotals);
  }

  addFunding() {
    let fundingDialog = this.dialog.open(FundingAddDialogComponent, {
      width: '35rem',
      disableClose: true
    });


    fundingDialog.afterClosed().subscribe(data => {
      if (data) {
        // If there are data, save
        this.createFunding(data);
      }
    });
  }

  createFundingPayload(data: any) {
    console.log("funding data", data);
    let formattedObj: PamObject = { id: data.id, name: data.name, fields: {} };
    formattedObj.name = data.name;
    formattedObj.fields = {};
    formattedObj.fields.fundingOrg = data.orgName;
    formattedObj.fields.fundingName = data.name;
    formattedObj.fields.plannedAmount = data.plannedAmount;
    formattedObj.fields.actualAmount = data.actualAmount
    formattedObj.fields.match = data.match;

    return formattedObj;
  }

  triggerFundingDelete(data: any) {
    const foundData = this.fundings.find((fund) => { return fund.id === data.id });
    if (foundData) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35rem',
        data: { title: 'Confirm Delete', text: 'Are you sure you want to delete this funding entry?', confirm: "Yes", cancel: "No" },
        disableClose: false
      });


      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Delete
          this.commitFundingDelete(foundData);
        } else {
          // No action needed
        }
      });
    }
  }

  removeFundingData(data: any) {
    this.fundings = this.fundings.filter(function (obj) {
      return obj.name !== data.name;
    });

    this.sortFundings();
  }

  newFundingData(data: any) {
    this.fundings.push(data);
    this.sortFundings();
  }

  sortFundings() {
    this.fundings.sort((a, b) => (a.fundingName > b.fundingName) ? 1 : ((b.fundingName > a.fundingName) ? -1 : 0));
  }

  isFundingsEmpty() {
    return this.fundings.length === 0 ? true : false;
  }

  routeToExplorer() {
    this.router.navigateByUrl(`/app/map?id=${this.target.id}&type=a`);
  }

  handleProjectLoad(target: any) {
    if (target && target?.fields?.Geometry && target?.fields?.Geometry?.wkt) {
      const geomObj: PamShapeData = {
        id: target.id,
        name: target.name,
        fields: {
          Geometry: { ... target.fields?.Geometry }
        }
      }
      if (this.slimMap) {
        this.slimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)', true);
      } else {
        // First page load, isSpatial check is likely underway and DOM has not
        // rendered the slim map comp yet.  OpenLayers needs a moment.
        const that = this;
        setTimeout(() => {
          that.slimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)', true);
        }, 1000);
      }
    }
  }

}
