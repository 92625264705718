import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { DirectiveLogic } from 'src/app/_shared/directives/directive-logic';
import { Organization } from 'src/app/_shared/models/user.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { ConfigurationService } from 'src/app/_shared/services/configuration.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';

@Component({
  selector: 'app-funding-add-dialog',
  templateUrl: './funding-add-dialog.component.html',
  styleUrls: ['./funding-add-dialog.component.scss']
})
export class FundingAddDialogComponent implements OnInit, OnDestroy {

  fields: any[];
  fieldsConfig: any;
  configSub: any;
  funding: any;
  filteredOrgs: Organization[] = [];
  editMode = false;

  //funding org dropdown
  fundingOrgs: any[] = [{ name: '' }];
  fundingOrgNames: any[] = [{ name: '' }];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FundingAddDialogComponent>,
    private fieldService: FieldService,
    private router: Router,
    private config: ConfigurationService,
    private api: ApiService
  ) { }


  fundingOrgFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  fundingNameFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  plannedFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  actualFormControl: FormControl = new FormControl({ value: null, disabled: false });
  matchFormControl: FormControl = new FormControl({ value: false, disabled: false }, Validators.required);

  ngOnInit(): void {
    this.configSub = this.config.getConfig().subscribe((res) => {
      this.fieldsConfig = res.fields;
      this.generateFocusDropdowns();
      if(this.data && this.data.editMode){
        this.editMode = this.data.editMode;
        this.setFormValues();
      }
    })

  }

  ngOnDestroy() {
    this.configSub.unsubscribe();
  }

  async setFormValues(){
    this.fundingOrgFormControl.setValue(this.data.funding.fundingOrg);
    this.generateFundingNames()
    this.fundingNameFormControl.setValue(this.data.funding.fundingName);
    this.plannedFormControl.setValue(this.data.funding.plannedAmount);
    this.actualFormControl.setValue(this.data.funding.actualAmount);
    this.matchFormControl.setValue(this.data.funding.match);
  }

  // TODO may want to pull this out into a separate fields manip service at some point
  generateFocusDropdowns() {
    const fundingOrgNames = this.fieldsConfig.Fundings.Organizations.map((obj => {
      return { name: obj.OrgName };
    }));
    this.fundingOrgs = fundingOrgNames;
    this.fundingOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
  }

  generateFundingNames() {
    const currentFundingOrg = this.fieldsConfig.Fundings.Organizations.find((obj => {
      return obj.OrgName === this.fundingOrgFormControl.value;
    }));

    if (!currentFundingOrg) {
      this.funding = [{ name: '' }];
      return;
    }

    const fundingNames = currentFundingOrg.Fundings.map((obj) => {
      return { name: obj.Name };
    });
    this.fundingOrgNames = fundingNames;
    this.fundingOrgNames.sort((a, b) => (a.name > b.name) ? 1 : -1);
  }

  clearType() {
    this.fundingNameFormControl.reset();
    this.generateFundingNames();
    this.fundingNameFormControl.enable();
  }

  onSubmit(): void {
    let fundingOrg = this.fundingOrgFormControl.value;
    let fundingName = this.fundingNameFormControl.value;
    let plannedData = DirectiveLogic.DecimalCap(this.plannedFormControl.value, 2);
    let actualData = DirectiveLogic.DecimalCap(this.actualFormControl.value, 2);
    let matchData = this.matchFormControl.value;

    let fundingObject =
    {
      "id": 0,
      "name": fundingName,
      "orgName": fundingOrg,
      "plannedAmount": plannedData,
      "actualAmount": actualData,
      "match": matchData
    }

    if(this.editMode){
      fundingObject.id = this.data.funding.id;
    }

    this.dialogRef.close(fundingObject);
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }

  isValid(): boolean {
    if (this.fundingOrgFormControl && this.fundingOrgFormControl.valid &&
      this.fundingNameFormControl && this.fundingNameFormControl.valid &&
      this.plannedFormControl && this.plannedFormControl.valid) {
      return true;
    } else {
      return false;
    }
  }
}
