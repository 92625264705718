import { Component, OnInit } from '@angular/core';
import { CoreEntity } from '../_shared/models/core-entity.model';
import { FieldService } from '../_shared/services/forms/field.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  fields: any[];

  constructor(private fieldService: FieldService,) { }

  ngOnInit(): void {
    this.fields = this.fieldService.getFields(CoreEntity.Project);
  }

}
