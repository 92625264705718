<mat-drawer-container fxFill id="drw" class="drawer-container mat-elevation-z8" autosize name="map-side-drawer">
  <mat-drawer #drawer class="drawer-sidenav" mode="side" [opened]="true" (openedStart)="drawerOpened = true"
    (closedStart)="drawerOpened = false">
    <app-sidebar-content #sidebar [identifyResults]="identifyResults" [mapZoom]="currentMapZoom"
      (onDrawerClose)="drawer.close()" [categories]="mapCategories" (selectLayer)="selectLayer($event)"
      (deselectLayer)="deselectLayer($event)" (opacityChange)="opacityChange($event)"
      (highlightFeature)="highlightFeature($event)" (unhighlightFeature)="unhighlightFeature($event)"
      (zoomToFeature)="zoomToFeature($event)" (zoomToExtent)="zoomToExtent($event, true)"
      (importFeature)="importFeature($event)" (returnToIdentifyExtent)="returnToIdentifyExtent()"
      (identifyExtentClick)="searchThisArea()" (tempHighlightFeature)="tempHighlight($event)">
    </app-sidebar-content>

  </mat-drawer>
  <mat-drawer-content>
    <div id="mapmap"></div>
    <div class="buttonsAndThings" fxFlex fxLayout="row" fxLayoutGap="0.5rem">
      <a id="image-download" download="map.png"></a>
    </div>
    <div (click)="drawer.open()" class="expand-button" *ngIf="!drawerOpened">
      <div>
        <i class="material-icons"> keyboard_arrow_right </i>
      </div>
    </div>
    <app-geo-search [ngStyle]="{ top: '0.75rem', right: '5rem' }" (zoomToCandidates)="zoomToCandidates($event)"
      (clearPoints)="clearPoints()" [mapCenter]="currentMapCenter" [appRequireACL]="'Map:UseSearch'">
    </app-geo-search>

    <div class="identifyContainer">
      <button mat-flat-button color="accent" [appRequireACL]="'Map:IdentifyFromMap'" (click)="searchThisArea()">IDENTIFY
        THIS AREA</button>
    </div>

  </mat-drawer-content>
</mat-drawer-container>







<div class="hide">
  <div mat-card id="assessmentEditOverlayContents">
    <mat-card class="assessmentEditOverlayContents" *ngIf="hasArea() && import.getState() == ImportState.Idle">
      <mat-card-title class="title">
        <div fxFlex fxLayout="row" fxLayoutGap="0.75rem">
          <p>Feature Type: {{featureType}}</p>
          <button mat-button class="align-right buttonhax" color="primary" (click)="dismissAssessmentEdit()">X</button>
        </div>
      </mat-card-title>
      <mat-card-content class="title align-bottom">
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-card id="importOverlayContents">
    <mat-card class="assessmentEditOverlayContents">

      <mat-card-title class="title">
        <div fxFlex fxLayout="column" fxLayoutGap="0rem" *ngIf="import.getState() == ImportState.NoResults">
          <div fxFlex fxLayout="row" fxLayoutGap="0.5rem">
            <strong>NO RESULTS</strong>
            <button mat-button class="align-right buttonhax" style="padding:0" color="primary"
              (click)="dismissImport()">X</button>
          </div>
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="0rem" *ngIf="import.getState() == ImportState.Searching">
          <div fxFlex fxLayout="row" fxLayoutGap="0.5rem">
            <strong>SEARCHING</strong>
            <button mat-button class="align-right buttonhax" style="padding:0" color="primary"
              (click)="dismissImport()">X</button>
          </div>
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="0rem" *ngIf="import.getState() == ImportState.HasResults">
          <div fxFlex fxLayout="row" fxLayoutGap="0.5rem">
            <strong>Feature Type: </strong>
            <div>{{import.getFocusedResult().data.Type || 'Inventory'}}</div>
            <button mat-button class="align-right buttonhax" style="padding:0" color="primary"
              (click)="dismissImport()">X</button>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content class="title align-bottom" *ngIf="import.getState() == ImportState.HasResults">
        <div fxFlex fxLayout="column" fxLayoutGap="0.75rem" style="font-size: 0.75rem;">
          <div fxFlex fxLayout="row" fxLayoutGap="0.5rem">
            <strong>Name: </strong>
            <div>{{import.getFocusedResult().name}}</div>
          </div>
          <div fxFlex fxLayout="row" *ngIf="import.getResultCount() > 1" style="width: 100%"
            fxLayoutAlign="center center">
            <button mat-button class="buttonhax" color="primary" (click)="importPreviousResult()"
              [disabled]="!import.hasPreviousResult()">&lt;</button>
            <div fxFlex fxLayout="row" style="margin:auto;">
              <p style="margin-left:auto; margin-right: 0.25rem;">{{import.getIndex() + 1}}</p>
              <p style="text-align: center;">of</p>
              <p style="margin-right:auto; margin-left: 0.25rem;">{{import.getResultCount()}}</p>
            </div>
            <button mat-button class="buttonhax" color="primary" (click)="importNextResult()"
              [disabled]="!import.hasNextResult()">&gt;</button>
          </div>
          <!-- <button mat-flat-button color="accent"
            (click)="importSelectedIdentify()">{{ hasArea() ? 'IMPORT THIS FEATURE' : 'CREATE AREA OF INTEREST' }}</button> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div id="assessmentEditOverlay">
</div>

<div id="importOverlay">
</div>
