<div class="appMainContainer">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxFlex=25 fxLayoutAlign="top left"
    style="margin:1.5rem 0; width: 100%; margin-right: 1.5rem;">
    <mat-card class="appSolidCard mat-elevation-z0" style="padding: 1rem!important;">
      <h1 class="appMainCardHeader" style="margin-bottom: 0;">I Want to...</h1>
      <div class="appButtonContainer" style="padding-right: 24px;">
        <button (click)="findSomething()" [appRequireOrg]="true" mat-flat-button color="accent" class="appSquareButton appLargeButton">Find
          Something</button>
      </div>
      <div class="appButtonContainer" [appRequireACL]="'Project:Create'">
        <button mat-flat-button color="accent" class="appSquareButton appLargeButton"
          routerLink="/app/project/create">Create New
          Project</button>
        <img alt="Info Tooltip" src="/assets/images/info-icon.png" style="margin-left: 0.25rem;" (click)="openDialog()"
          class="appSecondaryIcon appCustomTooltip">
      </div>
    </mat-card>
  </div>


  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxFlex=75 fxLayoutAlign="top left"
    style="margin:1.5rem 0; width: 100%;">

    <mat-card class="appSolidCard mat-elevation-z0" style="margin-bottom: 1rem; padding-top: 0 !important;">

      <div class="appTableSpacing">

        <div>
          <h1 class="appMainCardHeader">Our Active Projects</h1>
        </div>
        <div name="userGrid" class="mat-elevation-z0 appSolidTable" style="font-size: 0.9rem;">
          <table aria-describedby="Projects table" mat-table [dataSource]="dataSource" matSort style="width: 100%">


            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th id="appNameHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let project"> {{project.name}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th id="appIdHeader" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let project"> {{project.id}} </td>
            </ng-container>

            <!-- Last Modified Column -->
            <ng-container matColumnDef="modified">
              <th id="appIdHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Last Modified </th>
              <td mat-cell *matCellDef="let project"> {{project.modified | date: 'shortDate' }} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="projectType">
              <th id="appTypeHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
              <td mat-cell *matCellDef="let project"> {{project.projectType}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="projectStatus">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let project"> {{project.projectStatus}} </td>
            </ng-container>

            <!-- Lead Organization Column -->
            <ng-container matColumnDef="leadOrganization">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Coordinating Organization </th>
              <td mat-cell *matCellDef="let project"> {{project.leadOrganization}} </td>
            </ng-container>

            <!-- View Project Details Link Column -->
            <ng-container matColumnDef="view">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let project" class="appClickable" (click)="viewSummary(project.id)">View</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>

      </div>

    </mat-card>

  </div>

</div>
