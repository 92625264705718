<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <div *ngIf="!edit">
        <div *ngIf="isOpportunity">
          <span>Add Opportunity</span>
        </div>
        <div *ngIf="!isOpportunity">
          <span>Add Impediment</span>
        </div>
      </div>
      <div *ngIf="edit">
        <div *ngIf="isOpportunity">
          <span>Edit Opportunity</span>
        </div>
        <div *ngIf="!isOpportunity">
          <span>Edit Impediment</span>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div class="appNoteFormContainer">
      <app-dynamic-form #note [largeLabel]="true" [fields]="fields" [largeLabel]="true"></app-dynamic-form>
    </div>
    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="onNoClick()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()"
          target="_blank">Save</button>
      </div>
    </div>

  </div>
</div>
