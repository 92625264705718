<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <span>Add New Link</span>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Link Description</mat-label>
        <input maxlength="250" name="linkDesc" #linkDesc matInput [formControl]="linkDescriptionFormControl"
          placeholder="Ex. Volunteer Opportunities" required="true" />
      </mat-form-field>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Link URL</mat-label>
        <input maxlength="500" name="link" #link matInput [formControl]="linkFormControl"
          placeholder="Ex. www.placeholder.org" required="true" />
      </mat-form-field>
    </div>

    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="cancelClicked()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()"
          target="_blank">Save</button>
      </div>
    </div>
  </div>
</div>
