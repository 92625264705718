<div fxFlex fxLayout="column" fxAlign="center center" fxLayoutGap="0.01rem">

  <div fxFlex="nogrow" fxLayout="column" fxAlign="center center" style="padding:0.5rem;" class="mat-elevation-z0">
    <div fxFlex="nogrow" fxLayout="column" fxAlign="center center">
      <p *ngIf="this.identifyResults.hasResults && this.gotLatest" class="appH1Title appExpTitle">{{ this.getFeatureType(true) }}{{ this.currentName }}</p>
      <div class="appPaginatorContainer" *ngIf="this.getTotalCount() && this.getTotalCount() > 1">

        <span>
          Selection
          <button class="appExpPager" [disabled]="!this.canPageLeft()" (click)="this.pageLeft()">
            <mat-icon class="appExpPageKey">keyboard_arrow_left</mat-icon>
          </button>
          {{ this.identifyResults.hasResults ? this.currentIdx + 1 : 0 }} of {{ this.getTotalCount() || 0 }}
          <button class="appExpPager" [disabled]="!this.canPageRight()" (click)="this.pageRight()">
            <mat-icon class="appExpPageKey">keyboard_arrow_right</mat-icon>
          </button>
        </span>

      </div>
    </div>
  </div>

  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'" [style.height]="'32rem'">
    <div>
      <app-layer-explorer [hasCoreData]="this.gotLatest" [hasResults]="this.identifyResults.hasResults"
        [result]="this.getCurrentView()" (newName)="this.setName($event)" (zoomToExtent)="this.zoomExtent($event)"
        (unhighlightCurrent)="this.unhighlightCurrent()" (tempHighlightFeature)="this.tempHighlight($event)">
      </app-layer-explorer>
    </div>

  </mat-accordion>

</div>
