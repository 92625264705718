export class FieldBase<T> {
    value: T;
    key: string;
    label: string;
    example: string;
    required: boolean;
    order: number;
    controlType: string;
    elements?: any;
    hint?: string;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        example?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        elements?: any,
        hint?: string
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.example = options.example || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.elements = options.elements || '';
        this.hint = options.hint || null;
    }
}
