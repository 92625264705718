import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-add-org',
  templateUrl: './add-org.component.html',
  styleUrls: ['./add-org.component.scss']
})
export class AddOrgComponent implements OnInit {

  types: string[] = ['Federal', 'State', 'Local', 'Tribal', 'NGO', 'Academia', 'Other']
  guidanceMessage = "Please ensure the information you entered is correct." +
  " You will not be able to edit the details once added, and you will only" +
  " be able to archive this organization is there are no users affiliated to it."

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddOrgComponent>,
    private api: ApiService
  ) { }

  orgNameFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  orgShortFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);
  orgTypeFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);

  ngOnInit(): void {
  }

  onNoClick(){
    this.dialogRef.close();
  }

  isValid(){
    if (this.orgNameFormControl && this.orgNameFormControl.valid
      && this.orgShortFormControl && this.orgShortFormControl.valid
      && this.orgTypeFormControl && this.orgTypeFormControl.valid) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit(): void {
    let orgName = this.orgNameFormControl.value;
    let orgShort = this.orgShortFormControl.value;
    let orgType = this.orgTypeFormControl.value

    let orgObject =
    {
      "name": orgName,
      "orgShort": orgShort,
      "orgType": orgType
    }

    this.dialogRef.close(orgObject);
  }
}
