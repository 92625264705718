export class DirectiveLogic {

    public static MinMax = (value, min, max) => {
        if (!value)
            return null;

        let val = parseInt(value);
        let outVal = value;

        if (max !== null && max !== undefined  && val >= max)
            outVal = max.toString();
        else if (min !== null && min !== undefined  && val <= min)
            outVal = min.toString();
        
        return outVal;
    }

    public static DecimalCap = (value, cap) => {
        if (!value)
            return null;

        let outVal = value;
        if (DirectiveLogic.IsHittingDecimalCap(value, cap))
            outVal = DirectiveLogic.TruncateDecimals(Number.parseFloat(outVal), cap).toString();
        
        return outVal;
    }

    private static IsHittingDecimalCap = (value, cap) => {
        if (!cap)
            return false;

        let outVal = value.toString();
        if (
            outVal && 
            outVal.includes('.') && 
            outVal.split('.')?.[1]?.length >= cap
          ) {
            return true;
          } else {
            return false;
          }
      }

      private static TruncateDecimals =  (number, digits) => {
        let multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    
        return truncatedNum / multiplier;
    };
  }
  