import { FieldBase } from './field-base';

export class CheckboxField extends FieldBase<string> {
  controlType = 'checkbox';
  default: any;

  constructor(options: {} = {}) {
    super(options);
    this.default = options['default'] || null;
  }
}
