<div class="container" style="width: 100%; height: 100%;" *ngIf="target">
  <div fxFlex fxFill fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="centerX">
    <div fxFlex fxFill fxLayout="column" fxAlign="center center" class="centerX"
      style="padding: 0.5rem 3rem; padding-bottom: 0;">
      <div fxFlex fxFill fxLayout="row" fxAlign="center center">
        <div fxFlex>
          <strong style="font-size:1.25rem; line-height: 1.5rem; font-weight: bold;">{{target.title}}</strong>
          <div>
            <span><a style="font-size:0.75rem;line-height:0.75rem;" routerLink="../all">&lt; Back to Dashboard</a></span>
            <span *ngIf="target?.lastmodified" class="appCardLastUpdate">Last Updated: {{ target?.lastmodified | date:'short' }}</span>
          </div>
        </div>
        <div fxFlex class="appCardDescContainer">
          <div class="appCardDesc">{{ target?.description || '' }}</div>
        </div>
    </div>
    </div>
    <div fxLayout="column" style="width: 100%; overflow: hidden; padding: 0; height: 100%; margin:0 auto!important;"
      fxAlign="center center" fxLayoutGap="0.75rem" class="centerX">
      <iframe title="Dashboard" style="height:100%; width: 100%; border:0;" [src]="frameUrl"></iframe>
    </div>

  </div>
</div>
