import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-cancel-dialog',
  templateUrl: './activity-cancel-dialog.component.html',
  styleUrls: ['./activity-cancel-dialog.component.scss']
})
export class ActivityCancelDialogComponent implements OnInit {



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ActivityCancelDialogComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {

  }


  routerNavigate() {
    this.router.navigateByUrl(`/app/project/view?id=${this.data.projectId}`);
    this.dialogRef.close(null);
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }


}
