import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MapCategory, Service, MapLayer } from '../../../_shared/models/map/config.model';

import { MatSliderChange } from '@angular/material/slider'
import { Result } from '../../../_shared/services/map/identify.service';

@Component({
  selector: 'app-category-container',
  templateUrl: './category-container.component.html',
  styleUrls: ['./category-container.component.scss']
})
export class CategoryContainerComponent implements OnInit {

  @Input() categories: MapCategory[] = [];
  @Input() mapZoom: any;
  @Input() mode: string = "themes";

  @Output() layerSelected: EventEmitter<MapLayer> = new EventEmitter();
  @Output() layerDeselected: EventEmitter<MapLayer> = new EventEmitter();
  @Output() opacityChange: EventEmitter<any> = new EventEmitter();


  @Output() tempHighlightFeature: EventEmitter<any> = new EventEmitter();
  @Output() highlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() unhighlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() zoomToFeature: EventEmitter<Result> = new EventEmitter();
  @Output() importFeature: EventEmitter<Result> = new EventEmitter();
  @Output() returnToIdentifyExtent: EventEmitter<boolean> = new EventEmitter();
  @Output() identifyExtentClick: EventEmitter<boolean> = new EventEmitter();
  @Output() zoomToExtent: EventEmitter<number[]> = new EventEmitter();

  @Input() public identifyResults: any = {
    hasResults: true,
    extentChanged: true,
    resultMap: {},
    extent: []
  };

  @ViewChildren('idView') public idTool: QueryList<any>;
  @ViewChildren('catpan') public catPans: QueryList<any>;

  public expandAll() {
    this.catPans.toArray().forEach(element => {
      console.log(element);
      element.open();
    });

    this.idTool.toArray().forEach(element => {
      element.expand();
    })
  }

  public zoomExtent(data) {
    console.log('cat container');
    this.zoomToExtent.emit(data);
  }

  public scrollToResult(layer: string, id: string) {
    this.expandAll();

    this.idTool.toArray().forEach(element => {
      if (element.layer.key === layer) {
        element.scrollToResult(id);
      }
    })
  }

  goToSearchExtent() {
    this.returnToIdentifyExtent.next(true);
  }

  constructor() { }

  ngOnInit(): void {
  }

  selectionChange(category: MapCategory, layer: MapLayer, selected: boolean) {
    if (selected)
      this.layerSelected.next(layer);
    else
      this.layerDeselected.next(layer);
  }

  adjustOpacity(category: MapCategory, event: MatSliderChange) {
    this.opacityChange.next({ key: category.category_key, opacity: event.value });
  }

  hasLegend(category: MapCategory): boolean {
    if (category.layers)
      for (let layer of category.layers) {
        if (category.selection.isSelected(layer.key) && layer.legend && layer.legend.length > 0)
          return true;
      }

    return false;
  }

  hasResults(category: MapCategory): boolean {
    if (category.layers)
      for (let layer of category.layers) {
        if (category.selection.isSelected(layer.key) && this.identifyResults.resultMap[layer.key] && this.identifyResults.resultMap[layer.key].status != 'Idle')
          return true;
      }

    return false;
  }

  hasAnyIdle(): boolean {
    for (let cat of this.categories) {
      if (this.hasIdle(cat))
        return true;
    }

    return false;
  }

  hasIdle(category: MapCategory): boolean {
    if (category.layers)
      for (let layer of category.layers) {
        if (category.selection.isSelected(layer.key) && this.identifyResults.resultMap[layer.key] && this.identifyResults.resultMap[layer.key].status == 'Idle')
          return true;
      }

    return false;
  }

  hasAnySelectedIDLayers(): boolean {
    return Object.keys(this.identifyResults.resultMap).length > 0
  }

  hasAnySelectedLegendLayers(): boolean {
    for (let category of this.categories) {
      for (let layer of category.layers) {
        if (category.selection.isSelected(layer.key) && layer.legend)
          return true;
      }
    }
    return false
  }

  tempHighlight(geom) {
    this.tempHighlightFeature.next(geom);
  }

}
