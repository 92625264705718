import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IQueryFeaturesResponse, queryFeatures } from '@esri/arcgis-rest-feature-layer';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { ApiService } from 'src/app/_shared/services/api.service';
import { ReportService } from 'src/app/_shared/services/report.service';

declare var ol: any;
declare var famMap: any;
declare var window: any;

@Component({
  selector: 'app-focal-report',
  templateUrl: './focal-report.component.html',
  styleUrls: ['./focal-report.component.scss']
})
export class FocalReportComponent implements OnInit, AfterViewInit {

  name: string;
  priorityLandscape: string;
  strategies: any[];
  partners: any[];
  activeProjects: number;
  associatedProjects: any[];
  completedProjects: number;
  acresCompletedActivities: number;
  completedTreatmentActivities: number;
  totalPlannedFunding: number;
  totalActualFunding: number;
  totalFundingMatch: number;
  sources: any[] = [];
  mapGeometry: any;
  etlData: any;
  imageUrl: string;
  dialogRef: any;

  @ViewChild('slimMap') slimMap: SlimMapComponent;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading Users",
      disableClose: true
    });

    this.route.queryParams.subscribe(params => {
      let name = params['name'];
      this.name = name;
      if (name == undefined)
        return;

      this.checkForLastUpdated();

      this.priorityLandscape = this.reportService.getPriorityLandscape();
      this.activeProjects = this.reportService.getActiveProjects();
      this.partners = this.reportService.getPartners();
      this.strategies = this.reportService.getStrategies();
      this.completedProjects = this.reportService.getCompletedProjects();
      this.mapGeometry = this.reportService.getMapGeometry();
      this.associatedProjects = this.reportService.getAssociatedProjects();
    });


    saveRef.close();
  }

  processFundingSources(data: any) {
    this.sources = [];

    this.sources.forEach(function (source) {
      console.log(source);

    });
  }

  ngAfterViewInit() {
    this.dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Generating Map.",
      disableClose: true
    });
    this.setupMoveListener();
    this.slimMap.update(this.mapGeometry);
  }

  async checkForLastUpdated() {

    const resp: IQueryFeaturesResponse = await queryFeatures({
      url: 'https://services.arcgis.com/4fG3YBzBoefRGq66/arcgis/rest/services/focal_public/FeatureServer/0',
      where: `name='${this.name}'`,
      returnGeometry: false,
      outFields: [
        'ct_count',
        'ct_acres',
        'ct_total_funding',
        'ct_total_funding_match'
      ],
      resultRecordCount: 1
    }) as IQueryFeaturesResponse;

    if (resp?.features?.length > 0) {
      this.etlData = resp?.features[0];
      this.totalActualFunding = this.etlData.attributes.ct_total_funding;
      this.totalFundingMatch = this.etlData.attributes.ct_total_funding_match;
      this.acresCompletedActivities = this.etlData.attributes.ct_acres;
      this.completedTreatmentActivities = this.etlData.attributes.ct_count;
    }

  }


  printContent(){
    window.print();
  }

  private setupMapImage() {
    const map = famMap.getMap();

    map.once('rendercomplete', () => {
      const mapCanvas = document.createElement('canvas');
      const size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext('2d');

      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            const transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );


      this.imageUrl = mapCanvas.toDataURL();

      this.dialogRef.close();

    });

    setTimeout(() => { map.renderSync(); }, 500);
  }

  private setupMoveListener() {
    const map = famMap.getMap();
    const that = this;
    map.on('moveend', (e) => {
      console.log('move end');
      that.setupMapImage();
    });
  }

}
