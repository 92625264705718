import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../_shared/services/authorization.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  displayName: string = "Not Logged In";
  menuShown: boolean = false;

  constructor(
    private authorization: AuthorizationService,
    private router: Router
  ) {
    this.authorization.onLogin().subscribe((lo) => {
      this.displayName = lo.getName();
    });
  }

  ngOnInit(): void {
  }

  login(): void {
    this.authorization.authorize().subscribe((login) => {

    })
  }

  logout(): void {
    this.authorization.logout();
  }

  isLoggedIn(): boolean {
    return this.authorization.isLoggedIn();
  }

  getUsername(): string {
    return this.authorization.getLogin().getName() || this.displayName;
  }

  ready(): boolean {
    return true;
  }

  mouseout(): void {
    console.log('mouseout');
    this.menuShown = false;
  }

  onRootRoute() {
    return this.router.url === '/';
  }

  routeToHome() {
    this.router.navigate(['/app/home'])
  }

}
