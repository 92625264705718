import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationService, Configuration } from './configuration.service'

import { AuthorizationService } from './authorization.service'
import { User } from '../models/user.model'
import { Observable, ReplaySubject } from 'rxjs';

export interface ValidationResult<T> {
    validity: Validity,
    result: T
}

export interface Validity { }

export class Valid implements Validity {
    valid: boolean
}

export class Invalid implements Validity {
    invalid: string[] //List of reasons why the request was invalid
}

class EndpointConfig { }

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    endpointMap: Map<string, EndpointConfig> = new Map<string, EndpointConfig>();

    defaultEndpoint: EndpointConfig;

    constructor(private http: HttpClient, private configService: ConfigurationService, private auth: AuthorizationService) {
        this.configService.getConfig().subscribe((data: Configuration) => {
            this.defaultEndpoint = {
                baseUrl: data.apiRoot
            }
        });

        //     this.endpointMap = data.api.endpoints;
        // });
    }

    //Generic wrapper for a post request setting the auth header from the auth service
    post<T>(controller: string, action: string, data: any, withAuth: boolean = true): Observable<T> {

        var drs: ReplaySubject<T> = new ReplaySubject<T>(1);

        this.configService.getConfig().subscribe((config: Configuration) => {
            this.defaultEndpoint = {
                baseUrl: config.apiRoot
            }

            //Grab the endpoint config
            var cfg = this.endpointMap[controller];

            //Default if its not configured
            if (!cfg)
                cfg = this.defaultEndpoint;


            //Put the controller and action on the url
            var url: string = cfg.baseUrl + controller + "/" + action;

            //Make the post
            if (withAuth) {
                var authToken = this.auth.getToken();

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + authToken
                    })
                };
                return this.http.post<T>(url, data, httpOptions).subscribe(res => {
                    drs.next(res);
                }, error => { drs.error(error) });
            }
            else
                return this.http.post<T>(url, data).subscribe(res => {
                    drs.next(res);
                }, error => { drs.error(error) });
        }, error => {
            console.error(error);
        });

        return drs;
    }

    //Generic wrapper for a post request setting the auth header from the auth service
    postGetBlob<T>(controller: string, action: string, data: any, withAuth: boolean = true): Observable<T> {

      var drs: ReplaySubject<T> = new ReplaySubject<T>(1);

      this.configService.getConfig().subscribe((config: Configuration) => {
          this.defaultEndpoint = {
              baseUrl: config.apiRoot
          }

          //Grab the endpoint config
          var cfg = this.endpointMap[controller];

          //Default if its not configured
          if (!cfg)
              cfg = this.defaultEndpoint;


          //Put the controller and action on the url
          var url: string = cfg.baseUrl + controller + "/" + action;

          //Make the post
          if (withAuth) {
              var authToken = this.auth.getToken();

              const httpOptions = {
                  headers: new HttpHeaders({
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + authToken
                  }),
                  responseType: 'blob' as 'json'
              };

            return this.http.post<T>(url, data, httpOptions).subscribe(res => {
                  drs.next(res);
              }, error => { drs.error(error) });
          }
          else
              return this.http.post<T>(url, data).subscribe(res => {
                  drs.next(res);
              }, error => { drs.error(error) });
      }, error => {
          console.error(error);
      });

      return drs;
    }

    get<T>(controller: string, action: string, withAuth: boolean = true) {
        var drs: ReplaySubject<T> = new ReplaySubject<T>(1);

        this.configService.getConfig().subscribe((data: Configuration) => {
            this.defaultEndpoint = {
                baseUrl: data.apiRoot
            }

            //Grab the endpoint config
            var cfg = this.endpointMap[controller];

            //Default if its not configured
            if (!cfg)
                cfg = this.defaultEndpoint;


            //Put the controller and action on the url
            var url: string = cfg.baseUrl + controller + "/" + action;

            //Make the post
            if (withAuth) {
                var authToken = this.auth.getToken();

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + authToken
                    })
                };
                return this.http.get<T>(url, httpOptions).subscribe(res => {
                    drs.next(res);
                }, error => { drs.error(error) });
            }
            else
                return this.http.get<T>(url).subscribe(res => {
                    drs.next(res);
                }, error => { drs.error(error) });
        }, error => {
            console.error(error);
        });

        return drs;
    }

    get_profile() {
        return this.post<User>("user", "GetProfile", null);
    }
}
