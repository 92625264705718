import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-link-add-dialog',
  templateUrl: './link-add-dialog.component.html',
  styleUrls: ['./link-add-dialog.component.scss']
})
export class LinkAddDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LinkAddDialogComponent>,
    private api: ApiService
  ) { }

  linkFormControl!: FormControl;
  linkDescriptionFormControl!: FormControl;

  ngOnInit(): void {

    this.linkFormControl = new FormControl('', [
      Validators.required
    ]);

    this.linkDescriptionFormControl = new FormControl('', [
      Validators.required
    ]);
  }

  onSubmit(): void {
    let linkData = this.linkFormControl.value;
    let linkDescData = this.linkDescriptionFormControl.value;

    let linkObject =
    {
      "linkUrl": linkData,
      "linkDesc": linkDescData
    }

    this.dialogRef.close(linkObject);
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }

  isValid(): boolean {
    if (this.linkFormControl.valid && this.linkDescriptionFormControl.valid) {
      return true;
    } else {
      return false;
    }
  }

}
