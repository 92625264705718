<p mat-dialog-title color="primary"
    style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem; font-weight: bold;"
    class="themePrimaryTextInverse">Add Resource Concern</p>

<div mat-dialog-content style="flex-direction: column; overflow: hidden;">
    <h3>Select resource concerns to add to {{data.name}}</h3>
    <div fxFlex fxLayout="row" fxLayoutGap="0.5rem" class="" fxLayoutAlign="center center" style="width: 100%; overflow: hidden;">
        <mat-form-field fxflex class="example-full-width" style="flex-grow: 1;">
            <input #imp matInput placeholder="Resource Concern" aria-label="Resource Concern" [matAutocomplete]="auto"
                position='below' matAutocompletePosition="below" [formControl]="concernCtrl">
            <mat-autocomplete class="optionhax" #auto="matAutocomplete" position='below'
                style="height: auto; max-height:20rem;" [displayWith]="displayFn" (optionSelected)="addOpt($event)">
                <mat-option
                    style="height: auto; width: 100%; line-height:1.25rem; align-items: flex-start; padding: 0.25rem; border-bottom: 1px solid;"
                    *ngFor="let concern of filteredConcerns | async" [value]="concern">
                    <div fxFill fxFlex fxLayout="column" fxLayoutGap="0.25rem" fxLayoutAlign="center start"
                        style="width: 100%; padding: 0.25rem 0.5rem;">
                        <span style="font-size: 1.25rem; line-height: 2rem; font-weight: bold;">{{concern.value}}</span>
                        <span style="padding-left:0.5rem; font-size: 1rem;"><strong>Group: </strong>{{concern.group}}</span>
                        <span style="padding-left:0.5rem; font-size: 1rem;"><strong>Category: </strong>{{concern.category}}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

    </div>
    <div style="max-height: 20rem; overflow: auto; padding-right: 0.5rem;">
        <mat-grid-list cols="1" rowHeight="6.5rem">
            <mat-grid-tile *ngFor="let concern of selectedOptions">
                <div
                    style="display:flex; flex-direction: column; border: 1px solid; border-radius: 3px; padding: 0.25rem; padding-left: 0.5rem; overflow: hidden; width: 100%; ">
                    <div style="display: flex; font-size: 1rem; line-height: 1.75rem; height: 2rem; text-overflow: ellipsis; white-space: nowrap; align-items: center; margin-bottom: 0.5rem;">
                        <p style="font-size: 1.25rem; line-height: 2rem; margin:auto 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; flex-grow: 1; padding-bottom:0.5rem;">{{concern.value}}</p>
                        <button mat-icon-button class="align-right buttonhax" color="primary" (click)="delete(concern)" style="width: 24px; flex-shrink: 0;">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <span style="padding-left:0.25rem; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><strong>Group:
                        </strong>{{concern.group}}</span>
                    <span style="padding-left:0.25rem; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><strong>Category:
                        </strong>{{concern.category}}</span>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
<div mat-dialog-actions style="width: 100%;">
    <div fxFlex fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <button mat-flat-button color="primary" [mat-dialog-close]="undefined" style="width: 12rem;">CANCEL</button>
        <button mat-flat-button color="accent" [mat-dialog-close]="selectedOptions" [disabled]="!selectedOptions.length" cdkFocusInitial
            style="width: 12rem;">ADD</button>
    </div>
</div>