import { Component } from '@angular/core';
import { ApiService } from './_shared/services/api.service';
import { Configuration, ConfigurationService } from './_shared/services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'New Mexico Shared Stewardship Portal';

  config: Configuration;

  isNotProd = false //environment.production === false;

  constructor(
    private configService: ConfigurationService,
    private api: ApiService
    ) { }
}
