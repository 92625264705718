<div fxFlex="100" fxFill fxLayout="column"
  class="appFullWidth appFullHeight appCardContainer appSolidCard appBottomRounded appGeogContainer"
  fxLayoutGap="0.5rem">
  <div class="appGeoCard appTopCard">
    <app-geo-slot [hasStats]="this.projectGeoService.hasStats | async" [statRow]="this.geoStats.landscape">
    </app-geo-slot>
  </div>
  <div class="appGeoCard">
    <app-geo-slot [hasStats]="this.projectGeoService.hasStats | async" [statRow]="this.geoStats.focal" [isFocal]="true">
    </app-geo-slot>
  </div>
  <div class="appGeoCard">
    <app-geo-slot [hasStats]="this.projectGeoService.hasStats | async" [statRow]="this.geoStats.watershed">
    </app-geo-slot>
  </div>
  <div class="appGeoCard">
    <app-geo-slot [isLastElement]="true" [hasStats]="this.projectGeoService.hasStats | async"
      [statRow]="this.geoStats.ownership"></app-geo-slot>
  </div>
</div>
