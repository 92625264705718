<p mat-dialog-title color="primary" style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
    class="themePrimaryTextInverse">{{ data.title || 'Confirm Action' }}</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center" style="margin:1.5rem 0; width: 100%;">
        <div style="width: 100%;">
            <h2 style="text-overflow: ellipsis; overflow:hidden;">
                {{data.text}}
            </h2>
        </div>

    </div>
</div>
<div mat-dialog-actions style="width: 100%;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <button mat-button color="primary" [mat-dialog-close]="false" style="width: 12rem;">CLOSE</button>
    </div>
</div>