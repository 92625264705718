import { Component, OnInit, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlugmapAPIService } from '../../_shared/services/map/plugmap-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../_shared/services/api.service';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { FixedLengthArray } from '../../_shared/types/fixed-length-array';
import { Subscription, Observable, Subject } from 'rxjs';
import { MapService } from '../../_shared/services/map/map.service';
import { MapCategory } from '../../_shared/models/map/config.model';
import { FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ErrorDialogComponent } from '../../map/error-dialog/error-dialog.component';
import { AuthorizationService } from '../../_shared/services/authorization.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddConcernDialogComponent } from './add-concern-dialog/add-concern-dialog.component';
import { ResourceConcern, allConcerns } from 'src/app/_shared/models/resource-concerns';


declare var ol: any;
declare var famMap: any;
declare var window: any;


@Component({
  selector: 'app-manage-view',
  templateUrl: './manage-view.component.html',
  styleUrls: ['./manage-view.component.scss']
})
export class ManageViewComponent implements OnInit {

  allConcerns: ResourceConcern[] = allConcerns.slice(1, 5);

  getConcern(id) {
    let mapperd = (allConcerns.filter(a => a.id == id))
    if (mapperd.length)
      return mapperd[0];

    return null;
  }

  @Input() mapCenter: FixedLengthArray<[number, number]> = [-111.0937, 34.0489]; // Default to centroid of Arizona

  @Input() defaultZoom: number = 7;

  @Input() maxZoom: number = 22;

  @Input() minZoom: number = 3;

  @Input() defaultExtent: FixedLengthArray<[number, number, number, number]> = [-12880679.740636416, 3571967.4966187417, -12041808.375255927, 4520654.749830856]

  private mapConfigSub: Subscription;

  private mapConfigLoaded = false;

  mapCategories: MapCategory[];

  highlightLayer: any;

  wkt: any;

  public nameFormControl: FormControl;
  private mapDrawSub: Subscription;
  drawnShape: any;
  drawLayer: any;

  public editingName = false;
  public editingBounds = false;





  private assessmentEditOverlay: any;
  assessmentOverlayContainerHeight: number;


  constructor(
    private mapService: MapService, private route: ActivatedRoute,
    private mapAPI: PlugmapAPIService, private auth: AuthorizationService,
    private dialog: MatDialog,
    private api: ApiService, private router: Router) { }

  target: any = { fields: {} };

  ngOnInit(): void {
    this.bootMap();
    this.nameFormControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.minLength(1)
    ]);
    this.mapConfigSub = this.mapService.getSlimMapConfig().subscribe(config => {
      if (config && !this.mapConfigLoaded) {
        // On first map theme load
        this.mapCategories = config;
        this.mapConfigLoaded = true;
        this.mapAPI.setCategories(config);
        this.mapAPI.turnLayerOnByKey("lyr_esri_world_image");
        this.mapService.getServices().subscribe(services => {
          //this.mapAPI.setServices(services);
        });

      } else if (config) {
        // Add any routines that need to listen for other theme changes
      }
    });




    this.route.queryParams.subscribe(params => {
      let id = params["id"];
      if (id == undefined)
        return;

      let iid = parseInt(id);


      const saveRef = this.dialog.open(LoadingDialogComponent, {
        width: '25rem',
        data: "Loading Selected Area of Interest",
        disableClose: true
      });

      this.api.post<any>("Assessment", "Get", [iid]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleLoad(target);
        }
        saveRef.close();
      })
    });
  }

  handleLoad(target) {
    this.target = target;
    if (target.fields && target.fields.Geometry) {
      let obj = { wkt: target.fields.Geometry.wkt, left: 0, bottom: 0 }
      this.wkt = target.fields.Geometry.wkt;
      if (target.fields.Geometry.geoJSON)
        this.addShape(target.fields.Geometry.geoJSON);
      else
        this.addShapeWKT(target.fields.Geometry.wkt);

      this.stopDraw();
      setTimeout(() => { this.mapAPI.fitToWKT(obj) }, 250);
    }

    if (target.fields && target.fields.resourceConcerns) {
      let nrcs = [];
      target.fields.resourceConcerns.map(src => {
        let nrc = this.getConcern(src.id);
        if (nrc)
          nrcs.push(nrc)
      })

      target.fields.resourceConcerns = nrcs;
    }
  }

  epochDateToString(exp: number): string {
    let Exp = new Date(0);
    Exp.setUTCSeconds(exp);
    return (Exp.getMonth() + 1) + "/" + Exp.getDate() + "/" + Exp.getFullYear();
  }

  getField(field: string, element: any) {
    if (!element.fields)
      return;

    return element.fields[field];
  }


  toggleEditName() {
    this.editingName = !this.editingName;
    this.nameFormControl.setValue(this.target.name);
  }


  editing(): boolean {
    return this.editingName || this.editingBounds;
  }

  saveName() {
    if (!this.nameFormControl.valid)
      return;

    let obj = { id: this.target.id, name: this.nameFormControl.value };


    this.update(obj);
  }

  canManage(item: any): boolean {
    let login = this.auth.getLogin()
    let user = login.user;

    if (login.acls.includes("Assessment:ManageAny"))
      return true;


    return item.createdBy.id == user.id;

  }


  update(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Saving Area of Interest.",
      disableClose: true
    });

    this.api.post<any>("Assessment", "Update", obj).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleLoad(res.result)
      saveRef.close();

      this.editingName = false;

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }


  deleteClick() {
    const saveRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25rem',
      data: { title: "Confirm Delete", text: "Are you sure you want to delete " + this.target.name + "?", confirm: "YES", cancel: "NO" },
      disableClose: true
    });

    saveRef.afterClosed().subscribe(res => {


      if (res) {

        const delRef = this.dialog.open(LoadingDialogComponent, {
          width: '25rem',
          data: "Deleting Area of Interest.",
          disableClose: true
        });

        let obj = { id: this.target.id }

        this.api.post<any>("Assessment", "Delete", obj).subscribe(res => {
          if (!res.valid) {
            delRef.close();
            this.dialog.open(ErrorDialogComponent, {
              width: '25rem',
              data: res.messages,
              disableClose: false
            });
            return;
          }

          this.target = res.result;
          delRef.close();

          this.router.navigate(['../all'], { relativeTo: this.route })


        }, err => {
          delRef.close();
          this.dialog.open(ErrorDialogComponent, {
            width: '25rem',
            data: ["API Error occurred", err.message],
            disableClose: false
          });
        })

      }
    })
  }


  deleteConcern(concern) {
    this.target.fields.resourceConcerns = (this.target.fields.resourceConcerns || []).filter(e => e.id != concern.id)

    let obj = { id: this.target.id, name: this.target.name, fields: { resourceConcerns: this.target.fields.resourceConcerns } };
    this.update(obj);
  }

  addConcern() {

    const saveRef = this.dialog.open(AddConcernDialogComponent, {
      width: '125rem',
      data: { previouslySelected: this.target.fields.resourceConcerns || [], name: this.target.name },
      disableClose: true
    });

    saveRef.afterClosed().subscribe(res => {


      if (res && res.length) {
        this.target.fields.resourceConcerns = (this.target.fields.resourceConcerns || []).concat(res).sort((a, b) => a.id - b.id);

        let obj = { id: this.target.id, name: this.target.name, fields: { resourceConcerns: this.target.fields.resourceConcerns } };
        this.update(obj);
      }
    });
  }

  startReporting() {
    this.api.post<any>("Assessment", "StartJob", this.target.id).subscribe(res => {
      console.log(res);
      this.api.post<any>("Assessment", "Get", [this.target.id]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleLoad(target);
        }
      })
    });
  }

  viewReports() {
    // const saveRef = this.dialog.open(ManageReportsDialogComponent, {
    //   width: '125rem',
    //   data: { target: this.target },
    //   disableClose: true
    // });

    // saveRef.afterClosed().subscribe(res => {



    // });
  }



















  addShapeWKT(wkt: any) {
    let reader = new ol.format.WKT();
    let f = reader.readFeature(wkt);

    this.setFeat(f);

  }

  addShape(shape: any) {
    let reader = new ol.format.GeoJSON();
    let f = reader.readFeature(shape);

    this.setFeat(f);
  }

  setFeat(f: any) {

    this.setupHighlightLayer();

    let ext = ol.extent.createEmpty();

    ol.extent.extend(ext, f.getGeometry().getExtent());

    let center = [(ext[0] + ext[2]) / 2, ext[3]];

    this.highlightLayer.getSource().clear();
    this.highlightLayer.getSource().addFeature(f);
  }

  bootMap(): void {
    this.mapAPI.createMap("mgmap", this.mapCenter, this.defaultZoom, this.maxZoom, this.minZoom);
    setTimeout(() => { famMap.getMap().handleResize_() }, 500);

    this.installExtensions();
  }

  setupHighlightLayer() {
    if (this.highlightLayer)
      return;
    this.highlightLayer = new ol.layer.Vector();

    let src = new ol.source.Vector();
    this.highlightLayer.setSource(src);

    let sty = new ol.style.Style({
      fill: new ol.style.Fill({
        color: "rgba(0,77,168,0.8)"
      }),
      stroke: new ol.style.Stroke({
        color: "rgba(0,77,168,0.95)",
        width: 1
      })
    });
    this.highlightLayer.setStyle(sty);

    this.highlightLayer.setZIndex(9999);

    famMap.getMap().addLayer(this.highlightLayer);
  }

  private installExtensions(): void {
    this.mapAPI.initDraw();

    let map = famMap.getMap();

    let zte = new ol.control.ZoomToExtent({
      extent: this.defaultExtent,
    });

    zte.handleClickOld = zte.handleClick_;
    let that = this;

    zte.handleZoomToExtent = function () {

      let obj = { wkt: that.wkt, left: 0, bottom: 0 }

      that.mapAPI.fitToWKT(obj);
    }


    let sl = new ol.control.ScaleLine({ units: 'us' });

    map.controls.extend([zte, sl]);



    let assessmentEditOverlayContainer = document.getElementById('assessmentEditOverlay');
    this.assessmentOverlayContainerHeight = assessmentEditOverlayContainer.offsetHeight;

    let importOverlayContainer = document.getElementById('importOverlay');

    this.assessmentEditOverlay = new ol.Overlay({
      element: assessmentEditOverlayContainer,
      positioning: 'bottom-center',
      autoPan: {
        margin: 20
      }
    });
    map.addOverlay(this.assessmentEditOverlay);

  }

  editBoundsClick() {
    this.editingBounds = true;

    let draw = this.mapAPI.setWKTs([this.target.fields.Geometry.wkt]);


    if (this.mapDrawSub)
      this.mapDrawSub.unsubscribe();

    this.mapDrawSub =
      draw.subscribe(this.handleDrawResult.bind(this));

    this.highlightLayer.setVisible(false);

  }

  populateDrawLayer() {

    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    }

    if (!ml)
      console.error('Issue occured inmanage view'); //We shouldnt get here

    this.drawLayer = ml;
  }

  handleDrawResult(newShape) {

    console.log("Draw Result", newShape);

    //The plugin will emit an event with an empty shape when drawing is started. We handle this by not handling it
    if (!newShape) return;

    this.drawnShape = newShape;

    this.target.fields.featureType = "Custom"

    this.populateDrawLayer();

    if (!this.drawLayer) return; //Something bork

    let feat = this.drawLayer.getSource().getFeatureById(newShape.olUID);

    if (!feat)
      //Something bad happened
      feat = this.drawLayer.getSource().getFeatures()[0];

    if (!feat) {
      return;
    }

    let ext = feat.getGeometry().getExtent();
    let center = ol.extent.getCenter(ext);

    center = [(ext[0] + ext[2]) / 2, ext[3] + 50]

    this.setOverlayPositionAndContents(center);

    //this.fitToExtentAndOverlay(ext);

  }


  setOverlayPositionAndContents(center: number[]) {

    console.log("Set Position", center);

    let overlay = this.assessmentEditOverlay;

    //Set the position
    setTimeout(() => { overlay.setPosition(center); }, 150);

    //Populate contents
    let container = document.getElementById("assessmentEditOverlay");

    //Only append if its not already there
    if (container.children.length == 0) {
      let cnt = document.getElementById("assessmentEditOverlayContents");
      container.appendChild(cnt);
    }
  }

  drawNewAssessmentArea() {


    let dblClickInteraction;
    // find DoubleClickZoom interaction
    famMap.getMap().getInteractions().getArray().forEach(function (interaction) {
      if (interaction instanceof ol.interaction.DoubleClickZoom) {
        dblClickInteraction = interaction;
      }
    });

    // remove from map
    if (dblClickInteraction)
      famMap.getMap().removeInteraction(dblClickInteraction);


    let draw = this.mapAPI.startDrawingPolygon();

    if (!this.mapDrawSub)
      this.mapDrawSub =
        draw.subscribe(this.handleDrawResult.bind(this));
    this.target.fields.featureType = "Custom";
  }

  saveBoundsClick() {

    //This is the desired size of the icon
    let iconSize = [75, 75]




    this.grabSnapshotOfDrawnShape(iconSize).subscribe(icon => {
      let obj = {
        id: this.target.id, name: this.target.name, fields: {
          Geometry: {
            wkt: this.drawnShape.wkt,
            acres: this.drawnShape.acres
          },
          icon: icon
        }
      };

      this.update(obj);
    });
  }

  dismissAssessmentEdit() {
    this.stopDraw();
  }

  stopDraw(): void {
    this.mapAPI.stopDrawing();
    this.drawnShape = undefined;
    this.assessmentEditOverlay.setPosition(undefined);
    this.highlightLayer.setVisible(true);
    this.editingBounds = false;
  }

  grabSnapshotOfDrawnShape(targetSize: number[]): Observable<any> {
    //Show a loading screen when we are getting a screenshot
    const snapshotRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Generating Snapshot",
      disableClose: true
    });

    var ds = new Subject<any>();

    let map = famMap.getMap();
    let origSize = map.getSize();
    let that = this;

    map.setSize(targetSize);

    let ml = undefined;
    let layers = famMap.getMap().getLayers().array_
    for (var i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.get("id") === "lyr_internal_merged") {
        ml = layer;
        break;
      }
    }

    let pudding = [20, 20, 20, 20];
    let ext = [];

    try {
      ext = ml.getSource().getFeatureById(this.drawnShape.olUID).getGeometry().getExtent();
    }
    catch (ex) {
      snapshotRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["Error Generating Snapshot", "An error occured. Please try again. If this happens again, modify the shape slightly and click save."],
        disableClose: true
      });
      throw ex;
    }

    var oldStyle = ml.getStyle();

    // let sty = new ol.style.Style({
    //   fill: new ol.style.Fill({
    //     color: "rgba(0,77,168,0.8)"
    //   }),
    //   stroke: new ol.style.Stroke({
    //     color: "rgba(0,77,168,0.95)",
    //     width: 1
    //   })
    // });
    // ml.setStyle(sty);

    let vw = map.getView();
    vw.fit(ext, {
      size: targetSize,
      nearest: false,
      padding: pudding
    });

    map.once('rendercomplete', function () {
      var mapCanvas = document.createElement('canvas');

      mapCanvas.width = targetSize[0];
      mapCanvas.height = targetSize[1];
      var mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );

      map.setSize(origSize);

      snapshotRef.close();

      setTimeout(() => { ds.next(mapCanvas.toDataURL()); }, 100);

      //ml.setStyle(oldStyle);


      //Uncomment these to download the image generated
      // var link = document.getElementById('image-download');
      // link.setAttribute("href", mapCanvas.toDataURL());
      // link.click();


    });

    //Again there is some weirdness with resizing the map, so manually call renderSync after a delay of like 500ms or so
    setTimeout(() => { map.renderSync(); }, 500);

    return ds.asObservable();
  }

  clearBounds() {
    this.mapAPI.clearShape();
    this.drawnShape = undefined;
  }

  hasShape() {
    return this.drawnShape != undefined;
  }

  onLayerSelect(e) {
    switch (e.value) {
      case "topo":
        this.mapAPI.turnLayerOnByKey("lyr_esri_topo");
        break;
      case "aerial":
        this.mapAPI.turnLayerOnByKey("lyr_esri_world_image");
        break;
      default:
        break;
    }
  }
}
