<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        Are you sure you want to cancel adding this Activity?
      </p>
    </div>

    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="accent" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="routerNavigate()">Yes</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="cancelClicked()"
          target="_blank">No</button>
      </div>
    </div>
  </div>
</div>

