import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../_shared/services/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  displayName : string = "Not Logged In";

  constructor(private authorization : AuthorizationService)
  {
    this.authorization.onLogin().subscribe((lo) => {
      this.displayName = lo.getName();
    });
  }

  ngOnInit(): void {
  }

  login() : void {
    this.authorization.authorize().subscribe((login) =>
    {

    })
  }

  logout() : void {
    this.authorization.logout();
  }

  isLoggedIn() : boolean {
    return this.authorization.isLoggedIn();
  }

  getUsername() : string {
    return this.authorization.getLogin().getName() || this.displayName;
  }
}
