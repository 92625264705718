/* tslint:disable */

// Copied directly from AACD to be used as a template if desired - if we want to use
// mocks for NMSS will need to update.
import { Injector, Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Configuration, ConfigurationService } from './_shared/services/configuration.service'

import { HttpInterceptor, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http"
import { HttpRequest } from "@angular/common/http";

//Configuration service will provide the configuration as a 'singleton' across the app
@Injectable({
    providedIn: 'root'
})
export class MockConfigurationService extends ConfigurationService {
    client: ReplaySubject<Configuration> = new ReplaySubject<Configuration>();

    constructor(private mhttp: HttpClient) {
        super(mhttp);
        console.log("Config Mock Service");
        let cfg = new Configuration();
        cfg.apiRoot = "http://mock.example/"

        this.client.next(cfg);
    }

    getConfig(): Observable<Configuration> {
        return this.client;
    }

}

let overrides =
{

}

let authConfig =
{
    "host": "localhost:4200",
    "clientId": "123456",
    "useHttps": false,
    "clientSecret": "supersecret",
    "tokenEndpoint": "#/mockauth?action=token",
    "logoutEndpoint": "#/mockauth?action=logout",
    "userInfoEndpoint": "#/mockauth?action=userInfo",
    "unauthorizedRedirect": "/",
    "authorizationEndpoint": "#/mockauth?action=authorize",
    "inline": false
}

let fakeLogin =
{
    access_token: "asd",
    refresh_token: "asd",
    id_token: "asd"
}

let generalUserACLs = ["Map:Access", "Home:RedirectToMapAfterLogin", "Map:ViewLegend", "Map:UseSearch"]
let managerACLs = ["Map:Access", "Home:RedirectToMapAfterLogin", "Map:ViewLegend", "Manage:Access"];
let adminACLs = ["Map:DrawAssessmentArea", "Map:UseSearch", "Manage:Access", "Map:Identify", "Dashboard:Access", "Assessment:Reports:Manage", "Assessment:Reports:Delete", "Admin:Access", "Map:Access", "Map:DrawAssessmentArea", "Manage:Access", "Map:ClickToImport", "Assessment:Manage", "Assessment:ManageAny", "AOI:EditName", "AOI:Delete", "AOI:EditBoundary", "AOI:ToggleLayers", "AOI:AssessmentView", "AOI:AssessmentAdd", "AOI:AssessmentDelete", "Dashboard:Access", "Home:RedirectToMapAfterLogin", "Map:Access", "Map:DrawAssessmentArea", "Manage:Access", "Map:ClickToImport", "Assessment:Manage", "AOI:EditName", "AOI:Delete", "AOI:EditBoundary", "AOI:ToggleLayers", "AOI:AssessmentView", "AOI:AssessmentAdd", "AOI:AssessmentDelete", "Dashboard:Access", "Map:ViewLegend", "Map:UseSearch", "Map:Identify", "Map:IdentifyFromMap", "Map:ZoomFromIdentify", "Assessment:Reports:Manage"];

@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {
    users =
        [
            { id: "124", last: "Builder", first: "Alma", email: "alma@thebuilder.com", roles: [] },
            { id: "414", last: "Builder", first: "Dwayne", email: "dwayne@thebuilder.com", roles: ["Assessment Manager"] },
            { id: "624", last: "Builder", first: "Bob", email: "bob@thebuilder.com", roles: ["General User"] },
            { id: "965", last: "Builder", first: "Jane", email: "jane@thebuilder.com", roles: ["Administrator"] },
            { id: "461", last: "Builder", first: "Bryant", email: "jane@thebuilder.com", roles: ["Administrator", "Developer"] }
        ]

    constructor(private injector: Injector) { }

    fakeACLs(request: HttpRequest<any>): Observable<HttpEvent<any>> {

        //Grab the token fromt he header and fake the groups

        let tokenVal = request.headers.get("Authorization");
        if (tokenVal)
            tokenVal = tokenVal.replace("Bearer ", "");

        let token = this.parseJwt(tokenVal);

        let acls: string[] = [];

        switch (token.sub) {
            case "0000": acls = []; break; //No Group
            case "1111": acls = generalUserACLs; break; //General User
            case "2222": acls = managerACLs; break; //Manager
            case "3333": acls = adminACLs; break; //Sys Admin
        }

        return of(new HttpResponse({ status: 200, body: acls }));
    }

    fakeToken(request: HttpRequest<any>): Observable<HttpEvent<any>> {

        //Grab the token fromt he header and fake the groups

        let tokenVal = request.headers.get("Authorization");
        if (tokenVal)
            tokenVal = tokenVal.replace("Bearer ", "");

        let token = this.parseJwt(tokenVal);

        token.exp = new Date().getTime() / 1000 + 300;
        token.iat = new Date().getTime() / 1000;

        token = this.encode_token(token);

        return of(new HttpResponse({ status: 200, body: { access_token: token } }));
    }



    encode_token(value: any) {

        let str = JSON.stringify(value);
        let b64 = "fake." + btoa(str);

        let base64Url = b64.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let atobval = atob(base64);
        let atobmapped = atobval.split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
        let jsonPayload = decodeURIComponent(atobmapped);
        let rv = JSON.parse(jsonPayload);

        return b64
    }

    fakeUsers(request: HttpRequest<any>): Observable<HttpEvent<any>> {

        //Grab the token fromt he header and fake the groups

        let tokenVal = request.headers.get("Authorization");
        if (tokenVal)
            tokenVal = tokenVal.replace("Bearer ", "");

        let token = this.parseJwt(tokenVal);


        switch (token.sub) {
            case "3333": return of(new HttpResponse({ status: 200, body: this.users })); break; //Sys Admin
        }

        return of(new HttpResponse({ status: 401 }));
    }

    manageRoles(request: HttpRequest<any>) {

        let tokenVal = request.headers.get("Authorization");
        if (tokenVal)
            tokenVal = tokenVal.replace("Bearer ", "");

        let token = this.parseJwt(tokenVal);


        switch (token.sub) {
            case "3333":
                {
                    //return of(new HttpResponse({ status: 200, body: this.users })); break; //Sys Admin
                    let manageUser = undefined;
                    for (let usr of this.users) {
                        if (usr.id == request.body.id) {
                            manageUser = usr;
                            break;
                        }
                    }

                    if (!manageUser)
                        return of(new HttpResponse({ status: 401 }));
                    else {
                        let oldRoles = manageUser.roles;

                        manageUser.roles = [];

                        for (let role of oldRoles) {
                            if (!request.body.unassign.includes(role)) {
                                manageUser.roles.push(role)
                            }
                        }

                        for (let role of request.body.assign)
                            manageUser.roles.push(role);

                        return of(new HttpResponse({ status: 200, body: manageUser }));
                    }
                }
        }

        return of(new HttpResponse({ status: 401 }));
    }

    saveAssessment(request: HttpRequest<any>) {

        let tokenVal = request.headers.get("Authorization");
        if (tokenVal)
            tokenVal = tokenVal.replace("Bearer ", "");

        let token = this.parseJwt(tokenVal);


        switch (token.sub) {
            case "1111":
            case "2222":
            case "3333":
                {
                    let newObj = request.body;

                    if (newObj.fields.Geometry.acres > 3000)
                        return of(new HttpResponse({ status: 200, body: { valid: false, result: undefined, messages: ["The provided area is too large."] } }));


                    newObj.id = Math.floor(Math.random() * 10000)
                    return of(new HttpResponse({ status: 200, body: { valid: true, result: newObj } }));

                }; break;
            default: //User isnt authorized to perform that action
                return of(new HttpResponse({ status: 401 }));
        }

        return of(new HttpResponse({ status: 401 }));
    }


    allAssessment =
        {
            7: { id: 7, name: "Test Area", created: 1588258209, fields: {} },
            8: { id: 8, name: "Testing", created: 1588258249, fields: { type: "Copy Pasta" } },
            9: { id: 9, name: "Testing2", created: 1588259209, createdBy: "1", fields: { type: "Copy Pasta" } },
            1: { id: 1, name: "Fun Times", created: 1588259409, fields: { type: "Copy Pasta" } },
            3: { id: 3, name: "Lemmings", created: 1588262209, createdBy: "1", fields: { type: "Copy Pasta" } },
            2: { id: 2, name: "Testing", created: 1588288209, fields: { type: "Copy Pasta" } },
            4: { id: 4, name: "Testing", created: 1588358209, fields: { type: "Copy Pasta" } },
            5: { id: 5, name: "Testing", created: 1588388209, createdBy: "1", fields: { type: "Copy Pasta" } },
            6: { id: 6, name: "Testing", created: 1588488209, fields: { type: "Copy Pasta" } },
            0: { id: 0, name: "Testing", created: 1588958209, fields: { type: "Copy Pasta" } }
        };

    allAssessmentShort =
        [
            { id: 7 },
            { id: 8 },
            { id: 9, createdBy: "1" },
            { id: 1 },
            { id: 3, createdBy: "1" },
            { id: 2 },
            { id: 4 },
            { id: 5, createdBy: "1" },
            { id: 6, },
            { id: 0, }
        ];

    getCards(): any {
        return of(new HttpResponse({
            body: [
                [
                    {
                        "bg": "https://timmons-branding.s3.amazonaws.com/Shared+Images/Curvy+Line+Graph.svg",
                        "id": 2,
                        "link": "https://timmons-group.maps.arcgis.com/apps/MapSeries/index.html?appid=aaa82be74ce74b0b9c0bc5b7e88d1578",
                        "stats": [
                            {
                                "label": "Acres of Vegetation Departure",
                                "value": "43M"
                            }
                        ],
                        "title": "ASSESSMENTS"
                    }
                ],
                [
                    {
                        "bg": "https://timmons-branding.s3.amazonaws.com/Shared+Images/Flat+Bar+Graph.svg",
                        "id": 3,
                        "link": "https://timmons-group.maps.arcgis.com/apps/opsdashboard/index.html#/50522442f5c54a9a930375deb76322a9",
                        "stats": [

                        ],
                        "title": "RESOURCE CONCERNS",
                        "description": "The Resource Concern dashboard is based on data entered into this app."
                    }
                ]
            ]
        }))
    }


    getAssessmentsShort(request: HttpRequest<any>) {

        return of(new HttpResponse({ status: 200, body: { valid: true, result: this.allAssessmentShort } }));
    }

    getAssessments(request: HttpRequest<number[]>) {
        return of(new HttpResponse({ status: 200, body: { valid: true, result: request.body.map(id => { return this.allAssessment[id] }) } }));
    }

    getSearchCandidates(inString: string) {
        const mockCandidates = [
            { name: 'HUC 12', location: { x: -111.1, y: 34.1 }, extent: { xmax: 1.0, xmin: -1.0, ymax: 2.0, ymin: -2.0 }, source: 'HUC' },
            { name: 'Office', location: { x: -111.1, y: 34.1 }, extent: { xmax: 1.0, xmin: -1.0, ymax: 2.0, ymin: -2.0 }, source: 'Field Office' },
        ];
        const fitleredCandidates = mockCandidates.filter(item => {
            return item.name.toLowerCase().includes(inString.toLowerCase());
        })
        return of(new HttpResponse({ status: 200, body: fitleredCandidates }));
    }

    parseJwt(token: string | null): any {
        try {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
        catch {
            return {};
        }
    }

    getBuildInfo(): any {
        return of(new HttpResponse(
            {
                body: {
                    "version": "v0.1.7",
                    "buildNumber": "-",
                    "buildDate": "-"
                }
            }));
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("Intercepting call to " + request.url + " via " + request.method);
        switch (request.method) {
            case "GET":
                switch (request.url) {
                    case "/assets/overrides.json":
                        return of(new HttpResponse({ status: 404 }));
                        break;
                    case "http://localhost:4200/api/app/GetAuthConfig":
                        return of(new HttpResponse({ status: 200, body: authConfig }));
                        break;
                    case "http://localhost:4200/api/app/GetAppConfig":
                        return of(new HttpResponse({ status: 200, body: {} }));
                        break;
                    case "http://localhost:4200/api/app/GetMapConfig":
                        //Inject our mocked value
                        return of(new HttpResponse({ status: 200, body: (require("../assets/map.themes.database.json")) }));
                        break;
                    case "http://localhost:4200/api/app/GetSlimMapConfig":
                        return of(new HttpResponse({ status: 200, body: (require("../assets/map.themes.slim.json")) }));
                        break;
                    case "http://localhost:4200/api/app/GetMapServices":
                        //Inject our mocked value
                        return of(new HttpResponse({ status: 200, body: (require("../assets/map.services.api.json")) }));
                        break;
                    case "http://localhost:4200/api/User/GetACLs":
                        return this.fakeACLs(request);
                    case "http://localhost:4200/api/Users/GetAll":
                        return this.fakeUsers(request);
                    case "http://localhost:4200/api/Assessment/GetAllShort":
                        return this.getAssessmentsShort(request);
                    case "http://localhost:4200/api/dashboard/GetCards":
                        return this.getCards();
                    case "/buildInfo.json":
                        return this.getBuildInfo();

                }
                break;
            case "POST":
                switch (request.url) {
                    case "http://localhost:4200/#/mockauth?action=token":
                        return this.fakeToken(request);
                        break;
                    case "http://localhost:4200/api/Users/ManageRoles":
                        return this.manageRoles(request);
                        break;
                    case "http://localhost:4200/api/Assessment/Create":
                        return this.saveAssessment(request);
                        break;
                    case "http://localhost:4200/api/LayerSearch/GetCandidates":
                        return this.getSearchCandidates(request.body.searchString);
                        break;
                    case "http://localhost:4200/api/Assessment/Get":
                        return this.getAssessments(request);
                }

                break;
        }
        if (request.method === "GET" && request.url === "http://localhost:4200/users") {
            return of(new HttpResponse({ status: 200, body: { "test": "todo" } }));
        }

        console.log("Call fell through");
        return next.handle(request);
    }

}

@Injectable()
export class PassthroughInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
    }

}
