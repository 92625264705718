import { Directive, Input, ElementRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { AuthorizationService } from './_shared/services/authorization.service';

@Directive({
  selector: '[appRequireACL]'
})
export class RequireACLDirective implements OnInit {
  ngOnInit(): void {
    if (this.appRequireACL != undefined) {
      if (!this.auth.isLoggedIn())
        this.el.nativeElement.classList.add("hide");

      this.auth.onLogin().subscribe(login => {

        if (login.acls && login.acls.includes(this.appRequireACL))
          this.el.nativeElement.classList.remove("hide");
        else {
          this.el.nativeElement.classList.add("hide");
        }
      })
    }
  }

  @Input('appRequireACL') appRequireACL: string | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}


@Directive({
  selector: '[appRequireOrg]'
})
export class RequireOrgDirective implements OnInit {
  ngOnInit(): void {
    if (this.appRequireOrg != undefined) {
      if (!this.auth.isLoggedIn())
        this.el.nativeElement.classList.add("hide");

      this.auth.onLogin().subscribe(login => {

        if (this.appRequireOrg && login.orgs.organization && login.orgs.organization !== 'None - General Public')
          this.el.nativeElement.classList.remove("hide");
        else {
          this.el.nativeElement.classList.add("hide");
        }
      })
    }
  }

  @Input('appRequireOrg') appRequireOrg: boolean | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}


@Directive({
  selector: '[appRequireSameOrg]'
})
export class RequireSameOrgDirective implements OnInit {
  ngOnInit(): void {
    if (this.leadOrg != undefined) {
      if (!this.auth.isLoggedIn())
        this.el.nativeElement.classList.add("hide");

      this.auth.onLogin().subscribe(login => {

        if (this.leadOrg && login.orgs.organization && login.orgs.organization === this.leadOrg)
          this.el.nativeElement.classList.remove("hide");
        else {
          this.el.nativeElement.classList.add("hide");
        }
      })
    }
  }

  @Input('appRequireSameOrg') leadOrg: string | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}


@Directive({
  selector: '[appRequireACLAndSameOrg]'
})
export class RequireACLAndSameOrgDirective implements OnInit, OnChanges {
  ngOnInit(): void {
    this.check();
  }

  ngOnChanges(change: SimpleChanges) {
    this.check();
  }

  private check() {
    if (this.appRequireACLAndOrg != undefined) {
      if (!this.auth.isLoggedIn())
        this.el.nativeElement.classList.add("hide");

      this.auth.onLogin().subscribe(login => {

        if (
          this.appRequireACLAndOrg &&
          this.appRequireACLAndOrg.length === 2 &&
          login.acls &&
          login.acls.includes(this.appRequireACLAndOrg[0]) &&
          login.orgs.organization &&
          login.orgs.organization === this.appRequireACLAndOrg[1]
        )
          this.el.nativeElement.classList.remove("hide");
        else {
          this.el.nativeElement.classList.add("hide");
        }
      })
    }
  }

  @Input('appRequireACLAndSameOrg') appRequireACLAndOrg: string[] | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}



@Directive({
  selector: '[appRequireAclEapoPartner]'
})
export class RequireAclEapoPartner implements OnInit, OnChanges {
  ngOnInit(): void {
    this.check();
  }

  ngOnChanges(change: SimpleChanges) {
    this.check();
  }

  private check() {
    if (this.appRequireAclEapoPartner != undefined) {
      if (!this.auth.isLoggedIn())
        this.el.nativeElement.classList.add("hide");

      this.auth.onLogin().subscribe(login => {
        let partners = [];
        if (this.appRequireAclEapoPartner.length >= 3 && this.appRequireAclEapoPartner[2]) {
          partners = this.appRequireAclEapoPartner[2].map((data) => {
            return data.name;
          });
        }

        if (
          this.appRequireAclEapoPartner &&
          this.appRequireAclEapoPartner.length === 3 &&
          login.acls &&
          login.acls.includes(this.appRequireAclEapoPartner[0]) &&
          (
            login.orgs.organization &&
            (login.orgs.organization === this.appRequireAclEapoPartner[1] ||
              partners.includes(login.orgs.organization))
          )
        ) {
          this.el.nativeElement.classList.remove("hide");
        } else {
          this.el.nativeElement.classList.add("hide");
        }
      })
    }
  }

  @Input('appRequireAclEapoPartner') appRequireAclEapoPartner: any[] | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}



@Directive({
  selector: '[appRequireAnyACL]'
})
export class RequireAnyACLDirective implements OnInit {
  ngOnInit(): void {
    if (this.appRequireACL != undefined && this.appRequireACL.length > 0) {
      if (!this.auth.isLoggedIn()) {
        this.el.nativeElement.classList.add("hide");
      }

      this.auth.onLogin().subscribe(login => {
        for (let acl of this.appRequireACL) {
          if (login.acls && login.acls.includes(acl)) {
            this.el.nativeElement.classList.remove("hide");
            return;
          }
        }
        this.el.nativeElement.classList.add("hide");
      })
    }
  }

  @Input('appRequireAnyACL') appRequireACL: string[] | undefined;

  constructor(private auth: AuthorizationService, private el: ElementRef) {
  }

}
