import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { Card } from 'src/app/_shared/models/card.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { DashboardService } from 'src/app/_shared/services/dashboard.service';
import { IQueryFeaturesResponse, queryFeatures } from '@esri/arcgis-rest-feature-layer';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent implements OnInit {

  public target: Card = undefined;

  public frameUrl: SafeResourceUrl;

  constructor(private dialog: MatDialog, private dashboardService: DashboardService, private route: ActivatedRoute,
    private api: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      let id = params["id"];

      if (id == undefined)
        return;

      let iid = parseInt(id);


      var saveRef = this.dialog.open(LoadingDialogComponent, {
        width: '25rem',
        data: "Loading Selected Card",
        disableClose: false
      });

      this.dashboardService.getCardById(iid).subscribe(result => {
        if (result) {
          this.target = result;
        }
        setTimeout(() => { saveRef.close(); }, 500);

        // Set new iframe URL
        this.frameUrl = this.getUrl(this.target);

        this.checkForLastUpdated();
      })
    });
  }

  getUrl(target): SafeResourceUrl {
    console.log(target.link);
    return this.sanitizer.bypassSecurityTrustResourceUrl(target.link);
  }

  async checkForLastUpdated() {
    if (this.target?.lastModifiedField && this.target?.lastModifiedUrl) {
      const resp: IQueryFeaturesResponse = await queryFeatures({
        url: this.target?.lastModifiedUrl,
        where: `${this.target?.lastModifiedField} IS NOT NULL`,
        returnGeometry: false,
        outFields: [this.target?.lastModifiedField],
        resultRecordCount: 1
      }) as IQueryFeaturesResponse;

       if (resp?.features?.length > 0) {
        const dateData: Date = resp?.features[0]?.attributes?.[this.target?.lastModifiedField] || null
        this.target.lastmodified = dateData;
       }
    }
  }

}
