import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-update-org',
  templateUrl: './update-org.component.html',
  styleUrls: ['./update-org.component.scss']
})
export class UpdateOrgComponent implements OnInit {

  orgs: any[];
  email: string;
  selectedOrg: string;

  constructor(
    private dialogRef: MatDialogRef<UpdateOrgComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.email = this.data.email;

    this.api.get<any[]>("Organization", "GetAll", true).subscribe(res => {
      this.orgs = res;

      this.orgs.sort((a, b) => (a.name > b.name) ? 1 : -1);

      this.selectedOrg = this.orgs.find(item => {
        return item.name === this.data.organization;
      });
      console.log(this.selectedOrg);
    });
  }


  orgFormControl: FormControl = new FormControl({ value: null, disabled: false }, Validators.required);

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid(){
    return true
  }

  onSubmit(){

    let updatedOrg = this.orgFormControl.value;
    let email = this.email;

    let userObject =
    {
      "email": email,
      "organization": updatedOrg
    }

    this.dialogRef.close(userObject);

  }
}
