<div class="appScroller">

  <div class="appMainContainer">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxFlex=25 fxLayoutAlign="top left"
      style="margin:1.5rem 0; width: 100%; margin-right: 1.5rem;">

      <div fxFlex="94" fxLayout="row" fxLayoutGap="1em" >
        <mat-card class="appSolidCard mat-elevation-z0" style="padding: 1rem!important;" [style.overflow]="'auto'">
          <h1 class="appMainCardHeader">Filters</h1>
          <div class="appFilterHeaderContainer"></div>
          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select name="projType" #type [formControl]="typeDropdownFormControl" multiple>
                <mat-option *ngFor="let type of types" [value]="type.name">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Organization</mat-label>
              <mat-select name="projOrg" #org [formControl]="orgDropdownFormControl" multiple>
                <mat-option *ngFor="let org of orgs" [value]="org.name">
                  {{org.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select name="projStatus" #status [formControl]="statusDropdownFormControl" multiple>
                <mat-option *ngFor="let status of statuses" [value]="status.name">
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Focal Area</mat-label>
              <mat-select name="projFocal" #focal [formControl]="focalDropdownFormControl" multiple>
                <mat-option *ngFor="let focal of focals" [value]="focal.name">
                  {{focal.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Priority Landscape</mat-label>
              <mat-select name="projPriority" #priority [formControl]="priorityDropdownFormControl" multiple>
                <mat-option *ngFor="let priority of priorities" [value]="priority.name">
                  {{priority.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Activity Manager</mat-label>
              <mat-select name="projActivityManager" #activitymanager [formControl]="activityManagerDropdownFormControl" multiple>
                <mat-option *ngFor="let activityManager of activityManagers" [value]="activityManager.name">
                  {{activityManager.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFill appearance="outline">
              <mat-label>Activity Completion Date</mat-label>
              <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                <input [formControl]="activityCompletionDateStartControl" matStartDate placeholder="Start date">
                <input [formControl]="activityCompletionDateEndControl" matEndDate placeholder="End date">
              </mat-date-range-input>              
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div style="text-align:center">
            <div>
              <button style="margin-bottom: 1rem" mat-flat-button color="accent" class="appConfirmButton appSquareButton"
              (click)="applyFilter()">Apply</button>

              <button mat-flat-button color="warn" class="appConfirmButton appSquareButton"
                (click)="clearFilter()">Clear</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem"  fxLayoutAlign="top left"
    style="margin:1.5rem 0; width: 100%;">

    <mat-card class="appSolidCard mat-elevation-z0" style="margin-bottom: 1rem; padding-top: 0 !important;">

      <div class="appTableSpacing">

        <div>
          <div fxFlex=80>
            <h1 class="appMainCardHeader">Projects</h1>
          </div>
          <div fxFlex=5>
            <img alt="Info tooltip" src="/assets/images/info-icon.png" style="margin-left: 0.5rem;" (click)="openDialog()"
            class="appSecondaryIcon appCustomTooltip">
          </div>
          <div fxFlex=20>
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="exportFormat" #group="matButtonToggleGroup" (change)="onToggle(group.value)">
            <mat-button-toggle value="excel">Excel</mat-button-toggle>
            <mat-button-toggle value="shapefile">ShapeFile</mat-button-toggle>            
          </mat-button-toggle-group>
        </div>
          <div fxFlex=20>
            <button mat-button mat-flat-button color="accent" class="appConfirmButton appSquareButton appExportButton" (click)="export()">Export</button>
          </div>
        </div>
        <div name="userGrid" class="mat-elevation-z0 appSolidTable" style="font-size: 0.9rem;">
          <table aria-describedby="Projects table" mat-table [dataSource]="dataSource" matSort style="width: 100%">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th id="appIdHeader" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let project"> {{project.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th id="appNameHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let project"> {{project.name}} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th id="appTypeHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
              <td mat-cell *matCellDef="let project"> {{project.type}} </td>
            </ng-container>

            <!-- Lead Organization Column -->
            <ng-container matColumnDef="leadOrg">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Coordinating Organization </th>
              <td mat-cell *matCellDef="let project"> {{project.leadOrg}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let project"> {{project.status}} </td>
            </ng-container>

            <!-- Last Modified Column -->
            <ng-container matColumnDef="modified">
              <th id="appIdHeader" mat-header-cell *matHeaderCellDef mat-sort-header> Last Modified </th>
              <td mat-cell *matCellDef="let project"> {{project.modified | date: 'MM/dd/yyyy' }} </td>
            </ng-container>

            <!-- View Project Details Link Column -->
            <ng-container matColumnDef="view">
              <th id="appStatusHeader" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let project" class="appClickable" (click)="viewSummary(project.id)">Details</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="appEmptyTable" *ngIf="!this.projects?.length">
          <body>No results match the selected criteria</body>
        </div>

      </div>

    </mat-card>

    </div>
  </div>

</div>