import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ManageRolesComponent } from './admin/users/manage-roles/manage-roles.component';
import { UsersComponent } from './admin/users/users.component';
import { AuthorizingComponent } from './authorizing/authorizing.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AllCardsViewComponent } from './dashboard/all-cards-view/all-cards-view.component';
import { CardViewComponent } from './dashboard/card-view/card-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InfoDialogComponent } from './dashboard/info-dialog/info-dialog.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { LogoutComponent } from './logout/logout.component';
import { MainComponent } from './main/main.component';
import { ManageAllComponent } from './manage/manage-all/manage-all.component';
import { AddConcernDialogComponent } from './manage/manage-view/add-concern-dialog/add-concern-dialog.component';
import { ManageViewComponent } from './manage/manage-view/manage-view.component';
import { ManageComponent } from './manage/manage.component';
import { ErrorDialogComponent } from './map/error-dialog/error-dialog.component';
import { GeoSearchComponent } from './map/geo-search/geo-search.component';
import { MapComponent } from './map/map.component';
import { SaveDialogComponent } from './map/save-dialog/save-dialog.component';
import { SavedDialogComponent } from './map/saved-dialog/saved-dialog.component';
import { CategoryContainerComponent } from './map/sidebar/category-container/category-container.component';
import { LayerIdentifyComponent } from './map/sidebar/layer-identify/layer-identify.component';
import { LayerLegendComponent } from './map/sidebar/layer-legend/layer-legend.component';
import { LayerListItemComponent } from './map/sidebar/layer-list-item/layer-list-item.component';
import { SidebarContentComponent } from './map/sidebar/sidebar-content/sidebar-content.component';
import { MockauthComponent } from './mockauth/mockauth.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PanelComponent } from './panel/panel.component';
import { PortalComponent } from './portal/portal.component';
import { RegisterComponent } from './register/register.component';
import { RequireACLDirective, RequireAnyACLDirective, RequireOrgDirective, RequireSameOrgDirective, RequireACLAndSameOrgDirective, RequireAclEapoPartner } from './require-acl.directive';
import { SharedNavComponent } from './shared-nav/shared-nav.component';
import { SlimLoginComponent } from './slim-login/slim-login.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { VersionComponent } from './version/version.component';
import { AppRoutingModule, isMock } from './routing.config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.config';
import { MockBackendInterceptor, PassthroughInterceptor } from './mocks';
import { DynamicFormComponent } from './_shared/components/forms/dynamic-form/dynamic-form.component';
import { DynamicFormFieldComponent } from './_shared/components/forms/dynamic-form-field/dynamic-form-field.component';
import { TestComponent } from './test/test.component';
import { HomeMainComponent } from './home-main/home-main.component';
import { ProjectComponent } from './project/project.component';
import { SlimMapComponent } from './slim-map/slim-map.component';
import { ProjectViewComponent } from './project/project-view/project-view.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { ProjectInfoDialogComponent } from './project-info-dialog/project-info-dialog.component';
import { ProjectFilterDialogComponent } from './project-filter-dialog/project-filter-dialog.component';
import { RegisterErrorDialogComponent } from './register/register-error-dialog/register-error-dialog.component';
import { ActivityComponent } from './activity/activity.component';
import { ActivityCreateComponent } from './activity/activity-create/activity-create.component';
import { ActivityViewComponent } from './activity/activity-view/activity-view.component';
import { ActivityCreateDialogComponent } from './activity/activity-create-dialog/activity-create-dialog.component';
import { EditButtonClusterComponent } from './_shared/components/edit-button-cluster/edit-button-cluster.component';
import { PartnerComponent } from './partner/partner.component';
import { PartnerAddDialogComponent } from './partner/partner-add-dialog/partner-add-dialog.component';
import { MapEditDialogComponent } from './_shared/components/dialogs/map-edit-dialog/map-edit-dialog.component';
import { ActivityCardComponent } from './activity/activity-card/activity-card.component';
import { ActivityCancelDialogComponent } from './activity/activity-create/activity-cancel-dialog/activity-cancel-dialog.component';
import { FundingComponent } from './funding/funding.component';
import { FundingAddDialogComponent } from './funding/funding-add-dialog/funding-add-dialog.component';
import { ProjectGeoCardComponent } from './project/project-geo-card/project-geo-card.component';
import { GeoSlotComponent } from './project/project-geo-card/geo-slot/geo-slot.component';
import { DecimalPipe } from '@angular/common';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { ImpedimentComponent } from './impediment/impediment.component';
import { NoteCreateDialogComponent } from './note-create-dialog/note-create-dialog.component';
import { ProjectFundingComponent } from './project/project-funding/project-funding.component';
import { ProjectFundingSourceComponent } from './project/project-funding-source/project-funding-source.component';
import { FocalComponent } from './focal/focal.component';
import { FocalViewComponent } from './focal/focal-view/focal-view.component';
import { GenericCardComponent } from './generic-card/generic-card.component';
import { LinkComponent } from './link/link.component';
import { LinkAddDialogComponent } from './link/link-add-dialog/link-add-dialog.component';
import { ImportComponent } from './import/import.component';
import { ImportDialogComponent } from './import/import-dialog/import-dialog.component';
import { ProjectFilterComponent } from './project-filter/project-filter.component';
import { LayerExplorerComponent } from './map/sidebar/layer-explorer/layer-explorer.component';
import { LayerExplorerContainerComponent } from './map/sidebar/layer-explorer-container/layer-explorer-container.component';
import { FindSomethingComponent } from './_shared/components/dialogs/find-something/find-something.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { OrgEditComponent } from './admin/organizations/org-edit/org-edit.component';
import { OrgUsersComponent } from './admin/organizations/org-users/org-users.component';
import { UpdateOrgComponent } from './admin/organizations/update-org/update-org.component';
import { AddOrgComponent } from './admin/organizations/add-org/add-org.component';
import { DeleteOrgComponent } from './admin/organizations/delete-org/delete-org.component';
import { ArchiveErrorDialogComponent } from './admin/organizations/org-edit/archive-error-dialog/archive-error-dialog.component';
import { UpdateErrorDialogComponent } from './admin/organizations/org-users/update-error-dialog/update-error-dialog.component';
import { MinMaxDirective } from './_shared/directives/min-max.directive';
import { DecimalCapDirective } from './_shared/directives/decimal-cap.directive';
import { FocalReportComponent } from './focal/focal-report/focal-report.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PanelComponent,
    HomeComponent,
    NotFoundComponent,
    RegisterComponent,
    MapComponent,
    HeaderComponent,
    SlimLoginComponent,
    MockauthComponent,
    VersionComponent,
    AdminComponent,
    UsersComponent,
    PortalComponent,
    RequireACLDirective,
    RequireOrgDirective,
    RequireACLAndSameOrgDirective,
    RequireSameOrgDirective,
    RequireAnyACLDirective,
    DecimalCapDirective,
    RequireAclEapoPartner,
    MinMaxDirective,
    SharedNavComponent,
    ManageRolesComponent,
    UserMenuComponent,
    LogoutComponent,
    SidebarContentComponent,
    CategoryContainerComponent,
    LayerListItemComponent,
    LayerLegendComponent,
    AuthorizingComponent,
    GeoSearchComponent,
    SaveDialogComponent,
    ErrorDialogComponent,
    LoadingDialogComponent,
    SavedDialogComponent,
    ManageComponent,
    MainComponent,
    ManageViewComponent,
    ManageAllComponent,
    ConfirmDialogComponent,
    LayerIdentifyComponent,
    AddConcernDialogComponent,
    DashboardComponent,
    InfoDialogComponent,
    CardViewComponent,
    AllCardsViewComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    TestComponent,
    HomeMainComponent,
    ProjectComponent,
    SlimMapComponent,
    ProjectViewComponent,
    ProjectCreateComponent,
    ContactDialogComponent,
    ProjectInfoDialogComponent,
    ProjectFilterDialogComponent,
    RegisterErrorDialogComponent,
    ActivityComponent,
    ActivityCreateComponent,
    ActivityViewComponent,
    ActivityCreateDialogComponent,
    EditButtonClusterComponent,
    PartnerComponent,
    PartnerAddDialogComponent,
    MapEditDialogComponent,
    ActivityCardComponent,
    ActivityCancelDialogComponent,
    FundingComponent,
    FundingAddDialogComponent,
    ProjectGeoCardComponent,
    GeoSlotComponent,
    OpportunityComponent,
    ImpedimentComponent,
    NoteCreateDialogComponent,
    ProjectFundingComponent,
    ProjectFundingSourceComponent,
    FocalComponent,
    FocalViewComponent,
    GenericCardComponent,
    LinkComponent,
    LinkAddDialogComponent,
    ImportComponent,
    ImportDialogComponent,
    ProjectFilterComponent,
    LayerExplorerComponent,
    LayerExplorerContainerComponent,
    FindSomethingComponent,
    OrganizationsComponent,
    OrgEditComponent,
    OrgUsersComponent,
    UpdateOrgComponent,
    AddOrgComponent,
    DeleteOrgComponent,
    ArchiveErrorDialogComponent,
    UpdateErrorDialogComponent,
    FocalReportComponent,
    ForgotPasswordComponent
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: isMock ? MockBackendInterceptor : PassthroughInterceptor,
      multi: true
    },
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
