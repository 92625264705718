import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { DirectiveLogic } from './directive-logic';

@Directive({
  selector: '[decimalCapDirective]'
})
export class DecimalCapDirective {

  @Input()
  public decimalCap: number;

  constructor(private ref: ElementRef) { }

  @HostListener('input', [ '$event' ])
  public onInput(a_Event: InputEvent): void {
    if (this.ref.nativeElement?.value && this.ref.nativeElement.value !== '')
      this.ref.nativeElement.value = DirectiveLogic.DecimalCap(this.ref.nativeElement.value, this.decimalCap);
    else
      this.ref.nativeElement.value = null;
  }

}