import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public assessmentArea: any) {}

  nameFormControl: FormControl;


  ngOnInit(): void {
    this.nameFormControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.minLength(1)
    ]);
  }

  isValid() : boolean {
    return this.nameFormControl.valid;
  }

  onYesClick(): void {
    this.assessmentArea.name = this.nameFormControl.value;
    this.dialogRef.close(this.assessmentArea);
  }


  onNoClick(): void {
    this.dialogRef.close();
    console.log(this.assessmentArea)
  }

}
