<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <span>Update Role</span>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
        style="margin:1.5rem 0; width: 100%;">
        <div style="width: 100%;">
            <p style="font-size: 1.2rem; line-height: 1.5rem; text-overflow: ellipsis; overflow:hidden;">{{user.email}}
            </p>
        </div>
        <mat-radio-group aria-label="Select an option" style="padding: 0.25rem 0" name="selectedRole" [(ngModel)]="selectedRole" fxFlex
            fxLayout="column" fxLayoutGap="0.5rem">
            <mat-radio-button #roleSelect class="example-radio-button" *ngFor="let role of allRoles" [value]="role.name">
                {{role.name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>

<div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
      style="margin-right: 1rem;" (click)="onNoClick()">Cancel</button>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <button mat-flat-button color="accent" class="appConfirmButton appSquareButton appSkinnyFontButton"
      [mat-dialog-close]="selectedRole" cdkFocusInitial>Assign</button>
  </div>
</div>
