<div *ngIf="(!this.hasResults || this.isFetching()) && this.hasCoreData" fxFlex fxLayout="column"
  fxAlign="center center" fxLayoutGap="1.5rem" class="appCatContainer">
  <div style="width: 100%; display: flex; flex-direction: column" class="mat-elevation-z0 epansion-panel-like">
    <p style="text-align: center">
      Searching...
    </p>
    <div style="margin:auto">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<div *ngIf="!this.hasCoreData && !this.isFetching()" class="appCatContainer appCatContainerEmpty">
  Click on a Focal Area, Project or Activity shape on the map to populate the Explorer.
</div>

<div *ngIf="this.hasResults && !this.isFetching() && this.hasCoreData && this.featureType === 'lyr_exp_project'" fxFlex
  fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="appCatContainer">

  <!-- Summary -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #summpan hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Summary
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!summpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="summpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="appInteriorContents">

          <!-- ID -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Project ID: </span>
            <span class="appFieldContent appExpContent">{{ this.vm?.id || '' }}</span>
          </div>

          <!-- Type -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Project Type: </span>
            <span class="appFieldContent appExpContent">{{ this.vm?.fields?.projectType || '' }}</span>
          </div>

          <!-- Status -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Project Status: </span>
            <span class="appFieldContent appExpContent">{{ this.vm?.fields?.projectStatus || '' }}</span>
          </div>

          <!-- Lead Org -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Coordinating Organization: </span>
            <span class="appFieldContent appExpContent">{{ this.vm?.fields?.leadOrganization || '' }}</span>
          </div>

          <!-- Link -->
          <div class="appExpFieldContainer">
            <p class="appGenCardLink" (click)="this.linkTo('app/project/view', {id: this.vm?.id})">Go to Details</p>
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

  <!-- Project Geography -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #geogpan hideToggle="true" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Project Geography
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!geogpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="geogpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div>

          <mat-expansion-panel #prio hideToggle="true" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="appCatName">
                Priority Landscape(s)
              </mat-panel-title>
              <span>
                <mat-icon class="appExpExpandIcon" *ngIf="!prio.expanded">add_circle</mat-icon>
              </span>
              <span>
                <mat-icon class="appExpExpandIcon" *ngIf="prio.expanded">remove_circle</mat-icon>
              </span>
            </mat-expansion-panel-header>
            <div class="appInteriorContents">

              <div *ngIf="geogVm?.fields?.stats?.data?.landscapes?.stats?.name">
                <div [style.max-width]="'17.5rem'" *ngFor="let item of geogVm?.fields?.stats?.data?.landscapes?.stats?.name | keyvalue">
                    <span
                      class="appFieldContent appExpContent">{{ item.key !== 'Non-Priority' ? item.key : 'Outside of Priority Landscapes' }}</span><span
                      class="appExpIcon appIconZoomSolo">
                      <mat-icon *ngIf="item.key !== 'Non-Priority'" [matTooltip]="'Zoom to Feature'"
                        (click)="this.requestFeatureExtent(this.layerKeys.prioLand, item.key)">zoom_in</mat-icon>
                    </span>
                </div>
              </div>
              <div *ngIf="!geogVm?.fields?.stats?.data?.landscapes?.stats?.name">
                <span class="appFieldContent appExpContent">Pending Calculation</span>
              </div>

            </div>
          </mat-expansion-panel>

          <mat-expansion-panel #water hideToggle="true" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="appCatName">
                Top 500 Watershed(s)
              </mat-panel-title>
              <div>
                <span>
                  <mat-icon class="appExpExpandIcon" *ngIf="!water.expanded">add_circle</mat-icon>
                </span>
                <span>
                  <mat-icon class="appExpExpandIcon" *ngIf="water.expanded">remove_circle</mat-icon>
                </span>
              </div>
            </mat-expansion-panel-header>
            <div class="appInteriorContents">

              <div *ngIf="geogVm?.fields?.stats?.data?.watersheds?.stats?.name">
                <div [style.max-width]="'17.5rem'" class="appSimpleListItem" *ngFor="let item of geogVm?.fields?.stats?.data?.watersheds?.stats?.name | keyvalue">
                  <span class="appFieldContent appExpContent">{{item.key}}</span><span class="appExpIcon appIconZoomSolo">
                    <mat-icon [matTooltip]="'Zoom to Feature'"
                      (click)="this.requestFeatureExtent(this.layerKeys.watershed, item.key)">zoom_in</mat-icon>
                  </span>
                </div>
              </div>
              <div class="appSimpleListItem" *ngIf="!geogVm?.fields?.stats?.data?.watersheds?.stats?.name">
                <span class="appFieldContent appExpContent">Pending Calculation</span>
              </div>

            </div>
          </mat-expansion-panel>

          <mat-expansion-panel #focal hideToggle="true" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="appCatName">
                Focal Area(s)
              </mat-panel-title>
              <span>
                <mat-icon class="appExpExpandIcon" *ngIf="!focal.expanded">add_circle</mat-icon>
              </span>
              <span>
                <mat-icon class="appExpExpandIcon" *ngIf="focal.expanded">remove_circle</mat-icon>
              </span>
            </mat-expansion-panel-header>
            <div class="appInteriorContents">

              <div *ngIf="geogVm?.fields?.stats?.data?.focal?.stats?.name">
                <div [style.max-width]="'13.5rem'" class="appSimpleListItem" *ngFor="let item of geogVm?.fields?.stats?.data?.focal?.stats?.name | keyvalue">
                  <span
                    class="appFieldContent appExpContent">{{item.key !== 'Non-Focal Area' ? item.key : 'Outside of Focal Areas'}}</span>
                  <span *ngIf="item.key !== 'Non-Focal Area'" class="appExpIcon appIconZoom">
                    <mat-icon [matTooltip]="'Zoom to Feature'"
                      (click)="this.requestFeatureExtent(this.layerKeys.focal, item.key)">zoom_in</mat-icon>
                  </span>
                  <span class="appExpIcon appIconExplorer" *ngIf="item.key !== 'Non-Focal Area'">
                    <mat-icon [matTooltip]="'View in Explorer'" (click)="this.linkToFocal(item.key)">
                      format_list_bulleted</mat-icon>
                  </span>
                  <span *ngIf="item.key !== 'Non-Focal Area'" class="appExpIcon appIconDetail"
                    (click)="this.linkTo('app/focal/view', {name: item.key})">
                    <mat-icon [matTooltip]="'Go to Details'">library_books</mat-icon>
                  </span>
                </div>
              </div>
              <div *ngIf="!geogVm?.fields?.stats?.data?.focal?.stats?.name">
                <span class="appFieldContent appExpContent">Pending Calculation</span>
              </div>

            </div>
          </mat-expansion-panel>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

  <!-- Activtiies -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #actpan hideToggle="true" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Activities
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!actpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="actpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div>

          <div *ngIf="this.actVm.length > 0">
            <app-generic-card *ngFor="let act of this.actVm"
              [data]="{header: act?.name, subTextTitle: 'Activity Type', subTextValue: act?.activityType, linkUrl: 'app/activity/view', linkId: act?.id, zoomAction: true, subTextTitle2: 'Activity Status', subTextValue2: act?.activityStatus, detailsIcon: true, projectId: this.vm.id, leadOrg: this.vm?.fields?.leadOrganization }"
              (zoom)="this.requestFeatureExtent(this.layerKeys.activity, act?.id)">
            </app-generic-card>
          </div>

          <div class="appExpHelperMessage" *ngIf="this.actVm.length <= 0">
            Project has no mapped Activities
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

</div>

<div *ngIf="this.hasResults && !this.isFetching() && this.hasCoreData && this.featureType === 'lyr_exp_focal'" fxFlex
  fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="appCatContainer">

  <!-- Summary -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #summpan hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Summary
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!summpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="summpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="appInteriorContents">

          <!-- Strats -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Strategies</span>
            <ul>
              <li *ngFor="let strat of this.vm?.fields?.focus" class="appFieldContent appExpContent">{{ strat || '' }}
              </li>
            </ul>
          </div>

          <!-- Prio -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Priority Landscape: </span>
            <span class="appFieldContent appExpContent">{{ this.vm?.fields?.priorityLandscape || '' }}</span>
            <span class="appExpIconInline">
              <mat-icon [matTooltip]="'Zoom to Feature'"
                (click)="this.requestFeatureExtent(this.layerKeys.prioLand, this.vm?.fields?.priorityLandscape)">zoom_in
              </mat-icon>
            </span>
          </div>

          <!-- Link -->
          <div class="appExpFieldContainer">
            <p class="appGenCardLink" (click)="this.linkTo('app/focal/view', {name: this.vm?.name})">Go to Details</p>
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

  <!-- Summary -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #summpan hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Projects in Focal Area
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!summpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="summpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div>

          <div *ngIf="this.projVm?.length">
            <app-generic-card *ngFor="let item of this.projVm"
              [data]="{header: item?.name, subTextTitle: 'Project Type', subTextValue: item?.fields?.projectType, linkUrl: 'app/project/view', linkId: item?.id, zoomAction: true, exploreAction: true, subTextTitle2: 'Project Status', subTextValue2: item?.fields?.projectStatus, detailsIcon: true }"
              (zoom)="this.requestFeatureExtent(this.layerKeys.project, item?.id)" (explore)="this.linkTo('app/map', {id: item.id, type: 'p'})">
            </app-generic-card>
          </div>

          <div class="appExpHelperMessage" *ngIf="!this.projVm?.length">
            Project has no mapped Activities
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

</div>

<div *ngIf="this.hasResults && !this.isFetching() && this.hasCoreData && this.featureType === 'lyr_exp_activity'" fxFlex
  fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="appCatContainer">

  <!-- Summary -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #summpan hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Summary
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!summpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="summpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="appInteriorContents">

          <!-- Type -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Activity Type: </span>
            <span class="appFieldContent appExpContent">{{ this.activityVm?.activityType || '' }}</span>
          </div>

          <!-- Focus -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Primary Activity Focus: </span>
            <span class="appFieldContent appExpContent">{{ this.activityVm?.primaryFocus || '' }}</span>
          </div>

          <!-- Status -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Status: </span>
            <span class="appFieldContent appExpContent">{{ this.activityVm?.activityStatus || '' }}</span>
          </div>

          <!-- Work Agent -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Activity Manager: </span>
            <span class="appFieldContent appExpContent">{{ this.activityVm?.activityWorkAgent || '' }}</span>
          </div>

          <!-- Planned Completion Date -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Planned Completion Date: </span>
            <span class="appFieldContent appExpContent">{{ this.dateFormat(this.activityVm?.plannedCompletionDate) | date: 'shortDate' }}</span>
          </div>

          <!-- Actual Completion Date -->
          <div class="appExpFieldContainer">
            <span class="appFieldLabel appExpLabel">Actual Completion Date: </span>
            <span class="appFieldContent appExpContent">{{ this.dateFormat(this.activityVm?.actualCompletionDate) | date: 'shortDate' }}</span>
          </div>

          <!-- Link -->
          <div class="appExpFieldContainer">
            <p class="appGenCardLink" (click)="this.linkTo('app/activity/view', {id: this.activityVm?.id, projectid: this.activityVm?.projectId, projectleadorg: this.activityVm?.leadOrganization})">Go to Details</p>
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

</div>


<div *ngIf="this.hasResults && !this.isFetching() && this.hasCoreData && this.featureType === 'lyr_non_core'" fxFlex
  fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="appCatContainer">

  <!-- Summary -->
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div class="appExpCategory">
      <mat-expansion-panel #summpan hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            Summary
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!summpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="summpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="appInteriorContents">

          <div class="appExpFieldContainer" *ngFor="let nc of this.nonCoreVm">
            <span class="appFieldLabel appExpLabel">{{ nc.name }}: </span>
            <span class="appFieldContent appExpContent">{{ nc.value || '' }}</span>
          </div>

        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>

</div>