import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { ProjectInfoDialogComponent } from '../project-info-dialog/project-info-dialog.component';
import { DynamicFormComponent } from '../_shared/components/forms/dynamic-form/dynamic-form.component';
import { Project } from '../_shared/models/domain/project.model';

import { ApiService } from '../_shared/services/api.service';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { FindSomethingComponent } from '../_shared/components/dialogs/find-something/find-something.component';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit {
  displayedColumns: string[] = ['name','id','modified','projectType','projectStatus', 'leadOrganization', 'view']//,'leadOrganization'];
  projects: Project[];
  dataSource = new MatTableDataSource<Project>([]);
  leadOrg: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private auth: AuthorizationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (sortHeaderId == 'name') {
        return data['name'].toLocaleLowerCase();
      }

      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };





    this.api.get<Project[]>("Project", "GetAll", true).subscribe(result => {
      this.projects = result.filter(function(e) {
        return ["Planned","In Progress", "Deferred", "On Hold"].includes(e.projectStatus)
      });

      let leadOrg =  this.auth.login.orgs.organization;

      let hydratedProjects = [];
      this.projects.forEach(project => {
        if(project.leadOrganization === leadOrg){
          hydratedProjects.push(project);
        }
      });


      this.projects.forEach(project => {
        if(project.partners?.length){
          project.partners.forEach(partner => {
            if(partner.name === leadOrg){
              hydratedProjects.push(project);
            }
          })
        }
      })

      this.projects = hydratedProjects

      this.initDataSource();
    });
  }


  initDataSource() {
    this.dataSource.data = this.projects;
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProjectInfoDialogComponent, {
      width: '50rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  findSomething() {
    this.dialog.open(FindSomethingComponent, {
      width: '50rem',
      data: {}
    });
  }

  viewSummary(id: any) {
    this.router.navigateByUrl(`/app/project/view?id=${id}`);
  }

}
