<div class="sidebar-container mat-elevation-z8">
  <div class="map-tools-header">
    Explorer Tools
    <div (click)="onDrawerClose.emit()" class="collapse-button">
      <i class="material-icons"> keyboard_arrow_left </i>
    </div>
  </div>

  <div class="filter-tabs">
    <mat-button-toggle-group [value]="selected" #group="matButtonToggleGroup" (change)="onToolSelect($event)">

      <mat-button-toggle class="buttonToggleThemeingWorkaround" value="identify" checked>Explorer
      </mat-button-toggle>

      <mat-button-toggle class="buttonToggleThemeingWorkaround" value="themes">Layers</mat-button-toggle>

      <mat-button-toggle class="buttonToggleThemeingWorkaround" value="legend">Legend
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="filters">
    <div style="height:100%">
      <app-category-container #catcont (highlightFeature)="this.highlightFeature.next($event)"
        (zoomToFeature)="this.zoomToFeature.next($event)" (zoomToExtent)="zoomExtent($event)"
        (importFeature)="this.importFeature.next($event)" (unhighlightFeature)="this.unhighlightFeature.next($event)"
        [identifyResults]="identifyResults" [mapZoom]="mapZoom" [mode]="group.value" [categories]="categories"
        (layerSelected)="layerSelected($event)" (layerDeselected)="layerDeselected($event)"
        (opacityChange)="opacityChanged($event)" (returnToIdentifyExtent)="returnToIdentifyExtent.next($event)"
        (identifyExtentClick)="identifyExtentClick.next($event)" [style.height]="'100%'" (tempHighlightFeature)="tempHighlight($event)"></app-category-container>
    </div>
  </div>

</div>
