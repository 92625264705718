<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let field of this.fields" class="form-row">
      <div class="appFormField">
        <app-dynamic-form-field [largeLabel]="largeLabel" (newValue)="newOrgValue($event)" (newOptions)="updateDynamicOptions($event)"
          [activeEdit]="activeEdit" [field]="field" [form]="form">
        </app-dynamic-form-field>
      </div>
    </div>

    <div class="float-right" *ngIf="this.showSubmissionButtons">
      <button mat-button *ngIf="this.type === 'create'" id="appFormOk" type="button"
        class="btn  mr-2 btn-outline-primary" type="submit" [disabled]="!form.valid">
        {{submitButtonText}}
      </button>
      <button mat-button *ngIf="this.type === 'create'" id="appFormCancel" type="button"
        class="btn btn-outline-dark mr-2" (click)=this.clickCancel()>
        Cancel
      </button>
    </div>
  </form>
</div>
