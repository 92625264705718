import { FieldBase } from './field-base';

export class TextboxField extends FieldBase<string> {
  controlType = 'textbox';
  type: string;
  maxlength?: string;
  minlength?: string;
  triggersChange: boolean;
  changeFields: string[];
  dataUrl: string;
  dataAttributes: any[];
  decimalCappedNonNegative?: boolean;
  decimalCapValue?: number;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.maxlength = options['maxlength'] || '';
    this.minlength = options['minlength'] || '';
    this.triggersChange = options['triggersChange'] || false;
    this.changeFields = options['changeFields'] || [];
    this.dataUrl = options['dataUrl'] || null;
    this.dataAttributes = options['dataAttributes'] || [];
    this.decimalCappedNonNegative = options['decimalCappedNonNegative'] || false;
    this.decimalCapValue = options['decimalCapValue'] || null;
  }
}
