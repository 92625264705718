import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ApiService } from '../../_shared/services/api.service';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { AuthorizationService } from '../../_shared/services/authorization.service';


import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-all',
  templateUrl: './manage-all.component.html',
  styleUrls: ['./manage-all.component.scss']
})
export class ManageAllComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['icon', 'basicInfo', 'viewable', 'actions'];
  //dataSource = new MatTableDataSource<any>([]);
  data = [];
  rawData = [];
  mappedData = [];
  userID = "1"

  filterOwned = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private api: ApiService, private auth : AuthorizationService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    this.paginator.length = 0;

    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading Areas of Interest",
      disableClose: true
    });

    this.api.get<any>("Assessment", "GetAllShort").subscribe(assessments => {
      this.rawData = assessments;
      setTimeout(() => { this.onFilterSelect(undefined); saveRef.close(); }, 150);

    });

    this.paginator.page.subscribe(page => {
      var start = page.pageSize * page.pageIndex;

      this.updateData(start, page.pageSize)

    })
  }

  updateData(start: number, length: number) {
    var ids = this.data.slice(start, start + length).map(itm => { return itm.id });

    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading Selected Areas of Interest",
      disableClose: true
    });

    this.api.post<any>("Assessment", "Get", ids).subscribe(assessments => {
      this.mappedData = assessments;
      saveRef.close();
    });
  }

  toDate(exp: number): string {
    let Exp = new Date(0);
    Exp.setUTCSeconds(exp);
    return (Exp.getMonth() + 1) + "/" + Exp.getDate() + "/" + Exp.getFullYear();
  }

  getData() {
    if (!this.filterOwned)
      return this.data;

    return this.data.filter(elm => {
      return elm.createdBy == this.userID
    })
  }

  ngOnDestroy() {

  }

  onFilterSelect(evt: any) {
    console.log("Filter Select")
    if (evt && evt.value)
      this.filterOwned = (evt.value === "mine");

    //Set pagenation to 0
    if (!this.filterOwned)
      this.data = this.rawData;
    else
    {
      let user = this.auth.getLogin().user;

      this.data = this.rawData.filter(elm => {
        return elm.createdBy == user.id;
      })
    }

    this.paginator.length = this.data.length;
    this.paginator.pageSizeOptions = [5,10,20]

    this.paginator.firstPage();
    this.updateData(0, this.paginator.pageSize || 5);
  }

  getField(field: string, element: any) {
    if (!element.fields)
      return;

    return element.fields[field];
  }

  canManage(item : any) : boolean
  {
    let login = this.auth.getLogin()
    let user = login.user;

    if (login.acls.includes("Assessment:ManageAny"))
      return true;


    return item.createdBy.id == user.id;

  }

  manageReports(item : any) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading Selected Area of Interest",
      disableClose: true
    });

    // this.api.post<any>("Assessment", "Get", [item.id]).subscribe(results => {
    //   if (results && results.length) {
    //     let target = results[0];
    //     const reportRef = this.dialog.open(ManageReportsDialogComponent, {
    //       width: '125rem',
    //       data: { target : target }
    //     });
    //   }
    //   saveRef.close();
    // })
  }



}
