import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GenericCard } from './generic-card.model';

@Component({
  selector: 'app-generic-card',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss']
})
export class GenericCardComponent implements OnInit {

  @Input() data: GenericCard = null;
  @Output() zoom: EventEmitter<void> = new EventEmitter<void>();
  @Output() explore: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  linkTo() {
    if (this.data?.projectId && this.data?.leadOrg) {
      this.router.navigate([this.data.linkUrl], { queryParams: { id: this.data.linkId, projectleadorg: this.data.leadOrg, projectid: this.data.projectId } });
    } else {
      this.router.navigate([this.data.linkUrl], { queryParams: { id: this.data.linkId } });
    }
  }

  zoomRequest() {
    this.zoom.emit();
  }

  exploreRequest() {
    this.explore.emit();
  }

}
