<p mat-dialog-title color="primary" style="text-align: center; border-bottom: 1px solid rgba(118,118,118,1); font-size: 1.5rem;"
    class="themePrimaryTextInverse">Save As Area of Interest</p>

<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="2rem" fxLayoutAlign="center center" style="width: 100%;">
        <img alt="Map image" src={{assessmentArea.snapshot}} style="width:40rem; height: 20rem;"/>
        <div style="width: 100%;"> 
            <p style="font-size: 1.2rem; line-height: 1.5rem; text-overflow: ellipsis; overflow:hidden;"> 
                <mat-form-field fxFill class="example-full-width">
                    <mat-label>Area of Interest Name</mat-label>
                    <input name="name" #name matInput [formControl]="nameFormControl" placeholder="Area of Interest Name" required="true" />
                </mat-form-field>
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions style="width: 100%;">
    <div fxFlex fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
        <button mat-button color="primary" (click)="onNoClick()" style="width: 12rem;">CANCEL</button>
        <button [disabled]="!isValid()" mat-flat-button color="accent" (click)="onYesClick()" cdkFocusInitial style="width: 12rem;">SAVE</button>
    </div>
</div>