<!-- Header -->
<h4 mat-dialog-title>
    <mat-toolbar role="toolbar" class="task-header appToolbar">
      <mat-toolbar-row class="appModalHeaderCenter">
        <div class="appModalHeader">
          <span>I Want to Find...</span>
          <span class="appModalClose" (click)="this.dialogRef.close();"><mat-icon>close</mat-icon></span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </h4>
  
  <!-- Dialog content -->
  <div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto; padding-left: 5rem; padding-right: 5rem;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
      style="margin:1.5rem 0; width: 100%;">

      <div style="width:100%">
        <div fxLayout="row" fxLayoutAlign="center center">

            <form style="width:100%" [formGroup]="searchForm">
                <mat-form-field appearance="outline" style="width:100%" appearance="fill" class="appSearchField">
                    <button matSuffix mat-icon-button aria-label="Clear" class="appEyeglass">
                        <mat-icon>zoom_in</mat-icon>
                      </button>
                  <mat-label>Search</mat-label>
                  <input appearance="outline" type="text"
                         matInput
                         formControlName="searchGroup"
                         required
                         [matAutocomplete]="autoGroup">
                    <mat-autocomplete appearance="outline" #autoGroup="matAutocomplete" (optionSelected)="selected({value: $event.option.value, label: $event.option.group.label })">
                        <!-- Spinner -->
                        <mat-option *ngIf="isLoading && !lessThanMin" class="appIsLoading">Loading...</mat-option>

                        <!-- Actual data -->
                        <ng-container *ngIf="!isLoading && !lessThanMin">
                            <mat-optgroup *ngFor="let group of searchGroupOptions | async" [label]="getFeatureTypeName(group.featureType)">
                                <mat-option *ngFor="let name of group.names" [value]="name">
                                {{ name }}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </form>

        </div>

      </div>

      <h3 class="appTagLine">Enter the name of the Project, Focal Area, or Priority Landscape, or the Top 500 Watershed ID you want to find (minimum of 3 characters required)</h3>

    </div>

  </div>
  