import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from '../map/error-dialog/error-dialog.component';
import { NoteCreateDialogComponent } from '../note-create-dialog/note-create-dialog.component';
import { PamObject } from '../_shared/models/pam-object.model';
import { ApiService } from '../_shared/services/api.service';
import { AuthorizationService } from '../_shared/services/authorization.service';

@Component({
  selector: 'app-impediment',
  templateUrl: './impediment.component.html',
  styleUrls: ['./impediment.component.scss']
})
export class ImpedimentComponent implements OnInit {

  @Input() impediment: any;

  @Input() projectLeadOrg: any;

  @Input() projectId: any;

  @Output() impedimentToRemove: EventEmitter<any> = new EventEmitter();

  displayName: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private api: ApiService,
    private auth: AuthorizationService
  ){ }

  ngOnInit(): void {
    this.displayName = this.auth.login.getFullName();
    console.log(this.impediment);
  }

  removeImpediment(_: any){
    this.impedimentToRemove.emit(this.impediment);
  }

  editImpediment() {
    let noteDialog = this.dialog.open(NoteCreateDialogComponent, {
      width: '35rem',
      disableClose: false,
      data: { "impediment": this.impediment, "editMode": true, "isOpportunity": false }
    });


    noteDialog.afterClosed().subscribe(data => {
      if (data) {
        let formattedObj = this.createImpedimentPayload(data);
        this.updateImpediment(formattedObj)
      }
    });
  }

  createImpedimentPayload(data: any) {
    let formattedObj: PamObject = { id: data.id, name: "default", fields: {} };
    formattedObj.name = "Impediment";
    formattedObj.fields = {};
    formattedObj.fields.note = data.note;
    formattedObj.fields.isOpportunity = data.isOpportunity;
    formattedObj.fields.displayName = this.displayName;


    return formattedObj;
  }

  updateImpediment(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      //pass object here (edit mode and other things)
      data: "Saving Record.",
      disableClose: true
    });

    this.api.post<any>("Note", "Update", obj, true).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleImpedimentUpdate(res.result)
      saveRef.close();

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  handleImpedimentUpdate(res){
    console.log("transforming opportunity response");

    this.impediment.note = res.note;
    this.impediment.lastModified = res.lastModified;
    this.impediment.displayName = res.displayName;

  }

}
