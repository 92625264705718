import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-button-cluster',
  templateUrl: './edit-button-cluster.component.html',
  styleUrls: ['./edit-button-cluster.component.scss']
})
export class EditButtonClusterComponent implements OnInit {

  @Input() editModeOn = false;
  @Input() formsAreValid = false;
  @Input() disabledEdit = false;
  @Input() optionalEditTooltip = '';
  @Input() confirmName = 'Save';
  @Input() editName = 'Edit';
  @Input() cancelName = 'Cancel';
  @Output() saveClicked = new EventEmitter<void>();
  @Output() editClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  clickSave() {
    this.saveClicked.emit();
  }

  clickEdit() {
    this.editClicked.emit();
  }

  clickCancel() {
    this.cancelClicked.emit();
  }

}
