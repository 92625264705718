import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authorizing',
  templateUrl: './authorizing.component.html',
  styleUrls: ['./authorizing.component.scss']
})
export class AuthorizingComponent implements OnInit, OnDestroy {


  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthorizationService) { }

  subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.subs.forEach(sub => { sub.unsubscribe(); });
  }
  ngOnInit(): void {
    if (this.subs.length) {
      this.subs.forEach(sub => { sub.unsubscribe(); });
      this.subs = [];
    }

    this.subs.push(this.route.queryParams
      .subscribe(params => {
        if (params.redirectTo) {
          let parts = params.redirectTo.split("?");

          let path = parts[0];
          let parms = parts.length > 1 ? parts[1].split("&").map(itm => { let p = itm.split("="); return p; }) : [];
          let po = {};

          for (let tup of parms) {
            po[tup[0]] = decodeURIComponent(tup[1]);
          }

          //Regardless of our current state we want to redirect after logging in
          //This will retrigger the route guard that will pass it if the user has the ACL or bump them back to the home screen
          this.subs.push(this.auth.onFutureLogin().subscribe(login => {
            setTimeout(() => { this.router.navigate([path], { queryParams: po }); }, 1500);
          }));

          if (!this.auth.isLoggingIn && !this.auth.isRefreshing) {
            this.auth.authorize();
          }
        }
        else {
          //No parameter set, go back to home
          this.router.navigate(['/']);
        }
      }));

  }

  login(): void {
    this.auth.authorize();
  }

}
