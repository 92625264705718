<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-content class="appFieldContent">
        <div class="appBottomBorder">
          <!-- Opportunity fields here -->
          <p>{{opportunity.note}}</p>
        </div>
        <div style="margin-top: .35rem;">
          <div>
            <div style="position: relative; float: left">
              <p><em>{{opportunity.lastModified | date}} - {{opportunity.displayName}}</em></p>
            </div>
            <div class="appTrashCan" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
              <mat-icon (click)="removeOpportunity($event)">delete</mat-icon>
            </div>
            <div class="appEditIcon" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
              <mat-icon (click)="editOpportunity()">edit</mat-icon>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
