import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './_shared/services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(private router: Router, private auth: AuthorizationService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (next.data && next.data.acls && next.data.acls.length) {
      if (!this.auth.isLoggedIn()) {
        console.log("User is not logged in!");
        this.router.navigate(['/authorizing'], { queryParams: { redirectTo: state.url } });
        return false;
      }

      let login = this.auth.getLogin();

      if (next.data.mustHaveOrg) {
        if (!login.orgs.organization || login.orgs.organization === 'None - General Public') {
          return false;
        }
      }

      if (!login.acls || login.acls.length == 0)
        return false;

      for (let acl of next.data.acls)
        if (!login.acls.includes(acl)) {
          console.log("User doesnt have the permission", acl)
          this.router.navigate(['/app/map']);
          return false;
        }

      return true;
    }

    return true;
  }
}
