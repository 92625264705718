<section class="layerListItem" style="width:100%;">
  <div *ngIf="mode ==='themes'" style="width:100%; display: flex;" >
    <mat-checkbox class="example-margin matCheckboxHax" [checked]="selected" (change)="onChange($event)" *ngIf="multi">
      <span class="layerTitle">{{ layer.name }}</span>
    </mat-checkbox>
    <mat-radio-button [value]="layer.key" class="example-margin" [checked]="selected" (change)="onChange($event)"
      *ngIf="!multi">
      <span class="layerTitle">{{ layer.name }}</span>
    </mat-radio-button>
    <div class="smally" style="width: 2.5rem; flex-shrink: 0; padding: 0.1rem;">
      <img alt="Identify icon" src="https://timmons-branding.s3.amazonaws.com/Shared+Icons/73+-+Identify+Icon.svg" 
        matTooltip="This layer has a legend." 
        style="width: 1em; height: 1em; margin-left: 0; margin-right: auto;"
        *ngIf="layer.legend && layer.legend.length" aria-hidden="false" 
        [appRequireACL]="'Map:LegendConfigIcon'"
        aria-label="This layer has a legend." 
        />
      <img alt="Identify icon"src="https://timmons-branding.s3.amazonaws.com/Shared+Icons/73+-+Identify+Icon.svg" 
        matTooltip="Features from this layer can be identified." 
        style="width: 1em; height: 1em; margin-left: 0; margin-right: auto;"
        *ngIf="layer.identify" aria-hidden="false" 
        aria-label="Features from this layer can be identified."
        />
      <img alt="Select icon" src="https://timmons-branding.s3.amazonaws.com/Shared+Icons/73+-+Select+Icon.svg" 
        matTooltip="Features from this layer can be imported into a new Area of Interest." 
        style="width: 1em; height: 1em; margin-left: auto; margin-right: 0;"
        *ngIf="layer.identify && layer.identify.import" aria-hidden="false" 
        aria-label="Features from this layer can be imported into a new Area of Interest." 
        [appRequireACL]="'Map:ClickToImport'"
        />
    </div>
  </div>
</section>