import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ResourceConcern, allConcerns } from 'src/app/_shared/models/resource-concerns';


export interface State {
  flag: string;
  name: string;
  population: string;
}




export interface AddConcernData {
  previouslySelected : ResourceConcern[];
  name: string;
}


@Component({
  selector: 'app-add-concern-dialog',
  templateUrl: './add-concern-dialog.component.html',
  styleUrls: ['./add-concern-dialog.component.scss']
})
export class AddConcernDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddConcernData) {
    this.previousSelectedOptions = data.previouslySelected;

    this.filteredConcerns = this.concernCtrl.valueChanges
      .pipe(
        startWith(''),
        map(concern => this._filterConcerns(concern))
      );
  }

  tempOpt : ResourceConcern;
  selectedOptions : ResourceConcern[] = [];
  previousSelectedOptions : ResourceConcern[] = [];

  concernCtrl = new FormControl();
  filteredConcerns: Observable<ResourceConcern[]>;

  @ViewChild("imp") input;


  private _filterConcerns(concern: any): ResourceConcern[] {
    let selectedids = this.selectedOptions.map(e => e.id).concat(this.previousSelectedOptions.map(e => e.id));
    let startConcerns = allConcerns.filter( f => !selectedids.includes(f.id));

    if(!concern)
      return startConcerns;

    const filterValue = concern.value ? concern.value.toLowerCase() : concern.toLowerCase();

    return startConcerns.filter(concern => concern.value.toLowerCase().indexOf(filterValue) >= 0 || concern.group.toLowerCase().indexOf(filterValue) >= 0 || concern.category.toLowerCase().indexOf(filterValue) >= 0).sort((elma, elmb) => {
      let vai = elma.value.toLowerCase().indexOf(filterValue);
      let vbi = elmb.value.toLowerCase().indexOf(filterValue);

      if (vai == -1)
        vai = 1001;
      if (vbi == -1)
        vbi = 1001;

      let gai = elma.group.toLowerCase().indexOf(filterValue);
      let gbi = elmb.group.toLowerCase().indexOf(filterValue);

      if (gai == -1)
        gai = 501;
      if (gbi == -1)
        gbi = 501;

      let cai = elma.category.toLowerCase().indexOf(filterValue);
      let cbi = elmb.category.toLowerCase().indexOf(filterValue);

      if (cai == -1)
        cai = 251;
      if (cbi == -1)
        cbi = 251;

      return ( (1000 - vbi) - (1000 - vai) ) + ( ( 500 - gbi ) - ( 500 - gai ) ) + ( ( 250 - cbi ) - ( 250 - cai ) );
    })
  }

  addOpt(event) {
    this.selectedOptions.push(event.option.value);
    event.option.deselect();
    this.concernCtrl.setValue('');
    this.input.nativeElement.blur();
  }

  delete(concern: ResourceConcern)
  {
    this.selectedOptions = this.selectedOptions.filter(el => el.id != concern.id )
  }

  displayFn(concern: ResourceConcern): string {
    return concern ? concern.value + " | " + concern.group + " | " + concern.category : '';
  }

  ngOnInit(): void {
  }

}
