<div id="appScroller">

  <div fxFlex fxLayout="column" class="appMainContainer">
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">
      <div>
        <button mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton appButton" id="appPrintButton" (click)="printContent()">Print</button>
      </div>
    </div>

    <!-- Main content -->
    <div fxFlexFill fxLayoutAlign="center center">

      <!-- Content container -->
      <div fxFlexFill
           [fxLayout]="'row wrap'"
           fxLayoutGap="10px 5px grid"
           style="cursor: pointer;"
           id="focalReport">


        <div fxFlex="100">
          <div fxFlexFill fxLayoutAlign="center center">

            <div>
              <h5 class="appCenterText">New Mexico Shared Stewardship Portal Focal Area Report</h5>
              <h1>Focal Area | {{ this.name }}</h1>
            </div>

          </div>
        </div>
        <div fxFlex="50">
          <div fxFlexFill fxLayoutAlign="center center">

            <div class="appNextToMap">

              <div>
                <h2><u>Priority Landscape</u></h2>
                <p>{{this.priorityLandscape}}</p>
              </div>

              <div>
                <h2><u>Focal Area Strategies</u></h2>
                <div *ngFor="let strategy of strategies">
                  <p>{{strategy}}</p>
                </div>
              </div>

          </div>

          </div>
        </div>

        <div fxFlex="50">
          <div fxFlexFill fxLayoutAlign="center center">

            <div class="appMapContainer">
              <app-slim-map #slimMap [showEditCreateButtons]="false" [strokeColor]="'rgba(0, 34, 76, 0.5)'"
                [fillColor]="'rgba(32, 119, 255, 0.5)'" [hideControls]="true">
              </app-slim-map>
            </div>

            <div class="appImageContainer" *ngIf="imageUrl">
              <img [src]="imageUrl" alt="Map image">
            </div>

          </div>
        </div>

        <div fxFlex="50">
          <div fxFlexFill fxLayoutAlign="center center">

            <div>
              <h2><u>Collaborating Partners</u></h2>
              <div *ngFor="let partner of partners">
                <p>{{partner.name}}</p>
              </div>
            </div>

          </div>
        </div>

        <div fxFlex="50">
          <div fxFlexFill fxLayoutAlign="center center">

            <div>
              <div>
                <h2><u>Focal Area Investments at-a-Glance</u></h2>
                <div>
                  <p>{{this.activeProjects}}<span>&nbsp;&nbsp;</span>Total active projects</p>
                  <p>{{this.completedProjects}}<span>&nbsp;&nbsp;</span>Total completed projects to date</p>
                  <p>{{this.completedTreatmentActivities}}<span>&nbsp;&nbsp;</span>Total completed treatment activities to date</p>
                  <p>{{this.acresCompletedActivities | number}}<span>&nbsp;&nbsp;</span>Acres completed treatment to date</p>
                  <p>{{this.totalActualFunding | currency }}<span>&nbsp;&nbsp;</span>Total actual financial investment to date</p>
                  <p>{{this.totalFundingMatch | currency }}<span>&nbsp;&nbsp;</span>Total funding counted as match</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
</div>
<a id="image-download" download="map.png"></a>
