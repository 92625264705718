<div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxFlex=100 fxLayoutAlign="top left"
style="margin:1.5rem 0; width: 100%;">

  <mat-card class="appSolidCard mat-elevation-z0" style="margin-bottom: 1rem; padding-top: 0 !important;">

    <div class="appTableSpacing">
      <div class="appHeaderAlignment" style="display: inline-block">
        <div style="padding: 1rem 0; left: 1em" class="themePrimaryTextInverse">
          <h1 class="appMainCardHeader">Manage Organizations</h1>
          <span class="appOrgCreateButton">
            <button mat-flat-button *ngIf="this.currentView === this.filterTab.orgs" color="accent" class="appConfirmButton appSquareButton"
              (click)="createNewOrg()">Create New</button>
          </span>
        </div>
      </div>

      <div class="appToggleContainer">
        <mat-button-toggle-group #group="matButtonToggleGroup" style="border:0" (change)="onFilterSelect($event)">
            <mat-button-toggle class="appButtonToggle" [value]="filterTab.users"
                checked>Users</mat-button-toggle>
            <mat-button-toggle class="appButtonToggle" [value]="filterTab.orgs">Organizations</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <ng-template [ngIf]="this.currentView === this.filterTab.users">
          <app-org-users #userEdit></app-org-users>
      </ng-template>

      <ng-template [ngIf]="this.currentView === this.filterTab.orgs">
          <app-org-edit #orgEdit></app-org-edit>
      </ng-template>
    </div>
  </mat-card>
</div>
