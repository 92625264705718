import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-map-edit-dialog',
  templateUrl: './map-edit-dialog.component.html',
  styleUrls: ['./map-edit-dialog.component.scss']
})
export class MapEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSlimMap') modalSlimMap: SlimMapComponent
  currentGeom: PamShapeData;
  isInActiveEdit = false;
  firstLoad = true;
  fillColor: string = 'rgba(0,77,168,0.8)';
  strokeColor: string = 'rgba(0,77,168,0.95)';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MapEditDialogComponent>,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.fillColor = this.data.fillColor;
    this.strokeColor = this.data.strokeColor;

    if (this.data.refId) {
      this.api.post<any>("Project", "Get", [this.data.refId]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleProjectLoad(target);
        }
      });
    }
  }

  ngAfterViewInit() {
    const that = this;
    setTimeout(() => {
      that.modalSlimMap.update(that.data.feature);
    }, 250);
  }

  newShapeData(data: any) {
    this.currentGeom = data;
  }

  checkEditMode(mode: boolean) {
    this.isInActiveEdit = mode;
  }

  onSubmit() {
    this.dialogRef.close(this.currentGeom);
  }

  cancelClicked() {
    this.dialogRef.close(null);
  }

  saveClicked() {
    this.dialogRef.close(this.currentGeom);
  }

  handleProjectLoad(target: any) {
    if (target && target?.fields?.Geometry && target?.fields?.Geometry?.wkt) {
      const geomObj: PamShapeData = {
        id: target.id,
        name: target.name,
        fields: {
          Geometry: { ... target.fields?.Geometry }
        }
      }
      if (this.modalSlimMap) {
        this.modalSlimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)');
      } else {
        // First page load, isSpatial check is likely underway and DOM has not
        // rendered the slim map comp yet.  OpenLayers needs a moment.
        const that = this;
        setTimeout(() => {
          that.modalSlimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)');
        }, 1000);
      }
    }
  }

}
