<!-- Header -->
<h4 mat-dialog-title>
    <mat-toolbar role="toolbar" class="task-header appToolbar">
      <mat-toolbar-row class="appModalHeaderCenter">
        <div>
          <span>Add Organization</span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </h4>

  <!-- Dialog content -->
  <div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
      style="margin:1.5rem 0; width: 100%;">
      <div>
        <mat-form-field fxFill appearance="outline">
          <mat-label>Organization Name</mat-label>
          <input name="name" #orgName matInput [formControl]="orgNameFormControl" required="true" type="text" maxlength="50"/>
        </mat-form-field>
        <mat-form-field fxFill appearance="outline">
          <mat-label>Organization Abbreviation </mat-label>
          <input name="short" #orgShort matInput [formControl]="orgShortFormControl" required="true" type="text" maxlength="10"/>
        </mat-form-field>
        <mat-form-field fxFill appearance="outline">
          <mat-label>Organization Type</mat-label>
          <mat-select name="type" #orgType [formControl]="orgTypeFormControl" required="true">
            <mat-option *ngFor="let type of types" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <label>
          <body>
            <p>Please ensure the information you entered is correct. You will <strong>not be able to edit</strong> the details once added, and you will only be able to archive this organization if there are no users affiliated to it.</p>
          </body>
        </label>
      </div>

      <!-- Confirm buttons -->
      <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
            style="margin-right: 1rem;" (click)="onNoClick()">Cancel</button>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <button [disabled]="!isValid()" mat-flat-button color="accent"
            class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()" target="_blank">Add</button>
        </div>
      </div>
    </div>
  </div>
