import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from '../map/error-dialog/error-dialog.component';
import { PamObject } from '../_shared/models/pam-object.model';
import { ApiService } from '../_shared/services/api.service';
import { FundingAddDialogComponent } from './funding-add-dialog/funding-add-dialog.component';

@Component({
  selector: 'app-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss']
})
export class FundingComponent implements OnInit {

  @Input() funding: any;

  @Input() projectLeadOrg: any;

  @Input() partners: any[] = [];

  @Output() fundingToRemove: EventEmitter<any> = new EventEmitter();

  @Output() fundingUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private api: ApiService
  ){ }

  ngOnInit(): void {
    console.log("Current Funding", this.funding)
    console.log("Project Lead Org", this.projectLeadOrg)
  }


  editFunding() {
    let fundingDialog = this.dialog.open(FundingAddDialogComponent, {
      width: '35rem',
      disableClose: false,
      data: { "funding": this.funding, "editMode": true }
    });


    fundingDialog.afterClosed().subscribe(data => {
      if (data) {
        let formattedObj = this.createFundingPayload(data);
        this.updateFunding(formattedObj)
      }
    });
  }

  createFundingPayload(data: any) {
    console.log("funding data", data);
    let formattedObj: PamObject = { id: data.id, name: data.name, fields: {} };
    formattedObj.name = data.name;
    formattedObj.fields = {};
    formattedObj.fields.fundingOrg = data.orgName;
    formattedObj.fields.fundingName = data.name;
    formattedObj.fields.plannedAmount = data.plannedAmount;
    formattedObj.fields.actualAmount = data.actualAmount
    formattedObj.fields.match = data.match;

    return formattedObj;
  }


  updateFunding(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      //pass object here (edit mode and other things)
      data: "Saving Record.",
      disableClose: true
    });

    this.api.post<any>("Funding", "Update", obj, true).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleFundingUpdate(res.result)
      saveRef.close();

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  handleFundingUpdate(res){
    console.log("transforming funding response");

    this.funding.fundingName = res.fundingName;
    this.funding.fundingOrg = res.fundingOrg;
    this.funding.plannedAmount = res.plannedAmount;
    this.funding.actualAmount = res.actualAmount;
    this.funding.match = res.match;


    this.fundingUpdated.emit();

  }

  removeFunding(_: any){
    this.fundingToRemove.emit(this.funding);
  }
}
