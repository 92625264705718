import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { RegisterErrorDialogComponent } from '../register/register-error-dialog/register-error-dialog.component';
import { ForgotPasswordUser, RegistrationService } from '../registration.service';
import { ApiService } from '../_shared/services/api.service';

// Used for keeping track of the step in the registration process
export enum Steps {
  Enter,
  Communicating,
  Next,
  Error
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  // Main form data
  emailFormControl!: FormControl;

  // Forms workaround
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  // Keep track of the current step
  step: Steps = Steps.Enter;

  // Expose all the possible steps to the template
  Steps = Steps;

  constructor(
    private registration: RegistrationService,
    private api: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  ngAfterViewInit(): void {
    // (╯ ͠° ͟ʖ ͡°)╯┻━┻  https://github.com/angular/components/issues/15027
    setTimeout(() => this.formFields.forEach(ff => ff.updateOutlineGap()), 100);
  }

  // Returns true if the input is valid across all fields.
  isValid(): boolean {
    return this.emailFormControl.valid
  }

  register() {
    // Set the step to communicating so the frontend can display "progress"
    this.step = Steps.Communicating;

    // Create a forgot password user
    const fpUser: ForgotPasswordUser = new ForgotPasswordUser();

    fpUser.email = this.emailFormControl.value;

    // Pass the work to the registration service
    this.registration.forgotPassword(fpUser).subscribe((result) => {
      // The API can respond with valid = false if the account doesnt exist or the api cannot be reached.
      if (result.valid) {
        // Update the step to give the user feedback on what to do next
        this.step = Steps.Next;
      }
      else {
        console.log(result);
        // Something went wrong. Show it to the user.
        this.step = Steps.Error;
      }
    });
  }

  // Go back to being able to enter data without clearing the form
  return() {
    this.step = Steps.Enter;
  }

  openDialog() {
    // TODO: add an actual error component here
    const dialogRef = this.dialog.open(RegisterErrorDialogComponent, {
      width: '35rem',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // Do nothing
    });
  }

}
