
  <div name="userGrid" class="mat-elevation-z0" style="font-size: 0.9rem;">
    <table aria-describedby="Users table" mat-table [dataSource]="dataSource" matSort style="width: 100%">

      <!-- Org Name -->
      <ng-container matColumnDef="name">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
        <td mat-cell *matCellDef="let org"> {{org.name}} </td>
      </ng-container>

      <!-- Org Abbreviation -->
      <ng-container matColumnDef="orgShort">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Abbreviation </th>
        <td mat-cell *matCellDef="let org"> {{org.orgShort}} </td>
      </ng-container>

      <!-- Org Type -->
      <ng-container matColumnDef="orgType">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let org"> {{org.orgType}} </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let org">
          <button class="appClickable" mat-button color="accent" (click)="archiveOrg(org)"> Archive </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
