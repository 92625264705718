import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/_shared/models/user.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AddOrgComponent } from './add-org/add-org.component';
import { OrgEditComponent } from './org-edit/org-edit.component';
import { OrgUsersComponent } from './org-users/org-users.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  filterTab = {
    users: 'users',
    orgs: 'orgs'
  }
  currentView = this.filterTab.users;

  private orgTable!: OrgEditComponent;
  private userTable!: OrgUsersComponent;

  //workaround to ensure orgTable is defined
  @ViewChild('orgEdit', {static: false}) set orgContent(content: OrgEditComponent) {
     if(content) {
          this.orgTable = content;
     }
  }

  @ViewChild('userEdit', {static: false}) set userContent(content: OrgUsersComponent) {
    if(content) {
         this.userTable = content;
    }
 }

  constructor(
    private dialog: MatDialog,
    private api: ApiService
  ) { }

  ngOnInit(): void {
  }

  onFilterSelect(evt: any) {
    console.log(evt.value);
    this.currentView = evt.value;
  }

  createNewOrg(){
    let orgDialog = this.dialog.open(AddOrgComponent, {
      width: '35rem',
      disableClose: false,
    });


    orgDialog.afterClosed().subscribe(data => {
      if (data) {
        this.createOrg(data);
      }
    });

  }

  createOrg(data: any) {
    let formattedObj: any = {};
    formattedObj.name = data.name;
    formattedObj.fields = {};
    formattedObj.fields.orgShort = data.orgShort;
    formattedObj.fields.orgType = data.orgType;

    this.api.post<any>("Organization", "Create", formattedObj , true).subscribe(result => {
      if (!result.valid) {
        //Backend validation didnt pass show the errors``
        //this.showErrors(result.messages);
      }
      else {
        this.orgTable.retreiveOrgs();
      }
    }, error => {
      //Backend gave us a non-200 response
      //this.showErrors(["The api responded incorrectly.", JSON.stringify(error, undefined, 2)]);
    })
  }

}
