import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
// import { debug } from 'node:console';


//Defines configuration
export class Configuration {
  authorization: AuthConfig;
  app: AppConfig;
  map: MapConfig;
  watersheds: WatershedConfig;
  fields?: FieldsConfig;
  debug: boolean;
  apiRoot: string;

  //Our configuration is valid if all the required parts are not undefined
  isValid(): boolean {
    return (this.authorization != undefined) &&
      (this.app != undefined);
    // (this.map != undefined); //TODO ADD this back later
  }
}

//Defines configuration for our authentication
//Note in a future version we may want to remove the client secret
//  and allow the backend to handle it
export interface AuthConfig {
  host: string,
  clientId: string,
  clientSecret: string,
  authorizationEndpoint: string
  logoutEndpoint: string
  tokenEndpoint: string,
  userInfoEndpoint: string,
  unauthorizedRediret: string,
  useHttps: boolean,
  inline: boolean
}


//These are placeholders
export interface AppConfig {

}

export interface MapConfig {

}

export interface WatershedConfig {

}

export interface FieldsConfig {

}



//Configuration service will provide the configuration as a 'singleton' across the app
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  //In order to deal with caching and whatnot we will present a replay subject
  private dataSubject: ReplaySubject<Configuration> = new ReplaySubject<Configuration>(1);

  //We need HTTP to call the backend to get the config components we need
  constructor(private http: HttpClient) {
    //Previously we had a call to init here.
    //This was removed due to having a partial mock in which we override the getConfig method
    //In testing cases we dont want to make calls to the api or even grab the config in most cases
    //If we left it in, the mock service would still need to call super and thus the init which is not what we want
    //Instead it has been moved to getConfig so that it can be overwritten
  }

  //These are the default endpoints we will use to obtain configuration items
  //At the moment only thr auth config has been setup
  authConfigUrl: string = 'app/GetAuthConfig';
  appConfigUrl: string = 'app/GetAppConfig';
  mapConfigUrl: string = 'app/GetMapConfig';
  watershedConfigUrl: string = 'app/GetWatershedsConfig';
  fieldsConfigUrl: string = 'app/GetFieldsConfig';

  config: Configuration = new Configuration();

  serverRoot: string = "/";

  initializingFn: any;

  authConfigFn: Observable<AuthConfig>;

  //This is called to boot the configuration
  init(): any {
    //Dont try to re-initialize if we are already initializing
    if (!this.initializingFn) {
      this.initializingFn = this.http.get<any>("/assets/overrides.json");

      //If we get a valid response on the overrides process them and then call the sub init
      this.initializingFn.subscribe(res => {
        if (res && res.api_host) {
          console.log("Overriding API host")
          this.serverRoot = res.api_host;
        }
        else {
          this.useSelfHost();
        }
        this.initSub();
      }, err => {
        //99% of the time we wont have overrides to load but in the event we need to redirect the api we have it above
        this.useSelfHost();
        this.initSub();
      });
    }
  }

  useSelfHost(): void {
    this.serverRoot = window.location.origin;
    if (!this.serverRoot.endsWith("/api/"))
      this.serverRoot += "/api/";
  }

  //Here we will call the rest of the backend api for getting the config
  initSub(): any {
    this.config.apiRoot = this.serverRoot;
    //Grab the auth config
    if (!this.config.authorization && !this.authConfigFn) {
      this.authConfigFn = this.http.get<AuthConfig>(this.serverRoot + this.authConfigUrl);
      this.authConfigFn.subscribe(res => {
        this.config.authorization = res;
        this.configUpdated()
      }, err => {
        console.error(err);
      });
    }

    //Grab the app config
    if (!this.config.app) {
      this.http.get<AppConfig>(this.serverRoot + this.appConfigUrl).subscribe(res => {
        this.config.app = res;
        this.configUpdated()
      }, err => {

      });
    }

    //Grab the map config
    if (!this.config.map) {
      this.http.get<MapConfig>(this.serverRoot + this.mapConfigUrl).subscribe(res => {
        this.config.map = res;
        this.configUpdated()
      }, err => {

      });
    }

    //Grab the watershed config
    if (!this.config.map) {
      this.http.get<WatershedConfig>(this.serverRoot + this.watershedConfigUrl).subscribe(res => {
        this.config.watersheds = res;
        this.configUpdated()
      }, err => {

      });
    }

    //Grab the fields config
    if (!this.config.fields) {
      this.http.get<FieldsConfig>(this.serverRoot + this.fieldsConfigUrl).subscribe(res => {
        this.config.fields = res;
        this.configUpdated()
      }, err => {

      });
    }

    this.updateConfigRegardless();

  }

  //Check if the config is valid
  configUpdated(): void {
    //If the config is valid notify the observers of the config changes
    if (this.config.isValid()) {
      this.dataSubject.next(this.config);
    }

  }


  //We can use this to test config changes without leaving the app or resetting its state
  reInit() {
    this.purge();
    this.init();
  }

  //Purges the individual config portions
  private purge() {
    this.purgeApp();
    this.purgeAuth();
    this.purgeMap();
  }

  //The following components below are methods as I forsee returning an observable for the individual components
  //Doing so that way we can notify references to the config that it has changed
  private purgeAuth() {
    this.config.authorization = undefined;
  }

  private purgeApp() {
    this.config.app = undefined;
  }

  private purgeMap() {
    this.config.map = undefined;
  }


  //This returns an observable wrapper so we can change the config willynilly without worrying about the components
  public getConfig(): Observable<Configuration> {
    // public getConfig(): ReplaySubject<Configuration> {
    //Call init. Previously it was in the constructor but that proved bad for writing tests!
    this.init();
    return this.dataSubject;
  }

  // TODO - Temp work around until configs are updated
  updateConfigRegardless() {
    this.dataSubject.next(this.config);
  }

  async getConfigAsync(configUrl) {
    return this.http.get<any>(this.serverRoot + configUrl).toPromise();
  }

}
