<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <span>Add New Partner</span>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <h3 class="appTagLine">Adding an organization as a Project partner will allow that organization's Project Editors to add and update Activities for this Project.</h3>
    <div>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Partner</mat-label>
        <!-- Partner Org Dropdown -->
        <mat-select name="partner" #partner [formControl]="partnerFormControl" required="true">
          <mat-option *ngFor="let org of filteredOrgs" [value]="org.name">
            {{org.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Partnership Type</mat-label>
        <!-- Parter Type Dropdown -->
        <mat-select name="partnerType" #partnerType [formControl]="partnerTypeFormControl" required="true" multiple>
          <mat-option *ngFor="let type of types" [value]="type.name">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="cancelClicked()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()"
          target="_blank">Save</button>
      </div>
    </div>
  </div>
</div>
