<div fxFlex fxFill fxLayout="column" class="" fxLayoutGap="0.35rem"
  style="padding: 0.75rem 10rem; width: 100%; height: 100%; overflow: auto; background: rgb(245,245,245); ">

  <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" class=""
    style="flex-shrink: 1; flex-grow: 0; padding: 0 0.25rem;">

    <h2 style="width:100%; margin: auto; margin-left: 0; color: #54200E; padding-left: 1.5rem;">
      Areas of Interest
    </h2>

    <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="" style="width:100%; font-size: 1rem;">
      <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onFilterSelect($event)" style="border:0">
        <mat-button-toggle class="buttonToggleThemeingWorkaroundSmall" value="mine" checked>My Areas of Interest
        </mat-button-toggle>
        <mat-button-toggle class="buttonToggleThemeingWorkaroundSmall" value="all" [appRequireACL]="'Map:ViewLegend'">
          All Areas of Interest</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <p style="width:100%;">

    </p>
    <!-- Search goes here -->
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="start start" class="mat-elevation-z0"
    style="width: 100%; padding: 0.5rem 0.25rem; overflow-y: auto;">

    <mat-card *ngFor="let element of mappedData" style="width:100%">
      <div fxFlex fxLayout="row" fxLayoutGap="1.15rem" fxLayoutAlign="start start" class=""
        style="width: 100%; padding: 0rem 0.5rem;">
        <div style="display: flex; height: 100%;">
          <img alt="Project image" class="icon" [src]="getField('icon', element) || '/assets/images/MapMarker.svg'" />
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="0.15rem"
          style="font-size: 1rem; line-height: 1rem; padding: 0.5rem 0;">
          <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
            <strong>ID:</strong>
            <div>{{element.id}}</div>
          </div>
          <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
            <strong>Name:</strong>
            <div>{{element.name}}</div>
          </div>
          <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
            <strong>Date Created:</strong>
            <div>{{toDate(element.created)}}</div>
          </div>
          <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
            <strong>Feature Type:</strong>
            <div>{{getField("type", element) || "Custom"}}</div>
          </div>
          <!-- <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="" fxLayoutGap="0.75rem">
                        <b>Risk Score:</b>
                        <div>{{element.fields.score || ""}}</div>
                    </div> -->
        </div>
        <div fxFlex fxFill class="" fxLayout="column" fxLayoutGap="0.15rem"
          style="font-size: 1rem; line-height: 1rem; padding: 0.5rem 0; height: 100%;" fxLayoutAlign="end end">
          <mat-checkbox class="example-margin" [appRequireACL]="'Assessment:EditPublic'">Viewable by Public
          </mat-checkbox>
          <div class="actions" fxFlex="nogrow" fxLayout="row" fxLayoutGap="0.25rem"
            style="font-size: 1rem; line-height: 1rem; " fxLayoutAlign="end end">
            <a style="text-decoration: none; padding-right: 0.5rem; border-right: 1px solid; cursor: pointer;"
              routerLink="/app/manage/reports" [queryParams]="{id:element.id}"
              [appRequireACL]="'Assessment:Reports:Manage'">Manage Reports</a>
            <a style="text-decoration: none; padding: 0 0.5rem;" routerLink="/app/map" [queryParams]="{id:element.id}"
              [appRequireACL]="'Map:Access'">View on Map</a>
            <a style="text-decoration: none; padding-left: 0.5rem; border-left: 1px solid;"
              routerLink="/app/manage/view" [queryParams]="{id:element.id}" *ngIf="canManage(element)"
              [appRequireACL]="'Assessment:Manage'">Manage Area</a>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div fxFlex="nogrow" class="" fxLayout="row" fxLayoutGap="0.15rem"
    style="font-size: 1rem; line-height: 1rem; padding: 0.5rem 0; width: 100%;" fxLayoutAlign="end end">
    <mat-paginator class="mat-elevation-z2 fill" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
