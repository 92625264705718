import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CoreExplorerLayers } from 'src/app/map/sidebar/layer-explorer-container/layer-explorer.model';
import { CoreEntity } from 'src/app/_shared/models/core-entity.model';
import { ApiService } from 'src/app/_shared/services/api.service';

export interface DataGroup {
  featureType: string;
  names: string[];
}

export interface SearchReturn {
  value: string;
  label: string;
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-find-something',
  templateUrl: './find-something.component.html',
  styleUrls: ['./find-something.component.scss']
})
export class FindSomethingComponent implements OnInit {
  searchForm: FormGroup = this.fb.group({
    searchGroup: '',
  });
  searchData: DataGroup[] = [];
  errorMsg: string;
  isLoading: boolean;
  lessThanMin: boolean;

  searchGroupOptions: Observable<DataGroup[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FindSomethingComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.searchForm.get('searchGroup')!.valueChanges
      .pipe(
        debounceTime(500),
        tap((val) => {
          this.errorMsg = "";
          this.searchData = [];
          this.isLoading = true;
          if (val?.length < 3) {
            this.lessThanMin = true;
          } else {
            this.lessThanMin = false;
          }
        }),
        switchMap(value => this.api.post<any>('Search', "GetByName", { value }, true)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        this.isLoading = false
        if (!data) {
          this.errorMsg = 'Issue.';
          this.searchData = [];
        } else if (data && data.length === 0) {
          this.errorMsg = 'Not found.';
          this.searchData = [];
        } else {
          this.errorMsg = '';
          this.searchData = data;
        }

        this.searchGroupOptions = of(this.searchData);
      });
  }

  async selected(entry: SearchReturn) {
    console.log(entry);
    const url = 'app/map';
    let id: number;
    let type: string;
    let name = entry.value;

    switch (entry.label) {
      case this.getFeatureTypeName(CoreExplorerLayers.WatershedKey):
        type = 'w'; // not supported yet
        this.router.navigate([url], { queryParams: { name, type } });
        this.dialogRef.close();
        break;
      case this.getFeatureTypeName(CoreExplorerLayers.PrioLandKey):
        type = 'l'; // not supported yet
        this.router.navigate([url], { queryParams: { name, type } });
        this.dialogRef.close();
        break;
      case this.getFeatureTypeName(CoreExplorerLayers.FocalKey):
        type = 'f';
        this.api.post<any>('Focal', 'GetByName', { name }).subscribe(results => {
          if (results?.valid) {
            id = results.result.id;
            this.router.navigate([url], { queryParams: { id, type } });
            this.dialogRef.close();
          }
        });
        break;
      case this.getFeatureTypeName(CoreExplorerLayers.ProjectKey):
        type = 'p';
        this.api.post<any>('Project', 'GetByName', { name }, true).subscribe(results => {
          if (results?.valid) {
            id = results.result.id;
            this.router.navigate([url], { queryParams: { id, type } });
            this.dialogRef.close();
          }
        });
        break;
    
      default:
        break;
    }
  }

  private _filterGroup(value: string): DataGroup[] {
    if (value) {
      return this.searchData
        .map(group => ({featureType: group.featureType, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return this.searchData;
  }

  getFeatureTypeName(name:string): string {
    switch (name) {
      case CoreExplorerLayers.WatershedKey:
        return 'Watershed';
      case CoreExplorerLayers.PrioLandKey:
        return 'Priority Landscape';
      case CoreExplorerLayers.FocalKey:
        return 'Focal Area';
      case CoreExplorerLayers.ProjectKey:
        return 'Project';
    
      default:
        return '';
    }
  }

}
