import { FieldBase } from './field-base';

export class MultiSelectDropdown extends FieldBase<string> {
  controlType = 'multi-select';
  options: { key: string, value: string }[] = [];
  default: any;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.default = options['default'] || null;
  }
}
