import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { GenericCard } from 'src/app/generic-card/generic-card.model';
import { LinkAddDialogComponent } from 'src/app/link/link-add-dialog/link-add-dialog.component';
import { LinkComponent } from 'src/app/link/link.component';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from 'src/app/map/error-dialog/error-dialog.component';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { CoreEntity } from 'src/app/_shared/models/core-entity.model';
import { PamObject, PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';
import { ReportService } from 'src/app/_shared/services/report.service';

export interface PartnerVm {
  name?: string;
  type?: string | string[];
}

@Component({
  selector: 'app-focal-view',
  templateUrl: './focal-view.component.html',
  styleUrls: ['./focal-view.component.scss']
})
export class FocalViewComponent implements OnInit {

  name: string;
  heightValue = '100rem;';
  screenHeight: number;
  screenWidth: number;
  target: any = { fields: {} };
  focal: any;
  fields: any;
  links: any[] = [];
  associatedProjects: any[];
  inactiveProjectsVm: GenericCard[] = [];
  activeProjectsVm: GenericCard[] = [];
  partnersVm: any[] = [];
  focusFields: any[];
  @ViewChild('slimMap') slimMap: SlimMapComponent;
  @ViewChild('focus') focus: DynamicFormComponent;
  @ViewChild('linkData') linkData: LinkComponent;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private reportService: ReportService,
    private dialog: MatDialog,
    private fieldService: FieldService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let name = params['name'];
      this.name = name;
      if (name == undefined)
        return;

      this.api.post<any>('Focal', "GetByName", { name }, true).subscribe(results => {
        if (results && results.valid) {
          let target = results.result;
          this.handleLoad(target);
        }
      });

      this.api.post<any>('Focal', "GetProjectsBySpatial", { name }, true).subscribe(results => {
        if (results && results.valid) {
          let target = results.result;
          this.handleAssociated(target);
        }
      });
    });

    this.onResize();
  }

  routeToExplorer() {
    this.router.navigateByUrl(`/app/map?id=${this.target.id}&type=f`);
  }

  handleLoad(target: any): void {
    this.target = target;

    this.focal = { id: this.target.id, name: this.target.name, fields: this.target.fields };

    if (!this.focal.fields.links) {
      this.links = [];
    } else {
      this.links = this.focal.fields.links;
    }

    const temp = { PROJECT_STRATEGIES: this.focal?.fields?.focus || [] };
    this.focusFields = this.fieldService.getEditableFocusFields(CoreEntity.Project, temp, true)
    this.focus.refreshForm(this.focusFields);

    //reporting on project strategies
    this.reportService.setStrategies(this.focusFields);
    this.reportService.setPriorityLandscape(this.target.fields.priorityLandscape)
    this.updateMapGeom();
  }

  handleAssociated(target: any): void {
    this.associatedProjects = target;
    this.updateProjectVm();
    this.updatePartnersVm();
  }

  updateProjectVm() {
    if (!this.associatedProjects) {
      this.activeProjectsVm = [];
      this.inactiveProjectsVm = [];
      return;
    }

    const vm: GenericCard[] = this.associatedProjects.map(element => {
      return {
        header: element?.name || '',
        subTextTitle: 'Coordinating Organization',
        subTextValue: element?.fields?.leadOrganization || '',
        linkUrl: '/app/project/view',
        linkId: element.id,
        status: element?.fields?.projectStatus || ''
      };
    });

    const activeProj = vm.filter(e => { return (e.status === 'Planned') || e.status === 'In Progress' });
    const inactiveProj = vm.filter(e => { return e.status !== 'Planned' && e.status !== 'In Progress' });
    activeProj.sort(function (a, b) {
      if (a.header < b.header) { return -1; }
      if (a.header > b.header) { return 1; }
      return 0;
    });
    inactiveProj.sort(function (a, b) {
      if (a.header < b.header) { return -1; }
      if (a.header > b.header) { return 1; }
      return 0;
    });


    this.activeProjectsVm = activeProj;
    this.reportService.setActiveProjects(this.activeProjectsVm);
    this.reportService.setAssociatedProjects(this.associatedProjects);
    this.reportService.setCompletedProjects(this.associatedProjects);
    this.inactiveProjectsVm = inactiveProj;
  }

  updatePartnersVm() {
    if (!this.associatedProjects) {
      this.partnersVm = [];
      return;
    }

    let leadOrgs = [];
    if (this.associatedProjects) {
      leadOrgs = this.associatedProjects.map(element => {
        return element?.fields?.leadOrganization;
      });
    }

    // Get partner data
    let partners = [];
    this.associatedProjects.forEach(element => {
      if (element?.fields?.Partners) {
        partners = partners.concat(element.fields.Partners);
      }
    });

    const partnerNames = partners.map(element => {
      return element.name;
    });

    let setPartners = partnerNames.concat(leadOrgs);
    setPartners = Array.from(new Set(setPartners));
    setPartners = setPartners.map(e => {
      return { name: e, type: [] };
    });

    // Sort
    setPartners.sort(function (a, b) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });

    this.partnersVm = setPartners;
    this.reportService.setPartners(this.partnersVm);
  }

  updateMapGeom() {
    if (this.target.fields && this.target.fields.Geometry && this.target.fields.Geometry.wkt && this.target.fields.Geometry.type && this.target.fields.Geometry.type === 'exact') {
      const geomObj: PamShapeData = {
        id: this.target.id,
        name: this.target.name,
        fields: {
          Geometry: { ... this.target.fields.Geometry }
        }
      }
      this.slimMap.update(geomObj);
      this.reportService.setMapGeometry(geomObj)
    }
  }

  /**
   * Helpful Links
   */

  addLink() {
    let linkDialog = this.dialog.open(LinkAddDialogComponent, {
      width: '35rem',
      disableClose: true
    });


    linkDialog.afterClosed().subscribe(data => {
      if (data) {
        // If there are data, save
        this.newLinkData(data);
        const payload = this.createPayload();
        this.update(payload);
      }
    });
  }

  removeLinkData(_: any) {
    this.links = this.links.filter(function (obj) {
      return obj.linkUrl !== _.linkUrl;
    });

    this.sortLinks();
  }

  createPayload() {
    let formattedObj: PamObject = { id: this.target.id, name: this.target.name, fields: {} };
    formattedObj.fields.links = this.links;

    return formattedObj;
  }



  newLinkData(data: any) {
    this.links.push(data);
    this.sortLinks();
  }

  sortLinks() {
    this.links.sort(function (a, b) {
      return a.linkDesc.toLowerCase() > b.linkDesc.toLowerCase() ? 1 : b.linkDesc.toLowerCase() > a.linkDesc.toLowerCase() ? -1 : 0
    });
  }

  update(obj) {
    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Saving Record.",
      disableClose: true
    });

    this.api.post<any>("Focal", "Update", obj, true).subscribe(res => {
      if (!res.valid) {
        saveRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleLoad(res.result)
      saveRef.close();

      this.sortLinks();

    }, err => {
      saveRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  triggerLinkDelete(_: any) {
    const foundData = this.links.find((link) => { return link.linkUrl === _.linkUrl });
    if (foundData) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35rem',
        data: { title: 'Confirm Delete', text: 'Are you sure you want to delete this Helpful Link?', confirm: "Yes", cancel: "No" },
        disableClose: false
      });


      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Delete
          this.removeLinkData(_)
          const payload = this.createPayload();
          this.commitLinkDelete(payload);
        } else {
          // No action needed
        }
      });
    }
  }

  async commitLinkDelete(data: any) {
    const delRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Deleting Link.",
      disableClose: true
    });

    this.api.post<any>("Focal", "Update", data, true).subscribe(res => {
      if (!res.valid) {
        delRef.close();
        this.dialog.open(ErrorDialogComponent, {
          width: '25rem',
          data: res.messages,
          disableClose: false
        });
        return;
      }

      this.handleLoad(res.result)
      delRef.close();

      this.sortLinks();

    }, err => {
      delRef.close();
      this.dialog.open(ErrorDialogComponent, {
        width: '25rem',
        data: ["API Error occurred", err.message],
        disableClose: false
      });
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1366) {
      this.heightValue = '125rem;';
    } else {
      this.heightValue = '100rem;';
    }
  }

  routerNavigate(){
    this.router.navigateByUrl('/app/focal/report?name=' + this.name);
  }

}
