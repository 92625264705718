import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-funding-source',
  templateUrl: './project-funding-source.component.html',
  styleUrls: ['./project-funding-source.component.scss']
})
export class ProjectFundingSourceComponent implements OnInit {

  @Input() source: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.source);
  }

}
