<div style="display:flex; align-content: center; padding: 5rem;">
  <mat-card class="max-width-30rem">
    <mat-card-title>
      <p>Authorizing ...</p>
    </mat-card-title>
    <mat-card-content>
      <p>If the login doesn't open automatically, click the Sign In button below.</p>
      <br/>
      <p style="width: 100%; display: flex;" class="">
        <a class="linkAlt buttonLike" style="margin: auto auto" (click)="login()">Sign In</a>
      </p>
    </mat-card-content>
  </mat-card>
</div>
