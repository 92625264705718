/* tslint:disable */

import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MapCategory, MapLayer } from '../../../_shared/models/map/config.model';
import { Result } from '../../../_shared/services/map/identify.service';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss']
})
export class SidebarContentComponent implements OnInit {
  @Output() onDrawerClose: EventEmitter<null> = new EventEmitter();

  @Output() onToolChange: EventEmitter<'layers' | 'legend' | 'identify'> = new EventEmitter();

  @Output() zoomToExtent: EventEmitter<number[]> = new EventEmitter();

  @Output() tempHighlightFeature: EventEmitter<any> = new EventEmitter();

  @Input() categories: MapCategory[];


  @Output() selectLayer: EventEmitter<MapLayer> = new EventEmitter();
  @Output() deselectLayer: EventEmitter<MapLayer> = new EventEmitter();


  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter();


  @Output() opacityChange: EventEmitter<any> = new EventEmitter();

  @Input() identifyResults: any;
  @Input() mapZoom: any;


  @Output() highlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() unhighlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() zoomToFeature: EventEmitter<Result> = new EventEmitter();
  @Output() importFeature: EventEmitter<Result> = new EventEmitter();
  @Output() returnToIdentifyExtent: EventEmitter<boolean> = new EventEmitter();
  @Output() identifyExtentClick: EventEmitter<boolean> = new EventEmitter();

  selected: string = 'layers';


  @ViewChild('catcont') public catcont: any;

  constructor() { }

  ngOnInit(): void {
  }

  public onToolSelect(e) {
    console.log(e)
    this.selected = e.value;

    this.onToolChange.emit(e);
  }

  public openIdentify() {
    this.selected = 'identify';
  }

  public scrollToResult(layer: string, id: string) {
    this.catcont.scrollToResult(layer, id);
  }

  layerSelected(layer: MapLayer) {
    this.selectLayer.next(layer);
  }

  layerDeselected(layer: MapLayer) {
    this.deselectLayer.next(layer);
  }

  opacityChanged(evt: any) {
    this.opacityChange.next(evt);
  }

  zoomExtent(result) {
    console.log('layer c');
    this.zoomToExtent.next(result);
  }

  tempHighlight(geom) {
    this.tempHighlightFeature.next(geom);
  }

}
