import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version : string = "v0.1.7";
  buildNumber : string = "-";
  buildDate : string = "-"
  constructor(private http : HttpClient) { }

  ngOnInit(): void {
    this.http.get<any>("/buildInfo.json").subscribe( res => {
      this.version = res.packageVersion;
      this.buildNumber = res.buildNumber;
      this.buildDate = res.buildDate;
    })
  }

}
