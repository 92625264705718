import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { allRoles, Role } from 'src/app/_shared/models/roles.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AuthorizationService } from 'src/app/_shared/services/authorization.service';
import { User } from '../../admin.model';
import { ManageRolesComponent } from '../../users/manage-roles/manage-roles.component';
import { UpdateOrgComponent } from '../update-org/update-org.component';
import { UpdateErrorDialogComponent } from './update-error-dialog/update-error-dialog.component';

@Component({
  selector: 'app-org-users',
  templateUrl: './org-users.component.html',
  styleUrls: ['./org-users.component.scss']
})
export class OrgUsersComponent implements OnInit {
  displayedColumns: string[] = ['last', 'first', 'email', 'organization', 'actions'];
  users: User[];
  dataSource = new MatTableDataSource<User>([]);
  allRoles: Role[] = allRoles();
  acceptableRoles: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private auth: AuthorizationService
    ) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'lastfirst', start: 'asc' }) as MatSortable);
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (sortHeaderId == 'lastfirst') {
        return (data['last'] + data['first']).toLocaleLowerCase();
      }

      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };

    this.retrieveUsers();
  }

  retrieveUsers() {

    const saveRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Loading Users",
      disableClose: true
    });


    this.api.post<User[]>("Users", "GetAll", true).subscribe(res => {

      this.users = res;
      this.initDataSource();
      saveRef.close();
    })
  }

  initDataSource() {
    this.dataSource.data = this.users;// = new MatTableDataSource<User>(this.users);
  }

  updateOrganization(user: User) {
    const dialogRef = this.dialog.open(UpdateOrgComponent, {
      width: '35rem',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let req =
        {
          id: user.id,
          email: result.email,
          updatedOrg: result.organization.name
        };

        this.api.post<any>("Users", "UpdateUserOrganization", req, true).subscribe(usr => {
          if (!usr.valid && usr.messages.includes("UserHasProjectEditor")) {
            this.dialog.open(UpdateErrorDialogComponent, {
              width: '45rem'
            });
          } else {
            this.retrieveUsers();
          }
        });

      }
      console.log('The dialog was closed', result);

    });
  }
}
