import { FieldBase } from "./field-base";
import { CheckboxField } from "./field-checkbox";
import { DateField } from "./field-date";
import { DropdownField } from "./field-dropdown";
import { DynamicDropdownField } from "./field-dynamic-dropdown";
import { MultiSelectDropdown } from "./field-multi-select";
import { TextareaField } from "./field-textarea";
import { TextboxField } from "./field-textbox";
import { Fields } from "./fields.model";

export class EditableFields {

    // Project Fields - TODO these are temp values for testing
    public static ProjectFields: FieldBase<any>[] = [

        new TextboxField({
            key: Fields.ProjectName,
            label: 'Project Name',
            value: '',
            example: 'Ex: "Sandia Mountain Forest Health"',
            required: true,
            type: 'text',
            hint: 'Enter a project name, providing geographical context.  Example: "Sandia Mountain Forest Health."',
            order: 1,
            maxlength: 100,
            minlength: 3
        }),

        new DropdownField({
            key: Fields.ProjectType,
            label: 'Project Type',
            required: true,
            options: [
                { key: 'Forest Restoration', value: 'Forest Restoration' },
                { key: 'Watershed Health', value: 'Watershed Health' },
                { key: 'Riparian Restoration', value: 'Riparian Restoration' },
                { key: 'Community Protection', value: 'Community Protection' },
                { key: 'Timber or Forest Utilization', value: 'Timber or Forest Utilization' },
                { key: 'Range Improvement', value: 'Range Improvement' },
                { key: 'Planning/Surveys', value: 'Planning/Surveys' },
                { key: 'Other', value: 'Other' },
            ],
            hint: `Pick a Project Type. Use 'Other" for Education, Outreach, Monitoring, etc.`,
            order: 2
        }),

        new DropdownField({
            key: Fields.ProjectStatus,
            label: 'Project Status',
            required: true,
            options: [
                { key: 'Planned', value: 'Planned' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Complete', value: 'Complete' },
                { key: 'Cancelled', value: 'Cancelled' },
                { key: 'Deferred', value: 'Deferred' },
                { key: 'On Hold', value: 'On Hold' }
            ],
            hint: `Pick the current Project Status.`,
            default: 'Planned',
            order: 3
        }),

        // new DropdownField({
        //     key: Fields.FocalArea,
        //     label: 'Focal Area',
        //     required: false,
        //     options: [
        //         { key: 'Buffer Zone', value: 'Buffer Zone' },
        //         { key: 'Disturbance', value: 'Disturbance' },
        //         { key: 'Lake', value: 'Lake' },
        //         { key: 'Polygon of Interest', value: 'Polygon of Interest' }
        //     ],
        //     order: 4
        // }),
    ];

    // Project Narrative Fields
    public static ProjectNarrativeFields: FieldBase<any>[] = [

        new TextareaField({
            key: Fields.ProjectNarrative,
            label: 'Project Narrative',
            value: '',
            example: 'Placeholder text here',
            required: true,
            type: 'text',
            order: 1,
            maxlength: 500,
            minlength: 5,
            hint: `Describe this project relative to larger initiatives (if any), its proposed location, land ownership(s), goals, partners, funding sources, and expected start and end dates.`,
            rows: 5
        }),
    ];


    // Project Focus Fields
    public static ProjectFocusFields: FieldBase<any>[] = [

        new MultiSelectDropdown({
            key: Fields.ProjectFocus,
            label: 'Project Focus',
            required: true,
            options: [
                { key: 'Biodiversity', value: 'Biodiversity' },
                { key: 'Carbon Storage', value: 'Carbon Storage' },
                { key: 'Climate Change', value: 'Climate Change' },
                { key: 'Cultural Use', value: 'Cultural Use' },
                { key: 'Development and Fragmentation', value: 'Development and Fragmentation' },
                { key: 'Forest and Woodland Health', value: 'Forest and Woodland Health' },
                { key: 'Human Activity Impacts', value: 'Human Activity Impacts' },
                { key: 'Indigenous Communities', value: 'Indigenous Communities' },
                { key: 'Insects and Disease', value: 'Insects and Disease' },
                { key: 'Post Wildfire Hazards', value: 'Post Wildfire Hazards' },
                { key: 'Range/Grazing', value: 'Range/Grazing' },
                { key: 'Recreation', value: 'Recreation' },

                { key: 'Soil Health', value: 'Soil Health' },
                { key: 'Timber/Utilization', value: 'Timber/Utilization' },
                { key: 'Traditional Communities', value: 'Traditional Communities' },
                { key: 'Urban Forests and Communities', value: 'Urban Forests and Communities' },
                { key: 'Water Quality and Supply', value: 'Water Quality and Supply' },
                { key: 'Wildfire', value: 'Wildfire' },
                { key: 'Wildland Communities', value: 'Wildland Communities' }
            ],
            hint: `Select one or more concerns and/or interests that the Project addresses. Note that 'Biodiversity' refers to habitat for terrestrial, aerial, and aquatic species.`,
            order: 1
        }),

        new MultiSelectDropdown({
            key: Fields.ProjectStrategies,
            label: 'Strategies',
            required: true,
            options: [
                { key: 'Restore Forests and Watersheds', value: 'Restore Forests and Watersheds' },
                { key: 'Manage Fire for Public Safety and Ecological Benefits', value: 'Manage Fire for Public Safety and Ecological Benefits' },
                { key: 'Promote Private Land Stewardship', value: 'Promote Private Land Stewardship' },
                { key: 'Manage Utility Rights of Way', value: 'Manage Utility Rights of Way' },
                { key: 'Protect and Conserve Rare Plants', value: 'Protect and Conserve Rare Plants' },
                { key: 'Reforest Burned Areas and Other Lands', value: 'Reforest Burned Areas and Other Lands' },
                { key: 'Enhance Urban and Community Forests', value: 'Enhance Urban and Community Forests' },
                { key: 'Build Sustainable Communities, Businesses, and Jobs', value: 'Build Sustainable Communities, Businesses, and Jobs' },
                { key: 'Protect and Conserve Lands', value: 'Protect and Conserve Lands' },
                { key: 'Enhance Outdoor Recreation', value: 'Enhance Outdoor Recreation' }
            ],
            hint: `Select one or more Forest Action Plan Strategies to be used to accomplish project goals.`,
            order: 2
        }),
    ];

    public static ActivityFields: FieldBase<any>[] = [

        new TextboxField({
            key: Fields.ActivityName,
            label: 'Activity Name',
            value: '',
            example: '',
            required: true,
            type: 'text',
            hint: 'Enter a short name for this activity.',
            order: 1,
            maxlength: 50,
            minlength: 8
        }),

        new DropdownField({
            key: Fields.ActivityStatus,
            label: 'Status',
            required: true,
            options: [
                { key: 'Proposed', value: 'Proposed' },
                { key: 'Planned', value: 'Planned' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Completed', value: 'Completed' },
                { key: 'Cancelled', value: 'Cancelled' },
            ],
            default: 'Proposed',
            order: 1
        }),

        new DynamicDropdownField({
            key: Fields.ActivityWorkAgent,
            label: 'Activity Manager',
            required: true,
            options: [],
            dataObjName: 'Organization',
            dataAction: 'GetAllWorkAgents',
            getField: 'name',
            order: 1,
            orgDefault: true
        }),

        new DateField({
            key: Fields.PlannedCompletionDate,
            type: 'date',
            label: 'Planned Completion Date',
            value: new Date(),
            required: true
        }),


    ];


    public static ActivityWorkFields: FieldBase<any>[] = [

        new DateField({
            key: Fields.ActualCompletionDate,
            type: 'date',
            label: 'Actual Completion Date',
            required: false
        }),

        new TextboxField({
            key: Fields.ActivityUnit,
            label: 'Unit/Block',
            value: '',
            example: '',
            required: false,
            type: 'text',
            hint: '',
            order: 1,
            maxlength: 20,
            minlength: 1
        }),

        new TextboxField({
            key: Fields.ActivityAcres,
            label: 'Reported Acres',
            value: '',
            example: '',
            required: false,
            type: 'number',
            hint: '',
            order: 1,
            decimalCappedNonNegative: true,
            decimalCapValue: 1
        }),

        new CheckboxField({
            key: Fields.RiskAdjacent,
            type: 'checkbox',
            label: 'Adjacent to & within a 5-Mile Buffer of 1 or More Communities at Risk',
            value: false,
            required: false
        })
    ];

    // Project Opportunity Fields
    public static OppoortunityFields: FieldBase<any>[] = [

        new TextareaField({
            key: Fields.ProjectOpportunity,
            label: 'Opportunity Description',
            value: '',
            example: 'Placeholder text here',
            required: true,
            type: 'text',
            order: 1,
            maxlength: 200,
            minlength: 3,
            rows: 5
        }),
    ];

    // Project Impediment Fields
    public static ImpedimentFields: FieldBase<any>[] = [

        new TextareaField({
            key: Fields.ProjectImpediment,
            label: 'Impediment Description',
            value: '',
            example: 'Placeholder text here',
            required: true,
            type: 'text',
            order: 1,
            maxlength: 200,
            minlength: 3,
            rows: 5
        }),
    ];


    public static ActivityModalFields: FieldBase<any>[] = [

        new DropdownField({
            key: Fields.ActivityFocus,
            required: true,
            options: [
                { key: 'Planned', value: 'Planned' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Complete', value: 'Complete' },
                { key: 'Cancelled', value: 'Cancelled' },
                { key: 'Proposed', value: 'Proposed' }
            ],
            default: 'Proposed',
            order: 1
        }),

        new DropdownField({
            key: Fields.ActivityType,
            label: 'Activity Type',
            required: true,
            options: [
                { key: 'Forest Restoration', value: 'Forest Restoration' },
                { key: 'Watershed Health', value: 'Watershed Health' },
                { key: 'Riparian Restoration', value: 'Riparian Restoration' },
                { key: 'Community Protection', value: 'Community Protection' },
                { key: 'Timber or Forest Utilization', value: 'Timber or Forest Utilization' },
                { key: 'Range Improvement', value: 'Range Improvement' },
                { key: 'Planning/Surveys', value: 'Planning/Surveys' },
                { key: 'Other', value: 'Other' },
            ],
            hint: ``,
            order: 2
        })
    ];

    public static FundingModalFields: FieldBase<any>[] = [

        new TextboxField({
            key: Fields.PlannedAmount,
            type: 'number',
            label: 'Planned Amount',
            required: true,
            order: 1
        }),

        new TextboxField({
            key: Fields.ActualAmount,
            type: 'number',
            label: 'Actual Amount',
            required: false,
            order: 1
        }),

        new CheckboxField({
            key: Fields.FundingMatch,
            type: 'checkbox',
            label: "Is this funding counted as 'match'?",
            value: false,
            required: false
        }),

    ];

    public static FocalFields: FieldBase<any>[] = [

        new TextboxField({
            key: Fields.FocalName,
            label: 'Focal Area Name',
            value: '',
            example: '',
            required: true,
            type: 'text',
            hint: '',
            order: 1
        }),
    ];


    /**
     * Private helper functions.
     *
     */

    private static getDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        return dateString;
    }
}
