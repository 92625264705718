import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { SlimMapComponent } from 'src/app/slim-map/slim-map.component';
import { DynamicFormComponent } from 'src/app/_shared/components/forms/dynamic-form/dynamic-form.component';
import { DirectiveLogic } from 'src/app/_shared/directives/directive-logic';
import { Fields } from 'src/app/_shared/models/dynamic-forms/fields.model';
import { PamShapeData } from 'src/app/_shared/models/pam-object.model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { FieldService } from 'src/app/_shared/services/forms/field.service';

@Component({
  selector: 'app-activity-create',
  templateUrl: './activity-create.component.html',
  styleUrls: ['./activity-create.component.scss']
})
export class ActivityCreateComponent implements OnInit {

  fields: any[];
  fieldsWork: any[];
  primaryFocus: any;
  primaryType: any;
  featureGeom: PamShapeData;
  isSpatial = true;
  projectId: number;
  screenHeight: number;
  screenWidth: number;
  leadOrg: string;
  heightValue = '100rem;'

  @ViewChild('slimMap') slimMap: SlimMapComponent;
  @ViewChild('activityPlanning') activityPlanning: DynamicFormComponent
  @ViewChild('activityWork') activityWork: DynamicFormComponent

  constructor(
    private fieldService: FieldService,
    private api: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.fields = this.fieldService.getFields('ActivityFields');
    this.fieldsWork = this.fieldService.getFields('ActivityWorkFields');

    // Unpack Route
    this.route.queryParams.subscribe(params => {
      this.primaryFocus = params['focus'];
      this.primaryType = params['type'];
      this.isSpatial = (params['spatial'].toLowerCase() === 'true');
      this.projectId = parseInt(params['projectid']);
    });

    // Remove spatial fields if needed
    if (!this.isSpatial) {
      this.removeSpatialFields();
    }

    // Load reference shape form project
    if (this.isSpatial) {
      this.api.post<any>("Project", "Get", [this.projectId]).subscribe(results => {
        if (results && results.length) {
          let target = results[0];
          this.handleProjectLoad(target);
        }
      });
    }

  }

  createActivity(newObj: any) {
    let formattedObj: any = {};
    formattedObj.name = newObj.activityPlanningData.ACTIVITY_NAME;
    formattedObj.fields = {};
    formattedObj.fields.activityStatus = newObj.activityPlanningData.ACTIVITY_STATUS;
    formattedObj.fields.activityWorkAgent = newObj.activityPlanningData.ACTIVITY_WORK_AGENT;
    formattedObj.fields.plannedCompletionDate = newObj.activityPlanningData.PLANNED_COMPLETION_DATE;
    formattedObj.fields.activityUnit = newObj.activityWorkData.ACTIVITY_UNIT;
    formattedObj.fields.activityAcres = DirectiveLogic.DecimalCap(newObj.activityWorkData.ACTIVITY_ACRES, 1);
    formattedObj.fields.actualCompletionDate = newObj.activityWorkData.ACTUAL_COMPLETION_DATE;
    formattedObj.fields.riskAdjacent = newObj.activityWorkData.RISK_ADJACENT;
    formattedObj.fields.isSpatial = newObj.IS_SPATIAL;
    formattedObj.fields.activityType = newObj.ACTIVITY_TYPE;
    formattedObj.fields.primaryFocus = newObj.PRIMARY_FOCUS;
    if (this.isSpatial) {
      formattedObj.fields.Geometry = this.featureGeom.fields.Geometry;
    }

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      width: '25rem',
      data: "Creating Activity.",
      disableClose: true
    });
    this.api.post<any>('Activity', 'Create', { linkToId: this.projectId, newObject: formattedObj }, true).subscribe(result => {
      console.log('result');
      console.log(result);
      dialogRef.close();
      if (!result.valid) {
        //Backend validation didnt pass show the errors
        //this.showErrors(result.messages);
      }
      else {
        //Clean some stuff up
        //We should also keep a reference to the ID I guess?
        //this.refreshMap();
        this.showSaved(result.result.id);
      }
    }, error => {
      dialogRef.close();
      //Backend gave us a non-200 response
      //this.showErrors(["The api responded incorrectly.", JSON.stringify(error, undefined, 2)]);
    });
  }

  trySave(_: any): void {
    const activityPlanningData = this.activityPlanning.getPayload();
    const activityWorkData = this.activityWork.getPayload();

    let merged = {
      activityPlanningData,
      activityWorkData,
      IS_SPATIAL: this.isSpatial,
      ACTIVITY_TYPE: this.primaryType,
      PRIMARY_FOCUS: this.primaryFocus,
    };

    this.createActivity(merged);
  }

  isValid(): boolean {
    if (this.activityPlanning && this.activityWork && this.isSpatial) {
      const hasGeom = this.featureGeom ? true : false;
      return (this.activityPlanning.isValid() && this.activityWork.isValid() && hasGeom);
    } else if (this.activityPlanning && this.activityWork && !this.isSpatial) {
      return (this.activityPlanning.isValid() && this.activityWork.isValid());
    } else {
      return false;
    }
  }

  cancel() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35rem',
      data: { title: 'Back to Project', text: 'Are you sure you want to cancel adding this Activity?', confirm: "Yes", cancel: "No" },
      disableClose: false
    });


    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.router.navigateByUrl(`/app/project/view?id=${this.projectId}`);
      }
    });
  }

  showSaved(id: number): void {
    this.router.navigateByUrl(`/app/activity/view?id=${id}&projectid=${this.projectId}`);
  }

  newShapeData(data: PamShapeData) {
    console.log('Got Data');
    console.log(data);
    this.featureGeom = data;
  }

  saveHint() {
    if (!this.featureGeom && this.isSpatial)
      return 'You must map the Activity boundary in order to continue.';
    else
      return '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1366) {
      this.heightValue = '80rem;';
    } else {
      this.heightValue = '60rem;';
    }
  }

  removeSpatialFields() {
    const spatialFields = [Fields.ActivityAcres];

    spatialFields.forEach(element => {
      const foundIdx = this.fieldsWork.findIndex((data) => {
        return data.key === element;
      });
      if (foundIdx) {
        this.fieldsWork.splice(foundIdx, 1);
      }
    });
  }

  handleProjectLoad(target: any) {
    if (target && target?.fields?.Geometry && target?.fields?.Geometry?.wkt) {
      const geomObj: PamShapeData = {
        id: target.id,
        name: target.name,
        fields: {
          Geometry: { ... target.fields?.Geometry }
        }
      }
      if (this.slimMap) {
        this.slimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)');
      } else {
        // First page load, isSpatial check is likely underway and DOM has not
        // rendered the slim map comp yet.  OpenLayers needs a moment.
        const that = this;
        setTimeout(() => {
          that.slimMap.updateRefShape(geomObj, 'rgba(185, 232, 42, 0.25)', 'rgba(0, 19, 2, 0.5)');
        }, 1000);
      }
    }
  }
}
