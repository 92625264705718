<div id="appScroller">

  <!-- Main page container -->
  <div fxFlex fxLayout="column" class="appMainContainer" fxLayoutGap="0.15rem" [style]="'height:' + heightValue">

    <!-- Header bar and submit buttons -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth"
      style="padding: 0 0.25rem; height: 2rem; padding-bottom: 0.625rem;">

      <!-- Title -->
      <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" class="appFlexShrink appFullWidth">
        <h1 class="appProjectHeader">{{ this.target?.name || '' }}</h1>
      </div>

      <!-- Back to Project buttons -->
      <button style="margin-right: 1rem;" mat-flat-button color="warn"
        class="align-right appConfirmButton appSquareButton" [appRequireAclEapoPartner]="['Activity:Edit', leadOrg, partners]" (click)="triggerDelete()">Delete</button>

      <button style="margin-right: 1rem;" mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
        (click)="routerNavigate()">Project Details</button>

        <!-- Back to Project buttons -->
      <button *ngIf="this.isSpatial" mat-flat-button color="accent" class="align-right appConfirmButton appSquareButton"
      (click)="routeToExplorer()">View in Explorer</button>

      <!-- End Header bar and submit buttons -->
    </div>

    <!-- Map/Details -->
    <div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">

      <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard appSolidCard"
        [fxFlex]="dynamicFlexContainerWidth" fxLayoutGap="0.65rem">

        <div *ngIf="isSpatial" style="margin-left: 0.5rem;" fxFlex="50" fxFill fxLayout="column"
          class="appFullWidth appFullHeight appCardContainer appBottomRounded" fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0 appMapCard" fxFlex="100">
            <mat-card-header>
              <mat-card-title style="margin-left: 1rem;" class="appH1Title">Activity Map</mat-card-title>
              <div class="appEditIconContainer" [appRequireAclEapoPartner]="['Activity:Edit', leadOrg, partners]">
                <app-edit-button-cluster [editModeOn]="this.editMapOn" [formsAreValid]="this.formsAreValid"
                  (saveClicked)="this.toggleEditMap()" (cancelClicked)="this.cancelEditMap()"
                  (editClicked)="this.toggleEditMap()" [disabledEdit]="this.editModeOn"
                  [optionalEditTooltip]="this.editModeOn ? 'You must end the active edits underway on the page before editing the Activity boundary.' : ''">
                </app-edit-button-cluster>
              </div>
            </mat-card-header>
            <mat-card-content class="appMapCardContent">
              <app-slim-map *ngIf="!editMapOn" #slimMap [showEditCreateButtons]="false"
                (shapeUpdated)="newShapeData($event)" [strokeColor]="'rgba(48, 0, 0, 0.5)'"
                [fillColor]="'rgba(255, 42, 42, 0.5)'">
              </app-slim-map>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- End map -->

        <!-- Activity Details -->
        <div [fxFlex]="dynamicFlexChildWidth" fxFill fxLayout="column"
          class="appFullWidth appFullHeight appCardContainer appBottomRounded" fxLayoutGap="0.5rem">

          <mat-card class="appFullWidth appFullHeight mat-elevation-z0" fxFlex="100">
            <mat-card-header class="appProjectHeaderContainer">
              <mat-card-title class="appH1Title">General Details
              </mat-card-title>
              <div class="appEditIconContainer" [appRequireAclEapoPartner]="['Activity:Edit', leadOrg, partners]">
                <app-edit-button-cluster [editModeOn]="this.editModeOn" [formsAreValid]="this.formsAreValid"
                  (saveClicked)="this.toggleEditMode()" (cancelClicked)="this.cancelEdit()"
                  (editClicked)="this.toggleEditMode()">
                </app-edit-button-cluster>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="!this.editModeOn" style="font-weight: bold; margin-top:1rem;margin-bottom: 0.1rem;" class="appFieldLabel">Activity
                ID
              </div>
              <div *ngIf="!this.editModeOn" style="margin-bottom: 1rem;" class="appFieldContent">
                {{ this.target.id || '' }}
              </div>
              <div *ngIf="!this.editModeOn" style="font-weight: bold; margin-top:1rem;margin-bottom: 0.1rem;" class="appFieldLabel">Activity
                Type
              </div>
              <div *ngIf="!this.editModeOn" style="margin-bottom: 1rem;" class="appFieldContent">
                {{ this.activityType || '' }}
              </div>
              <div *ngIf="!this.editModeOn" style="font-weight: bold; margin-top:1rem;margin-bottom: 0.1rem;" class="appFieldLabel">Primary
                Activity Focus</div>
              <div *ngIf="!this.editModeOn" style="margin-bottom: 1rem;" class="appFieldContent">
                {{ this.primaryFocus || '' }}
              </div>
              <div class="appSpacer" *ngIf="this.editModeOn"></div>
              <app-dynamic-form #coreDetails [activeEdit]="this.editModeOn" [fields]="this.fields"></app-dynamic-form>
              <app-dynamic-form #additionalDetails [activeEdit]="this.editModeOn" [fields]="this.fieldsWork">
              </app-dynamic-form>
              <div *ngIf="!this.editModeOn && this.isSpatial">
                <div style="font-weight: bold;" class="appFieldLabel">GIS Acres</div>
                <div style="margin-bottom: 1rem;" class="appFieldContent">
                  {{ this.target?.geometry?.acres | number : '1.1-1' || '' }}</div>
              </div>
              <div *ngIf="!this.editModeOn" style="font-weight: bold; margin-top:1rem;margin-bottom: 0.1rem;" class="appIdText appFieldLabel">Total Planned
                Funding</div>
              <div *ngIf="!this.editModeOn" style="margin-bottom: 1rem;" class="appIdText appFieldContent">
                {{ this.amountTotals?.plannedAmount || 0 | currency }}</div>
              <div *ngIf="!this.editModeOn" style="font-weight: bold; margin-top:1rem;margin-bottom: 0.1rem;" class="appIdText appFieldLabel">Total Actual
                Funding</div>
              <div *ngIf="!this.editModeOn" style="margin-bottom: 1rem;" class="appIdText appFieldContent">
                {{ this.amountTotals?.actualAmount || 0 | currency }}</div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card>
    </div>

    <div fxFlex="35" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
      class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden">

      <div fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded" fxLayoutGap="0.5rem">
        <mat-card class="appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Funding Sources
            </mat-card-title>
            <div class="appCollapseIconContainer appEditIconContainer">
              <button mat-flat-button class="appAddText appBrownActionButton" [appRequireAclEapoPartner]="['Activity:Edit', leadOrg, partners]" (click)="addFunding()">+ Add</button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="appFundingOverflow">
              <ul id="fundings" *ngIf="!isFundingsEmpty()">
                <li *ngFor="let funding of fundings">
                  <app-funding #funding [funding]="funding" [projectLeadOrg]="leadOrg" [partners]="partners"
                    (fundingToRemove)="triggerFundingDelete($event)"
                    (fundingUpdated)="getFundingRecords(this.activityId)"></app-funding>
                </li>
              </ul>
              <div *ngIf="isFundingsEmpty()">
                <div style="margin-top: 1rem;">No funding has been added for this Activity.</div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Project Geography -->
      <div *ngIf="this.isSpatial" fxFlex="50" fxFill fxLayout="column" class="appCardContainer appBottomRounded"
        fxLayoutGap="0.5rem">
        <mat-card class="appSolidCard mat-elevation-z0" fxFlex="100">
          <mat-card-header class="appProjectHeaderContainer">
            <mat-card-title class="appH1Title">Activity Geography
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="appGeogOverflow">
              <app-project-geo-card #projGeo [projectId]="this.target?.id || null" [entityType]="'activity'">
              </app-project-geo-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>

  </div>
  <!-- End Main page container -->
</div>
