<!-- Zoom Check Failed (Map zoomed too far out for a layer -->
<section class="layerListItem" *ngIf="minZoomCheck()">
    <div style="width: 100%; display: flex; flex-direction: column" class="mat-elevation-z2 epansion-panel-like">
        <p>{{ layer.name }}</p>
        <p style="text-align: center">
            This layer has a zoom limit in place. Please zoom in further to identify this layer. The minimum zoom is {{layer.identify.minZoom}}; the current zoom is {{floor(mapZoom)}}.
        </p>
    </div>
</section>

<!-- The layer has  not been identified -->
<section class="layerListItem" *ngIf="isIdle()">
    <div style="width: 100%; display: flex; flex-direction: column" class="mat-elevation-z2 epansion-panel-like">
        <p>{{ layer.name }}</p>
        <p style="text-align: center">
            Not identified yet.
        </p>
    </div>
</section>

<!-- Layer is activly being searched (Awaiting REST) -->
<section class="layerListItem" *ngIf="isSearching()">
    <div style="width: 100%; display: flex; flex-direction: column" class="mat-elevation-z2 epansion-panel-like">
        <p>{{ layer.name }}</p>
        <p style="text-align: center">
            Searching...
        </p>
        <div style="margin:auto">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</section>

<!-- Layer has been searched, no results -->
<section class="layerListItem" *ngIf="!isSearching() && !isIdle() && !hasResults() && !minZoomCheck()">
    <div style="width: 100%; display: flex; flex-direction: column" class="mat-elevation-z2 epansion-panel-like">
        <p>{{ layer.name }}</p>
        <p style="text-align: center">
            No results.
        </p>
    </div>
</section>

<!-- Layer has been searched, results were found -->
<section class="layerListItem" *ngIf="hasResults()">
    <mat-expansion-panel #panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                ({{results.length}}) - {{ layer.name }}
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxFlex="grow" fxLayout="column" fxLayoutGap="0.5rem" style="padding: 0 1rem;">
            <div *ngIf="error">
                <p style="color: red; font-weight: bold; font-size: 1rem;">
                    {{error}}
                </p>
            </div>
            <div #result fxFlex="nogrow" fxLayoutGap="0.25rem" fxLayout="column" *ngFor="let result of results" [id]="result.id" style="width: 100%; padding: 0.5rem 0">
                <div fxFlex="nogrow" fxLayoutGap="0.25rem" fxLayout="column" style="border: 1px solid #CCC; border-radius: 0.25rem; padding: 0.5rem;"
                    [ngClass]="result.highlight ? 'highlighted' : 'nothighlighted'" class="" (mouseenter)="highlight(result)"
                    (mouseleave)="unhighlight(result)">
                    <div fxFlex="nogrow" fxLayoutGap="0.25rem" fxLayout="column" style="padding:0.25rem">
                        <div fxFlex="grow" fxLayout="column" fxLayoutGap="0.25rem" *ngFor="let field of result.data" style="font-size:0.95rem; line-height: 0.95rem; padding: 0.1rem 0.3rem;">
                            <strong style="flex-shrink: 0">{{field.name}}:</strong>
                            <div>{{field.value}}</div>
                        </div>
                    </div>
                    <div class="divider" [appRequireAnyACL]="['Map:ImportFromIdentify','Map:ZoomFromIdentify']">
                        <p></p>
                    </div>
                    <button mat-flat-button color="accent" [appRequireACL]="'Map:ImportFromIdentify'" *ngIf="layer.identify.import">{{ isCreating ? 'IMPORT SHAPE' : 'CREATE AREA OF INTEREST' }}</button>
                    <button mat-button color="primary" [appRequireACL]="'Map:ZoomFromIdentify'" (click)="zoomTo(result)">ZOOM TO</button>
                </div>

            </div>
            <div style="width: 100%; display: flex; flex-direction: column" *ngIf="isIdle()">
                
            </div>
        </div>
    </mat-expansion-panel>
</section>