<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        Please indicate which Organization you are requesting Project Editor capabilities for.
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions style="width: 100%;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
    <button mat-button color="primary" (click)="onNoClick()" style="width: 12rem;">CLOSE</button>
  </div>
</div>
