import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';


@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent {
}
