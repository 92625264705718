import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shared-nav',
  templateUrl: './shared-nav.component.html',
  styleUrls: ['./shared-nav.component.scss']
})
export class SharedNavComponent implements OnInit {

  links: Link[] =
    [
      { name: "homeLink", acl: "Home:Access", link: "/app/home", matchingPaths: [], display: "Home", org: true },
      { name: "mapLink", acl: "Map:Access", link: "/app/map", matchingPaths: [], display: "Explorer" },
      { name: "dashboardLink", acl: "Map:Access", link: "/app/dashboard/all", matchingPaths: ["/app/dashboard/view"], display: "Dashboard" },
      { name: "managementLink", acl: "Search:Access", link: "/app/manage/all", matchingPaths: ["/app/manage/view"], display: "Search" },
      { name: "accessManagerLink", acl: "Manage:Access", link: "/app/admin/users", matchingPaths: [], display: "Manage Users" },
      { name: "accessManagerLink", acl: "Admin:Access", link: "/app/admin/orgs", matchingPaths: [], display: "Manage Orgs" },
    ]

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  active(link: Link): boolean {
    if (this.router.isActive(link.link, false))
      return true;

    for (let path of link.matchingPaths) {
      if (this.router.isActive(path, false))
        return true;
    }

    return false;

  }

}

export interface Link {
  name: string;
  acl: string;
  link: string;
  matchingPaths: string[];
  display: string;
  org?: boolean;
}
