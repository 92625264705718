<!-- Themes -->
<div *ngIf="mode =='themes'" fxFlex fxLayout="column" fxAlign="center center" fxLayoutGap="1.5rem"
  class="appCatContainer">
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div *ngFor="let category of categories" class="appExpCategory">
      <mat-expansion-panel #catpan hideToggle="true" [expanded]="category?.openness === 'open'? true : false">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            {{ category.name }}
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!catpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="catpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="layerListItemContainer" *ngIf="mode === 'themes'">
          <mat-slider style="width: 98%" [value]="category.opacity" [min]="0" [max]="1" [step]="0.01"
            (input)="adjustOpacity(category,$event)" *ngIf="category.multiphasic"></mat-slider>
          <mat-list>
            <mat-divider></mat-divider>
            <mat-radio-group>
              <mat-list-item *ngFor="let layer of category.layers" class="appLayerListItem" style="width:100%; height: 2.25rem;">
                <app-layer-list-item [mode]="mode" [layer]="layer" [selected]="category.selection.isSelected(layer.key)"
                  [multi]="category.selection.selection_type === 'polyselection'"
                  (selectionChange)="selectionChange(category,layer,$event)" class="fill"></app-layer-list-item>
              </mat-list-item>
            </mat-radio-group>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>

<!-- Legend -->
<div *ngIf="mode =='legend'" fxFlex fxLayout="column" fxAlign="center center" fxLayoutGap="1.5rem"
  class="appLegendAccordContainer">
  <div fxFlex="nogrow" fxLayout="column" fxAlign="center center" style="padding:0.5rem;" class="mat-elevation-z4"
    *ngIf="!hasAnySelectedLegendLayers()">
    <div fxFlex="nogrow" fxLayout="column" fxAlign="center center">
      <p style="text-align: center">Select layers from the Explorer tab to view the legend.</p>
    </div>
  </div>
  <mat-accordion #acord multi [style.overflow]="'auto'" [style.scroll-behavior]="'smooth'">
    <div *ngFor="let category of categories" class="appLegendCat">
      <mat-expansion-panel #catpan [hideToggle]="true" *ngIf="hasLegend(category)" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="appCatName">
            {{ category.name }}
          </mat-panel-title>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="!catpan.expanded">add_circle</mat-icon>
          </span>
          <span>
            <mat-icon class="appExpExpandIcon" *ngIf="catpan.expanded">remove_circle</mat-icon>
          </span>
        </mat-expansion-panel-header>
        <div class="layerListItemContainer" *ngIf="hasLegend(category)">
          <mat-list>
            <mat-accordion multi>
              <div *ngFor="let layer of category.layers">
                <app-layer-legend [layer]="layer" *ngIf="category.selection.isSelected(layer.key)"></app-layer-legend>
              </div>
            </mat-accordion>
          </mat-list>
        </div>

      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>

<!--Explorer-->
<div fxFlex fxLayout="column" fxAlign="center center" fxLayoutGap="1.5rem" *ngIf="mode ==='identify'">

  <app-layer-explorer-container [identifyResults]="this.identifyResults"
    (highlightFeature)="this.highlightFeature.next($event)" (unhighlightFeature)="this.unhighlightFeature.next($event)"
    (zoomToExtent)="this.zoomExtent($event)" (tempHighlightFeature)="this.tempHighlight($event)">
  </app-layer-explorer-container>

</div>
