import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MapLayer } from '../../../_shared/models/map/config.model';
import { ConfiguredIdentifySources, Result } from '../../../_shared/services/map/identify.service';

import { IdentifyLayer, LayerState } from '../../layer-identify-result'

import { MatExpansionPanel } from '@angular/material/expansion';
import { ApiService } from 'src/app/_shared/services/api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CoreExplorerLayers } from '../layer-explorer-container/layer-explorer.model';
import { ConfigurationService } from 'src/app/_shared/services/configuration.service';
import { MapService } from 'src/app/_shared/services/map/map.service';

declare var famMap: any;

@Component({
  selector: 'app-layer-explorer',
  templateUrl: './layer-explorer.component.html',
  styleUrls: ['./layer-explorer.component.scss']
})
export class LayerExplorerComponent implements OnInit {

  constructor(
    private api: ApiService,
    private router: Router,
    private identify: ConfiguredIdentifySources,
    private config: ConfigurationService,
    private mapService: MapService,
  ) { }

  @Input() result: Observable<any>;
  @Input() mapZoom?: number = 0;
  @Input() hasResults = false;
  @Input() hasCoreData = false;

  @Output() highlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() unhighlightFeature: EventEmitter<Result> = new EventEmitter();
  @Output() unhighlightCurrent: EventEmitter<void> = new EventEmitter();
  @Output() zoomToFeature: EventEmitter<Result> = new EventEmitter();
  @Output() tempHighlightFeature: EventEmitter<any> = new EventEmitter();
  @Output() zoomToExtent: EventEmitter<number[]> = new EventEmitter();
  @Output() newName: EventEmitter<string> = new EventEmitter();

  @ViewChildren('panel') public panel: QueryList<any>;
  @ViewChildren('result') public resultsEles: QueryList<any>;

  featureType: string;
  vm: any = {};
  activityVm: any = {};
  actVm: any[] = [];
  geogVm: any = {};
  projVm: any[];
  nonCoreVm: any[];
  fetching = {
    summary: false,
    geog: false,
    activities: false,
  };
  layerKeys = {
    project: CoreExplorerLayers.ProjectKey,
    activity: CoreExplorerLayers.ActivityKey,
    focal: CoreExplorerLayers.FocalKey,
    prioLand: CoreExplorerLayers.PrioLandKey,
    watershed: CoreExplorerLayers.WatershedKey,
  }

  ngOnInit(): void {
    this.result.subscribe(data => {
      if (!data.data) {
        this.reset();
        return;
      }

      const iid = data?.data.find(e => { return e.name === 'objectid' })?.value || null;
      if (iid) {
        if (data?.id === CoreExplorerLayers.ProjectKey) {
          this.featureType = CoreExplorerLayers.ProjectKey;
          this.processProject(data, iid);
        } else if (data?.id === CoreExplorerLayers.FocalKey) {
          this.featureType = CoreExplorerLayers.FocalKey;
          this.processFocal(data, iid);
        }
        else if (data?.id === CoreExplorerLayers.ActivityKey) {
          this.featureType = CoreExplorerLayers.ActivityKey;
          this.processActivity(data, iid);
        } else {
          this.featureType = null;
        }
      }
      if (data?.id === CoreExplorerLayers.NonCore) {
        this.featureType = CoreExplorerLayers.NonCore;
        this.processNonCore(data);
      }
    });
  }

  processNonCore(data: any) {
    let name;
    this.mapService.getMapConfig().subscribe(config => {
      config.forEach(e => {
        const found = e.layers.find(i => i.key === data?.key);
        if (found) {
          name = found.name;
        }
      });

      this.nonCoreVm = data?.data;
      this.newName.emit(name);
    });
  }

  processProject(data, iid) {
    this.fetching.summary = true;
    this.fetching.activities = true;
    this.fetching.geog = true;
    this.api.post<any>("Project", "Get", [parseInt(iid)]).subscribe(results => {
      if (results && results.length) {
        let target = results[0];
        this.fetching.summary = false;
        this.handleLoad(target);
      } else {
        this.handleLoad({});
      }
    });

    this.api.post<any>("Activity", "ReadAll", { linkToId: parseInt(iid) }).subscribe(results => {
      const filtered = results.filter(e => e?.isSpatial === true);
      this.actVm = filtered;
      this.fetching.activities = false;
      this.actVm.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    });

    this.api.post<any>("Project", "CheckProjectStats", [parseInt(iid)]).subscribe(results => {
      this.geogVm = results;
      this.fetching.geog = false;
      if (this.geogVm && this.geogVm?.fields?.stats?.data?.landscapes?.stats?.name && Object.keys(this.geogVm?.fields?.stats?.data?.landscapes?.stats?.name).length > 1 && Object.keys(this.geogVm?.fields?.stats?.data?.landscapes?.stats?.name).includes('Non-Priority')) {
        delete this.geogVm?.fields?.stats?.data?.landscapes?.stats?.name['Non-Priority'];
      }
      if (this.geogVm && this.geogVm?.fields?.stats?.data?.focal?.stats?.name && Object.keys(this.geogVm?.fields?.stats?.data?.focal?.stats?.name).length > 1 && Object.keys(this.geogVm?.fields?.stats?.data?.focal?.stats?.name).includes('Non-Focal Area')) {
        delete this.geogVm?.fields?.stats?.data?.focal?.stats?.name['Non-Focal Area'];
      }
    });
  }

  processActivity(data, iid) {
    this.fetching.summary = true;
    this.api.post<any>("Activity", "LongRead", parseInt(iid)).subscribe(results => {
      if (results && results.result) {
        let target = results.result;
        this.fetching.summary = false;
        this.activityVm = target;
        this.newName.emit(this.activityVm?.name || '');
      } else {
        this.activityVm = {};
        this.newName.emit(this.activityVm?.name || '');
      }
    });
  }

  linkToFocal(name: string) {
    this.api.post<any>('Focal', "GetByName", { name }).subscribe(results => {
      if (results && results.valid) {
        let target = results.result;
        this.linkTo('app/map', { id: target.id, type: 'f' });
      }
    });
  }

  processFocal(data, iid) {
    this.fetching.summary = true;
    this.fetching.activities = true;
    this.fetching.geog = true;
    this.api.post<any>("Focal", "Get", [parseInt(iid)]).subscribe(results => {
      if (results?.result) {
        let target = results.result;
        this.fetching.summary = false;

        this.handleLoad(target);

        const name = this.vm?.name;
        this.api.post<any>('Focal', 'GetProjectsBySpatial', { name }).subscribe(projResults => {
          if (projResults && projResults.valid) {
            this.fetching.activities = false;
            this.fetching.geog = false;
            this.projVm = projResults.result;
            this.projVm.sort((a, b) => {
              if (a.name < b.name) { return -1; }
              if (a.name > b.name) { return 1; }
              return 0;
            });
          }
        });
      } else {
        this.handleLoad({});
      }
    });
  }

  handleLoad(target) {
    this.vm = target;

    (this.newName.emit(this.vm?.name || ''));
  }

  public expand() {
    this.panel.toArray().forEach(element => {
      element.open();
    });
  }

  scrollToResult(id: string) {
    this.expand();
    setTimeout(() => {
      this.resultsEles.toArray().forEach(element => {
        console.log(element)
        if (element.nativeElement.id === (id + "")) {
          element.nativeElement.scrollIntoView();
        }
      })
    }, 500);
  }

  highlight(result: Result) {
    this.highlightFeature.next(result);
  }

  unhighlight(result: Result) {
    this.unhighlightFeature.next(result);
  }

  async requestFeatureExtent(featureKey, featureId) {
    const extent = await this.identify.requestFeatureExtent(featureKey, featureId);
    const featureGeom = await this.identify.requestFeatureGeom(featureKey, featureId);
    this.zoomExtent(extent);
    this.tempHighlight(featureGeom);
  }

  tempHighlight(geom: any) {
    this.tempHighlightFeature.next(geom);
  }

  zoomTo(result: Result) {
    this.zoomToFeature.next(result);
  }

  zoomExtent(result) {
    console.log('sent');
    this.zoomToExtent.next(result);
  }

  linkTo(url, queryParams) {
    this.unhighlightCurrent.emit();
    this.router.navigate([url], { queryParams })
  }

  isFetching(): boolean {
    return this.fetching.summary || this.fetching.activities || this.fetching.geog;
  }

  reset() {
    this.vm = {};
    this.actVm = [];
    this.geogVm = {};
    this.fetching = {
      summary: false,
      geog: false,
      activities: false,
    };
  }

  floor(n) {
    return Math.floor(n);
  }

  dateFormat(date: string) {
    if (!date) {
      return date = "";
    } else {
      return new Date(date)
    }
  }

  private getNonCoreArray(resultMap) {
    const notAllowed = [CoreExplorerLayers.ActivityKey, CoreExplorerLayers.FocalKey, CoreExplorerLayers.ProjectKey];
    const nonCoreObj = Object.keys(resultMap)
      .filter(key => !notAllowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = resultMap[key];
        return obj;
      }, {});
    const nonCore: any[] = Object.values(nonCoreObj);

    return nonCore;
  }
}
