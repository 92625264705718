import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Card } from '../models/card.model';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private dataSubject: ReplaySubject<Card[][]> = new ReplaySubject<Card[][]>(1);
    private dataSubject2: ReplaySubject<Card> = new ReplaySubject<Card>(1);

    //We need HTTP to call the backend to get the config components we need
    constructor(private api : ApiService) {
        //Previously we had a call to init here. 
        //This was removed due to having a partial mock in which we override the getConfig method
        //In testing cases we dont want to make calls to the api or even grab the config in most cases
        //If we left it in, the mock service would still need to call super and thus the init which is not what we want
        //Instead it has been moved to getConfig so that it can be overwritten
    }

    getCards() {
        setTimeout( () => {
        this.api.get<Card[][]>("dashboard", "GetCards").subscribe(res => {
            this.dataSubject.next(res);
          }, error => {
            // If we need more error handling we can add here
            console.error(`Error: ${error} Unable to load map config from external resource`);
          });
        }, 50);

        return this.dataSubject;
    }

    getCardById(id : number) {
        setTimeout( () => {
        this.api.post<Card>("dashboard", "GetCard", id).subscribe(res => {
            this.dataSubject2.next(res);
          }, error => {
            // If we need more error handling we can add here
            console.error(`Error: ${error} Unable to load map config from external resource`);
          });
        }, 50);

        return this.dataSubject2;

    }


}