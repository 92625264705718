<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <div *ngIf=editMode>
        <span>Edit Funding</span>
      </div>
      <div *ngIf=!editMode>
        <span>Add Funding</span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>

<!-- Dialog content -->
<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Funding Organization</mat-label>
        <mat-select name="fundingOrg" #fundingOrg [formControl]="fundingOrgFormControl" (selectionChange)="clearType()"required="true">
          <mat-option *ngFor="let org of fundingOrgs" [value]="org.name">
            {{org.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Funding Name</mat-label>
        <mat-select name="fundingName" #fundingName [formControl]="fundingNameFormControl" required="true">
          <mat-option *ngFor="let org of fundingOrgNames" [value]="org.name">
            {{org.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Planned Amount</mat-label>
        <input 
          name="planned" 
          #planned 
          matInput 
          [formControl]="plannedFormControl" 
          required="true" 
          type="number" 
          minMaxDirective 
          decimalCapDirective
          [decimalCap]="2"
          [min]="0"/>
      </mat-form-field>
      <mat-form-field fxFill appearance="outline">
        <mat-label>Actual Amount</mat-label>
        <input  
          name="actual" 
          #actual matInput 
          [formControl]="actualFormControl" 
          required="false" 
          type="number"  
          minMaxDirective 
          decimalCapDirective
          [decimalCap]="2"
          [min]="0"/>
      </mat-form-field>
      <div>
        <mat-checkbox value="1" [formControl]="matchFormControl" style="margin-bottom: 1.5em;">This funding counts as 'match'
        </mat-checkbox>
      </div>

    </div>

    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="cancelClicked()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()"
          target="_blank">Save</button>
      </div>
    </div>
  </div>
</div>

