import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfiguredIdentifySources } from 'src/app/_shared/services/map/identify.service';
import { CoreExplorerLayers } from './layer-explorer.model';

@Component({
  selector: 'app-layer-explorer-container',
  templateUrl: './layer-explorer-container.component.html',
  styleUrls: ['./layer-explorer-container.component.scss']
})
export class LayerExplorerContainerComponent implements OnInit, DoCheck, OnDestroy {

  @Input() public identifyResults: any = {
    hasResults: false,
    extentChanged: false,
    resultMap: {},
    extent: []
  };

  @Output() highlightFeature: EventEmitter<any> = new EventEmitter();
  @Output() tempHighlightFeature: EventEmitter<any> = new EventEmitter();
  @Output() unhighlightFeature: EventEmitter<any> = new EventEmitter();
  @Output() zoomToExtent: EventEmitter<number[]> = new EventEmitter();

  currentView: BehaviorSubject<any> = new BehaviorSubject<any>({});

  currentIdx: number = 0;
  gotLatest = false;
  currentName: string;
  currentFeatureType: string;

  routeListener: Subscription;

  constructor(
    private route: ActivatedRoute,
    private identify: ConfiguredIdentifySources,
  ) { }

  ngOnInit(): void {
    this.updateData();

    this.routeListener = this.identify.newIdentifyFromQuery.subscribe(_ => {
      this.gotLatest = false;
      this.updateData();
    });
  }

  ngOnDestroy() {
    this.routeListener.unsubscribe();
  }

  ngDoCheck(): void {
    this.updateData()
  }

  private updateData() {
    // Verify that the identify has results and that all expected reuslts are "Ready"
    // Additionally, keep track of gotLatest.  When gotLatest = true the current identify result has already been emitted
    // to the child component which invokes an API requrest and does not need to be done again
    if (
      this.identifyResults.hasResults &&
      (
        this.coreExistsAndIsReady(CoreExplorerLayers.ProjectKey) && 
        this.coreExistsAndIsReady(CoreExplorerLayers.FocalKey) && 
        this.coreExistsAndIsReady(CoreExplorerLayers.ActivityKey) && 
        this.nonCoreLayersReady()) &&
      (
        this.coreExistsAndHasResults(CoreExplorerLayers.ProjectKey) ||
        this.coreExistsAndHasResults(CoreExplorerLayers.FocalKey) ||
        this.coreExistsAndHasResults(CoreExplorerLayers.ActivityKey) ||
        this.nonCoreLayersHaveResult()
      ) &&
      this.gotLatest === false
    ) {
      this.updateCurrentView();
      this.gotLatest = true;
    } else if (
      (!this.identifyResults.hasResults ||
        this.coreExistsAndIsNotReady(CoreExplorerLayers.ProjectKey) || 
        this.coreExistsAndIsNotReady(CoreExplorerLayers.FocalKey) || 
        this.coreExistsAndIsNotReady(CoreExplorerLayers.ActivityKey) || 
        this.nonCoreLayersNotReady()
      ) &&
      (this.gotLatest === true)
    ) {
      this.gotLatest = false;
      this.currentIdx = 0;
      this.currentName = '';
    }
  }

  private coreExistsAndIsReady(key: string): boolean {
    if (
      this.identifyResults.resultMap?.[key] && 
      this.identifyResults.resultMap?.[key]?.status === 'Ready'
      ) {
        return true;
    } else if (!this.identifyResults.resultMap?.[key]) {
      return true;
    } else {
      return false;
    }
  }

  private coreExistsAndIsNotReady(key: string): boolean {
    if (
      this.identifyResults.resultMap?.[key] && 
      this.identifyResults.resultMap?.[key]?.status !== 'Ready'
      ) {
        return true;
    } else if (!this.identifyResults.resultMap?.[key]) {
      return false;
    } else {
      return false;
    }
  }

  private coreExistsAndHasResults(key: string): boolean {
    if (
      this.identifyResults.resultMap?.[key] && 
      this.identifyResults.resultMap?.[key]?.results?.length > 0
      ) {
        return true;
    }  else if (!this.identifyResults.resultMap?.[key]) {
      return false;
    } else {
      return false;
    }
  }

  getCurrentView(): Subject<any> {
    return this.currentView;
  }

  handleLoad(target): void {
    this.currentView = target;
  }

  getTotalCount(): number {
    if (!this.identifyResults.hasResults) return null;

    const projectLayers = this.identifyResults.resultMap?.[CoreExplorerLayers.ProjectKey]?.results || [];
    const focalLayers = this.identifyResults.resultMap?.[CoreExplorerLayers.FocalKey]?.results || [];
    const actLayers = this.identifyResults.resultMap?.[CoreExplorerLayers.ActivityKey]?.results || [];
    const nonCoreResults = this.flattenNonCore(this.getNonCoreArray());

    let viewLayers = focalLayers.concat(projectLayers);
    viewLayers = viewLayers.concat(actLayers);
    viewLayers = viewLayers.concat(nonCoreResults);
    return viewLayers.length;
  }

  pageRight(): void {
    if (this.currentIdx + 1 === this.getTotalCount()) return;

    this.unhighlight(this.getResultFromIdx());

    this.currentIdx = this.currentIdx + 1;

    this.updateCurrentView();
  }

  pageLeft(): void {
    if (this.currentIdx === 0) return;

    this.unhighlight(this.getResultFromIdx());

    this.currentIdx = this.currentIdx - 1;

    this.updateCurrentView();
  }

  canPageRight(): boolean {
    return (this.currentIdx + 1) < this.getTotalCount();
  }

  canPageLeft(): boolean {
    return this.currentIdx > 0;
  }

  setName(name) {
    this.currentName = name;
  }

  zoomExtent(result) {
    this.zoomToExtent.next(result);
  }

  private highlight(result: any) {
    this.highlightFeature.next(result);
  }

  private unhighlight(result: any) {
    this.unhighlightFeature.next(result);
  }

  public unhighlightCurrent() {
    this.unhighlight(this.getResultFromIdx());
  }

  private getResultFromIdx() {
    const projectResults = this.identifyResults.resultMap?.[CoreExplorerLayers.ProjectKey]?.results || [];
    const focalResults = this.identifyResults.resultMap?.[CoreExplorerLayers.FocalKey]?.results || [];
    const activityResults = this.identifyResults.resultMap?.[CoreExplorerLayers.ActivityKey]?.results || [];
    const nonCoreResults = this.flattenNonCore(this.getNonCoreArray());

    projectResults.forEach(e => {
      e.id = CoreExplorerLayers.ProjectKey;
    });
    projectResults.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    focalResults.forEach(e => {
      e.id = CoreExplorerLayers.FocalKey;
    });
    focalResults.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    activityResults.forEach(e => {
      e.id = CoreExplorerLayers.ActivityKey;
    });
    activityResults.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    nonCoreResults.forEach(e => {
      e.id = CoreExplorerLayers.NonCore;
    });
    let allResults = focalResults.concat(projectResults);
    allResults = allResults.concat(activityResults);
    allResults = allResults.concat(nonCoreResults);
    const outData = { ...allResults[this.currentIdx] };
    this.currentFeatureType = outData.id;
    return outData;
  }

  getFeatureType(withSeparator) {
    switch (this.currentFeatureType) {
      case CoreExplorerLayers.ProjectKey:
        return withSeparator ? 'Project: ' : 'Project';
      case CoreExplorerLayers.FocalKey:
        return withSeparator ? 'Focal Area: ' : 'Focal Area';
      case CoreExplorerLayers.ActivityKey:
        return withSeparator ? 'Activity: ' : 'Activity';
      default:
        return '';
    }
  }

  private updateCurrentView() {
    const data = this.getResultFromIdx();
    this.currentView.next(data);
    this.highlight(data);
  }

  private getNonCoreArray(unpackResults: boolean = false) {
    Object.keys(this.identifyResults.resultMap).forEach(key => {
      this.identifyResults.resultMap[key].key = key;
    });
    const notAllowed = [CoreExplorerLayers.ActivityKey, CoreExplorerLayers.FocalKey, CoreExplorerLayers.ProjectKey];
    const nonCoreObj = Object.keys(this.identifyResults.resultMap)
      .filter(key => !notAllowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.identifyResults.resultMap[key];
        return obj;
      }, {});
    const nonCore: any[] = Object.values(nonCoreObj);

    return nonCore;
  }

  private flattenNonCore(nonCoreRaw) {
    const nonCoreResults = [];
    nonCoreRaw.forEach(e => {
      const key = e.key;
      e.results.forEach(i => {
        i.key = key;
        nonCoreResults.push(i);
      });
    });

    return nonCoreResults;
  }

  private nonCoreLayersReady() {
    return this.getNonCoreArray().every(e => e?.status === 'Ready');
  }

  private nonCoreLayersNotReady() {
    return this.getNonCoreArray().some(e => e?.status !== 'Ready');
  }

  private nonCoreLayersHaveResult() {
    return this.getNonCoreArray().some(e => e?.results?.length > 0);
  }

  tempHighlight(geom) {
    this.tempHighlightFeature.next(geom);
  }

}
