import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-archive-error-dialog',
  templateUrl: './archive-error-dialog.component.html',
  styleUrls: ['./archive-error-dialog.component.scss']
})
export class ArchiveErrorDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ArchiveErrorDialogComponent>) { }

  ngOnInit(): void {
  }
}
