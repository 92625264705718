<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <div>
      <p>
        You cannot archive this Organization because one or more users are still associated to it. Please ensure no users are affiliated to this Organization and then try again.
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions style="width: 100%;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
    <button mat-flat-button color="warn" class="appSquareButton appConfirmButton appSkinnyFontButton" matDialogClose style="width: 12rem;">Close</button>
  </div>
</div>
