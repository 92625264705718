<div class="container" style="padding: 0.5rem 3rem;width: 100%; height: 100%;">
  <div fxFlex fxFill fxLayout="column" fxAlign="center center" fxLayoutGap="0.25rem" class="centerX">
    <p style="font-size:1.25rem; font-weight: bold;"></p>
    <div fxLayout="column"
      style="max-width: 60rem; overflow: auto; padding: 0 1rem; height: 100%; margin:0 auto!important;"
      fxAlign="center center" fxLayoutGap="0.75rem" class="centerX fullWidth">
      <div *ngFor="let row of cardGrid" fxFlex="nogrow" fxLayout="row" fxAlign="center center" fxLayoutGap="0.75rem"
        class="growhax">
        <div *ngFor="let card of row" class="dashboardCard mat-elevation-z4"
          [style.width]="row.length == 3 ? '14rem' : '100%'" [style.height]="row.length == 3 ? '13rem' : '8rem'">
          <a [routerLink]="card.id != undefined ? '../view' : './'" [queryParams]="{id:card.id}"
            style="position: relative; width: 100%; height: 100%; overflow: hidden; border-radius: 0.05rem; background: rgb(47, 72, 60); margin: 0 auto;">
            <img alt="Dashboard icon." [src]="card.bg"
              style="position: absolute; bottom:0%; left: 50%; transform: translate(-50%,-0%); width: 101%; height: auto; z-index: 0; opacity: 0.5;" />
            <div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 2; padding: 0.5rem;"
              class="outline-font" fxFlex fxLayout="column" fxLayoutGap="0.5rem">
              <div fxLayout="row" class="" fxLayoutGap="0.5rem">
                <strong style="font-size: 1rem;">{{card.title}}</strong>
                <div class="info-icon-container" [matTooltip]="card.description" *ngIf="card.description">
                  <img alt="Dashboard icon." src="/assets/images/info-icon.svg" class="info-icon" />
                </div>
              </div>
              <!-- TODO Something about calculating the size-->
              <div class="statBlock" fxLayout="column" *ngFor="let stat of card.stats"
                [style.width]="row.length == 3 ? '12rem' : '100%'">
                <p>{{stat.value}}</p>
                <p>{{stat.label}}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
