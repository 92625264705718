import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { element } from 'protractor';
import { DynamicFormComponent } from '../_shared/components/forms/dynamic-form/dynamic-form.component';
import { CoreEntity } from '../_shared/models/core-entity.model';
import { Fields } from '../_shared/models/dynamic-forms/fields.model';
import { AuthorizationService } from '../_shared/services/authorization.service';
import { FieldService } from '../_shared/services/forms/field.service';

@Component({
  selector: 'app-note-create-dialog',
  templateUrl: './note-create-dialog.component.html',
  styleUrls: ['./note-create-dialog.component.scss']
})
export class NoteCreateDialogComponent implements OnInit, AfterViewInit{

  fields: any[];
  fieldsConfig: any;
  isOpportunity: boolean;
  edit: boolean
  displayName: string;

  constructor(
    private dialogRef: MatDialogRef<NoteCreateDialogComponent>,
    private fieldService: FieldService,
    private auth: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild('note') noteInfo: DynamicFormComponent

  ngOnInit(): void {
    this.displayName = this.auth.login.getFullName();

    if(!this.data.hasOwnProperty('opportunity') && !this.data.hasOwnProperty('impediment')){
      this.isOpportunity = this.data.isOpportunity;
      this.isOpportunity === true ?
      this.fields = this.fieldService.getFields(CoreEntity.Opportunity) :
      this.fields = this.fieldService.getFields(CoreEntity.Impediment);
    } else if(this.data.hasOwnProperty('opportunity')){
      //do opportunity edit things
      this.edit = true;
      this.isOpportunity = true
      let opportunityToEdit =  this.data.opportunity
      console.log(opportunityToEdit);

      const opportunityFields: any = {};
      opportunityFields[Fields.ProjectOpportunity] = opportunityToEdit.note;
      this.fields = this.fieldService.getEditableFieldsGeneric(CoreEntity.Opportunity, opportunityFields);


    } else {
      this.edit = true;
      this.isOpportunity = false
      //do impediment edit things
      let impedimentToEdit = this.data.impediment
      console.log(impedimentToEdit);

      const impedimentFields: any = {};
      impedimentFields[Fields.ProjectImpediment] = impedimentToEdit.note;
      this.fields = this.fieldService.getEditableFieldsGeneric(CoreEntity.Impediment, impedimentFields);
    }
  }

  ngAfterViewInit(): void {
    this.noteInfo.refreshForm(this.fields);
  }

  isValid(): boolean {
    if (this.noteInfo && this.noteInfo.isValid) {
      return true;
    } else {
      return false;
    }
  }


  onSubmit(): void {
    let noteData = this.noteInfo.getPayload();

    let noteObject = { id: 0, isOpportunity: this.isOpportunity, displayName: this.displayName, note: "default"}
    if(noteData.hasOwnProperty('PROJECT_OPPORTUNITY')){
      noteObject =
      {
        "id": 0,
        "isOpportunity": this.isOpportunity,
        "displayName": this.displayName,
        "note": noteData.PROJECT_OPPORTUNITY
      }
      if(this.edit){
        noteObject.id = this.data.opportunity.id
      }
    } else {
      noteObject =
      {
        "id": 0,
        "isOpportunity": this.isOpportunity,
        "displayName": this.displayName,
        "note": noteData.PROJECT_IMPEDIMENT
      }
      if(this.edit){
        noteObject.id = this.data.impediment.id
      }
    }

    this.dialogRef.close(noteObject);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
