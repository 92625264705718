<!-- Header -->
<h4 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header appToolbar">
    <mat-toolbar-row class="appModalHeaderCenter">
      <div>
        <span>Update Organization</span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</h4>


<div mat-dialog-content style="align-items: center; text-align: center; width: 100%; margin:auto;">
  <div fxFlex fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center"
    style="margin:1.5rem 0; width: 100%;">
    <h3 class="appTagLine">{{this.email}}</h3>
    <div style="width: 100%;">
      <mat-form-field fxFill appearance="outline">
        <mat-select name="updateOrg" #org [formControl]="orgFormControl" [(ngModel)]="selectedOrg">
          <mat-option *ngFor="let org of orgs" [value]="org">
            {{org.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Confirm buttons -->
    <div mat-dialog-actions style="width: 100%; margin-bottom: 0.5rem;">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button mat-flat-button color="warn" class="appConfirmButton appSquareButton appSkinnyFontButton"
          style="margin-right: 1rem;" (click)="onNoClick()">Cancel</button>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!isValid()" mat-flat-button color="accent"
          class="appConfirmButton appSquareButton appSkinnyFontButton" (click)="onSubmit()" target="_blank">Update</button>
      </div>
    </div>
  </div>
</div>

