<div fxFlex="100" fxFill fxLayout="row" fxLayoutGap="0.65rem" fxLayoutAlign="start start"
  class="mat-elevation-z0 appFullWidth appRowContainer" style="overflow: hidden;">
  <div fxFlex="100" fxFill fxLayout="column" class="appCardContainer appFullWidth appBottomRounded"
    fxLayoutGap="0.5rem">
    <mat-card class="appFullWidth appFullHeight appSolidCard mat-elevation-z0" fxFlex="100">
      <mat-card-header class="appTrashCanHeaderBuffer">
        <mat-card-title class="appH3SecondaryHeader">{{activity.name}}
        </mat-card-title>
        <div class="appTrashCan" [appRequireACLAndSameOrg]="['Project:Edit', projectLeadOrg]">
          <mat-icon (click)="removeActivity($event)">delete</mat-icon>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div>
          <p><span class="appFieldLabel">Activity Type:</span><span class="appFieldContent">
              {{activity.activityType}}</span></p>
          <p><span class="appFieldLabel">Primary Activity Focus:</span><span class="appFieldContent">
              {{activity.primaryFocus}}</span></p>
          <p><span class="appFieldLabel">Status:</span><span class="appFieldContent"> {{activity.activityStatus}}</span>
          </p>
          <p><span class="appFieldLabel">Unit/Block:</span><span class="appFieldContent">
              {{activity.activityUnit || 'N/A'}}</span></p>
          <div>
            <div class="appClickable" style="position: absolute; right: 1rem; bottom: .25rem">
              <p class="appViewColor" (click)="viewSummary(activity.id)"><strong>View Details</strong></p>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
